const PaymentPaypalRedirect = ({ classname }) => (
  <div className={classname}>
    <div className="loading paypal-redirecting" />
    <hr />
    <h1>Redirecting to Paypal</h1>
    <p>Please wait</p>
  </div>
)

const PaymentPaypalLoading = ({ classname }) => (
  <div className={classname}>
    <div className="loading" />
    <hr />
    <h1>Payment in progress</h1>
    <p>Please wait</p>
  </div>
)

const PaymentPaypalError = ({ classname }) => {
  const style = {
    st0: { fill: "#E61E79" }
  }

  return (
    <div className={classname}>
      <svg version="1.1" viewBox="0 0 153 153" x="0px" y="0px">
        <path
          d="M76.5,153C34.3,153,0,118.7,0,76.5S34.3,0,76.5,0C118.7,0,153,34.3,153,76.5S118.7,153,76.5,153
          z M76.5,3C36,3,3,36,3,76.5S36,150,76.5,150c40.5,0,73.5-33,73.5-73.5S117,3,76.5,3z"
          fill={style.st0.fill}
          id="XMLID_17_"
        />
        <path
          d="M53.4,101.1c-0.4,0-0.8-0.1-1.1-0.4c-0.6-0.6-0.6-1.5,0-2.1l46.2-46.2c0.6-0.6,1.5-0.6,2.1,0
          c0.6,0.6,0.6,1.5,0,2.1l-46.2,46.2C54.2,101,53.8,101.1,53.4,101.1z"
          fill={style.st0.fill}
          id="XMLID_16_"
        />
        <path
          d="M99.6,101.1c-0.4,0-0.8-0.1-1.1-0.4L52.3,54.5c-0.6-0.6-0.6-1.5,0-2.1c0.6-0.6,1.5-0.6,2.1,0
          l46.2,46.2c0.6,0.6,0.6,1.5,0,2.1C100.4,101,100,101.1,99.6,101.1z"
          fill={style.st0.fill}
          id="XMLID_15_"
        />
      </svg>
      <hr className="payment_error_line" />
      <h1>Sorry, your payment failed</h1>
      <p>No charges were made</p>
    </div>
  )
}

const PaymentPaypalSuccess = ({ classname }) => {
  const style = {
    st0: { fill: "#75BF45" }
  }

  return (
    <div className={classname}>
      <svg version="1.1" viewBox="0 0 153 153" x="0px" y="0px">
        <path
          d="M76.5,153C34.3,153,0,118.7,0,76.5S34.3,0,76.5,0C118.7,0,153,34.3,153,76.5S118.7,153,76.5,153
        z M76.5,3C36,3,3,36,3,76.5S36,150,76.5,150c40.5,0,73.5-33,73.5-73.5S117,3,76.5,3z"
          fill={style.st0.fill}
          id="XMLID_16_"
        />
        <path
          d="M67,103.5c-0.4,0-0.8-0.2-1.1-0.5L45.8,82.1c-0.6-0.6-0.6-1.5,0-2.1c0.6-0.6,1.5-0.6,2.1,0
        l18.9,19.7l38-44.9c0.5-0.6,1.5-0.7,2.1-0.2c0.6,0.5,0.7,1.5,0.2,2.1l-39.1,46.2C67.9,103.2,67.5,103.4,67,103.5
        C67,103.5,67,103.5,67,103.5z"
          fill={style.st0.fill}
          id="XMLID_15_"
        />
      </svg>
      <hr className="payment_success_line" />
      <h1>Payment successful!</h1>
      <p>You subscription is now active</p>
    </div>
  )
}

const PaymentFinish = ({ stage }) => {
  switch (stage) {
    case "loading":
      return <PaymentPaypalLoading classname={"element-appear"} />
    case "redirect":
      return <PaymentPaypalRedirect classname={"element-appear"} />
    case "success":
      return <PaymentPaypalSuccess classname={"element-appear"} />
    case "error":
      return <PaymentPaypalError classname={"element-appear"} />
    default:
      return null
  }
}

export default PaymentFinish
