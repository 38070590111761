import { PropsWithChildren } from "react"

interface ModalProps {
  isOpened: boolean
  className?: string
  portalClassName?: string
  overlayClassName?: string
  overlayStyle?: Record<string, string | number | Record<any, any>>
  contentStyle?: Record<string, string | number | Record<any, any>>
}

export const Modal = ({
  children,
  isOpened,
  className = "",
  portalClassName = "",
  overlayClassName = "",
  overlayStyle,
  contentStyle
}: PropsWithChildren<ModalProps>) =>
  isOpened ? (
    <div className={portalClassName}>
      <div className={`ReactModal__Overlay ReactModal__Overlay--after-open ${overlayClassName}`} style={overlayStyle}>
        <div
          className={`ReactModal__Content ReactModal__Content--after-open fixed top-0 left-0 z-50 w-full overflow-hidden outline-0 ${className}`}
          style={contentStyle}
        >
          {children}
        </div>
      </div>
    </div>
  ) : null
