export const NotActiveAccountSvg = () => (
  <svg version="1.1" viewBox="0 0 253.9 190" x="0px" y="0px">
    <g>
      <path
        d="M230.1,190H247c3.8,0,6.9-3.1,6.9-6.9s-3.1-6.9-6.9-6.9h-30.7c-3.8,0-6.9-3.1-6.9-6.9s3.1-6.9,6.9-6.9h16.8
                c3.8,0,6.9-3.1,6.9-6.9c0-3.8-3.1-6.9-6.9-6.9h-33.6c-3.8,0-6.9-3.1-6.9-6.9c0-3.8,3.1-6.9,6.9-6.9h13.3c3.8,0,6.9-3.1,6.9-6.9
                s-3.1-6.9-6.9-6.9h-57.6c-3.8,0-6.9,3.1-6.9,6.9s3.1,6.9,6.9,6.9s6.9,3.1,6.9,6.9c0,3.8-3.1,6.9-6.9,6.9h-26
                c-3.8,0-6.9,3.1-6.9,6.9c0,3.8,3.1,6.9,6.9,6.9h30.4c3.8,0,6.9,3.1,6.9,6.9s-3.1,6.9-6.9,6.9h-11.4c-3.8,0-6.9,3.1-6.9,6.9
                s3.1,6.9,6.9,6.9h37.3C189.4,190,226.3,190,230.1,190z"
        fill={"#829FC0"}
        opacity={"0.1"}
      />
      <path
        d="M107.8,93.3h16.8c3.8,0,6.9-3.1,6.9-6.9c0-3.8-3.1-6.9-6.9-6.9H93.9c-3.8,0-6.9-3.1-6.9-6.9s3.1-6.9,6.9-6.9
                h16.8c3.8,0,6.9-3.1,6.9-6.9s-3.1-6.9-6.9-6.9H77.2c-3.8,0-6.9-3.1-6.9-6.9s3.1-6.9,6.9-6.9h13.3c3.8,0,6.9-3.1,6.9-6.9
                s-3.1-6.9-6.9-6.9H32.9c-3.8,0-6.9,3.1-6.9,6.9s3.1,6.9,6.9,6.9s6.9,3.1,6.9,6.9s-3.1,6.9-6.9,6.9h-26c-3.8,0-6.9,3.1-6.9,6.9
                s3.1,6.9,6.9,6.9h30.4c3.8,0,6.9,3.1,6.9,6.9s-3.1,6.9-6.9,6.9H26c-3.8,0-6.9,3.1-6.9,6.9c0,3.8,3.1,6.9,6.9,6.9h37.3
                C67.1,93.3,104,93.3,107.8,93.3z"
        fill={"#829FC0"}
        opacity={"0.1"}
      />
      <g>
        <path
          d="M174.2,184.7H76.5c-5,0-9-4-9-9V89.6c0-5,4-9,9-9h97.7c5,0,9,4,9,9v86.1
                    C183.2,180.7,179.1,184.7,174.2,184.7z"
          fill={"#FFFFFF"}
        />
        <path
          d="M174.2,185.7H76.5c-5.5,0-10-4.5-10-10V89.6c0-5.5,4.5-10,10-10h97.7c5.5,0,10,4.5,10,10v86.1
                    C184.2,181.2,179.7,185.7,174.2,185.7z M76.5,81.7c-4.4,0-8,3.6-8,8v86.1c0,4.4,3.6,8,8,8h97.7c4.4,0,8-3.6,8-8V89.6
                    c0-4.4-3.6-8-8-8H76.5z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <rect fill={"#E8EEF3"} height="63.7" width="115.7" x="67.5" y="100.8" />
        <path d="M184.2,165.5H66.5V99.8h117.7V165.5z M68.5,163.5h113.7v-61.7H68.5V163.5z" fill={"#829FC0"} />
      </g>
      <g>
        <path
          d="M133.2,128.4c0-4.3-3.5-7.8-7.8-7.8c-4.3,0-7.8,3.5-7.8,7.8c0,3.5,2.3,6.4,5.4,7.4v6.5
                    c0,1.4,1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5v-6.5C130.9,134.8,133.2,131.9,133.2,128.4z"
          fill={"#C8D7E3"}
        />
        <path
          d="M125.3,145.8c-1.9,0-3.5-1.6-3.5-3.5v-5.8c-3.2-1.4-5.4-4.6-5.4-8.1c0-4.9,4-8.8,8.8-8.8
                    s8.8,4,8.8,8.8c0,3.6-2.1,6.7-5.4,8.1v5.8C128.8,144.2,127.2,145.8,125.3,145.8z M125.3,121.6c-3.8,0-6.8,3.1-6.8,6.8
                    c0,2.9,1.9,5.6,4.7,6.5l0.7,0.2v7.2c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-7.2l0.7-0.2c2.8-0.9,4.7-3.5,4.7-6.5
                    C132.2,124.6,129.1,121.6,125.3,121.6z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <path
          d="M173.7,80.7h-11.7V49.4c0-20.3-16.5-36.8-36.8-36.8c-20.3,0-36.8,16.5-36.8,36.8v31.2H76.9
                    V49.4C76.9,22.7,98.6,1,125.3,1c26.7,0,48.4,21.7,48.4,48.4V80.7z"
          fill={"#C8D7E3"}
        />
        <path
          d="M174.7,81.7h-13.7V49.4c0-19.7-16-35.8-35.8-35.8s-35.8,16-35.8,35.8v32.2H75.9V49.4
                    C75.9,22.2,98.1,0,125.3,0c27.2,0,49.4,22.2,49.4,49.4V81.7z M163.1,79.7h9.7V49.4c0-26.1-21.3-47.4-47.4-47.4
                    S77.9,23.3,77.9,49.4v30.2h9.7V49.4c0-20.8,16.9-37.8,37.8-37.8c20.8,0,37.8,16.9,37.8,37.8V79.7z"
          fill={"#829FC0"}
        />
      </g>
      <g opacity={"0.2"}>
        <path
          d="M174.7,80.7h-0.4V49.4c0-26.7-21.7-48.4-48.4-48.4c-1.6,0-3.2,0.1-4.8,0.2c24.5,2.4,43.6,23.1,43.6,48.2v31.2
                    h0.4c5,0,9,4,9,9v86.1c0,5-4,9-9,9h9.6c5,0,9-4,9-9V89.6C183.7,84.7,179.7,80.7,174.7,80.7z"
          fill={"#829FC0"}
        />
      </g>
      <g opacity={"0.3"}>
        <path
          d="M78.5,142.3h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S79,142.3,78.5,142.3z
                    M72.5,142.3h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S73,142.3,72.5,142.3z M66.5,142.3h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2
                    c0.6,0,1,0.4,1,1S67,142.3,66.5,142.3z M60.5,142.3h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S61,142.3,60.5,142.3z"
          fill={"#829FC0"}
        />
        <path
          d="M55.1,142.3H38.7c-0.6,0-1-0.4-1-1s0.4-1,1-1h16.4c0.6,0,1,0.4,1,1S55.7,142.3,55.1,142.3z"
          fill={"#829FC0"}
        />
        <path
          d="M200.9,44.6h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S201.5,44.6,200.9,44.6z
                    M194.9,44.6h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S195.5,44.6,194.9,44.6z M188.9,44.6h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1
                    h2c0.6,0,1,0.4,1,1S189.5,44.6,188.9,44.6z M182.9,44.6h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S183.5,44.6,182.9,44.6z"
          fill={"#829FC0"}
        />
        <path
          d="M221,44.6h-16.7c-0.6,0-1-0.4-1-1s0.4-1,1-1H221c0.6,0,1,0.4,1,1S221.5,44.6,221,44.6z"
          fill={"#829FC0"}
        />
        <path d="M87,149.3H63.5c-0.6,0-1-0.4-1-1s0.4-1,1-1H87c0.6,0,1,0.4,1,1S87.5,149.3,87,149.3z" fill={"#829FC0"} />
        <path
          d="M212.6,52.1h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S213.2,52.1,212.6,52.1z
                    M206.6,52.1h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S207.2,52.1,206.6,52.1z M200.6,52.1h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1
                    h2c0.6,0,1,0.4,1,1S201.2,52.1,200.6,52.1z M194.6,52.1h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S195.2,52.1,194.6,52.1z"
          fill={"#829FC0"}
        />
        <path
          d="M80.6,52.1h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S81.2,52.1,80.6,52.1z M74.6,52.1
                    h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S75.2,52.1,74.6,52.1z M68.6,52.1h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2
                    c0.6,0,1,0.4,1,1S69.2,52.1,68.6,52.1z M62.6,52.1h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S63.2,52.1,62.6,52.1z
                    M56.6,52.1h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S57.2,52.1,56.6,52.1z M50.6,52.1h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2
                    c0.6,0,1,0.4,1,1S51.2,52.1,50.6,52.1z"
          fill={"#829FC0"}
        />
      </g>
    </g>
    <path
      d="M207.6,86c-2.4,0-4.3-1.9-4.3-4.3s1.9-4.3,4.3-4.3s4.3,1.9,4.3,4.3S210,86,207.6,86z
            M207.6,79.4c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3s2.3-1,2.3-2.3S208.9,79.4,207.6,79.4z"
      fill={"#829FC0"}
      opacity={"0.3"}
    />
    <path
      d="M28.2,118c-2.4,0-4.3-1.9-4.3-4.3s1.9-4.3,4.3-4.3s4.3,1.9,4.3,4.3S30.5,118,28.2,118z
            M28.2,111.4c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3s2.3-1,2.3-2.3S29.4,111.4,28.2,111.4z"
      fill={"#829FC0"}
      opacity={"0.3"}
    />
    <path
      d="M66.8,9.6c-2.4,0-4.3-1.9-4.3-4.3S64.4,1,66.8,1s4.3,1.9,4.3,4.3S69.2,9.6,66.8,9.6z M66.8,3
            c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3s2.3-1,2.3-2.3S68.1,3,66.8,3z"
      fill={"#829FC0"}
      opacity={"0.3"}
    />
    <path
      d="M199.8,163.9c-2.4,0-4.3-1.9-4.3-4.3s1.9-4.3,4.3-4.3s4.3,1.9,4.3,4.3S202.2,163.9,199.8,163.9z
            M199.8,157.3c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3s2.3-1,2.3-2.3S201.1,157.3,199.8,157.3z"
      fill={"#829FC0"}
      opacity={"0.3"}
    />
  </svg>
)
