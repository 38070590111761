import { convertSecondsToTime } from "../../utils/dates"
import { Tab, Tabs } from "./BatchShareTokenItems/TabsPanel"
import { PlayIcon, TimeIcon } from "../../../svg/SvgIcons"
import Slides from "./BatchShareTokenItems/Slides"
import HeatMap from "./BatchShareTokenItems/HeatMap"
import LogEntry from "./LogEntry"

const BatchOpenedByShareTokenLog = ({ record, index, activeAccordionId, setActiveAccordionId }) => {
  const recordTime = new Date(record.lastUsedAt || record.createdAt).getTime()
  const isOpened = activeAccordionId === index
  const isSlides = record.duration > 0

  let location
  if (!record.viewerLocation) {
    location = "unknown location"
  } else {
    location = [record.viewerLocation.city, record.viewerLocation.region, record.viewerLocation.country]
      .filter((item) => !!item)
      .join(", ")
  }

  const countSeenSlides = (slideLogs) => {
    const uniqueSlideIds = new Set()

    slideLogs.forEach((log) => {
      const slideId = log.slide.id
      uniqueSlideIds.add(slideId)
    })

    return uniqueSlideIds.size
  }

  const headerContent = (
    <>
      <div className="w-[95px] h-auto max-w-full">
        {record?.presentation?.thumbUrl && (
          <div className={"image-container bg-[#f6f7f8] w-[95px] h-[54px] shadow-[0_0_12px_1px_rgba(122,127,135,0.2)]"}>
            <picture>
              <img
                alt="thumbnail"
                className="w-full h-auto border border-[#EEE] border-solid"
                src={record.presentation.thumbUrl.replace("{width}", "95")}
                srcSet={`${record.presentation.thumbUrl.replace("{width}", "190")} 2x`}
              />
            </picture>
          </div>
        )}
      </div>
      <div className="flex flex-col flex-grow overflow-hidden">
        <p className="text-[15px] mb-2.5 overflow-ellipsis overflow-hidden text-left" data-testid="activity-log-entry">
          <span>
            <strong>{record?.presentation?.name}</strong> viewed from <strong>{location}</strong>
          </span>
        </p>
        <div className="flex gap-2 text-xs text-[#979797] flex-wrap">
          <div className="flex items-center">
            <PlayIcon />
            <span className="ml-2">
              {countSeenSlides(record.log)} of {record.presentation.slides.length} slides seen
            </span>
          </div>
          <div className="flex items-center">
            <TimeIcon />
            <span className="ml-2">Viewing time: {convertSecondsToTime(record.duration)}</span>
          </div>
        </div>
      </div>
    </>
  )

  const detailsContent = (
    <>
      <Tabs>
        <Tab label="Slides">
          <Slides isOpened={isOpened} logs={record.log} slides={record.presentation.slides} />
        </Tab>
        <Tab label="Heatmap">
          <HeatMap isOpened={isOpened} logs={record.log} slides={record.presentation.slides} />
        </Tab>
      </Tabs>
    </>
  )

  return (
    <LogEntry
      activeAccordionId={activeAccordionId}
      detailsContent={isSlides ? detailsContent : null}
      headerContent={headerContent}
      iconName="log-eye"
      index={index}
      isMoreContentAvailable={isSlides}
      recordTime={recordTime}
      setActiveAccordionId={setActiveAccordionId}
    />
  )
}

export default BatchOpenedByShareTokenLog
