import { useState } from "react"

const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(children[0].props.label)

  const handleClick = (label) => {
    setActiveTab(label)
  }

  return (
    <div className="w-full p-9 bg-[#F9F9FA] border">
      <div className="flex items-center gap-6">
        {children.map((child) => {
          const { label } = child.props

          return (
            <button
              className={` ${
                activeTab === label
                  ? "font-semibold text-xs text-[#548EF9] pb-2 border-b border-[#548EF9]"
                  : "pb-2 text-xs text-[#979797]"
              }`}
              key={label}
              onClick={() => handleClick(label)}
            >
              {label}
            </button>
          )
        })}
      </div>
      {children.map((child, index) => {
        if (child.props.label !== activeTab) return undefined
        return (
          <div className="transition-opacity duration-150 ease-linear data-[te-tab-active]:block" key={index}>
            {child.props.children}
          </div>
        )
      })}
    </div>
  )
}

const Tab = (props) => {
  return <>{props.children}</>
}

export { Tabs, Tab }
