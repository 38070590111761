import { useLazyQuery, useMutation } from "@apollo/client"
import { useContext, useEffect, useRef, useState } from "react"
import Payment from "payment"
import { CardType } from "../../svg/paymentIcons"
import { createStripeSubscription, updateStripeSubscription } from "../../graphql/mutations"
import { UserData } from "../../graphql/queries"
import { PricingModalContext } from "../../hooks/PricingModalHook"
import { UserDataContext } from "../../hooks/UserDataHook"

const getStripeToken = (card) =>
  new Promise((resolve, reject) => {
    window.Stripe.card.createToken(card, (status, { error, id }) => {
      if (error) {
        reject(error.message)
      } else {
        resolve(id)
      }
    })
  })

function loadStripeScript(callback) {
  const existingScript = document.getElementById("stripe-js")

  if (!existingScript) {
    const script = document.createElement("script")
    script.src = "https://js.stripe.com/v2/"
    script.id = "stripe-js"
    document.head.appendChild(script)

    script.onload = () => {
      if (callback) callback()
    }
  } else {
    if (callback) callback()
  }
}

const PaymentMethodComponent = ({ type }) => {
  const [number, setNumber] = useState("")
  const [cvc, setCvc] = useState("")
  const [expiration, setExpiration] = useState("")
  const [cardType, setCardType] = useState(null)
  const { user } = useContext(UserDataContext)

  const [getUserData] = useLazyQuery(UserData)

  const [_createStripeSubscription] = useMutation(createStripeSubscription)
  const [_updateStripeSubscription] = useMutation(updateStripeSubscription)
  // const [_createPaypalSubscription] = useMutation(createPaypalSubscription)

  const _number = useRef()
  const _expiration = useRef()
  const _cvc = useRef()

  const {
    // openModal,
    modalOpened,
    selectedPlan,
    closeModal,
    setPaymentStage,
    stripeStage,
    setStripeStage,
    stripeError,
    setStripeError
  } = useContext(PricingModalContext)

  useEffect(() => {
    loadStripeScript(() => {
      if (window.Stripe) {
        window.Stripe.setPublishableKey(import.meta.env.VITE_STRIPE_PUBLIC_KEY)
      }
    })

    return () => {
      setPaymentStage("pay")
    }
  }, [setPaymentStage])

  useEffect(() => {
    if (_number && _expiration && _cvc) {
      Payment.formatCardNumber(_number.current)
      Payment.formatCardExpiry(_expiration.current)
      Payment.formatCardCVC(_cvc.current)
    }
  }, [_number, _expiration, _cvc])

  const changeCardType = (e) => {
    const thisCardType = Payment.fns.cardType(e.target.value)
    setCardType(thisCardType)
  }

  const resetCard = () => {
    setNumber("")
    setCvc("")
    setExpiration("")
    setCardType(null)
    setStripeError({ numberFormat: null, expirationFormat: null, cvcFormat: null })
  }

  const handleInput = () => {
    setNumber(_number.current.value)
    setCvc(_cvc.current.value)
    setExpiration(_expiration.current.value)
  }

  const onFocusErrorRest = () => {
    setStripeError({ numberFormat: null, expirationFormat: null, cvcFormat: null })
  }

  const validateClientData = () => {
    const numberFormat = Payment.fns.validateCardNumber(_number.current.value) ? "is-valid" : "is-invalid"
    const expirationFormat = Payment.fns.validateCardExpiry(_expiration.current.value) ? "is-valid" : "is-invalid"
    const cvcFormat = Payment.fns.validateCardCVC(_cvc.current.value) ? "is-valid" : "is-invalid"
    setStripeError({ numberFormat, expirationFormat, cvcFormat })
    return numberFormat === "is-valid" && expirationFormat === "is-valid" && cvcFormat === "is-valid"
  }

  const handleSubmitCard = (e) => {
    e.preventDefault()
    if (type === "payment") {
      window.fbq && window.fbq("track", "InitiateCheckout")
    }
    const valid = validateClientData()

    if (valid) {
      e.preventDefault()
      setStripeStage("pending")

      const expMonth = parseInt(expiration.split("/")[0], 10)
      const expYear = parseInt(expiration.split("/")[1], 10)
      const card = { number, expMonth, expYear, cvc }

      getStripeToken(card)
        .then((token) => {
          if (type === "payment") {
            card.creditCardToken = token
            card.planId = selectedPlan.planId
            card.planAccessToken = selectedPlan.accessToken
            return card
          }
          return token
        })
        .then((_card) =>
          type === "payment"
            ? _createStripeSubscription({ variables: { ..._card } })
            : _updateStripeSubscription({
                variables: {
                  subscriptionId: user.subscription.subscriptionId,
                  creditCardToken: _card
                }
              })
        )
        .then(() => {
          resetCard()
          if (modalOpened) {
            setStripeStage("success")
          } else {
            setStripeStage("pay")
          }

          window.fbq &&
            window.fbq("track", "Purchase", {
              value: (selectedPlan.amount / 100).toString(),
              currency: "USD"
            })
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({ event: "scSuccessfulSubscription", payment: { method: "stripe" } })

          getUserData({
            query: UserData,
            fetchPolicy: "network-only"
          })

          return new Promise((resolve) => {
            setTimeout(resolve, 2500)
          })
        })
        .then(() => {
          closeModal()
        })
        .catch((error) => {
          resetCard()
          switch (error) {
            case "Your card number is incorrect.":
              setStripeStage("pay")
              setStripeError((prevState) => ({ ...prevState, numberFormat: "is-valid" }))
              break
            case "Your card's expiration year is invalid.":
              setStripeStage("pay")
              setStripeError((prevState) => ({ ...prevState, expirationFormat: "is-valid" }))
              break
            case "Your card's expiration month is invalid.":
              setStripeStage("pay")
              setStripeError((prevState) => ({ ...prevState, expirationFormat: "is-valid" }))
              break
            default:
              setStripeStage("pay")
              setStripeError({
                expirationFormat: "is-valid",
                numberFormat: "is-valid",
                cvcFormat: "is-valid"
              })
              console.error(error)
          }
        })
    }
  }

  const getBorderColorClass = (field) => {
    switch (stripeError[field]) {
      case "is-invalid":
        return "border-red-500"
      case "is-valid":
        return "border-[#198754]"
      default:
        return null
    }
  }

  const getValidationIcon = (field) => {
    switch (stripeError[field]) {
      case "is-valid":
        return (
          <i className="fas fa-check-circle text-[#198754] absolute right-[10px] top-1/2 transform -translate-y-1/2"></i>
        )
      case "is-invalid":
        return (
          <i className="fas fa-exclamation-circle text-red-500 absolute right-[10px] top-1/2 transform -translate-y-1/2"></i>
        )
      default:
        return null
    }
  }

  // const handleSubmitPayPal = (e) => {
  //   e.preventDefault()
  //   window.fbq && window.fbq("track", "InitiateCheckout")

  //   const card = {
  //     planId: selectedPlan.planId,
  //     planAccessToken: selectedPlan.accessToken
  //   }

  //   openModal("payresponse")
  //   setPaymentStage("redirect")

  //   _createPaypalSubscription({ variables: { ...card } })
  //     .then(({ data }) => {
  //       window.location = data.session.createPaypalSubscription
  //     })
  //     .catch(() => {
  //       setPaymentStage("error")
  //     })
  // }

  return (
    <div className="CreditCard">
      <div className="pricing-checkout-body">
        <div>
          <div className="checkout-plan">
            <label>{type === "payment" ? "Payment Method" : "Credit Card Update"}</label>
            <div className="credit-card mt-[10px]">
              <button className="card-method btn btn-primary">
                <svg version="1.1" viewBox="0 0 31.9 25.1" x="0px" y="0px">
                  <path
                    className="card-fill"
                    d="M9.1,8.1h14.2V1.2C23.3,0.6,22.7,0,22,0H1.2C0.6,0,0,0.6,0,1.2v12.5C0,14.4,0.6,15,1.2,15h5.3
                  v-4.4C6.6,9.2,7.7,8.1,9.1,8.1z M2.7,4V3.4c0-0.5,0.4-0.8,0.8-0.8h3.8c0.5,0,0.8,0.4,0.8,0.8V4c0,0.5-0.4,0.8-0.8,0.8H3.6
                  C3.1,4.8,2.7,4.5,2.7,4z M30.2,9.3H9.5c-0.9,0-1.7,0.7-1.7,1.7v12.5c0,0.9,0.7,1.7,1.7,1.7h20.8c0.9,0,1.7-0.7,1.7-1.7V11
                  C31.9,10.1,31.2,9.3,30.2,9.3z M8.6,11c0-0.5,0.4-0.8,0.8-0.8h20.8c0.5,0,0.8,0.4,0.8,0.8v2.1H8.6V11z M31.1,23.5
                  c0,0.5-0.4,0.8-0.8,0.8H9.5c-0.5,0-0.8-0.4-0.8-0.8v-8.1h22.4V23.5z M18.1,17.9c0,0.2-0.2,0.4-0.4,0.4H11c-0.2,0-0.4-0.2-0.4-0.4
                  s0.2-0.4,0.4-0.4h6.7C18,17.5,18.1,17.6,18.1,17.9z"
                  />
                </svg>
              </button>
              {/* {type === "payment" && (
                <Button className="paypal-method" onClick={handleSubmitPayPal}>
                  <PaypalNew />
                </Button>
              )} */}
            </div>
          </div>
          <div className="checkout-card">
            <div className="checkout-card-inputs">
              {/* <div className="checkout-card-number" validationState={stripeError.numberFormat}> */}
              <div className="checkout-card-number flex flex-col">
                {/* <p>BU: {stripeError.numberFormat}</p> */}
                <label>Payment Details</label>
                <div className="relative">
                  <input
                    className={`form-control border w-full ${getBorderColorClass("numberFormat")}`}
                    onChange={handleInput}
                    onFocus={onFocusErrorRest}
                    onKeyUp={changeCardType}
                    placeholder="Card Number"
                    ref={_number}
                    type="text"
                  />
                  {getValidationIcon("numberFormat")}
                </div>
                {/* <FormControl
                  className={`form-control ${stripeError.numberFormat}`}
                  onChange={handleInput}
                  onFocus={onFocusErrorRest}
                  onKeyUp={changeCardType}
                  placeholder="Card Number"
                  ref={_number}
                  type="text"
                /> */}
                <div className="checkout-card-list">
                  <ul>
                    <CardType cardType={cardType} />
                  </ul>
                </div>
              </div>
            </div>
            <div className="checkout-card-inputs form-group flex gap-[12px]">
              {/* <div className="checkout-card-exp" validationState={stripeError.expirationFormat}> */}
              <div className="checkout-card-exp form-group">
                <div className="relative">
                  <input
                    className={`form-control w-full ${getBorderColorClass("expirationFormat")}`}
                    onChange={handleInput}
                    onFocus={onFocusErrorRest}
                    placeholder="MM / YYYY"
                    ref={_expiration}
                    type="text"
                  />
                  {getValidationIcon("expirationFormat")}
                </div>
                {/* <FormControl
                  className={`form-control ${stripeError.expirationFormat}`}
                  onChange={handleInput}
                  onFocus={onFocusErrorRest}
                  placeholder="MM / YYYY"
                  ref={_expiration}
                  type="text"
                /> */}
              </div>
              <div className="checkout-card-cvc form-group">
                <div className="relative">
                  <input
                    className={`form-control w-full ${getBorderColorClass("cvcFormat")}`}
                    onChange={handleInput}
                    onFocus={onFocusErrorRest}
                    placeholder="CVC"
                    ref={_cvc}
                    type="password"
                  />
                  {getValidationIcon("cvcFormat")}
                </div>
                {/* <FormControl
                  className={`form-control ${stripeError.cvcFormat}`}
                  onChange={handleInput}
                  onFocus={onFocusErrorRest}
                  placeholder="CVC"
                  ref={_cvc}
                  type="password"
                /> */}
              </div>
            </div>
            <PaymentButton
              handleSubmitCard={handleSubmitCard}
              price={selectedPlan && selectedPlan.amount ? selectedPlan.amount / 100 : null}
              stage={stripeStage}
              type={type}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentMethodComponent

const PaymentButton = ({ stage, handleSubmitCard, price, type }) => {
  switch (stage) {
    case "pay":
      return (
        <button className="btn btn-checkout-confirm btn btn-primary btn-block" onClick={handleSubmitCard}>
          {type === "payment" ? (
            <>
              <svg className="locked" fill="#FFFFFF" version="1.1" viewBox="0 0 7.7 10.8" x="0px" y="0px">
                <path
                  d="M6.8,5.1V2.9C6.8,1.3,5.5,0,3.8,0C2.2,0,0.9,1.3,0.9,2.9v2.1C0.4,5.1,0,5.5,0,6.1v3.7c0,0.6,0.5,1,1,1h5.7
            c0.6,0,1-0.4,1-1V6.1C7.7,5.5,7.3,5.1,6.8,5.1z M1.9,5.1V2.9C1.9,1.9,2.8,1,3.8,1c1.1,0,1.9,0.9,1.9,1.9v2.1H1.9z"
                />
              </svg>
              <p>
                Pay <span>${price}</span>
              </p>
            </>
          ) : (
            <p>Update credit card</p>
          )}
        </button>
      )
    case "pending":
      return (
        <button className="btn btn btn-primary btn-block" disabled>
          {type === "payment" ? "Purchase" : "Change"} in process...
        </button>
      )
    case "success":
      return (
        <button className="btn btn-checkout-success btn btn-primary btn-block">
          <svg className="circle-ok" fill="#FFFFFF" version="1.1" viewBox="0 0 11 11" x="0px" y="0px">
            <path
              d="M5.5,11C2.5,11,0,8.5,0,5.5S2.5,0,5.5,0S11,2.5,11,5.5S8.5,11,5.5,11z M5.5,1C3,1,1,3,1,5.5
              C1,8,3,10,5.5,10C8,10,10,8,10,5.5C10,3,8,1,5.5,1z M4.9,7.7c0.1,0,0.3-0.1,0.4-0.2l2.6-3.1C8,4.2,8,3.9,7.8,3.7
              C7.6,3.6,7.3,3.6,7.1,3.8L4.8,6.5l-1-1c-0.2-0.2-0.5-0.2-0.7,0C3,5.6,3,5.9,3.2,6.1l1.3,1.4C4.6,7.6,4.7,7.7,4.9,7.7
              C4.9,7.7,4.9,7.7,4.9,7.7z"
            />
          </svg>
          <p>{type === "payment" ? "Payment" : "Update"} successful</p>
        </button>
      )
    default:
      return <button />
  }
}
