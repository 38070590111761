import { useApolloClient } from "@apollo/client"
import { useContext } from "react"
import EditCategory from "./EditCategory"
import { MainModalContext } from "../../../hooks/MainModalHook"
import { fragments } from "../../../graphql/fragments"
import { CategoryContext } from "@/context/CategoryContext"

interface EditCategoryWrapperProps {
  type: "add" | "edit"
  id: string
  batchId: string
  categoryId: string
  isSection: boolean
  refetch(): void
}

const EditCategoryWrapper = ({ type, id, batchId, categoryId, isSection }: EditCategoryWrapperProps) => {
  const { closeModal } = useContext(MainModalContext)
  const { addPresentation, editPresentation } = useContext(CategoryContext)

  // TODO: BU: replace all apollo clients with apollo client react hooks
  const client = useApolloClient()

  const calculateInitialValues = () => {
    if (type === "edit") {
      const { name, icon } = client.readFragment({
        id,
        fragment: categoryId ? fragments.presentationSmall : fragments.batchSmall
      })

      return { initialName: name, initialIcon: icon }
    }
    return { initialName: "", initialIcon: "Presentation" }
  }

  const handleSubmit = ({ name, icon }: { name: string; icon: string }) => {
    if (type === "edit") {
      editPresentation({ id: batchId, name, icon, category: categoryId })
    } else {
      addPresentation(
        {
          variables: {
            name,
            icon,
            category: categoryId
          }
        },
        isSection
      )
    }
    closeModal()
  }

  const { initialName, initialIcon } = calculateInitialValues()
  return <EditCategory handleSubmit={handleSubmit} initialIcon={initialIcon} initialName={initialName} type={type} />
}

export default EditCategoryWrapper
