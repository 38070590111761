import Dropzone from "react-dropzone"
import TagsInput from "react-tagsinput"

export const InputWrapper = ({ error, children, label }) => (
  <div className="profile-input">
    {error && <span className="sc-form-error">{error}</span>}
    {children}
    <label>{label}</label>
  </div>
)

export const DropzoneInput = ({ name, files, onChange }) => (
  <div>
    {/* <Dropzone
      name={name}
      multiple={false}
      style={{}}
      onDrop={(filesToUpload) => onChange(filesToUpload)}
      className="template-dropzone-wrapper"
    >
      <div className="template-dropzone">{files && Array.isArray(files) && <p>{files[0].name}</p>}</div>
    </Dropzone> */}
    <Dropzone name={name} onDrop={(filesToUpload) => onChange(filesToUpload)}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="template-dropzone-wrapper">
            <div className="template-dropzone">{files && Array.isArray(files) && <p>{files[0].name}</p>}</div>
          </div>
        </div>
      )}
    </Dropzone>
  </div>
)

export const TagWrapper = ({ name, value, onChange, placeholder }) => (
  <TagsInput
    addKeys={[9, 13, 188]}
    inputProps={{ placeholder }}
    maxTags={5}
    onChange={(value) => {
      onChange({ persist: () => {}, target: { name: name, value } })
    }}
    value={value}
  />
)

export const RadioIconInput = ({ icon, value, handleChange, checked }) => {
  return (
    <div aria-checked={checked} className="w-[68px] h-[68px] m-[6px] group">
      <label className="relative h-[68px] w-[68px] !m-0 flex items-center justify-center">
        <input
          checked={checked}
          className="w-[68px] h-full absolute invisible"
          name="icon"
          onChange={handleChange}
          type="radio"
          value={value}
        />
        <div
          className={`w-full h-full border rounded-full transition duration-300 ease-in-out cursor-pointer ${
            checked ? "border-[#3390ff]" : "border-transparent"
          }`}
        >
          <img
            alt={icon}
            className={`w-full h-full p-4 absolute top-0 left-0 group-hover:opacity-0 ${
              checked ? "opacity-0" : "opacity-100"
            }`}
            src={`/icons/collectionIcons/regular/${icon}.svg`}
          />
          <img
            alt={icon}
            className={`w-full h-full p-4 absolute top-0 left-0 group-hover:opacity-100 ${
              checked ? "opacity-100" : "opacity-0"
            }`}
            src={`/icons/collectionIcons/blue/${icon}.svg`}
          />
        </div>
      </label>
    </div>
  )
}
