import { useMutation } from "@apollo/client"
import { useContext, useState } from "react"
import { Link } from "react-router-dom"
import { InputWrapper } from "../utils/forms"
import { login as loginMutation, confirmResetPassword as confirmResetPasswordMutation } from "../../graphql/mutations"
import { withRouter } from "react-router"
import { AuthContext } from "../../hooks/AuthHook"
import { useForceUpdate } from "../../hooks/ForceUpdate"
import { useForm } from "../../hooks/FormHook"
import { isRequired } from "../utils/helpers"
import { ErrorPlaceholder } from "../utils/placeholders"

const ResetPasswordForm = ({ history, match }) => {
  const { checkCurrentUser } = useContext(AuthContext)
  const [error, setError] = useState(false)
  const [formError, setFormError] = useState(null)

  const [confirmResetPassword, { loading: recoveryLoading }] = useMutation(confirmResetPasswordMutation, {
    context: { isUsingNewScApi: true }
  })
  const [login, { loading: loginLoading }] = useMutation(loginMutation, {
    context: { isUsingNewScApi: true }
  });

  const forceUpdate = useForceUpdate()
  const [values, onChange, validations, validate] = useForm([
    {
      name: "newPassword",
      validators: [isRequired]
    },
    {
      name: "confirmPassword",
      validators: [isRequired, (val) => (values.newPassword === val ? false : "Passwords do not match")]
    }
  ])

  const submitForm = () => {
    if (!validate()) return forceUpdate()

    confirmResetPassword({
      variables: {
        userId: match.params.userId,
        newPassword: values.newPassword,
        ott: match.params.ott
      },
      onCompleted: async ({ confirmResetPassword }) => {
        if (!confirmResetPassword.success) return setFormError(confirmResetPassword.message)

        setFormError(null)
        login({
          variables: {
            email: confirmResetPassword.email,
            password: values.newPassword
          }
        }).then((response) => {
          localStorage.setItem("jwtToken", response.data.login)
          checkCurrentUser()
          history.push("/dashboard")
        })
      }
    })
  }

  const onKeyDown = (e) => {
    if (e.key === "Enter" && !(recoveryLoading || loginLoading)) submitForm()
  }

  return (
    <div className="sc-auth-panel">
      <ErrorPlaceholder tryAgain={() => setError(false)} visible={error} />
      <div className="sc-forgot-panel" style={error ? { display: "none" } : {}}>
        <h2>Set new password</h2>
        <p>Please enter your new password.</p>
        <form className="sc-authentic-form" onKeyDown={onKeyDown}>
          {formError && <span className="sc-form-error-top">{formError}</span>}
          <InputWrapper error={validations.newPassword} label="New Password">
            <input
              disabled={recoveryLoading || loginLoading}
              name="newPassword"
              onChange={onChange}
              placeholder="Click here to insert your New Password"
              type="password"
              value={values.newPassword}
            />
          </InputWrapper>
          <InputWrapper error={validations.confirmPassword} label="Confirm New Password">
            <input
              disabled={recoveryLoading || loginLoading}
              name="confirmPassword"
              onChange={onChange}
              placeholder="Click here to confirm your New Password"
              type="password"
              value={values.confirmPassword}
            />
          </InputWrapper>
          <div className="sc-auth-links">
            <button
              className="btn btn-lightblue"
              disabled={recoveryLoading || loginLoading}
              onClick={submitForm}
              type="button"
            >
              Save
            </button>
            <p>Back to</p>
            <Link to="/users/sign_in">Sign In</Link>
          </div>
        </form>
      </div>
    </div>
  )
}

export default withRouter(ResetPasswordForm)
