import React, { useContext } from "react"
import { MainModalContext } from "../../hooks/MainModalHook"

const InvitationLink = ({ link }) => {
  const { closeModal } = useContext(MainModalContext)
  return (
    <div className="modal-profile-invite">
      <p className="modal-profile-invite-header">Invitation Link</p>
      <span className="w-full break-words text-sc-blue cursor-copy underline">{link}</span>
      <div className="button-wrapper">
        <button className="btn btn-dark-blue" onClick={closeModal} type="button">
          Close
        </button>
      </div>
    </div>
  )
}

export default InvitationLink
