import { useMutation } from "@apollo/client"
import { useState } from "react"
import { setCancellationSurveyFeedback } from "../../../graphql/mutations"

const SubscriptionQuestionnaireForm = ({ closeModal, subscription }) => {
  const [_setCancellationSurveyFeedback, { loading }] = useMutation(setCancellationSurveyFeedback)
  const [answer, setAnswer] = useState("expectations")
  const [answerOther, setAnswerOther] = useState("")

  const submitForm = () =>
    _setCancellationSurveyFeedback({
      variables: {
        feedback: answer === "other" ? answerOther || "other" : answer,
        subscriptionId: subscription.subscriptionId
      }
    }).then(() => {
      closeModal()
    })

  const handleRadioChange = (e) => {
    setAnswer(e.target.value)
  }

  const handleTextChange = (e) => {
    setAnswerOther(e.target.value)
  }

  return (
    <form autoComplete="off" className="sc-modal-profile-sub-cancel-quest">
      <h3>Why did you cancel your subscriptions?</h3>
      <div className="sc-modal-profile-sub-answers">
        <label className="profile-sub-cancel-answer">
          <input checked={answer === "expectations"} onChange={handleRadioChange} type="radio" value="expectations" />
          It didn’t meet my expectations
          <div className="cancel-answer-bg" />
        </label>
        <label className="profile-sub-cancel-answer">
          <input checked={answer === "expensive"} onChange={handleRadioChange} type="radio" value="expensive" />
          It was too expensive
          <div className="cancel-answer-bg" />
        </label>
        <label className="profile-sub-cancel-answer">
          <input checked={answer === "another_app"} onChange={handleRadioChange} type="radio" value="another_app" />
          I found another alternative
          <div className="cancel-answer-bg" />
        </label>
        <label className="profile-sub-cancel-answer">
          <input
            checked={answer === "no_upcoming_presentations"}
            onChange={handleRadioChange}
            type="radio"
            value="no_upcoming_presentations"
          />
          I don’t have any upcoming presentations
          <div className="cancel-answer-bg" />
        </label>
        <label className="profile-sub-cancel-answer">
          <input checked={answer === "i'll_be_back"} onChange={handleRadioChange} type="radio" value="i'll_be_back" />
          This is only temporary, I’ll be back
          <div className="cancel-answer-bg" />
        </label>
        <label className="profile-sub-cancel-answer">
          <input checked={answer === "other"} onChange={handleRadioChange} type="radio" value="other" />
          Other
          <input disabled={answer !== "other"} onChange={handleTextChange} type="text" value={answerOther} />
          <div className="cancel-answer-bg" />
        </label>
      </div>
      <div className="profile-buttons">
        <button className="profile-link-skip" onClick={closeModal}>
          Skip questions
        </button>
        <button className="btn btn-dark-blue" disabled={loading} onClick={submitForm} type="button">
          Submit answer
        </button>
      </div>
    </form>
  )
}

export default SubscriptionQuestionnaireForm
