import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { useContext, useEffect, useState } from "react"
import { MainModalContext } from "@/hooks/MainModalHook"
import { Batch } from "@/graphql/types/queries/batch"

const GET_COLLECTIONS = gql`
  query collections {
    collections {
      id
      batchId
      name
      icon
    }
  }
`

const MOVE_SLIDE_BETWEEN_CATEGORIES = gql`
  mutation moveSlideBetweenCategories($slideId: String!, $fromCategoryId: String!, $toCategoryId: String!) {
    moveSlideBetweenCategories(slideId: $slideId, fromCategoryId: $fromCategoryId, toCategoryId: $toCategoryId) {
      code
      success
      message
      fromCategoryJob {
        id
        queueName
        lifecycle {
          status
          progress
          error
        }
      }
      toCategoryJob {
        id
        queueName
        lifecycle {
          status
          progress
          error
        }
      }
    }
  }
`

const GET_PRESENTATIONS = gql`
  query presentations($ids: [String!]!) {
    presentations(ids: $ids) {
      _id
      id
      thumbUrl
      name
      batchId
      icon
      isFavourite
      sharedPresentationLinks {
        isActive
        token
        isDownloadable
        _id
      }
      slides {
        id
        slideId
        blueprintId
        thumbUrl
        name
        tags
        downloadUrl
        linksDataHeight
        linksDataWidth
        state
        isFavourite
      }
      category {
        _id
      }
    }
  }
`

interface MoveSlideToCategoryProps {
  blueprintId: string
  batchId: string
  refetch: () => void
}

interface Collection extends Batch {
  selected: boolean
}

const MoveSlideToCategory = ({ blueprintId, batchId }: MoveSlideToCategoryProps) => {
  const { closeModal } = useContext(MainModalContext)
  const [collections, setCollections] = useState<Collection[]>([])
  const selectedCategoryBatchId = collections.find((c) => c.selected)?.batchId || ""
  const [getPresentationsData, { stopPolling: stopPresentationDataPolling }] = useLazyQuery(GET_PRESENTATIONS, {
    context: { isUsingNewScApi: true },
    pollInterval: 1500,
    fetchPolicy: "network-only",
    onCompleted: ({ presentation }) => {
      if (!presentation) {
        stopPresentationDataPolling()
        return
      }
      const slides = presentation.slides
      if (!slides?.length) return
      if (slides[0]?.state === "uploaded") {
        stopPresentationDataPolling()
      }
    }
  })
  const [moveSlideBetweenCategories] = useMutation(MOVE_SLIDE_BETWEEN_CATEGORIES, {
    context: { isUsingNewScApi: true }
  })

  const { data } = useQuery(GET_COLLECTIONS, { context: { isUsingNewScApi: true } })

  useEffect(() => {
    if (data) {
      setCollections(
        data.collections
          .filter((collection: Collection) => collection.batchId !== batchId)
          .map((c: Collection) => ({ ...c, selected: false }))
      )
    }
  }, [data, batchId])

  const onCategoryClick = (batchId: string) => {
    if (batchId) {
      const selectedCount = collections.filter((c) => c.selected).length
      if (selectedCount > 0)
        setCollections((state) =>
          state.map((c) => ({
            ...c,
            selected: false
          }))
        )
      setCollections((state) => state.map((c: Collection) => (c.batchId === batchId ? { ...c, selected: true } : c)))
    }
  }

  const handleSubmit = async () => {
    if (!selectedCategoryBatchId) return

    await moveSlideBetweenCategories({
      variables: {
        slideId: blueprintId,
        fromCategoryId: batchId,
        toCategoryId: selectedCategoryBatchId
      }
    })
    await getPresentationsData({ variables: { ids: [batchId, selectedCategoryBatchId] } })
    closeModal()
  }

  return (
    // BU: this is "move to category" modal content (category list)
    <div className="categories-manager">
      <h1 className="change-order">Move to</h1>
      <div className="order-list">
        {collections.map((collection) => (
          <div
            className={"order-list-cat"}
            key={collection.batchId}
            onClick={() => onCategoryClick(collection.batchId)}
            style={{ backgroundColor: collection.selected ? "#e4e8ea" : "#ffffff" }}
          >
            <div className="order-icon">
              <img
                alt={collection.icon}
                className="w-full h-full p-7"
                src={`/icons/collectionIcons/regular/${collection.icon}.svg`}
              />
            </div>
            <p>{collection.name}</p>
          </div>
        ))}
      </div>

      <button
        // TODO: BU: this should be "grayed out" when disabled...
        className="btn btn-lightblue"
        disabled={!selectedCategoryBatchId}
        onClick={handleSubmit}
      >
        Move
      </button>
    </div>
  )
}

export default MoveSlideToCategory
