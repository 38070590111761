import { useState, createContext } from "react"

interface OpenModalParams {
  content: string | null
  data?: Record<string, any>
  type?: string
  closeable?: boolean
}

interface UseUserDataContext {
  modalContent: string | null
  modalData: Record<string, any>
  modalType: string
  modalCloseable: boolean
  openModal(params: OpenModalParams): void
  closeModal(): void
}

export const useMainModal = (): UseUserDataContext => {
  const [modalContent, setModalContent] = useState<UseUserDataContext["modalContent"]>(null)
  const [modalData, setModalData] = useState<UseUserDataContext["modalData"]>({})
  const [modalType, setModalType] = useState<UseUserDataContext["modalType"]>("main") // 'main' or 'fullscreen'
  const [modalCloseable, setModalCloseable] = useState<UseUserDataContext["modalCloseable"]>(true)

  const openModal = ({ content, data = {}, type = "main", closeable = true }: OpenModalParams) => {
    setModalContent(content)
    setModalData(data)
    setModalType(type)
    setModalCloseable(closeable)
  }

  const closeModal = () => {
    setModalContent(null)
    setModalData({})
    setModalType("main")
    setModalCloseable(true)
  }

  return {
    modalContent,
    modalData,
    modalType,
    modalCloseable,
    openModal,
    closeModal
  }
}

export const MainModalContext = createContext({} as UseUserDataContext)
