import { useMutation } from "@apollo/client"
import { useContext } from "react"
import { withRouter } from "react-router"
import { initGodMode } from "../../graphql/mutations"
import { AuthContext } from "../../hooks/AuthHook"
import { UserDataContext } from "../../hooks/UserDataHook"

const AdminInfoBar = () => {
  const { user } = useContext(UserDataContext)
  const { logout } = useContext(AuthContext)
  const [_initGodMode] = useMutation(initGodMode)

  const { firstName, lastName, sessionOwnerId } = user

  const handleExit = () => {
    _initGodMode({
      variables: {
        emailOrId: sessionOwnerId
      }
    }).then((res) => {
      const token = res.data.admin.createSession
      const array = document.domain.split(".")
      const prefix = document.domain.split(".")[0]
      array.shift()
      const targetUrl = array.join(".")
      logout()
      window.location.replace(
        `${window.location.protocol}//${prefix}.${targetUrl}:${window.location.port}/user/redirect?token=${token}`
      )
    })
  }

  return (
    <div style={{ backgroundColor: "#cc0000", height: "50px", display: "flex", textAllign: "center" }}>
      <p style={{ margin: 0, fontSize: "18px", color: "#7f0000", fontWeight: "bold" }}>
        {firstName} {lastName}! You are on GOD MODE. Be careful!!! Please!!!
      </p>
      <button onClick={handleExit} style={{ height: "30px" }}>
        Exit
      </button>
    </div>
  )
}

export default withRouter(AdminInfoBar)
