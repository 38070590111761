import { Route, Switch } from "react-router-dom"
import CategoryNavLinks from "./navLinks"
import CategoryPage from "./page"
import { useContext } from "react"
import { UserDataContext } from "../../hooks/UserDataHook"
import { useIsInsidePowerPoint } from "../../context/IsInsidePowerPointContext"
import PowerPointCategoryPage from "./PowerPointCategoryPage"

const Category = () => {
  const {
    user: { cart }
  } = useContext(UserDataContext)
  const isInsidePowerPoint = useIsInsidePowerPoint()

  return (
    <div className="w-full">
      {!isInsidePowerPoint && (
        <div
          className={`bg-white mobile-xs:px-[24px] ${cart ? "tablet-xl:px-[60px]" : "tablet-sm:px-[60px]"}`}
          data-testid="navigation-sub-menu"
        >
          <CategoryNavLinks />
        </div>
      )}
      <Switch>
        <Route
          component={isInsidePowerPoint ? PowerPointCategoryPage : CategoryPage}
          path="/library/:urlToken?/:name?/:slideId?"
        />
      </Switch>
    </div>
  )
}

export default Category
