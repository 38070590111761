import { useState, useRef, useEffect } from "react"

const Tooltip = ({ text, cutText }) => {
  const [isVisible, setIsVisible] = useState(false)
  const show = () => {
    setIsVisible(true)
  }
  const hide = () => {
    setIsVisible(false)
  }
  const ref = useRef()
  useEffect(() => {
    ref.current.addEventListener("mouseover", show)
    ref.current.addEventListener("mouseleave", hide)
  }, [])

  return (
    <span
      className="relative"
      ref={(_ref) => {
        ref.current = _ref
      }}
    >
      <span className="line-clamp-1">{`${cutText}...`}</span>
      {isVisible && (
        <div
          className="sc-tooltip profile-long-text-tooltip"
          style={{ position: "absolute", bottom: 30, left: 0, zIndex: 1000 }}
        >
          <div className="rc-tooltip-content">
            <div className="rc-tooltip-arrow ellipsis-tooltip-arrow" />
            <div className="rc-tooltip-inner">
              <span>{text}</span>
            </div>
          </div>
        </div>
      )}
    </span>
  )
}

export const Ellipsis = ({ text, length, tooltip = true }) => {
  const cutText = text.toString().substring(0, length)
  if (!tooltip) {
    return text !== cutText ? (
      <span className="line-clamp-1">{`${cutText}...`}</span>
    ) : (
      <span className="line-clamp-1">{text}</span>
    )
  }

  return text !== cutText ? <Tooltip cutText={cutText} text={text} /> : <span className="line-clamp-1">{text}</span>
}
