import { useState, useEffect, useContext } from "react"
import Presentation from "../../presentation/Presentation"
import Breadcrumbs from "./Breadcrumbs"
import { SharedEmptySvg } from "../../../svg/EmptyContainerSvgs"
import { LoadingPlaceholder } from "../../utils/placeholders"
import { UserDataContext } from "../../../hooks/UserDataHook"
import { gql, useQuery } from "@apollo/client"

export const NEW_BATCHES = gql`
  query organizationPresentations($limit: Int!) {
    organizationPresentations(limit: $limit) {
      collections {
        _id
        id
        name
        mode
        batchId
        icon
        labels
        urlToken
        isFavourite
        creator {
          id
          firstName
          lastName
        }
        updatedAt
        accessibleByUsers {
          _id
          firstName
          lastName
        }
        accessibleByUnits {
          _id
          name
        }
        accessibleByAll
        slides {
          id
          slideId
          blueprintId
          thumbUrl
          name
          tags
          downloadUrl
          state
          isFavourite
        }
        category {
          _id
        }
      }
      presentations {
        _id
        id
        name
        mode
        batchId
        labels
        urlToken
        isFavourite
        creator {
          id
          firstName
          lastName
        }
        updatedAt
        sharedPresentationLinks {
          isActive
          token
          isDownloadable
          _id
        }
        accessibleByUsers {
          _id
          firstName
          lastName
        }
        accessibleByUnits {
          _id
          name
        }
        accessibleByAll
        slides {
          id
          slideId
          blueprintId
          thumbUrl
          name
          tags
          state
          isFavourite
        }
        category {
          _id
        }
      }
    }
  }
`

// BU: dashboard & categories / collections
const Batches = (props) => {
  const [openedBatch, setOpenedBatch] = useState(null)
  const [noPermission, setNoPermission] = useState(false)
  const { user } = useContext(UserDataContext)
  const { teamOwner, cart } = user
  const [collections, setCollections] = useState([])
  const [presentations, setPresentations] = useState([])

  const { data, loading, refetch } = useQuery(NEW_BATCHES, {
    variables: { limit: 6 },
    context: { isUsingNewScApi: true }
  })

  useEffect(() => {
    setNoPermission(props.rest.match.params.id && !openedBatch)
  }, [props.match, openedBatch])

  useEffect(() => {
    if (data) {
      setCollections(data.organizationPresentations.collections)
      setPresentations(data.organizationPresentations.presentations)
    }
  }, [data])

  if (noPermission) return <div>no permission to see this</div>

  const makeCategoryUrl = (batch) => `/assets/${batch.batchId}`
  const categoriesNavName = "New collections"
  const presentationsNavName = "New presentations"

  return (
    <div className="my-[60px] w-full flex flex-col items-center">
      <div
        className={`w-full flex justify-center mobile-xs:px-[24px] ${
          cart ? "tablet-xl:px-[60px]" : "tablet-sm:px-[60px]"
        }`}
      >
        <div className="w-full desktop-big:w-[1812px]">
          <Breadcrumbs title={categoriesNavName} />
          {loading && <LoadingPlaceholder />}
          {collections.length !== 0 ? (
            <div
              className={`my-[25px] grid gap-[20px] mobile-xs-cols-1 ${
                cart
                  ? "tablet-md:grid-cols-2 tablet-xl:grid-cols-3 desktop-sm:grid-cols-4 desktop-xl:grid-cols-5 desktop-2xl:grid-cols-6 desktop-3xl:grid-cols-7 min-[2040px]:grid-cols-8"
                  : "mobile-sm:grid-cols-2 mobile-md:grid-cols-3 tablet-lg:grid-cols-4 tablet-xl:grid-cols-5 desktop-lg:grid-cols-6 desktop-xl:grid-cols-7 desktop-2xl:grid-cols-8 desktop-3xl:grid-cols-9"
              }`}
            >
              {collections.map((batch) => (
                <Presentation
                  actions={{ download: true, share: teamOwner, edit: false, remove: false }}
                  active={openedBatch?.id === batch.batchId}
                  batch={batch}
                  categoryHandleClick={() => {
                    ;(props.push || props.rest.history.push)({
                      pathname: makeCategoryUrl(batch)
                    })
                  }}
                  id={batch.batchId}
                  isFavourite={batch.isFavourite}
                  key={batch.batchId}
                  name={batch.name || "No given name"}
                  refetch={refetch}
                  setOpenedBatch={setOpenedBatch}
                  subscribeToMore={true}
                  type="templates"
                />
              ))}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center text-center">
              <SharedEmptySvg />
              <h2 className="text-[25px] text-sc-blue font-bold">NO SHARED COLLECTIONS YET</h2>
              <p className="mt-[15px] font-light">
                There are no collections shared <br />
                by the team owner yet.
              </p>
            </div>
          )}
        </div>
      </div>
      <div
        className={`w-full flex justify-center mobile-xs:px-[24px] ${
          cart ? "tablet-xl:px-[60px]" : "tablet-sm:px-[60px]"
        }`}
      >
        <div className="w-full desktop-big:w-[1812px]">
          <Breadcrumbs title={presentationsNavName} />
          {loading && <LoadingPlaceholder />}
          {presentations.length !== 0 ? (
            <div
              className={`grid gap-[20px] mobile-sm:grid-cols-1 w-full ${
                cart
                  ? "desktop-sm:grid-cols-2 desktop-xl:grid-cols-3 desktop-3xl:grid-cols-4"
                  : "tablet-md:grid-cols-2 desktop-lg:grid-cols-3 desktop-2xl:grid-cols-4"
              }`}
            >
              {presentations.map((batch) => (
                <Presentation
                  actions={{ download: true, share: !!teamOwner, edit: false, remove: false }}
                  active={openedBatch?.id === batch.batchId}
                  batch={batch}
                  id={batch.batchId}
                  isFavourite={batch.isFavourite}
                  key={batch.batchId}
                  name={batch.name || "No given name"}
                  refetch={refetch}
                  setOpenedBatch={setOpenedBatch}
                  subscribeToMore={true}
                  type={teamOwner ? "batches" : "sharedBatches"}
                />
              ))}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center text-center">
              <SharedEmptySvg />
              <h2 className="text-[25px] text-sc-blue font-bold">NO SHARED PRESENTATIONS YET</h2>
              <p className="mt-[15px] font-light">
                There are no presentations shared <br />
                by the team owner yet.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Batches
