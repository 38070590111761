import { useEffect, useState } from "react"
import { ApolloError, useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import BatchPresenter from "./BatchPresenter"
import { PresentationIsEmptyMessage, SlideShowErrorMessage, SlideShowLoader } from "../utils/placeholders"
import { preloadImage } from "../utils/helpers"
import { SharedBatchData } from "@/graphql/queries"
import { Batch, Slide } from "../../graphql/types/queries"

interface BatchContainerProps {
  batchId?: string
  trigger?: string
  exitFullScreen?(val: boolean): void
}

const BatchContainer = (params: BatchContainerProps) => {
  const { shareToken } = useParams<{ shareToken: string }>()

  const [loaded, setLoaded] = useState<boolean>(false)
  const [preloadError, setPreloadError] = useState<boolean>(false)

  // unused logic
  // const isInternal: boolean = !!batchId && !!trigger && !!exitFullScreen

  let fetchedData: Record<"batchByShareToken" | "batchById", Batch>,
    isLoading: boolean,
    apolloError: ApolloError | undefined

  // unused logic
  // if (isInternal) {
  //   const { data, loading, error } = useQuery(SharedBatchDataById, {
  //     variables: {
  //       batchId: batchId
  //     }
  //   })

  //   fetchedData = data
  //   isLoading = loading
  //   apolloError = error
  // } else {
  const { data, loading, error } = useQuery(SharedBatchData, {
    context: { isUsingNewScApi: true },
    variables: { shareToken, openedBySlideShow: "false" }
  })

  fetchedData = data
  isLoading = loading
  apolloError = error
  // }

  useEffect(() => {
    if (fetchedData && !loaded && fetchedData.batchByShareToken) {
      const slides: Slide[] = fetchedData.batchByShareToken?.slides
      const slidesToPreload = slides
        .slice(0, 3)
        .map((slide) => slide.thumbUrl.replace("{width}", window.devicePixelRatio > 1 ? "2880" : "1440"))
      const smallSlidesToPreload = slides.map((slide) =>
        slide.thumbUrl.replace("{width}", window.devicePixelRatio > 1 ? "346" : "173")
      )
      Promise.all([...slidesToPreload, ...smallSlidesToPreload].map((img) => preloadImage(img)))
        .then(() => {
          setLoaded(true)
        })
        .catch(() => {
          setPreloadError(true)
        })
    }
  }, [fetchedData, loaded])

  if (!isLoading && (apolloError || preloadError || !fetchedData?.batchByShareToken)) return <SlideShowErrorMessage />
  if (isLoading || !loaded) return <SlideShowLoader />
  if (!fetchedData.batchByShareToken.slides.length) return <PresentationIsEmptyMessage />

  // unused logic
  // if (isInternal) {
  //   return <BatchPresenter batch={fetchedData.batchById} exitFullScreen={exitFullScreen} trigger={trigger} />
  // } else {
  return <BatchPresenter batch={fetchedData.batchByShareToken} />
  // }
}

export default BatchContainer
