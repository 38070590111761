import { useContext, useEffect } from "react"
import { useMutation, gql } from "@apollo/client"
import { MainModalContext } from "@/hooks/MainModalHook"
import { ErrorPlaceholder } from "@/components/utils/placeholders"
import { useForm } from "@/hooks/FormHook"
import { isRequired } from "@/components/utils/helpers"
import UnitsAndTeamMembersInput from "@/components/modals/share/UnitsAndTeamMembersInput"
import useUnitsAndTeamMembersInputLogic from "@/hooks/useUnitsAndTeamMembersInputLogic"

const ADD_USERS_TO_UNIT = gql`
  mutation addUnitMembers($unitId: String!, $memberIds: [String!]!) {
    addUnitMembers(unitId: $unitId, memberIds: $memberIds) {
      code
      success
      message
    }
  }
`

const CREATE_UNIT = gql`
  mutation createUnit($name: String!, $description: String) {
    createUnit(name: $name, description: $description) {
      code
      success
      message
      unit {
        _id
      }
    }
  }
`

const AddNewTeam = ({ refetch }) => {
  useEffect(() => {
    // import.meta.env.DEV && console.log("♻️ AddMemberToUnit")
  })

  const { closeModal } = useContext(MainModalContext)

  const [createUnit, { error }] = useMutation(CREATE_UNIT)
  const [addUsersToUnit] = useMutation(ADD_USERS_TO_UNIT)

  const { options, selectedOptions, handleOptionChange, formatOptionLabel, addedAndRemovedIds } =
    useUnitsAndTeamMembersInputLogic()

  const [values, onChange, , validate] = useForm([
    {
      name: "name",
      validators: [isRequired]
    },
    {
      name: "description"
    },
    {
      name: "unitMembers"
    }
  ])

  const handleSave = async () => {
    if (!validate()) {
      return
    }

    await createUnit({
      variables: {
        name: values.name,
        description: values.description
      },
      context: { isUsingNewScApi: true },
      onCompleted: async (data) => {
        if (selectedOptions.length >= 1) {
          await addUsersToUnit({
            variables: {
              unitId: data.createUnit.unit._id,
              memberIds: addedAndRemovedIds.addedUserIds
            },
            context: { isUsingNewScApi: true },
            onCompleted: () => {
              refetch()
            }
          })
        } else {
          refetch()
        }
      }
    })

    closeModal()
  }

  if (error) {
    return (
      <div className="modal-error">
        <ErrorPlaceholder />
      </div>
    )
  }

  return (
    <div>
      <div className="sc-modal-header">
        <h1>Create team</h1>
      </div>
      <form className="team-share">
        <label>Name</label>
        <input
          className="team-info"
          name="name"
          onChange={onChange}
          placeholder="New team name"
          type="text"
          value={values.name}
        />
        <label>Purpose</label>
        <input
          className="team-info"
          name="description"
          onChange={onChange}
          placeholder="e.g. research"
          type="text"
          value={values.description}
        />
        <label htmlFor="teamMembersAndUnits">Invite users:</label>
        <UnitsAndTeamMembersInput
          formatOptionLabel={formatOptionLabel}
          handleOptionChange={handleOptionChange}
          options={options.members}
          selectedOptions={selectedOptions}
        />
        <div className="sc-modal-buttons">
          <button className="btn-gray-blue" onClick={closeModal} type="button">
            Cancel
          </button>
          <button className="btn-dark-blue" disabled={!values.name} onClick={handleSave} type="button">
            Save
          </button>
        </div>
      </form>
    </div>
  )
}

export default AddNewTeam
