import { useContext } from "react"
import { AuthContext } from "@/hooks/AuthHook"
import { NotificationContext } from "@/hooks/NotificationHook"
import axios from "axios"
import { NotificationError } from "../../utils/notifications"
import { dateToLLFormat } from "../../utils/dates"
import { useState } from "react"

interface InvoiceItem {
  invoice: any
}

const InvoiceItem = ({ invoice }: InvoiceItem) => {
  const { date, number } = invoice
  const { token } = useContext(AuthContext)
  const { open } = useContext(NotificationContext)

  const downloadInvoice = () => {
    axios
      .get(import.meta.env.VITE_SC_API_GATEWAY_PATH + invoice.downloadUrl + "&ses=" + encodeURIComponent(token), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
          "Content-Type": "multipart/form-data"
        }
      })
      .then((response) => {
        window.open(response.data.url, "_blank")
      })
      .catch(() => {
        open({
          type: "error",
          duration: 3,
          content: <NotificationError message="Error downloading invoice" title="Error" />
        })
      })
  }

  return (
    <div className="profile-invoice-table-tr">
      <div className="profile-invoice-table-td">{dateToLLFormat(date)}</div>
      <div className="profile-invoice-table-td">{`Invoice #${number}`}</div>
      <div className="profile-invoice-table-td-dwnl">
        <button className="invoice-download" onClick={downloadInvoice}>
          <svg version="1.1" viewBox="0 0 12.2 12.2" x="0px" y="0px">
            <path
              className="invoice-download-ico"
              d="M6.1,0C2.7,0,0,2.7,0,6.1s2.7,6.1,6.1,6.1s6.1-2.7,6.1-6.1S9.5,0,6.1,0z M6.1,11.5
                c-3,0-5.4-2.4-5.4-5.4s2.4-5.4,5.4-5.4s5.4,2.4,5.4,5.4S9.1,11.5,6.1,11.5z M8.2,6.5c0.1,0.1,0.1,0.4,0,0.5L6.4,8.9
                C6.3,8.9,6.2,9,6.1,9C6,9,5.9,8.9,5.8,8.9L4,7C3.8,6.9,3.8,6.6,4,6.5c0.1-0.1,0.4-0.1,0.5,0l1.2,1.2V3.6c0-0.2,0.2-0.4,0.4-0.4
                s0.4,0.2,0.4,0.4v4.1l1.2-1.2C7.8,6.3,8.1,6.3,8.2,6.5z"
            />
          </svg>
          Download PDF
        </button>
      </div>
    </div>
  )
}

interface InvoicesProps {
  invoices: any[]
}

const Invoices = ({ invoices }: InvoicesProps) => {
  const [limit, setLimit] = useState<number>(5)
  if (invoices.length === 0) {
    return null
  }

  return (
    <div className="profile-invoice">
      <hr />
      <h1>Your invoices</h1>
      <div className="profile-invoice-table">
        <div className="profile-invoice-table-thead">
          <div className="profile-invoice-table-tr">
            <div className="profile-invoice-table-td">Date</div>
            <div className="profile-invoice-table-td">Type</div>
            <div className="profile-invoice-table-td-dwnl">Invoice</div>
          </div>
        </div>
        <div className="profile-invoice-table-body">
          {invoices.slice(0, limit).map((invoice: any, i: number) => (
            <InvoiceItem invoice={invoice} key={i} />
          ))}
        </div>
      </div>
      <div className="profile-buttons-ctr">
        {invoices.slice(0, limit).length < invoices.length && (
          <button className="btn btn-dark-blue" onClick={() => setLimit(limit + 5)}>
            See more
          </button>
        )}
      </div>
    </div>
  )
}

export default Invoices
