import React from "react"
import { useEffect, useState } from "react"
import { LinkData } from "@/graphql/types/queries"
import { useDebouncedLayoutEffect } from "@/hooks/useDebouncedLayouEffect"
import useResizeObserver from "@react-hook/resize-observer"

interface ViewerSlide {
  slideLoaded: string
  slide: string
  linksData: LinkData[]
  linksHeight: number
  linksWidth: number
}

function reducer(currentState: any, newState: any) {
  return { ...currentState, ...newState }
}
const useSize = (target: any) => {
  const [{ contentWidth, contentHeight }, setState] = React.useReducer(reducer, {
    contentWidth: 0,
    contentHeight: 0
  })

  useDebouncedLayoutEffect(
    () => {
      setState({
        contentWidth: target.current.offsetWidth,
        contentHeight: target.current.offsetHeight
      })
    },
    700,
    [target]
  )

  useResizeObserver(target, (entry: any) => {
    setState({
      contentWidth: entry.contentRect.width,
      contentHeight: entry.contentRect.height
    })
  })
  return [contentWidth, contentHeight]
}
const ViewerSlide = ({ slideLoaded, slide, linksData, linksHeight, linksWidth }: ViewerSlide) => {
  const [linksHighlighted, setLinksHighlighted] = useState<boolean>(false)
  const contentRef = React.useRef(null)
  const [imageWidth, imageHeight] = useSize(contentRef)

  //const imgRef:  = useRef()

  useEffect(() => {
    document.body.classList.add("scroll-lock")
    return () => {
      document.body.classList.remove("scroll-lock")
    }
  }, [])

  return (
    <div className="viewer-slide">
      {slideLoaded ? (
        <img
          alt="slide"
          ref={contentRef}
          src={slide.replace("{width}", "1440")}
          srcSet={`${slide.replace("{width}", "2880")} 2x`}
        />
      ) : (
        <img alt="slide" src={slide.replace("{width}", "173")} srcSet={`${slide.replace("{width}", "346")} 2x`} />
      )}
      {slideLoaded && linksData && (
        <div className="slideshow-links">
          {linksData.map((link: any) =>
            link.Positions.map((pos: any, index: number) => (
              <a
                className={`slideshow-link${linksHighlighted ? " slideshow-link__highlighted" : ""}`}
                href={link.URL}
                key={index}
                onMouseOut={() => {
                  setLinksHighlighted(false)
                }}
                onMouseOver={() => {
                  setLinksHighlighted(true)
                }}
                rel="noopener noreferrer"
                style={{
                  left: Math.floor(pos.Left * (imageWidth / linksWidth) + (window.innerWidth - imageWidth) / 2),
                  top: Math.floor(pos.Top * (imageHeight / linksHeight) + (window.innerHeight - imageHeight) / 2),
                  width: Math.floor(pos.Width * (imageWidth / linksWidth)) + 1,
                  height: Math.floor(pos.Height * (imageHeight / linksHeight)) + 1
                }}
                target="_blank"
              >
                {" "}
              </a>
            ))
          )}
        </div>
      )}
    </div>
  )
}

export default ViewerSlide
