// import {useMutation} from "@apollo/client"

import { useForm } from "../../hooks/FormHook"
import { useForceUpdate } from "../../hooks/ForceUpdate"
import { isRequired } from "../utils/helpers"
import { InputWrapper } from "../utils/forms"
// import {addNewFiles} from "../../graphql/mutations"
import { UploadFormProps } from "./types"

const UploadTemplateForm = ({ groups, initialValues, wizard }: UploadFormProps) => {
  // const [_createPresentation, {loading: createLoading}] = useMutation(createPresentation)
  // const [_addNewFiles, {loading: addLoading}] = useMutation(addNewFiles)

  const forceUpdate = useForceUpdate()
  const [values, onChange, validations, validate] = useForm([
    {
      name: "name",
      validators: [isRequired]
    },
    {
      name: "icon",
      validators: [isRequired]
    },
    {
      name: "group",
      validators: [isRequired],
      initialValue: initialValues ? initialValues.group : undefined
    },
    {
      name: "colors"
    }
  ])

  const submitForm = async () => {
    if (!validate()) {
      forceUpdate()
      return
    }
  }

  return (
    <div className="admin-upload-templates scroll">
      <form>
        <div className="row">
          <div className="admin-upload-fields">
            <div>
              <InputWrapper error={validations.name} label="Name">
                <input name="name" onChange={onChange} placeholder="Name" type="text" value={values.name} />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper error={validations.icon} label="Icon">
                <input name="icon" onChange={onChange} placeholder="Icon" type="text" value={values.icon} />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper error={validations.group} label="Group">
                <select disabled={wizard} name="group" onChange={onChange} value={values.group}>
                  <option />
                  {groups.map((group) => (
                    <option key={group.groupId} value={group.groupId}>
                      {group.groupId}
                    </option>
                  ))}
                </select>
              </InputWrapper>
            </div>
            <div className="admin-button-submit-fixed">
              <button className="btn btn-dark-blue" onClick={submitForm} type="button">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default UploadTemplateForm
