import { Collapse } from "react-collapse"
import { ArrowDownIcon, ArrowUpIcon, GreenEyeIcon } from "../../../svg/SvgIcons"
import { timeAgo } from "../../utils/dates"

const LogEntry = ({
  index,
  activeAccordionId,
  setActiveAccordionId,
  headerContent,
  detailsContent,
  isMoreContentAvailable,
  recordTime,
  iconName
}) => {
  const isOpened = activeAccordionId === index

  return (
    <div className="relative">
      <div className="mt-1">
        <button
          className={`bg-white flex py-4 ${
            iconName === "log-eye" ? "sm:h-[100px] py-6" : "sm:h-[75px]"
          } tablet-lg:items-center flex-col gap-9 h-full items-start tablet-lg:flex-row px-9 ${
            isMoreContentAvailable ? "cursor-pointer" : "cursor-default"
          } w-full border border-[#E9EAED] border-solid`}
          onClick={() => setActiveAccordionId(isOpened ? null : index)}
        >
          <div className="flex items-center flex-col">
            <div className={`bg-contain ${iconName !== "log-eye" && `${iconName} w-[20px] h-[20px]`}`}>
              {iconName === "log-eye" && <GreenEyeIcon />}
            </div>
            <p className="text-[10px] mt-2 w-[53px] break-words">{timeAgo(Number(recordTime))}</p>
          </div>
          {headerContent}
          {isMoreContentAvailable && (
            <div
              className="flex h-2.5 m-3.5 absolute sm:relative right-7 sm:right-[-9px] top-4.5 sm:top-auto w-3.5"
              data-testid="activity-log-expand-button"
            >
              {isOpened ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </div>
          )}
        </button>
      </div>
      <Collapse isOpened={isOpened}>{detailsContent}</Collapse>
    </div>
  )
}

export default LogEntry
