import React, { useEffect, useState } from "react"
import { gql, useLazyQuery } from "@apollo/client"
import { GET_UPDATED_SLIDES } from "@/components/topbar/PowerPointNavbar"
import { deleteOutdatedSlide, getUpdatedSlides, insertSlideInPowerPoint } from "@/components/utils/powerpoint/add-in"
import { HelpIcon, NotificationIcon } from "@/svg/SvgIcons"

const SLIDE_DOWNLOAD_URL = gql`
  query slideDownloadUrl($slideId: String!, $presentationName: String!) {
    slideDownloadUrl(slideId: $slideId, presentationName: $presentationName)
  }
`

const SlideSync = () => {
  const [updatedSlides, setUpdatedSlides] = useState([])

  const [fetchUpdatedSlides] = useLazyQuery(GET_UPDATED_SLIDES, {
    context: { isUsingNewScApi: true },
    fetchPolicy: "network-only"
  })
  const [getSlideDownloadUrl] = useLazyQuery(SLIDE_DOWNLOAD_URL, {
    fetchPolicy: "network-only",
    context: { isUsingNewScApi: true }
  })

  const handleOutdatedSlides = async () => {
    const slides = await getUpdatedSlides(fetchUpdatedSlides)
    if (slides) {
      setUpdatedSlides(slides)
    }
  }

  useEffect(() => {
    if (!Office.context.isDialog) {
      handleOutdatedSlides()
      Office.context.document.addHandlerAsync(Office.EventType.DocumentSelectionChanged, handleOutdatedSlides)
      return () => {
        Office.context.document.removeHandlerAsync(Office.EventType.DocumentSelectionChanged, {
          handler: handleOutdatedSlides
        })
      }
    }
  }, [])

  const handleUpdateOutdatedSlides = async () => {
    try {
      await window.PowerPoint.run(async function (context) {
        const slides = context.presentation.slides
        slides.load("tags/key, tags/value")
        await context.sync()
        const uniqueSlideUrls = {}
        for (const updatedSlide of updatedSlides) {
          await deleteOutdatedSlide(updatedSlide.index)
          const targetSlide =
            updatedSlide.index > 0 ? context.presentation.slides.getItemAt(updatedSlide.index - 1) : null
          targetSlide?.load("id")
          await context.sync()
          const urlKey = `${updatedSlide.id}/${updatedSlide.phash}`
          if (!uniqueSlideUrls[urlKey]) {
            const { data } = await getSlideDownloadUrl({
              variables: {
                slideId: updatedSlide.id,
                presentationName: ""
              }
            })
            uniqueSlideUrls[urlKey] = data.slideDownloadUrl
          }
          await insertSlideInPowerPoint(uniqueSlideUrls[urlKey], targetSlide?.id)
        }
      })
      setUpdatedSlides([])
    } catch (error) {
      console.error("Error:  running add-in" + error)
      throw error
    }
  }

  const handleDialogBox = () => {
    const dialogUrl = `${window.location.origin}/dialog`
    Office.context.ui.displayDialogAsync(dialogUrl, { height: 50, width: 50 }, (asyncResult) => {
      if (asyncResult.status === Office.AsyncResultStatus.Failed) {
        console.error("Error opening dialog:", asyncResult.error.message)
      } else {
        const dialog = asyncResult.value
        // Handle messages sent from the dialog
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, (event) => {
          const message = JSON.parse(event.message)
          console.log("message from child", message, updatedSlides)
          if (message.dialogReady) {
            dialog.messageChild(JSON.stringify(updatedSlides))
          }
        })
      }
    })
  }

  return (
    <div className="pb-[20px] text-[12px]">
      <h2 className="text-[9px] text-[#717171] mb-[15px]">SLIDESYNC</h2>
      <div className="bg-white p-[10px] rounded-[4px] flex items-center justify-between">
        <div className="flex items-center gap-1">
          <span className={`${updatedSlides.length ? "text-sc-blue" : "text-inherit"} flex items-center`}>
            <NotificationIcon />
          </span>
          <span>
            {!updatedSlides.length
              ? "All slides are up to date"
              : `There ${updatedSlides.length === 1 ? "is" : "are"} ${updatedSlides.length} outdated slide${
                  updatedSlides.length > 1 ? "s" : ""
                }`}
          </span>
          {!!updatedSlides.length && (
            <button className="flex items-center" onClick={handleDialogBox}>
              <HelpIcon />
            </button>
          )}
        </div>
        {!!updatedSlides.length && (
          <button className="text-sc-blue" onClick={handleUpdateOutdatedSlides}>
            Update
          </button>
        )}
      </div>
    </div>
  )
}

export default SlideSync
