import { useQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import UploadTemplateForm from "./UploadTemplateForm"
import UploadLibraryForm from "./UploadLibraryForm"
import InviteTeamOwnerForm from "./InviteTeamOwnerForm"
import ChangeTeamOwner from "./ChangeTeamOwner"
import GodMode from "./GodMode"
import CreateGroup from "./CreateGroup"
import CreatePlan from "./CreatePlan"
import DeleteUser from "./DeleteUser"
import Trial from "./Trial"
import { AdminData, OrganizationsData, OrganizationsSlidesStatistics } from "../../graphql/queries"
import { ErrorPlaceholder, MainLoader } from "../utils/placeholders"
import { Modal } from "../modals/Modal"
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table"

const columnHelper = createColumnHelper()

const AdminPage = () => {
  const [isOpened, setIsOpened] = useState(false)
  const [component, setComponent] = useState("")
  const [action, setAction] = useState("add")
  const [objectId, setObjectId] = useState("")
  const [plans, setPlans] = useState([])
  const [groups, setGroups] = useState([])

  const {
    data: organizationsData,
    loading: getOrganizationsLoading,
    error: getOrganizationsError
  } = useQuery(OrganizationsData, {
    context: { isUsingNewScApi: true },
    fetchPolicy: "network-only"
  })
  const { data, loading, error } = useQuery(AdminData)
  const { data: statisticsResponse = { organizationsSlidesStatistics: [] } } = useQuery(OrganizationsSlidesStatistics, {
    fetchPolicy: "network-only",
    context: { isUsingNewScApi: true }
  })
  const { organizationsSlidesStatistics: statistics } = statisticsResponse

  useEffect(() => {
    if (data) {
      setPlans(data.admin.plans)
    }
  }, [data])

  useEffect(() => {
    if (organizationsData?.getOrganizations && organizationsData.getOrganizations.success) {
      const organizations = organizationsData.getOrganizations.organizations.map((organization) => {
        return {
          ...organization,
          id: `Group~${organization.id}`,
          groupId: organization.id,
          owner: {
            acceptTeamOwnerInvitationToken: organization.owner
              ? organization.owner.acceptTeamOwnerInvitationToken || "Owner registered already"
              : "Owner does not exist"
          }
        }
      })
      setGroups(organizations)
    }
  }, [organizationsData])

  const openModal = (_component, _action, _objectId) => {
    setIsOpened(true)
    setComponent(_component)
    setAction(_action)
    setObjectId(_objectId)
  }

  const closeModal = () => {
    setIsOpened(false)
    setAction("add")
    setObjectId("")
  }

  const planColumns = [
    columnHelper.accessor("planId", { header: "Plan" }),
    columnHelper.accessor("name", { header: "Name" }),
    columnHelper.accessor("amount", { header: "Amount" }),
    columnHelper.accessor("interval", { header: "Interval" }),
    columnHelper.accessor("id", {
      header: "Action",
      cell: (info) => (
        <button
          onClick={() => {
            openModal("create_plan", "edit", info.getValue())
          }}
        >
          Edit
        </button>
      )
    })
  ]

  const groupColumns = [
    columnHelper.accessor("groupId", { header: "Group" }),
    columnHelper.accessor("owner.acceptTeamOwnerInvitationToken", { header: "Invitation Link" }),
    columnHelper.accessor("id", {
      header: "Action",
      cell: (info) => (
        <button
          onClick={() => {
            openModal("create_group", "edit", info.getValue())
          }}
        >
          Edit
        </button>
      )
    })
  ]

  const plansTable = useReactTable({ columns: planColumns, data: plans, getCoreRowModel: getCoreRowModel() })
  const groupsTable = useReactTable({ columns: groupColumns, data: groups, getCoreRowModel: getCoreRowModel() })

  const tables = [
    { title: "Plans", tableInstance: plansTable },
    { title: "Groups", tableInstance: groupsTable }
  ]

  if (loading || getOrganizationsLoading) return <MainLoader />
  if (error || getOrganizationsError) return <ErrorPlaceholder />

  const renderContent = () => {
    switch (component) {
      case "invitation":
        return <InviteTeamOwnerForm />
      case "upload":
        return <UploadTemplateForm groups={data.admin.groups} />
      case "upload_library":
        return <UploadLibraryForm groups={data.admin.groups} />
      case "god_mode":
        return <GodMode />
      case "create_group":
        return <CreateGroup action={action} objectId={objectId} plans={data.admin.plans} />
      case "create_plan":
        return <CreatePlan action={action} objectId={objectId} />
      case "change_team_owner":
        return <ChangeTeamOwner />
      case "delete_user":
        return <DeleteUser />
      case "trial":
        return <Trial plans={data.admin.plans} />
      default:
        return <div />
    }
  }

  return (
    <div className="profile-page">
      <Modal
        className="sc-modal-body admin-modal"
        isOpened={isOpened}
        overlayClassName="sc-modal-bg"
        portalClassName="sc-modal"
      >
        <button aria-label="Close" className="close" onClick={closeModal}>
          <span aria-hidden="true">×</span>
        </button>
        {renderContent()}
      </Modal>
      <div className="admin-nav">
        <button
          className="btn btn-lightblue"
          onClick={() => {
            openModal("invitation")
          }}
          style={{ marginRight: "10px" }}
        >
          Invite team owner
        </button>
        <button
          className="btn btn-lightblue"
          onClick={() => {
            openModal("upload")
          }}
          style={{ marginRight: "10px" }}
        >
          Upload templates
        </button>
        <button
          className="btn btn-lightblue"
          onClick={() => {
            openModal("upload_library")
          }}
          style={{ marginRight: "10px" }}
        >
          Upload library
        </button>
        <button
          className="btn btn-lightblue"
          onClick={() => {
            openModal("god_mode")
          }}
          style={{ marginRight: "10px" }}
        >
          God Mode
        </button>
        <button
          className="btn btn-lightblue"
          onClick={() => {
            openModal("create_group", "add", "")
          }}
          style={{ marginRight: "10px" }}
        >
          Create Group
        </button>
        <button
          className="btn btn-lightblue"
          onClick={() => {
            openModal("trial", "add", "")
          }}
          style={{ marginRight: "10px" }}
        >
          Start trial
        </button>
        <button
          className="btn btn-lightblue"
          onClick={() => {
            openModal("create_plan", "add", "")
          }}
        >
          Create Plan
        </button>
        <button
          className="btn btn-lightblue"
          onClick={() => {
            openModal("change_team_owner")
          }}
        >
          ChangeTeam Owner
        </button>
        <button
          className="btn btn-lightblue"
          onClick={() => {
            openModal("delete_user")
          }}
        >
          Delete user
        </button>
        <Link className="btn btn-lightblue" to="/admin-panel/users">
          Go to users
        </Link>
        {/*<Link className="btn btn-lightblue" to="/admin-panel/new-client">*/}
        {/*	New client*/}
        {/*</Link>*/}
      </div>
      <h2 className="mb-3">Groups slides statistics:</h2>
      <div className="mb-3">
        {statistics.map((item) => (
          <div className="mb-2" key={item.groupName}>
            <div>Client Name: {item.groupName}</div>
            <ul>
              {item.resolutions.map((item) => (
                <li key={item.label}>
                  <div>
                    Resolution {item.label}: {item.count} slides | {item.percentage.toFixed(1)}% of total
                  </div>
                </li>
              ))}
            </ul>
            <div>Total: {item.total} slides | 100%</div>
          </div>
        ))}
      </div>
      {tables.map((table) => (
        <div className="-striped -highlight border-b-2 border-solid border-sc-hover-dark p-2 m-2" key={table.title}>
          <h2 className="font-bold text-lg mb-2">{table.title}</h2>
          <table className="w-[100%]">
            <thead className="">
              {table.tableInstance.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th className="border-2 border-solid border-sc-hover-dark p-4" key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.tableInstance.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td className="p-4" key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
            <tfoot>
              {table.tableInstance.getFooterGroups().map((footerGroup) => (
                <tr key={footerGroup.id}>
                  {footerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </tfoot>
          </table>
        </div>
      ))}
    </div>
  )
}

export default AdminPage
