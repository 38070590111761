import { useContext } from "react"
import { UserDataContext } from "@/hooks/UserDataHook"

const UserEditMode = () => {
  const {
    handleEditModeChange,
    user: { isEditModeActive }
  } = useContext(UserDataContext)

  return (
    <div className="flex items-center">
      <div className="h-[19.5px] border-l border-solid border-l-[#8792A0] mx-[21px]" />
      <div className="flex items-center gap-[14px]" data-testid="edit-mode">
        <span className={`${isEditModeActive ? "text-[#FFF]" : "text-[#ffffff80]"} text-[13px] text-nowrap`}>
          Edit mode
        </span>
        <label className="inline-flex items-center cursor-pointer  w-[29.75px] h-[17px]">
          <input
            className="sr-only peer"
            defaultChecked={isEditModeActive}
            onChange={(e) => handleEditModeChange(e.target.checked)}
            type="checkbox"
            value=""
          />
          <div className="w-[29.75px] h-[17px] relative bg-[#8792A0] peer-focus:outline-none rounded-full peer dark:bg-gray-600 peer-checked:after:translate-x-[11px] rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[1px] after:start-[2px] after:bg-white  after:rounded-full after:h-[15px] after:w-[15px] after:transition-all peer-checked:bg-[#1683FB]" />
        </label>
      </div>
    </div>
  )
}
export default UserEditMode
