import { ResetPasswordSvgIcon } from "../../svg/ResetPasswordSvg"
import { Ellipsis } from "../utils/tooltip"

export const SentPasswordInfo = ({ email, handleTryAgain }) => (
  <div className="sc-auth-panel">
    <div className="sc-forgot-panel sc-check-email">
      <h1>
        Reset instructions has been sent to <br />
        <b>
          <Ellipsis length={40} text={email} tooltip />
        </b>
      </h1>
      <ResetPasswordSvgIcon />
      <p>
        If you don't see our email within minutes
        <br />
        check your spam folder or <span onClick={handleTryAgain}>try again</span>
      </p>
    </div>
  </div>
)
