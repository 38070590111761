import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client"
import { useContext, useEffect, useRef, useState } from "react"
import { useDrop } from "react-dnd"
import { useLocation } from "react-router-dom"
import { CircularProgressbar } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"

import CartItemsList from "./CartItemsList"
import ScrollLockUtils from "../utils/scrolllock"
import { MERGE_SLIDES } from "../../graphql/mutations"
import { PRESENTATION_URL } from "../../graphql/queries"
import { GET_JOB } from "../../graphql/queries"
import { Modal } from "../modals/Modal"

import CartHeader from "./CartHeader"
import CartModalForm from "../modals/CartModalForm"
import CartModalQuestion from "../modals/CartModalQuestion"
import { UserDataContext } from "../../hooks/UserDataHook"
import { SourceSlide } from "./types"

const CartContainer = () => {
  const [downloading, setDownloading] = useState<boolean>(false)
  const [cartError, setCartError] = useState(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [content, setContent] = useState<string>("question")
  const [closeAfterSave, setCloseAfterSave] = useState<boolean>(false)
  const [downloadProgress, setDownloadProgress] = useState<number>(0)
  const { user, updateSlidesInCartFunction } = useContext(UserDataContext)
  const { cart, teamOwner, group } = user

  const client = useApolloClient()

  // const [jobCompleted, setJobCompleted] = useState(true)

  // const [getJob, { data: jobData, startPolling, stopPolling }] = useLazyQuery(GET_JOB, {
  const [getJob, { stopPolling }] = useLazyQuery(GET_JOB, {
    notifyOnNetworkStatusChange: true,
    context: { isUsingNewScApi: true },
    pollInterval: 1500,
    fetchPolicy: "network-only",
    onCompleted: ({ job }) => {
      const { lifecycle } = job[0]
      if (lifecycle.status !== "active" && lifecycle.status !== "waiting") {
        stopPolling()
      }

      if (lifecycle.status === "failed") {
        setCartError(true)
      }

      if (lifecycle.status === "completed") {
        setDownloading(false)
        getPresentationUrl()
      }

      setDownloadProgress(lifecycle.progress)
    }
  })

  // useEffect(() => {
  //   if (jobData) {
  //     console.log("BU: jobData", jobData)
  //     if (jobData.job[0].lifecycle.status === "active") {
  //       setJobCompleted(false)
  //     } else if (jobData.job[0].lifecycle.status === "completed") {
  //       setJobCompleted(true)
  //       setDownloading(false)
  //       getPresentationUrl()
  //     }
  //   }
  // }, [jobData])

  // useEffect(() => {
  //   if (!jobCompleted) {
  //     startPolling(3000)
  //   } else {
  //     stopPolling()
  //   }
  //   return () => {
  //     stopPolling()
  //   }
  // }, [stopPolling, startPolling, jobCompleted])

  const [getPresentationUrl] = useLazyQuery(PRESENTATION_URL, {
    context: { isUsingNewScApi: true },
    variables: { id: cart?.batchId },
    fetchPolicy: "no-cache",
    onCompleted: ({ presentationUrl }) => {
      if (presentationUrl) {
        window.location.href = presentationUrl
      } else {
        setCartError(true)
      }
    }
  })

  const [mergeSlides] = useMutation(MERGE_SLIDES, {
    context: { isUsingNewScApi: true },
    onCompleted: async ({ mergeSlides }) => {
      await getJob({ variables: { queueName: mergeSlides.job.queueName, jobIds: [mergeSlides.job.id] } })
    }
  })

  const [{ isOver }, dropRef] = useDrop({
    accept: "cartSlide",
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true })
    }),
    drop: (sourceSlide: SourceSlide, monitor) => {
      if (!monitor.isOver({ shallow: true })) {
        return
      }
      const items = Array.from(cart?.slides || [])
      if (cart?.slides.some((slide) => slide.slideId === sourceSlide.slide.slideId)) {
        items.splice(sourceSlide.position - 1, 1)
      }
      items.push(sourceSlide.slide)
      updateSlidesInCartFunction(items)
    }
  })

  let timeout: number

  const cartBody = useRef<HTMLDivElement | null>()
  useEffect(() => {
    if (cartBody.current) {
      ScrollLockUtils.scrollLock(cartBody.current)
    }
    return () => {
      ScrollLockUtils.scrollRelease(cartBody.current)
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartBody.current])

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
    setContent("question")
    setCloseAfterSave(false)
  }

  const changeModalContent = () => {
    setContent("form")
  }

  const openForm = () => {
    setIsOpen(true)
    setContent("form")
  }

  const closeCart = () => {
    if (cart && cart.slides.length > 0 && !JSON.parse(localStorage.getItem("cartIsSaved") as string)) {
      openModal()
      setCloseAfterSave(true)
    } else {
      updateSlidesInCartFunction([])
    }
  }

  const handleDownload = async () => {
    setCartError(false)
    setDownloadProgress(0)
    setDownloading(true)
    await mergeSlides({ variables: { id: cart?.batchId } })
  }

  // const cssCartBody = `cart-body ${isOver ? "is-over-up" : ""}`

  return (
    <>
      <Modal
        className="sc-modal-body sc-modal-del-save h-auto"
        isOpened={isOpen}
        overlayClassName="sc-modal-bg"
        portalClassName="sc-modal"
      >
        {content === "form" ? (
          <CartModalForm
            // TODO: BU: replace createBatch later (everywhere)
            client={client}
            closeAfterSave={closeAfterSave}
            closeModal={closeModal}
            slides={cart?.slides || []}
          />
        ) : (
          <CartModalQuestion changeModalContent={changeModalContent} client={client} closeModal={closeModal} />
        )}
      </Modal>
      <div
        className="fixed w-[312px] min-[2050px]:w-[402px] h-full z-30 top-0 right-0 border-l border-[#D6DEE5] shadow-[-5px_2px_28px_#00000026] bg-[#f6f7f8]"
        data-testid="cart"
        ref={dropRef}
      >
        <div className="flex flex-col h-cart-top-h ">
          <CartHeader canSave={group.groupId !== "management" || teamOwner} closeCart={closeCart} openForm={openForm} />
          <div
            className="pb-[10px] min-h-[90%] max-h-[100%] mt-[15px] mr-[14px] overflow-y-auto scrollbar-webkit"
            ref={(_ref) => {
              cartBody.current = _ref
            }}
          >
            <CartItemsList setCartError={setCartError} />
            {isOver && <span className="line" />}
          </div>
          <div
            className="w-full self-end border-t border-[#DCDEE3] p-[20px] absolute right-0 bottom-0 text-center"
            data-testid="cart-footer"
          >
            {cartError && (
              <p>
                <small>
                  <strong>⚠️ The download was unable to complete.</strong>
                  <br />
                  Sorry, there was a problem downloading the slides.
                  <br />
                  Please try again later.
                </small>
              </p>
            )}
            <button
              className="btn btn-lightblue w-[232px] py-[6px] px-[32px] rounded-[18px]"
              data-testid="btn-cart-download"
              disabled={downloading || !cart || cart?.slides.length === 0}
              onClick={handleDownload}
            >
              {downloading ? (
                <span>
                  <span>Downloading </span>
                  <span className="saving">
                    <span>. </span>
                    <span>. </span>
                    <span>.</span>
                  </span>
                  <CircularProgressbar className="download-progress" strokeWidth={14} value={downloadProgress} />
                </span>
              ) : (
                "Download slides"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default CartContainer
