import { useContext } from "react"
import { PricingModalContext } from "../../hooks/PricingModalHook"
import { UserDataContext } from "../../hooks/UserDataHook"

const PricingComponent = ({ data }) => {
  const { openModal, setSelectedPlan } = useContext(PricingModalContext)
  const { user } = useContext(UserDataContext)
  const { subscription, group } = user

  const currentPlan = subscription && subscription.plan
  const groupId = group.groupId
  const activeSubscription = subscription && subscription.active

  const onClick = (data) => {
    openModal("payment")
    setSelectedPlan(data)
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "scPlanChosen",
      plan: { id: data.planId, name: data.name, amount: data.amount }
    })
    window.dataLayer.push({ event: "scCheckoutOpened" })
  }

  const currentPlanId = currentPlan && currentPlan.planId

  return (
    <div
      className={`plan${
        data.planId === "slidecamp-plus-monthly" && data.planId !== currentPlanId ? " plan-recommended" : ""
      }`}
    >
      <h2>{data.name}</h2>
      <div className="info">
        <h3>
          ${data.amount / 100}
          <span>{`/ ${data.interval}`}</span>
        </h3>
        <ul>
          {data.description.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      </div>
      {(data.planId !== currentPlanId || !activeSubscription) && groupId !== "prudential" ? (
        <button className="btn btn-info" onClick={onClick.bind(this, data)}>
          Choose This Plan
        </button>
      ) : (
        <button className="btn btn-info" disabled>
          Your current plan
        </button>
      )}
    </div>
  )
}

export default PricingComponent
