import { useContext, useEffect } from "react"
import { useDrop, useDrag } from "react-dnd"
import { getEmptyImage } from "react-dnd-html5-backend"
import { Slide } from "../../graphql/types/queries/batch"
import { SourceSlide } from "./types"
import { UserDataContext } from "@/hooks/UserDataHook"

interface CartItemProps {
  position: number
  slide: Slide
}

const CartItem = ({ position, slide }: CartItemProps) => {
  const {
    updateSlidesInCartFunction,
    user: { cart }
  } = useContext(UserDataContext)

  const [{ isOver }, dropRef] = useDrop({
    accept: "cartSlide",
    collect: (monitor) => ({
      isOver: monitor.isOver()
    }),
    drop: (sourceSlide: SourceSlide) => {
      const items = Array.from(cart?.slides || [])
      if (cart?.slides.some((slide) => slide.slideId === sourceSlide.slide.slideId)) {
        items.splice(sourceSlide.position - 1, 1)
        sourceSlide.position < position
          ? items.splice(position - 2, 0, sourceSlide.slide)
          : items.splice(position - 1, 0, sourceSlide.slide)
        window.dataLayer = window.dataLayer || []
        sourceSlide.position !== (sourceSlide.position < position ? position - 1 : position) &&
          window.dataLayer.push({
            event: "scReorderSlideInCart",
            reorderedSlide: {
              id: sourceSlide.slide.slideId,
              name: sourceSlide.slide.name,
              previousPosition: sourceSlide.position,
              currentPosition: sourceSlide.position < position ? position - 1 : position
            }
          })
      } else {
        items.splice(position - 1, 0, sourceSlide.slide)
      }
      updateSlidesInCartFunction(items)
    }
  })

  const [{ isDragging }, dragRef, preview] = useDrag({
    type: "cartSlide",
    item: {
      slide,
      position,
      source: "cart"
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteSlide = async () => {
    const items = cart?.slides.filter((x) => x.slideId !== slide.slideId)
    await updateSlidesInCartFunction(items || [])
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "scDeleteSlideFromCart",
      deletedSlide: { id: slide.slideId, name: slide.name }
    })
  }

  const cssClass = `img-wrapper ${isDragging ? "on-drag" : ""}`
  const cssClassWrapper = `slide-wrapper ${isOver ? "is-over-down" : ""}`
  return (
    <li ref={dropRef}>
      <div className={cssClassWrapper}>
        <span>{position}</span>
        <div className={cssClass}>
          {slide === null ? (
            <div className="cart-loading" ref={dragRef}>
              <div className="sc-loading" />
            </div>
          ) : (
            <img
              alt="thumbnail"
              data-testid="cart-thumbnail"
              ref={dragRef}
              src={slide.thumbUrl.replace("{width}", "173")}
              srcSet={`${slide.thumbUrl.replace("{width}", "346")} 2x`}
            />
          )}
          {!isDragging && (
            <button className="close-icon-circle" onClick={deleteSlide}>
              <svg version="1.1" viewBox="0 0 8.7 8.7" x="0px" y="0px">
                <path
                  className="close-icon-circle-fill"
                  d="M5.1,4.4l3.5-3.5c0.1-0.1,0.1-0.2,0.1-0.4s0-0.3-0.1-0.4C8.4,0,8,0,7.9,0.1L4.4,3.6L0.9,0.1
                  C0.7,0,0.3,0,0.1,0.1C0,0.2,0,0.4,0,0.5s0,0.3,0.1,0.4l3.5,3.5L0.1,7.9C0,8,0,8.1,0,8.2s0,0.3,0.1,0.4s0.2,0.1,0.4,0.1
                  s0.3,0,0.4-0.1l3.5-3.5l3.5,3.5C8,8.7,8.1,8.7,8.2,8.7s0.3,0,0.4-0.1s0.1-0.2,0.1-0.4s0-0.3-0.1-0.4L5.1,4.4z"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
    </li>
  )
}

export default CartItem
