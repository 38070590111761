import { useContext } from "react"
import { Link } from "react-router-dom"
import { AuthContext } from "@/hooks/AuthHook"
import { useClickOutside } from "@/hooks/ClickOutsideHook"
import { Ellipsis } from "../utils/tooltip"
import { dateDiffInDays } from "../utils/dates"

const UserMenuList = ({ closeDropdown, user, pricingTrigger }) => {
  const { logout } = useContext(AuthContext)
  const ref = useClickOutside(closeDropdown)

  const handleClickPricing = () => {
    pricingTrigger()
    closeDropdown()
  }

  const plan = user.subscription && user.subscription.plan

  return (
    <div
      className="rounded-none text-[#0f2642] p-0"
      ref={(_ref) => {
        ref.current = _ref
      }}
    >
      <div className="overflow-auto p-[20px]">
        <div
          className="flex items-center justify-center bg-[#e9eaed] text-[#cccccc] float-left text-lg font-medium h-[70px] w-[70px] uppercase p-0"
          data-testid="user-dropdown-menu-avatar"
        >
          {user.firstName.charAt(0) + user.lastName.charAt(0)}
        </div>
        <div className="float-left w-[190px] text-[14px] ml-[15px] p-0">
          <div className="font-bold">{`${user.firstName} ${user.lastName}`}</div>
          <div className="font-light mb-[15px]">
            <Ellipsis length={20} text={user.email} tooltip={false} />
          </div>
          {!plan && user.trialTo && new Date() < user.trialTo ? (
            <div>
              <p className="float-left font-light text-[13px]">Trial</p>
              <Link id="pricing_link" style={{ color: "#3390FF" }} to="/pricing">
                {`${dateDiffInDays(user.trialTo, new Date())} days left`}
              </Link>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
      <ul className="w-auto" data-testid="user-dropdown-menu-list">
        <li className="border-t border-[#e9eaed] inline-block text-[15px] font-light m-0 w-full">
          <Link
            className="border-l-[3px] border-white text-[#6b6f7a] cursor-pointer block p-4 pl-5 no-underline hover:border-l-[#0080ff] hover:text-[#0080ff]"
            id="my_profile"
            onClick={closeDropdown}
            to="/myprofile/account-information"
          >
            My profile
          </Link>
        </li>
        {(user.teamOwner || user.role === "admin") && (
          <li className="border-t border-[#e9eaed] inline-block text-[15px] font-light m-0 w-full">
            <Link
              className="border-l-[3px] border-white text-[#6b6f7a] cursor-pointer block p-4 pl-5 no-underline hover:border-l-[#0080ff] hover:text-[#0080ff]"
              id="my_profile"
              onClick={closeDropdown}
              to="/myprofile/myteam"
            >
              Users
            </Link>
          </li>
        )}
        {(user.teamOwner || user.role === "admin") && (
          <li className="border-t border-[#e9eaed] inline-block text-[15px] font-light m-0 w-full">
            <Link
              className="border-l-[3px] border-white text-[#6b6f7a] cursor-pointer block p-4 pl-5 no-underline hover:border-l-[#0080ff] hover:text-[#0080ff]"
              id="my_profile"
              onClick={closeDropdown}
              to="/myprofile/teams"
            >
              Teams
            </Link>
          </li>
        )}
        {user.admin && (
          <li className="border-t border-[#e9eaed] inline-block text-[15px] font-light m-0 w-full">
            <Link
              className="border-l-[3px] border-white text-[#6b6f7a] cursor-pointer block p-4 pl-5 no-underline hover:border-l-[#0080ff] hover:text-[#0080ff]"
              id="admin_panel"
              onClick={closeDropdown}
              to="/admin-panel"
            >
              Admin panel
            </Link>
          </li>
        )}
        <li className="border-t border-[#e9eaed] inline-block text-[15px] font-light m-0 w-full">
          <a
            className="border-l-[3px] border-white text-[#6b6f7a] cursor-pointer block p-4 pl-5 no-underline hover:border-l-[#0080ff] hover:text-[#0080ff]"
            href="mailto:kep064ao@intercom-mail.com"
            id="intercom_support"
            onClick={closeDropdown}
          >
            Support
          </a>
        </li>
        {user.role === "owner" && (
          <li className="border-t border-[#e9eaed] inline-block text-[15px] font-light m-0 w-full">
            <Link
              className="border-l-[3px] border-white text-[#6b6f7a] cursor-pointer block p-4 pl-5 no-underline hover:border-l-[#0080ff] hover:text-[#0080ff]"
              id="pricing_link"
              onClick={handleClickPricing}
              to="/pricing"
            >
              {!plan ? "Upgrade plan" : "Change plan"}
            </Link>
          </li>
        )}
        <li className="border-t border-[#e9eaed] inline-block text-[15px] font-light m-0 w-full">
          <span
            className="border-l-[3px] border-white text-[#6b6f7a] cursor-pointer block p-4 pl-5 no-underline hover:border-l-[#0080ff] hover:text-[#0080ff]"
            onClick={logout}
          >
            Log Out
          </span>
        </li>
      </ul>
    </div>
  )
}

export default UserMenuList
