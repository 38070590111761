interface UploadingIndicatorProps {
  slidesWithProgress: Record<string, number>
}

const UploadingIndicator = ({ slidesWithProgress }: UploadingIndicatorProps) => {
  const slides = Object.entries(slidesWithProgress)
  return (
    <div className="uploading-indicator-wrapper">
      <div className="uploading-indicator">
        Uploading: <br />
        {slides.map((slide) => {
          return (
            <div className="upload-item" key={slide[0]}>
              <b>{slide[0]}</b> - {Math.round(slide[1] * 100)}%
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default UploadingIndicator
