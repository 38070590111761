import { dateToLLFormat } from "../../utils/dates"

const SubscriptionRemoval = ({ closeModal, removeSubscription, plan, subscription, busy }) => (
  <div className="sc-modal-profile-sub-cancel-text">
    <h2>
      Are you sure you want to <span>cancel&nbsp;your&nbsp;subscription</span>?
    </h2>
    <p>
      We hate to see you go! You’ll still be able to use your <b>{plan.name}</b> until the end of the pay period{" "}
      <b>{dateToLLFormat(subscription.payedTo)}</b> and will always have access to our free slides! Upgrade again at any
      time!
    </p>
    <div className="profile-buttons-ctr">
      <button className="btn btn-gray-blue" disabled={busy} onClick={closeModal}>
        No, don't cancel
      </button>
      <button className="btn btn-dark-blue" disabled={busy} onClick={removeSubscription}>
        Cancel subscription
      </button>
    </div>
  </div>
)

export default SubscriptionRemoval
