import { gql, useQuery } from "@apollo/client"
import { Fragment, useContext, useState } from "react"
import { withRouter } from "react-router"
import { Ellipsis } from "../utils/tooltip"
import { MainModalContext } from "../../hooks/MainModalHook"
import { ErrorPlaceholder } from "../utils/placeholders"
import { Menu, Transition } from "@headlessui/react"
import { UserDataContext } from "@/hooks/UserDataHook"

const ORGANIZATION_UNITS_DATA = gql`
  query organization($_id: String!) {
    organization(_id: $_id) {
      units {
        _id
        name
        description
        members {
          _id
          firstName
          lastName
        }
      }
    }
  }
`

const Teams = ({ history }) => {
  const [hoveredUnitId, setHoveredUnitId] = useState(null)
  const {
    user: { group }
  } = useContext(UserDataContext)
  const { openModal } = useContext(MainModalContext)
  const { data, loading, error, refetch } = useQuery(ORGANIZATION_UNITS_DATA, {
    variables: { _id: group._id },
    context: { isUsingNewScApi: true },
    fetchPolicy: "cache-and-network"
  })

  if (loading) return <div className="profile-loading" />
  if (error) return <ErrorPlaceholder />

  const {
    organization: { units }
  } = data

  return (
    <div className="profile-team">
      <h1>Teams </h1>
      <div className="teams-box-container">
        <div className="new-team-button" onClick={() => openModal({ content: "addNewTeam", data: { refetch } })}>
          <p>New Team</p>
        </div>
        {units.map((unit) => (
          <div
            className="relative"
            key={unit._id}
            onMouseEnter={() => setHoveredUnitId(unit._id)}
            onMouseLeave={() => setHoveredUnitId(null)}
          >
            {unit.name !== "all" && (
              <Menu
                as="div"
                className={`absolute text-left top-0 right-3 ${
                  hoveredUnitId === unit._id ? "opacity-100" : "opacity-0"
                }`}
              >
                {() => {
                  return (
                    <>
                      <Menu.Button
                        className="flex text-gray-300 hover:text-blue-500"
                        data-testid="teams-context-menu"
                        id="contextMenu"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <svg
                          aria-hidden="true"
                          className="w-10 h-10"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                        </svg>
                      </Menu.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          className="absolute right-0 z-20 w-56 origin-top-right rounded-md bg-white shadow-lg ring-gray-300 focus:outline-none py-1 transform opacity-100 scale-100"
                          id="contextMenuItems"
                        >
                          <Menu.Item>
                            <>
                              <span
                                className="cursor-pointer block px-4 py-2 text-xs text-gray-500 hover:bg-gray-100 hover:text-[#0b387f]"
                                onClick={() => {
                                  openModal({
                                    content: "removeUnit",
                                    data: { unit, organizationId: group._id }
                                  })
                                }}
                              >
                                Delete
                              </span>
                            </>
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </>
                  )
                }}
              </Menu>
            )}
            <div className="team-box" onClick={() => history.push(`/myprofile/team/${unit._id}`)}>
              <div className="team-info">
                <h2>
                  <Ellipsis length={25} text={unit.name} tooltip />
                </h2>
                <p>
                  <Ellipsis length={25} text={unit.description} tooltip />
                </p>
              </div>
              <MemberBadges members={unit.members} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default withRouter(Teams)

const MemberBadges = ({ members }) => {
  const renderPlusBadge = () => {
    return <div className="team-member team-member-more" key="plus">{`+${members.length - 4}`}</div>
  }

  const number = members.length > 5 ? 4 : members.length

  return (
    <div className="team-member-list">
      <p>{`${members.length} Members`}</p>
      {members.slice(0, number).map((member) => {
        return (
          <div className="team-member" key={member._id}>
            {member.firstName.charAt(0) + member.lastName.charAt(0)}
          </div>
        )
      })}
      {members.length > 5 && renderPlusBadge()}
    </div>
  )
}
