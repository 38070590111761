import { NotSeenEyeIcon, RepeatIcon, TimeIcon } from "../../../../svg/SvgIcons"
import { convertSecondsToTime } from "../../../utils/dates"

const THUMBNAIL_WIDTH = "95"
const THUMBNAIL_WIDTH_2X = "190"

const Slides = ({ logs = [], slides = [], isOpened }) => {
  const data = slides.map((slide) => {
    const { duration, times } = logs.reduce(
      (acc, log) => (log.slide.id === slide.id ? { duration: acc.duration + log.duration, times: acc.times + 1 } : acc),
      {
        duration: 0,
        times: 0
      }
    )

    return { ...slide, duration, times }
  })

  return (
    <div className="grid grid-cols-1 tablet-md:flex flex-wrap gap-4 pt-9">
      {data.map((slide, i) => (
        <div key={slide.id}>
          {isOpened && (
            <div className="w-full">
              <div className="relative">
                <div
                  className={
                    "bg-[#f6f7f8] w-full h-auto shadow-[0_0_12px_1px_rgba(122,127,135,0.2)]"
                  }
                >
                  <picture>
                    <img
                      alt={`Slide ${i}`}
                      className="w-full"
                      src={slide.thumbUrl.replace("{width}", THUMBNAIL_WIDTH)}
                      srcSet={`${slide.thumbUrl.replace("{width}", THUMBNAIL_WIDTH_2X)} 2x`}
                    />
                  </picture>
                  {slide.duration === 0 && (
                    <div className="absolute inset-0 bg-black bg-opacity-40 flex justify-center items-center">
                      <NotSeenEyeIcon />
                    </div>
                  )}
                </div>
              </div>
              <div className="flex gap-2.5 mt-2">
                <div className="flex items-center text-[#979797] text-xs">
                  <TimeIcon />
                  <p className="ml-1" data-testid="activity-slide-viewing-time">
                    {convertSecondsToTime(slide.duration)}
                  </p>
                </div>
                {slide.times > 1 && (
                  <div className="flex items-center text-[#979797] text-xs">
                    <RepeatIcon />
                    <p className="ml-1">
                      <span>{slide.times}</span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default Slides
