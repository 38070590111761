import { useMutation, useQuery } from "@apollo/client"
import { Fragment, useState } from "react"
import InvoiceForm from "../InvoiceForm"
import { BillingInfoData } from "../../../graphql/queries"
import { cancelSubscription } from "../../../graphql/mutations"
import BillingInfo from "./BillingInfo"
import Invoices from "./Invoices"
import { ErrorPlaceholder } from "../../utils/placeholders"

const BillingInfoWrapper = () => {
  const [displayForm, setDisplayForm] = useState(false)
  const { data, loading, error } = useQuery(BillingInfoData, { fetchPolicy: "cache-and-network" })
  const [_cancelSubscription] = useMutation(cancelSubscription, {
    refetchQueries: [{ query: BillingInfoData }]
  })

  if (loading) return <div className="profile-loading" />
  if (error) return <ErrorPlaceholder />

  const { subscription, billingData, invoices } = data.session.user

  const showInvoiceForm = () => {
    setDisplayForm((prev) => !prev)
  }

  return (
    <div className="profile-billing">
      {displayForm ? (
        <InvoiceForm initialValues={billingData || {}} showInvoiceForm={showInvoiceForm} />
      ) : (
        <Fragment>
          <BillingInfo
            billingInfo={billingData}
            cancelSubscription={_cancelSubscription}
            key={"billingInfo"}
            showInvoiceForm={showInvoiceForm}
            subscription={subscription}
          />
          <Invoices billingInfo={billingData} invoices={invoices} key={"invoices"} />
        </Fragment>
      )}
    </div>
  )
}

export default BillingInfoWrapper
