import { ChangeEvent, useContext, useState } from "react"
import { useHistory } from "react-router"
import { Link } from "react-router-dom"
import AppNavLinks from "./AppNavLinks"
import UserInfoTooltip from "../userPanel/UserInfoTooltip"
import { UserDataContext } from "@/hooks/UserDataHook"
import UserEditMode from "../userPanel/UserEditMode"
import { AuthContext } from "@/hooks/AuthHook"
import { useIsInsidePowerPoint } from "@/context/IsInsidePowerPointContext"

const NewNavigationBar = () => {
  const { user } = useContext(UserDataContext)
  const { hasAdminPowers } = useContext(AuthContext)
  const { group, teamOwner, subscription, cart } = user
  const { push } = useHistory()
  const [query, setQuery] = useState("")

  const onSearch = (e: any) => {
    e.preventDefault()
    push(`/search?utf8=✓&query=${query}`)
    setQuery("")
  }

  const inputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
  }

  const userGroup = group.groupId

  const isInsidePowerPoint = useIsInsidePowerPoint()

  // let topNavigation
  // if (userGroup === "management") {
  //   topNavigation = <ManagementNavLinks subscription={subscription} teamOwnerRightsEnabled={teamOwner} />
  // } else if (userGroup === "latimes") {
  //   topNavigation = <LANavLinks subscription={subscription} teamOwnerRightsEnabled={teamOwner} />
  // } else if (userGroup === "bum") {
  //   topNavigation = <AppNavLinks teamOwnerRightsEnabled={teamOwner} />
  // } else {
  //   topNavigation = <AppNavLinksOld teamOwnerRightsEnabled={teamOwner} />
  // }

  const topNavigation = <AppNavLinks teamOwnerRightsEnabled={teamOwner} />

  return (
    <div className={`${cart ? "mr-[312px] min-[2050px]:mr-[402px]" : ""}`}>
      <div
        className={`w-full h-[58px] flex items-center justify-center bg-[#0f2642] mobile-xs:px-[24px] border-b border-solid border-b-[#0f2642] ${
          cart ? "tablet-xl:px-[60px]" : "tablet-sm:px-[60px]"
        }`}
      >
        <div
          className="w-full flex items-center nav-container gap-6 desktop-big:w-[1812px]"
          data-testid="navigation-main-menu"
        >
          <div className="flex items-center w-full justify-start">
            <Link className="flex" to="/dashboard">
              <svg className="h-[19px] w-[85px] mr-[18px]" version="1.1" viewBox="0 0 84.7 17.9" x="0px" y="0px">
                <path
                  d="M83.8,0H0.9C0.4,0,0,0.4,0,0.9v16.2c0,0.5,0.4,0.9,0.9,0.9h82.9c0.5,0,0.9-0.4,0.9-0.9V0.9
                    C84.7,0.4,84.3,0,83.8,0z M83.4,16.7H1.3V1.3h82.1V16.7z M18.3,10.3c0-0.2,0.1-0.3,0.3-0.3h1c0.2,0,0.4,0,0.4,0.3
                    c0,0.1,0.1,0.3,0.2,0.4c0.2,0.2,0.6,0.3,1,0.3c0.6,0,1-0.1,1-0.7c0-0.6-0.9-0.6-1.8-0.9c-1-0.3-2-0.7-2-2.1c0-1.3,1.2-2,2.5-2.1
                    c1.3,0,2.8,0.6,2.8,2c0,0.2-0.1,0.3-0.3,0.3h-1c-0.2,0-0.2-0.1-0.3-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.2-0.2-0.6-0.3-0.9-0.3
                    c-0.3,0-0.9,0.1-0.9,0.6c0,0.6,1.5,0.9,1.9,1c0.7,0.2,1.8,0.5,1.8,1.9c0,1.5-1.2,2.2-2.8,2.2C19.8,12.5,18.3,11.8,18.3,10.3z
                    M25.6,12.1V5.9c0-0.2,0.1-0.3,0.3-0.3h1c0.2,0,0.3,0.1,0.3,0.3v5.1h2.4c0.2,0,0.3,0.1,0.3,0.3v0.8c0,0.2-0.1,0.3-0.3,0.3h-3.7
                    C25.6,12.4,25.6,12.3,25.6,12.1z M35.7,12.4h2.3c1.2,0,2.8-0.7,2.8-2.3V7.9c0-1.6-1.6-2.3-2.8-2.3h-2.3c-0.2,0-0.3,0.1-0.3,0.3v6.2
                    C35.4,12.3,35.5,12.4,35.7,12.4z M37.1,7h1c0.7,0,1.2,0.4,1.2,1v1.9c0,0.7-0.5,1-1.2,1h-1V7z M42.7,12.1V5.9c0-0.2,0.1-0.3,0.3-0.3
                    h4.5c0.2,0,0.3,0.1,0.3,0.3v0.9c0,0.2-0.1,0.3-0.3,0.3h-3.1v1.1h2.8c0.2,0,0.3,0.1,0.3,0.3v0.8c0,0.2-0.1,0.3-0.3,0.3h-2.8v1.4h3.3
                    c0.2,0,0.3,0.1,0.3,0.3v0.8c0,0.2-0.1,0.3-0.3,0.3H43C42.8,12.4,42.7,12.3,42.7,12.1z M49.5,10.2V7.8c0-1.6,1.5-2.3,2.8-2.3
                    c1.3,0,2.6,0.7,2.7,2c0,0.2-0.1,0.3-0.3,0.3h-1c-0.2,0-0.2-0.2-0.3-0.3c-0.2-0.3-0.5-0.5-1.1-0.5c-0.7,0-1.1,0.4-1.1,1V10
                    c0,0.7,0.5,1,1.1,1c0.6,0,1-0.2,1.1-0.7c0-0.2,0.1-0.4,0.3-0.4h1c0.2,0,0.3,0.1,0.3,0.4c0,1.3-1.4,2.2-2.7,2.2
                    C51,12.5,49.5,11.8,49.5,10.2z M56.4,12.4h1.1c0.2,0,0.3-0.1,0.4-0.3l0.3-0.8h2.5l0.3,0.8c0.1,0.2,0.2,0.3,0.4,0.3h1.1
                    c0.2,0,0.2-0.2,0.2-0.3l-2.3-6.2c-0.1-0.2-0.2-0.3-0.4-0.3h-1c-0.2,0-0.3,0.1-0.4,0.3l-2.3,6.2C56.1,12.2,56.1,12.4,56.4,12.4z
                    M59.4,7.5l0.8,2.3h-1.6L59.4,7.5z M64.1,12.1V5.9c0-0.2,0.1-0.3,0.3-0.3h1c0.4,0,0.4,0.2,0.5,0.4l1.4,2.5l1.4-2.5
                    c0.1-0.1,0.1-0.4,0.5-0.4h1c0.2,0,0.3,0.1,0.3,0.3v6.2c0,0.2-0.1,0.3-0.3,0.3h-1c-0.2,0-0.3-0.1-0.3-0.3V8.6l-1,1.8
                    c-0.1,0.1-0.1,0.2-0.3,0.2H67c-0.2,0-0.2-0.1-0.3-0.2l-1-1.8v3.5c0,0.2-0.1,0.3-0.3,0.3h-1C64.2,12.4,64.1,12.3,64.1,12.1z
                    M72.8,12.4h1c0.2,0,0.3-0.1,0.3-0.3v-1.7h1.3c1.8,0,2.7-1,2.7-2.4c0-1.4-0.9-2.4-2.7-2.4h-2.7c-0.2,0-0.3,0.1-0.3,0.3v6.2
                    C72.5,12.3,72.6,12.4,72.8,12.4z M74.1,7h1.3c0.8,0,1.2,0.4,1.2,1c0,0.5-0.4,1-1.2,1h-1.3V7z M31.7,12.2V5.7c0-0.1,0.1-0.2,0.2-0.2
                    h1.3c0.1,0,0.2,0.1,0.2,0.2v6.5c0,0.1-0.1,0.2-0.2,0.2h-1.3C31.8,12.4,31.7,12.3,31.7,12.2z M5.5,12v-0.6c0-0.2,0.2-0.4,0.4-0.4
                    l8.7,0c0.2,0,0.4,0.2,0.4,0.4l0,0.6c0,0.2-0.2,0.4-0.4,0.4l-8.7,0C5.6,12.4,5.5,12.2,5.5,12z M14.9,8.6l0,0.6c0,0.2-0.2,0.4-0.4,0.4
                    l-5.5,0c-0.2,0-0.4-0.2-0.4-0.4V8.6c0-0.2,0.2-0.4,0.4-0.4l5.5,0C14.8,8.3,14.9,8.4,14.9,8.6z M7.1,6.6V5.9c0-0.2,0.2-0.4,0.4-0.4
                    l7.1,0c0.2,0,0.4,0.2,0.4,0.4l0,0.6c0,0.2-0.2,0.4-0.4,0.4l-7.1,0C7.3,6.9,7.1,6.8,7.1,6.6z"
                  fill="#FFFFFF"
                  id="XMLID_14_"
                />
              </svg>
            </Link>
            {topNavigation}
          </div>
          <div className="w-[40%] items-center flex-row mt-[2px] justify-end flex">
            <ul className="relative flex items-center flex-row justify-start m-0 p-0 border-x-0">
              <li className="sc-search flex-row text-base font-normal mx-1.5 my-0 pt-0.5 hidden tablet-xl:flex">
                <form className="relative" onSubmit={onSearch}>
                  <input
                    className="bg-transparent border-none shadow-none text-[#0f2642] cursor-pointer inline-block text-sm font-hairline h-6 outline-0 pl-6 relative w-0 z-10 hover:opacity-100 focus:border-b focus:border-b-[#fff] focus:shadow-none focus:text-white focus:cursor-text focus:w-64 focus:z-10"
                    name="search"
                    onChange={inputOnChange}
                    placeholder="Search slides"
                    type="text"
                    value={query}
                  />
                  <input
                    className="bg-[url('/icons/search.svg')] bg-no-repeat bg-[length:16px_16px] border-none text-red-500 cursor-pointer inline-block h-4.75 outline-none text-[0px] absolute left-0 top-[0.3rem] w-5 opacity-50 hover:opacity-100 focus:shadow-none focus:outline-none"
                    type="submit"
                  />
                </form>
              </li>
              <li className="flex-row text-[13px] font-normal mx-[6px] my-0 pt-[2px] hidden tablet-xl:flex">
                <Link
                  className="text-[#ffffff80] h-full p-0 m-0 w-full hover:text-white hover:font-normal"
                  to="/favorites"
                >
                  <svg className="heart-icon my-[2px] h-[16px] w-[19px]" viewBox="0 0 18 15.4">
                    <path
                      className="transition-colors duration-200 hover:stroke-white"
                      d="M2,1.7C2.3,1.3,2.8,1,3.3,0.8C5.5-0.1,8,0.9,9,3c1-2.1,3.5-3.1,5.7-2.2c2.3,0.9,3.4,3.5,2.5,5.8
                c-0.5,1.1-2.4,3.1-4.3,4.8L12.3,12c-1.6,1.5-3.1,2.7-3.3,2.9c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0s0,0,0,0c-0.4-0.3-7.3-6-8.2-8.2
                C0.2,5.1,0.5,3.5,1.4,2.2L2,1.7z"
                      fill="transparent"
                      stroke="rgba(255, 255, 255, 0.5)"
                    />
                  </svg>
                </Link>
              </li>
              <UserInfoTooltip user={user} />
              {hasAdminPowers && !isInsidePowerPoint && <UserEditMode />}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewNavigationBar
