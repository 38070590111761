import LogEntry from "./LogEntry"

const SearchPerformedLog = ({ record, index, activeAccordionId, setActiveAccordionId }) => {
  const recordTime = new Date(record.createdAt).getTime()
  const { collections, presentations, slides } = record

  const numberOfResults = (collections.length ?? 0) + (presentations.length ?? 0) + (slides.length ?? 0)

  const getResultList = () => {
    return (
      <ul>
        {record.presentations.map((presentation) => (
          <li key={presentation.id}>[Presentation] {presentation.name}</li>
        ))}
        {record.categories.map((category) => (
          <li key={category.id}>[Category] {category.name}</li>
        ))}
        {record.slides.map((slide) => (
          <li key={slide.id}>[Slide] {slide.name}</li>
        ))}
      </ul>
    )
  }

  const headerContent = (
    <>
      <div className="text-left flex flex-col flex-grow overflow-hidden">
        <p className="text-sm" data-testid="activity-log-entry">
          <strong>{`${record.user?.firstName} ${record.user?.lastName}`}</strong> {"searched for "}
          <strong>{record.query}</strong> and received
          <strong> {numberOfResults} results</strong>
        </p>
      </div>
    </>
  )

  const detailsContent = (
    <>
      {activeAccordionId === index && (
        <div className="bg-[#f6f7f8] border grid grid-cols-1 tablet-md:flex flex-wrap gap-4 p-9">
          <>
            {record.slides.slice(0, 20).map((slide, i) => (
              <div
                className="bg-[#f6f7f8] w-full tablet-md:w-[169px] shadow-[0_0_12px_1px_rgba(122,127,135,0.2)]"
                key={i}
              >
                <picture className="w-full h-full inline-block">
                  <img
                    alt={slide.name}
                    className="inline w-full"
                    key={`${recordTime}-${slide.id}-${i}`}
                    src={slide?.thumbUrl.replace("{width}", "208")}
                  />
                </picture>
              </div>
            ))}
            {/* TODO: add styles for the rest of the results */}
            {/* {getResultList(record)} */}
          </>
        </div>
      )}
    </>
  )

  return (
    <LogEntry
      activeAccordionId={activeAccordionId}
      detailsContent={numberOfResults ? detailsContent : null}
      headerContent={headerContent}
      iconName="log-search"
      index={index}
      isMoreContentAvailable={!!numberOfResults}
      recordTime={recordTime}
      setActiveAccordionId={setActiveAccordionId}
    />
  )
}

export default SearchPerformedLog
