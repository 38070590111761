import Subscription from "./Subscription"
import SubscriptionPlaceholder from "./SubscriptionPlaceholder"
import BillingInfoDetails from "./BillingInfoDetails"

const BillingInfo = ({ billingInfo, showInvoiceForm, subscription, cancelSubscription }) => (
  <div className="profile-billing-info">
    <h1>Billing Information</h1>
    {subscription ? (
      <Subscription cancelSubscription={cancelSubscription} subscription={subscription} />
    ) : (
      <SubscriptionPlaceholder />
    )}
    <BillingInfoDetails billingInfo={billingInfo} showInvoiceForm={showInvoiceForm} subscription={subscription} />
  </div>
)

export default BillingInfo
