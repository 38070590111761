interface CardTypeProps {
  cardType: string
}

export const CardType = ({ cardType }: CardTypeProps) => {
  switch (cardType) {
    case "visa":
      return (
        <li>
          <i className={cardType} data-brand={cardType}>
            <Visa />
          </i>
        </li>
      )
    case "mastercard":
      return (
        <li>
          <i className={cardType} data-brand={cardType}>
            <MasterCard />
          </i>
        </li>
      )
    case "amex":
      return (
        <li>
          <i className={cardType} data-brand={cardType}>
            <AmericanExpress />
          </i>
        </li>
      )
    case "maestro":
      return (
        <li>
          <i className={cardType} data-brand={cardType}>
            <Maestro />
          </i>
        </li>
      )
    case "discover":
      return (
        <li>
          <i className={cardType} data-brand={cardType}>
            <Discover />
          </i>
        </li>
      )
    case "jcb":
      return (
        <li>
          <i className={cardType} data-brand={cardType}>
            <Jcb />
          </i>
        </li>
      )
    case "dinersclub":
      return (
        <li>
          <i className={cardType} data-brand={cardType}>
            <DinersClub />
          </i>
        </li>
      )
    case "elo":
      return (
        <li>
          <i className={cardType} data-brand={cardType}>
            <Elo />
          </i>
        </li>
      )
    case "laser":
      return (
        <li>
          <i className={cardType} data-brand={cardType}>
            <Laser />
          </i>
        </li>
      )
    case "unionpay":
      return (
        <li>
          <i className={cardType} data-brand={cardType}>
            <UnionPay />
          </i>
        </li>
      )
    default:
      return null
  }
}

export const PaypalNew = () => (
  <svg className="btn-paypal" id="Layer_1" version="1.1" viewBox="0 0 52.5 14" x="0px" y="0px">
    <path
      className="paypal-font-first"
      d="M3.7,5.3l0.4-2.7c0-0.2,0.2-0.3,0.3-0.3h0.3c0.7,0,1.4,0,1.8,0.4c0.2,0.3,0.3,0.6,0.2,1.1
        C6.6,5.3,5.4,5.3,4.3,5.3H3.7 M6.3,0H2.2C1.9,0,1.7,0.2,1.7,0.5L0,11c0,0.2,0.1,0.4,0.3,0.4h2c0.3,0,0.5-0.2,0.6-0.5L3.3,8
        c0-0.3,0.3-0.5,0.6-0.5h1.3c2.7,0,4.2-1.3,4.7-3.9c0.2-1.1,0-2-0.5-2.6C8.7,0.4,7.7,0,6.3,0 M13.6,9.5c-0.6,0-1-0.2-1.3-0.5
        C12,8.6,11.9,8.1,12,7.6c0.2-1.1,1.1-1.9,2.2-1.9c0.6,0,1,0.2,1.3,0.5c0.3,0.4,0.4,0.8,0.3,1.4C15.6,8.7,14.7,9.5,13.6,9.5
         M13.7,3.6c-2.2,0-4,1.6-4.4,3.9c-0.2,1.1,0.1,2.2,0.7,3c0.6,0.7,1.4,1,2.5,1c1.7,0,2.7-1.1,2.7-1.1L15.1,11c0,0.2,0.1,0.4,0.3,0.4
        h1.8c0.3,0,0.5-0.2,0.6-0.5l1.1-6.7c0-0.2-0.1-0.4-0.3-0.4h-2c-0.2,0-0.3,0.1-0.3,0.3l-0.1,0.5L16,4.4C15.6,3.8,14.6,3.6,13.7,3.6
         M28.9,3.8h-2c-0.2,0-0.4,0.1-0.5,0.2l-2.7,4l-1.1-3.8c-0.1-0.2-0.3-0.4-0.5-0.4h-1.9c-0.2,0-0.4,0.2-0.3,0.5l2.2,6.4l-2,2.9
        c-0.2,0.2,0,0.5,0.3,0.5h2c0.2,0,0.4-0.1,0.5-0.2l6.5-9.4C29.4,4.1,29.2,3.8,28.9,3.8"
    />
    <path
      className="paypal-font-second"
      d="M32.9,5.3l0.4-2.7c0-0.2,0.2-0.3,0.3-0.3h0.3c0.7,0,1.4,0,1.8,0.4c0.2,0.3,0.3,0.6,0.2,1.1
        c-0.2,1.5-1.3,1.5-2.4,1.5H32.9 M35.5,0h-4.1c-0.3,0-0.5,0.2-0.6,0.5L29.2,11c0,0.2,0.1,0.4,0.3,0.4h2.1c0.2,0,0.4-0.1,0.4-0.3
        l0.5-3c0-0.3,0.3-0.5,0.6-0.5h1.3c2.7,0,4.2-1.3,4.7-3.9c0.2-1.1,0-2-0.5-2.6C37.8,0.4,36.8,0,35.5,0 M42.7,9.5
        c-0.6,0-1-0.2-1.3-0.5c-0.3-0.3-0.4-0.8-0.3-1.4c0.2-1.1,1.1-1.9,2.2-1.9c0.6,0,1,0.2,1.3,0.5C44.9,6.6,45,7,44.9,7.6
        C44.8,8.7,43.9,9.5,42.7,9.5 M42.8,3.6c-2.2,0-4,1.6-4.4,3.9c-0.2,1.1,0.1,2.2,0.7,3c0.6,0.7,1.4,1,2.5,1c1.7,0,2.7-1.1,2.7-1.1
        L44.3,11c0,0.2,0.1,0.4,0.3,0.4h1.8c0.3,0,0.5-0.2,0.6-0.5L48,4.2c0-0.2-0.1-0.4-0.3-0.4h-2c-0.2,0-0.3,0.1-0.3,0.3l-0.1,0.5
        l-0.1-0.2C44.7,3.8,43.8,3.6,42.8,3.6 M52.2,0h-1.9C50.1,0,50,0.1,50,0.3L48.3,11c0,0.2,0.1,0.4,0.3,0.4h1.7c0.3,0,0.5-0.2,0.6-0.5
        l1.7-10.5C52.6,0.2,52.4,0,52.2,0"
    />
  </svg>
)

export const Visa = () => {
  const style = {
    visa_background: { fill: "#3957a7" },
    visa_font: { fill: "#FFFFFF" },
    visa_font_v: { fill: "#F9A533" }
  }
  return (
    <svg id="Layer_1" version="1.1" viewBox="0 0 37.5 23.4" x="0px" y="0px">
      <path
        d="M37.5,22.2c0,0.7-0.6,1.2-1.3,1.2h-35c-0.7,0-1.3-0.6-1.3-1.2V1.3C0,0.6,0.6,0,1.3,0h35
	        c0.7,0,1.3,0.6,1.3,1.3V22.2z"
        fill={style.visa_background.fill}
      />
      <polyline fill={style.visa_font.fill} points="18.4,8.2 16.6,8.2 15.4,15.2 17.3,15.2 18.4,8.2   " />
      <path
        d="M7.5,8.3L7.5,8.3L7.5,8.3 M7.5,8.3L7.5,8.3L7.5,8.3 M15.9,8.2h-2L12.1,13l-0.2-1c0,0-0.4-1.3-1.7-2.3
	          c-1.3-1-2.6-1.3-2.7-1.4c0.1,0,0.9,0.2,1.9,0.8l1.6,6.1h1.9L15.9,8.2"
        fill={style.visa_font.fill}
      />
      <path
        d="M26.3,12.8l1-2.6l0.5,2.6H26.3 M28.5,8.2H27c-0.7,0-0.8,0.5-0.8,0.5l-2.7,6.5h1.9l0.4-1h2.3l0.2,1H30L28.5,8.2
	          "
        fill={style.visa_font.fill}
      />
      <path
        d="M22.2,8.1c-0.9,0-3.1,0.4-3.1,2.3c0,1.8,2.5,1.8,2.5,2.8c0,0.5-0.6,0.7-1.2,0.7
	          c-0.7,0-1.4-0.2-1.8-0.5L18.4,15c0,0,0.8,0.4,2.1,0.4c1.3,0,3.1-0.6,3.1-2.4c0-1.8-2.6-2-2.6-2.8c0-0.4,0.5-0.6,1.1-0.6
	          c0.5,0,1.1,0.1,1.5,0.3l0.3-1.5C23.9,8.4,23.1,8.1,22.2,8.1"
        fill={style.visa_font.fill}
      />
      <path
        d="M10.4,8.2H7.6l0,0.1l0,0l0,0l0,0l0,0l0,0c0.1,0,1.4,0.3,2.7,1.4c1.3,1,1.7,2.3,1.7,2.3
	          l-0.6-3.2C11.3,8.8,11.2,8.2,10.4,8.2"
        fill={style.visa_font_v.fill}
      />
    </svg>
  )
}

export const MasterCard = () => {
  const style = {
    mastercard_background: { fill: "#13457c" },
    mastercard_font: { fill: "#FFFFFF" },
    mastercard_medium_circle: { fill: "#FF5F00" },
    mastercard_right_circle: { fill: "#F79E1B" },
    mastercard_left_circle: { fill: "#EB001B" }
  }
  return (
    <svg id="Layer_1" version="1.1" viewBox="0 0 37.5 23.4" x="0px" y="0px">
      <path
        d="M36.3,0h-35C0.6,0,0,0.6,0,1.3v20.9c0,0.7,0.6,1.2,1.3,1.2h35c0.7,0,1.3-0.6,1.3-1.2V1.3
              C37.5,0.6,36.9,0,36.3,0"
        fill={style.mastercard_background.fill}
      />
      <g transform="translate(-502.86126,-41.013507)">
        <path
          d="M516.3,59.2v-0.9c0-0.3-0.2-0.5-0.5-0.5c-0.2,0-0.4,0.1-0.5,0.2
                c-0.1-0.2-0.2-0.2-0.5-0.2c-0.1,0-0.3,0-0.4,0.2v-0.2h-0.3v1.4h0.3v-0.8c0-0.2,0.1-0.4,0.3-0.4c0.2,0,0.3,0.1,0.3,0.4v0.8h0.3v-0.8
                c0-0.2,0.1-0.4,0.3-0.4c0.2,0,0.3,0.1,0.3,0.4v0.8H516.3z M520.8,57.8h-0.5v-0.4H520v0.4h-0.3v0.3h0.3v0.6c0,0.3,0.1,0.5,0.5,0.5
                c0.1,0,0.3,0,0.4-0.1l-0.1-0.3c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2-0.1-0.2-0.2v-0.6h0.5V57.8z M523.3,57.8
                c-0.2,0-0.3,0.1-0.4,0.2v-0.2h-0.3v1.4h0.3v-0.8c0-0.2,0.1-0.4,0.3-0.4c0.1,0,0.1,0,0.2,0l0.1-0.3
                C523.5,57.8,523.4,57.8,523.3,57.8L523.3,57.8z M519.5,57.9c-0.1-0.1-0.3-0.1-0.6-0.1c-0.3,0-0.6,0.2-0.6,0.4
                c0,0.2,0.2,0.4,0.5,0.4l0.1,0c0.2,0,0.2,0.1,0.2,0.1c0,0.1-0.1,0.2-0.3,0.2c-0.2,0-0.4-0.1-0.5-0.1l-0.1,0.2
                c0.2,0.1,0.4,0.2,0.6,0.2c0.4,0,0.6-0.2,0.6-0.4c0-0.2-0.2-0.4-0.5-0.4l-0.1,0c-0.1,0-0.2,0-0.2-0.1c0-0.1,0.1-0.2,0.3-0.2
                c0.2,0,0.3,0.1,0.4,0.1L519.5,57.9L519.5,57.9z M527.5,57.8c-0.2,0-0.3,0.1-0.4,0.2v-0.2h-0.3v1.4h0.3v-0.8c0-0.2,0.1-0.4,0.3-0.4
                c0.1,0,0.1,0,0.2,0l0.1-0.3C527.6,57.8,527.5,57.8,527.5,57.8L527.5,57.8z M523.6,58.5c0,0.4,0.3,0.7,0.7,0.7c0.2,0,0.3,0,0.5-0.2
                l-0.1-0.2c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.4-0.2-0.4-0.4c0-0.3,0.2-0.4,0.4-0.4c0.1,0,0.2,0,0.4,0.1l0.1-0.2
                c-0.1-0.1-0.3-0.2-0.5-0.2C523.9,57.8,523.6,58.1,523.6,58.5L523.6,58.5L523.6,58.5z M526.4,58.5v-0.7h-0.3V58
                c-0.1-0.1-0.2-0.2-0.4-0.2c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7c0.2,0,0.3-0.1,0.4-0.2v0.2h0.3V58.5L526.4,58.5z M525.3,58.5
                c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4c0,0.2-0.2,0.4-0.4,0.4C525.5,58.9,525.3,58.8,525.3,58.5L525.3,58.5z M521.7,57.8
                c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7c0.2,0,0.4-0.1,0.6-0.2l-0.1-0.2c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2,0-0.4-0.1-0.4-0.3h1
                c0,0,0-0.1,0-0.1C522.3,58.1,522.1,57.8,521.7,57.8L521.7,57.8L521.7,57.8z M521.7,58.1c0.2,0,0.3,0.1,0.3,0.3h-0.7
                C521.3,58.2,521.5,58.1,521.7,58.1L521.7,58.1z M529.2,58.5v-1.2h-0.3V58c-0.1-0.1-0.2-0.2-0.4-0.2c-0.4,0-0.7,0.3-0.7,0.7
                s0.3,0.7,0.7,0.7c0.2,0,0.3-0.1,0.4-0.2v0.2h0.3V58.5L529.2,58.5z M528.1,58.5c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4
                c0,0.2-0.2,0.4-0.4,0.4C528.2,58.9,528.1,58.8,528.1,58.5L528.1,58.5z M518,58.5v-0.7h-0.3V58c-0.1-0.1-0.2-0.2-0.4-0.2
                c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7c0.2,0,0.3-0.1,0.4-0.2v0.2h0.3V58.5L518,58.5z M516.9,58.5c0-0.2,0.2-0.4,0.4-0.4
                c0.2,0,0.4,0.2,0.4,0.4c0,0.2-0.2,0.4-0.4,0.4C517,58.9,516.9,58.8,516.9,58.5z"
          fill={style.mastercard_font.fill}
        />
      </g>
      <rect fill={style.mastercard_medium_circle.fill} height="8.1" width="4.5" x="16.5" y="6.3" />
      <path
        d="M16.8,10.4c0-1.7,0.8-3.1,2-4.1c-0.9-0.7-2-1.1-3.2-1.1
              c-2.9,0-5.2,2.3-5.2,5.2s2.3,5.2,5.2,5.2c1.2,0,2.3-0.4,3.2-1.1C17.6,13.5,16.8,12.1,16.8,10.4z"
        fill={style.mastercard_left_circle.fill}
      />
      <path
        d="M27.1,10.4c0,2.9-2.3,5.2-5.2,5.2c-1.2,0-2.3-0.4-3.2-1.1
              c1.2-0.9,2-2.4,2-4.1s-0.8-3.1-2-4.1c0.9-0.7,2-1.1,3.2-1.1C24.8,5.2,27.1,7.6,27.1,10.4z"
        fill={style.mastercard_right_circle.fill}
      />
    </svg>
  )
}

export const AmericanExpress = () => {
  const style = {
    amex_background: { fill: "#0078a9" },
    amex_font_shadow: { fill: "#FFFFFF" },
    amex_font: { fill: "#0078a9" }
  }
  return (
    <svg id="Layer_1" version="1.1" viewBox="0 0 37.5 23.4" x="0px" y="0px">
      <path
        d="M30.8,12.4c0,0,0.3-0.2,0.7-0.2c0.3,0,2,0,2,0v3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.1v1.3c0,0-0.3,0.3-0.8,0.3
            H30v-0.1c0,0-0.3,0.1-0.6,0.1c-0.4,0-7.1,0-7.1,0s0-1.1,0-1.2c0-0.1-0.1-0.1-0.2-0.1H22v1.3h-2.2v-1.3c0,0-0.2,0.1-0.7,0.1
            c-0.4,0-0.8,0-0.8,0v1.2h-2.7L15,16.5l-0.7,0.8H9.9v-5h4.5l0.6,0.7l0.7-0.7h3.5c0,0,0.3,0,0.6,0.2v-0.2c0,0,2.7,0,3.1,0
            c0.4,0,0.7,0.2,0.7,0.2v-0.2h4v0.2c0,0,0.3-0.2,0.7-0.2c0.4,0,2.6,0,2.6,0V12.4 M10.7,7.4l0.6-1.2h8.2h0c0,0,0.4,0,0.7,0.2l0-0.2
            h2.1v0.3c0,0,0.4-0.3,0.9-0.3s3.6,0,3.6,0L27,6.9V6.2h2.3l0.4,0.7V6.2H32v5h-2.3l-0.5-0.9v0.9h-2.8l-0.4-0.9h-0.6L25,11.2h-1.7
            c0,0-0.6,0-1.1-0.4c0,0.2,0,0.4,0,0.4h-3.4c0,0,0-1,0-1.2c0-0.1-0.1-0.2-0.1-0.2h0h-0.1v1.3h-6.9v-0.4l-0.2,0.4H9.9l-0.2-0.4v0.4
            H6.8l-0.3-0.8H5.9l-0.4,0.8H4V8.7l1.1-2.5h2.1l0.3,0.7V6.2h2.6L10.7,7.4 M36.3,0h-35C0.6,0,0,0.6,0,1.3v20.9c0,0.7,0.6,1.2,1.3,1.2
            h35c0.7,0,1.3-0.6,1.3-1.2V1.3C37.5,0.6,36.9,0,36.3,0"
        fill={style.amex_background.fill}
      />
      <path
        d="M18.9,7.7h-1.1v0.8h1.1c0.4,0,0.5-0.2,0.5-0.4C19.4,7.9,19.3,7.7,18.9,7.7 M25.7,7.7l-0.5,1.2h1.1L25.7,7.7
             M6.1,7.7L5.6,8.9h1L6.1,7.7 M10.6,9.3l1.1-2.4H13v3.5h-0.9V7.9L11,10.4h-0.8L9.1,7.8v2.6H7.3L7,9.7H5.3l-0.3,0.8H4l1.5-3.5h1.1
            l1.5,3.4V6.9h1.4L10.6,9.3 M13.7,10.4V6.9h2.8v0.8h-2v0.7h1.9V9h-1.9v0.7h2v0.7H13.7 M17,10.4V6.9h2.2c0.4,0,1,0.2,1,0.9
            c0,0.4-0.1,0.7-0.6,1c0.4,0,0.6,0.3,0.6,0.9l0,0.7h-0.8l0-0.6c0-0.3-0.1-0.6-0.4-0.6h-1.1v1.2H17 M30.5,9.4V6.9h0.9v3.5h-1.2
            l-1.6-2.6v2.6h-1.7l-0.3-0.8h-1.7l-0.3,0.8h-1.1c-1,0-1.4-0.8-1.4-1.8c0-1.4,0.9-1.7,1.3-1.7h1.1v0.8h-0.5c-0.6,0-1,0.1-1,0.9v0.3
            c0,0.6,0.3,0.8,0.5,0.8l0.6,0l1.2-2.7h1.1l1.5,3.3V6.9H29L30.5,9.4 M20.7,10.4V6.9h0.9v3.5H20.7 M19.4,6.2L19.4,6.2h-8.2l-0.6,1.2
            l-0.6-1.2H7.5v0.7L7.2,6.2H5.1L4,8.7v2.5h1.5l0.4-0.8h0.6l0.3,0.8h2.9v-0.4l0.2,0.4h1.6l0.2-0.4v0.4h6.9V9.9h0.1h0
            c0,0,0.1,0,0.1,0.2c0,0.2,0,1.2,0,1.2h3.4c0,0,0-0.2,0-0.4c0.5,0.3,1.1,0.4,1.1,0.4H25l0.4-0.9h0.6l0.4,0.9h2.8v-0.9l0.5,0.9H32v-5
            h-2.2v0.7l-0.4-0.7H27v0.7l-0.3-0.7c0,0-3.1,0-3.6,0s-0.9,0.3-0.9,0.3V6.2h-2.1l0,0.2C19.8,6.2,19.4,6.2,19.4,6.2"
        fill={style.amex_font_shadow.fill}
      />
      <path
        d="M18.6,13.8h-1.1v0.8h1.1c0.4,0,0.5-0.2,0.5-0.4C19.1,14,19,13.8,18.6,13.8 M22.3,13.8h-1.1v0.8h1.1
            c0.4,0,0.5-0.2,0.5-0.4C22.8,14,22.7,13.8,22.3,13.8 M11.4,13.7v0.7h1.9v0.7h-1.9v0.7h2l0.9-1.1l-0.8-1L11.4,13.7 M16.6,13.3
            l-1.2,1.4l1.2,1.5V13.3 M20.4,16.5V13h2.2c0.4,0,1,0.2,1,0.9c0,0.4-0.1,0.7-0.6,1c0.4,0,0.6,0.3,0.6,0.9l0,0.7h-0.8l0-0.6
            c0-0.3-0.1-0.6-0.4-0.6h-1.1v1.2H20.4 M27.3,16.5v-0.8h1.4c0.2,0,0.6,0,0.6-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.5,0-0.6,0
            c-1,0-1.2-0.5-1.2-1.1c0-0.7,0.5-1,1-1h1.8v0.8h-1.3c0,0,0,0,0,0c0,0,0,0-0.1,0c-0.3,0-0.5,0-0.5,0.3c0,0.2,0.2,0.3,0.4,0.3l0.6,0
            c1.1,0.1,1.1,0.6,1.1,1.1c0,0.7-0.5,1-1.1,1H27.3 M10.5,16.5V13h3.4l1,1.1l1-1.1h3c0.4,0,1,0.2,1,1.1c0,1.2-0.8,1.2-1.3,1.2h-1.2
            v1.2h-1.7l-1-1.2l-1,1.2H10.5 M24.1,16.5V13h2.8v0.8h-2v0.7h1.9v0.7h-1.9v0.7h2v0.7H24.1 M33.5,12.2c0,0-1.7,0-2,0
            c-0.3,0-0.7,0.2-0.7,0.2v-0.2c0,0-2.2,0-2.6,0c-0.4,0-0.7,0.2-0.7,0.2v-0.2h-4v0.2c0,0-0.3-0.2-0.7-0.2c-0.4,0-3.1,0-3.1,0v0.2
            c-0.3-0.2-0.6-0.2-0.6-0.2h-3.5L15.1,13l-0.6-0.7H9.9v5h4.4l0.7-0.8l0.6,0.8h2.7V16c0,0,0.4,0,0.8,0c0.4,0,0.7-0.1,0.7-0.1v1.3H22
            v-1.3h0.1c0.1,0,0.2,0,0.2,0.1c0,0.1,0,1.2,0,1.2s6.7,0,7.1,0c0.4,0,0.6-0.1,0.6-0.1v0.1h2.7c0.6,0,0.8-0.3,0.8-0.3v-1.3
            c-0.1,0.6-0.6,0.9-1.1,0.9h-1.8v-0.8H32c0.2,0,0.6,0,0.6-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.5,0-0.6,0c-1,0-1.2-0.5-1.2-1.1
            c0-0.7,0.5-1,1-1h1.8v0.8H32c0,0,0,0,0,0c0,0,0,0-0.1,0c-0.3,0-0.5,0-0.5,0.3c0,0.2,0.2,0.3,0.4,0.3l0.6,0c0.9,0,1.1,0.4,1.1,0.9
            V12.2"
        fill={style.amex_font_shadow.fill}
      />
      <polyline
        fill={style.amex_font.fill}
        points="26.9,13 24.1,13 24.1,16.5 26.9,16.5 26.9,15.8 24.9,15.8 24.9,15.1 26.8,15.1 26.8,14.4 24.9,14.4
            24.9,13.7 26.9,13.7 26.9,13   "
      />
      <path
        d="M33.3,13h-1.8c-0.6,0-1,0.4-1,1c0,0.6,0.2,1.1,1.2,1.1c0.1,0,0.5,0,0.6,0c0.3,0,0.3,0.1,0.3,0.3
            c0,0.3-0.4,0.3-0.6,0.3h-1.4v0.8h1.8c0.5,0,1-0.3,1.1-0.9c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3c0-0.5-0.2-0.8-1.1-0.9l-0.6,0
            c-0.2,0-0.4-0.1-0.4-0.3c0-0.3,0.3-0.3,0.5-0.3c0,0,0,0,0.1,0c0,0,0,0,0,0h1.3V13"
        fill={style.amex_font.fill}
      />
      <path
        d="M30.1,13h-1.8c-0.6,0-1,0.4-1,1c0,0.6,0.2,1.1,1.2,1.1c0.1,0,0.5,0,0.6,0c0.3,0,0.3,0.1,0.3,0.3
            c0,0.3-0.4,0.3-0.6,0.3h-1.4v0.8h1.8c0.6,0,1.1-0.4,1.1-1c0-0.6-0.1-1.1-1.1-1.1l-0.6,0c-0.2,0-0.4-0.1-0.4-0.3
            c0-0.3,0.3-0.3,0.5-0.3c0,0,0,0,0.1,0c0,0,0,0,0,0h1.3V13"
        fill={style.amex_font.fill}
      />
      <path
        d="M17.5,14.6v-0.8h1.1c0.4,0,0.5,0.2,0.5,0.4c0,0.2-0.1,0.4-0.5,0.4H17.5 M11.4,14.4v-0.7l2.1,0l0.8,1l-0.9,1.1
            h-2v-0.7h1.9v-0.7H11.4 M16.6,16.2l-1.2-1.5l1.2-1.4V16.2 M13.9,13h-3.4v3.5h3.3l1-1.2l1,1.2h1.7v-1.2h1.2c0.4,0,1.3,0,1.3-1.2
            c0-0.9-0.6-1.1-1-1.1h-3l-1,1.1L13.9,13"
        fill={style.amex_font.fill}
      />
      <path
        d="M21.2,14.5v-0.8h1.1c0.4,0,0.5,0.2,0.5,0.4c0,0.2-0.1,0.4-0.5,0.4H21.2 M22.6,13h-2.2v3.5h0.9v-1.2h1.1
            c0.3,0,0.4,0.3,0.4,0.6l0,0.6h0.8l0-0.7c0-0.6-0.2-0.8-0.6-0.9c0.5-0.2,0.6-0.5,0.6-1C23.6,13.2,23,13,22.6,13"
        fill={style.amex_font.fill}
      />
      <polyline fill={style.amex_font.fill} points="21.6,6.9 20.7,6.9 20.7,10.4 21.6,10.4 21.6,6.9  " />
      <polyline
        fill={style.amex_font.fill}
        points="16.5,6.9 13.7,6.9 13.7,10.4 16.5,10.4 16.5,9.7 14.5,9.7 14.5,9 16.4,9 16.4,8.4 14.5,8.4 14.5,7.7
            16.5,7.7 16.5,6.9   "
      />
      <path
        d="M17.8,8.5V7.7h1.1c0.4,0,0.5,0.2,0.5,0.4c0,0.2-0.1,0.4-0.5,0.4H17.8 M19.2,6.9H17v3.5h0.9V9.2H19
            c0.3,0,0.4,0.3,0.4,0.6l0,0.6h0.8l0-0.7c0-0.6-0.2-0.8-0.6-0.9c0.5-0.2,0.6-0.5,0.6-1C20.3,7.1,19.6,6.9,19.2,6.9"
        fill={style.amex_font.fill}
      />
      <path
        d="M5.6,8.9l0.5-1.2l0.5,1.2H5.6 M13,6.9h-1.4l-1.1,2.4L9.6,6.9H8.2v3.4L6.7,6.9H5.6L4,10.4h0.9l0.3-0.8H7
            l0.3,0.8h1.7V7.8l1.2,2.6H11l1.2-2.6v2.6H13V6.9"
        fill={style.amex_font.fill}
      />
      <path
        d="M25.2,8.9l0.5-1.2l0.5,1.2H25.2 M31.3,6.9h-0.9v2.4L29,6.9h-1.3v3.3l-1.5-3.3h-1.1l-1.2,2.7l-0.6,0
            c-0.2,0-0.5-0.2-0.5-0.8V8.6c0-0.8,0.4-0.9,1-0.9h0.5V6.9h-1.1c-0.4,0-1.2,0.3-1.3,1.7c0,1,0.4,1.8,1.4,1.8h1.1l0.3-0.8h1.7
            l0.3,0.8h1.7V7.9l1.6,2.6h1.2V6.9"
        fill={style.amex_font.fill}
      />
    </svg>
  )
}

export const Maestro = () => {
  const style = {
    maestro_background: { fill: "#13457c" },
    maestro_font: { fill: "#FFFFFF" },
    maestro_medium_circle: { fill: "#6975c0" },
    maestro_right_circle: { fill: "#02a2df" },
    maestro_left_circle: { fill: "#e72419" }
  }
  return (
    <svg version="1.1" viewBox="0 0 25.6 16" x="0px" y="0px">
      <g>
        <path
          d="M24.7,0H0.9C0.4,0,0,0.4,0,0.9v14.3C0,15.6,0.4,16,0.9,16h23.9c0.5,0,0.9-0.4,0.9-0.9V0.9
              C25.6,0.4,25.2,0,24.7,0"
          fill={style.maestro_background.fill}
        />
      </g>
      <rect fill={style.maestro_medium_circle.fill} height="5.6" width="3.1" x="11.3" y="4.3" />
      <path
        d="M11.5,7.1C11.5,6,12,5,12.8,4.3c-0.6-0.5-1.4-0.8-2.2-0.8
            c-2,0-3.5,1.6-3.5,3.5s1.6,3.5,3.5,3.5c0.8,0,1.6-0.3,2.2-0.8C12,9.2,11.5,8.2,11.5,7.1z"
        fill={style.maestro_left_circle.fill}
      />
      <path
        d="M18.5,7.1c0,2-1.6,3.5-3.5,3.5c-0.8,0-1.6-0.3-2.2-0.8
            c0.8-0.6,1.3-1.6,1.3-2.8S13.6,5,12.8,4.3c0.6-0.5,1.4-0.8,2.2-0.8C16.9,3.6,18.5,5.2,18.5,7.1z"
        fill={style.maestro_right_circle.fill}
      />
      <path
        d="M9.5,12.4c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0-0.2,0-0.4,0-0.5c0,0,0-0.1,0-0.1c0-0.1-0.1-0.1-0.2-0.1
              c0,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.1,0.2c0,0,0,0.1,0,0.1c0,0.2,0,0.4,0,0.5c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0-0.3,0-0.7,0-1
              c0.1,0,0.1,0,0.2,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.2,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0
              c0,0,0,0,0,0c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0.1,0.2,0.3c0,0,0,0.1,0,0.1c0,0.2,0,0.4,0,0.6c0,0,0,0,0,0
              c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0-0.2,0-0.4,0-0.5c0,0,0-0.1,0-0.1c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.1,0.2
              c0,0,0,0.1,0,0.1C9.5,12,9.5,12.2,9.5,12.4C9.5,12.4,9.5,12.4,9.5,12.4z"
        fill={style.maestro_font.fill}
      />
      <path
        d="M11.4,12.4c-0.1,0-0.1,0-0.2,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.3,0-0.4-0.1
              c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1,0-0.2,0-0.3c0.1-0.2,0.2-0.2,0.4-0.3c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0
              c0,0,0-0.1,0-0.1c0.1,0,0.1,0,0.2,0C11.4,11.7,11.4,12.1,11.4,12.4z M11.2,11.9C11.2,11.9,11.2,11.9,11.2,11.9
              c0-0.1-0.1-0.2-0.1-0.3c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.2,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0.1,0.2
              c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.3-0.1C11.1,12,11.2,12,11.2,11.9z"
        fill={style.maestro_font.fill}
      />
      <path
        d="M12.5,12c-0.2,0-0.5,0-0.7,0c0,0,0,0.1,0,0.1c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0,0.3-0.1c0,0,0,0,0,0
              c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0c-0.2-0.1-0.3-0.2-0.3-0.4c0-0.1,0-0.2,0-0.4
              c0.1-0.2,0.2-0.3,0.4-0.3c0.1,0,0.2,0,0.3,0c0.1,0.1,0.2,0.1,0.2,0.3C12.5,11.8,12.5,11.9,12.5,12z M12.3,11.8
              c0-0.1-0.1-0.2-0.2-0.2c-0.2,0-0.3,0.1-0.3,0.2C12,11.8,12.1,11.8,12.3,11.8z"
        fill={style.maestro_font.fill}
      />
      <path
        d="M16.1,12.4c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.2
              c0.1,0,0.2,0,0.4,0c0.2,0.1,0.3,0.2,0.3,0.4c0,0.2,0,0.3-0.1,0.4C16.4,12.3,16.3,12.4,16.1,12.4C16.2,12.4,16.2,12.4,16.1,12.4z
               M15.8,11.9C15.8,11.9,15.8,11.9,15.8,11.9c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.3-0.1c0-0.1,0.1-0.1,0-0.2
              c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0.1C15.8,11.7,15.8,11.8,15.8,11.9z"
        fill={style.maestro_font.fill}
      />
      <path
        d="M12.7,12.3c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0.1,0c0.1,0,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0
              c0,0,0-0.1,0-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.1-0.1c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0.1-0.2,0.2-0.3
              c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0c-0.1-0.1-0.2-0.1-0.3-0.1
              c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1c0,0,0,0.1,0,0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0.1
              c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0C12.8,12.3,12.8,12.3,12.7,12.3
              C12.7,12.3,12.7,12.3,12.7,12.3z"
        fill={style.maestro_font.fill}
      />
      <path
        d="M14,11.6c-0.1,0-0.1,0-0.2,0c0-0.1,0-0.1,0-0.2c0.1,0,0.1,0,0.2,0c0-0.1,0-0.2,0-0.3c0.1,0,0.1,0,0.2,0
              c0,0.1,0,0.2,0,0.3c0.1,0,0.2,0,0.3,0c0,0.1,0,0.1,0,0.2c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0.1
              c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0
              c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.2c0,0,0-0.1,0-0.1C14,11.9,14,11.8,14,11.6
              C14,11.6,14,11.6,14,11.6z"
        fill={style.maestro_font.fill}
      />
      <path
        d="M15.1,12.4c-0.1,0-0.1,0-0.2,0c0-0.3,0-0.7,0-1c0.1,0,0.1,0,0.2,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0
              c0.1-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0,0.1-0.1,0.2c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
              c-0.1,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.1C15.1,12,15.1,12.2,15.1,12.4C15.1,12.4,15.1,12.4,15.1,12.4z"
        fill={style.maestro_font.fill}
      />
      <path
        d="M16.8,12.3c0-0.1,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0.1C17,12.4,17,12.4,16.8,12.3C16.9,12.4,16.8,12.4,16.8,12.3
              z M16.9,12.3C16.9,12.3,16.8,12.3,16.9,12.3c-0.1,0.1,0,0.2,0,0.2C17,12.4,17,12.4,16.9,12.3C17,12.3,17,12.3,16.9,12.3z"
        fill={style.maestro_font.fill}
      />
      <path
        d="M16.9,12.3C16.9,12.3,16.9,12.3,16.9,12.3C16.9,12.3,17,12.3,16.9,12.3C17,12.3,17,12.3,16.9,12.3
              C16.9,12.3,16.9,12.3,16.9,12.3C16.9,12.4,16.9,12.4,16.9,12.3C16.9,12.4,16.9,12.4,16.9,12.3C16.9,12.4,16.9,12.4,16.9,12.3
              C16.9,12.4,16.9,12.4,16.9,12.3C16.9,12.4,16.9,12.4,16.9,12.3C16.9,12.4,16.9,12.4,16.9,12.3C16.9,12.4,16.9,12.3,16.9,12.3z
               M16.9,12.3C16.9,12.3,16.9,12.3,16.9,12.3C16.9,12.3,16.9,12.3,16.9,12.3C16.9,12.3,16.9,12.3,16.9,12.3
              C16.9,12.3,16.9,12.3,16.9,12.3C16.9,12.3,16.9,12.3,16.9,12.3z"
        fill={style.maestro_font.fill}
      />
    </svg>
  )
}

export const Discover = () => {
  const style = {
    discover_background: { fill: "#F1F2F2" },
    discover_font: { fill: "#001722" },
    discover_circle: { fill: "#F48120" },
    discover_label: { fill: "#F58220" }
  }
  return (
    <svg version="1.1" viewBox="0 0 25.6 16" x="0px" y="0px">
      <path
        d="M25.6,15.1c0,0.5-0.4,0.9-0.9,0.9H0.9C0.4,16,0,15.6,0,15.1V0.9C0,0.4,0.4,0,0.9,0h23.9
            c0.5,0,0.9,0.4,0.9,0.9V15.1z"
        fill={style.discover_background.fill}
      />
      <path d="M7,16h17.7c0.5,0,0.9-0.4,0.9-0.9v-6C23.9,10.2,17.5,13.9,7,16z" fill={style.discover_label.fill} />
      <path
        d="M13.1,6.8c-0.7,0-1.3,0.6-1.3,1.2c0,0.7,0.5,1.3,1.3,1.3c0.7,0,1.3-0.5,1.3-1.2
            C14.4,7.3,13.8,6.8,13.1,6.8z"
        fill={style.discover_circle.fill}
      />
      <path
        d="M5.9,6.8H5.2v2.4h0.7c0.4,0,0.6-0.1,0.9-0.3C7,8.7,7.1,8.3,7.1,8C7.1,7.3,6.6,6.8,5.9,6.8z
             M6.4,8.6C6.3,8.7,6.1,8.8,5.8,8.8H5.6V7.2h0.1c0.3,0,0.5,0.1,0.6,0.2C6.6,7.5,6.7,7.8,6.7,8C6.7,8.2,6.6,8.5,6.4,8.6z M7.4,6.8h0.5
            v2.4H7.4V6.8z M9.7,8.5c0,0.5-0.3,0.8-0.8,0.8C8.5,9.3,8.2,9.1,8,8.8l0.3-0.3c0.1,0.2,0.3,0.3,0.5,0.3c0.2,0,0.4-0.1,0.4-0.3
            c0-0.1,0-0.2-0.1-0.2c0,0-0.1-0.1-0.3-0.1C8.3,7.9,8.1,7.8,8.1,7.4c0-0.4,0.3-0.7,0.7-0.7c0.3,0,0.5,0.1,0.7,0.3L9.3,7.3
            C9.2,7.2,9.1,7.2,9,7.2c-0.2,0-0.3,0.1-0.3,0.3c0,0.1,0.1,0.2,0.4,0.3C9.5,7.9,9.7,8.1,9.7,8.5z M11.1,6.7c0.2,0,0.4,0,0.6,0.1v0.5
            c-0.2-0.2-0.4-0.3-0.6-0.3c-0.4,0-0.8,0.4-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8c0.2,0,0.4-0.1,0.6-0.3v0.5c-0.2,0.1-0.4,0.1-0.6,0.1
            c-0.7,0-1.3-0.5-1.3-1.3C9.8,7.3,10.4,6.7,11.1,6.7z M16.1,6.8h0.5l-1,2.5h-0.3l-1-2.5h0.5l0.6,1.6L16.1,6.8z M16.8,6.8h1.3v0.4
            h-0.9v0.5h0.8v0.4h-0.8v0.6h0.9v0.4h-1.3V6.8z M20,7.5c0-0.4-0.3-0.7-0.8-0.7h-0.7v2.4h0.5v-1H19l0.6,1h0.6l-0.8-1
            C19.8,8.1,20,7.9,20,7.5z M19,7.9h-0.1V7.2h0.1c0.3,0,0.4,0.1,0.4,0.4C19.5,7.8,19.3,7.9,19,7.9z M20.2,6.8c-0.1,0-0.2,0.1-0.2,0.2
            c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2C20.4,6.9,20.3,6.8,20.2,6.8z M20.2,7.2c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0.1-0.2,0.1-0.2
            c0.1,0,0.1,0.1,0.1,0.2C20.4,7.1,20.3,7.2,20.2,7.2z"
        fill={style.discover_font.fill}
      />
    </svg>
  )
}

export const Jcb = () => {
  const style = {
    st0: { fill: "#0C489C" },
    st1: { fill: "#FFFFFF" }
  }
  return (
    <svg version="1.1" viewBox="0 0 25.6 16" x="0px" y="0px">
      <g>
        <path
          d="M25.6,15.1c0,0.5-0.4,0.9-0.9,0.9H0.9C0.4,16,0,15.6,0,15.1V0.9C0,0.4,0.4,0,0.9,0h23.9
          c0.5,0,0.9,0.4,0.9,0.9V15.1z"
          fill={style.st0.fill}
          id="XMLID_9_"
        />
        <path
          d="M17.3,8.5c0,0.9,0.1,1.9,0,2.8c0,0.4-0.3,0.7-0.6,0.9c-0.3,0.2-0.6,0.2-0.9,0.2l-1.2,0l0-7.9
          c0.1-0.5,0.5-1,0.9-1.1c0.6-0.1,1.2,0,1.8-0.1l0,0.4v4.5l0,0V8.5z"
          fill={style.st1.fill}
          id="XMLID_8_"
        />
        <path
          d="M8.3,8.2V4.5c0.1-0.4,0.4-0.8,0.8-1c0.6-0.2,1.3-0.1,1.9-0.1c0,0.6,0,1.4,0,1.9l0,4.9
          C11,11,11.3,12,10.4,12.4c-0.6,0.3-1.4,0.1-2.1,0.2l0-0.1V8.4V8.2z"
          fill={style.st1.fill}
          id="XMLID_7_"
        />
        <path
          d="M14.2,4l0,7.6c-0.1,0.4-0.4,0.7-0.8,0.9c-0.6,0.2-1.3,0.1-2,0.1l0-8c0-0.4,0.3-0.7,0.6-0.9
          c0.2-0.2,0.5-0.2,0.8-0.2h1.3L14.2,4z"
          fill={style.st1.fill}
          id="XMLID_6_"
        />
        <g>
          <g id="XMLID_74_">
            <path
              d="M8.3,8.4C8.6,8.7,8.9,9,9.3,9c0.5,0,1.2,0.1,1.5-0.4C11.1,8.4,10.9,8,11,7.7l0-0.6
            c-0.3-0.1-0.6,0-0.9,0c-0.2,0.6,0.3,1.4-0.5,1.7c-0.2,0-0.4,0-0.6-0.1C8.7,8.6,8.9,8.2,8.5,8.3l-0.2,0V8.4z"
              fill={style.st0.fill}
              id="XMLID_80_"
            />
            <path
              d="M14,7.1c0,0.1,0,0.2,0,0.3c-0.5-0.1-1-0.3-1.5,0.1c-0.2,0.2-0.2,0.4-0.2,0.6
            c0,0.3,0.2,0.5,0.5,0.7c0.4,0.1,0.8,0,1.2-0.1c0,0.1,0,0.2,0,0.2h-1.8c-0.3-0.1-0.6-0.2-0.7-0.5c-0.1-0.3-0.1-0.7,0.2-0.9
            c0.2-0.2,0.5-0.3,0.7-0.3L14,7.1z"
              fill={style.st0.fill}
              id="XMLID_79_"
            />
            <path
              d="M17.3,8.5c0,0.2-0.2,0.4-0.4,0.4h-2.2l-0.1,0c0-0.6,0-1.2,0-1.8H16c0.4,0,0.9-0.1,1.1,0.3
            c0.1,0.1,0,0.3-0.1,0.4C17,7.9,16.9,7.9,16.8,8c0.2,0,0.4,0.2,0.4,0.4V8.5z M15.4,7.3c0,0.2,0,0.4,0,0.6c0.3,0,0.7,0.1,1-0.1
            c0.1-0.1,0-0.2,0-0.3C16.1,7.2,15.7,7.3,15.4,7.3z M16.2,8.1c-0.3,0-0.6,0-0.9,0c0,0.2,0,0.4,0,0.6c0.3,0,0.7,0,0.9-0.1
            c0.1-0.1,0.1-0.2,0.1-0.3C16.4,8.2,16.3,8.2,16.2,8.1z"
              fill={style.st0.fill}
              id="XMLID_75_"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export const DinersClub = () => {
  const style = {
    st0: { fill: "#084D95" },
    st1: { fill: "#FFFFFF" }
  }

  return (
    <svg version="1.1" viewBox="0 0 25.6 16" x="0px" y="0px">
      <g>
        <path
          d="M25.6,15.1c0,0.5-0.4,0.9-0.9,0.9H0.9C0.4,16,0,15.6,0,15.1V0.9C0,0.4,0.4,0,0.9,0h23.9c0.5,0,0.9,0.4,0.9,0.9
            V15.1z"
          fill={style.st0.fill}
        />
        <path
          d="M13.4,8.7c1.4,0,2.8-1.2,2.8-2.6c0-1.6-1.3-2.7-2.8-2.7h-1.2c-1.5,0-2.7,1.1-2.7,2.7c0,1.4,1.2,2.6,2.7,2.6
            H13.4z"
          fill={style.st1.fill}
        />
        <path
          d="M12.7,7.5V4.6c0.6,0.2,1,0.8,1,1.4C13.7,6.7,13.3,7.3,12.7,7.5 M10.6,6.1c0-0.7,0.4-1.2,1-1.4v2.9
            C11,7.3,10.6,6.7,10.6,6.1 M12.2,3.7c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4
            C14.6,4.7,13.5,3.7,12.2,3.7"
          fill={style.st0.fill}
        />
        <g>
          <path
            d="M6.8,10.5c0,0.2,0.2,0.2,0.3,0.2c0.6,0,0.8-0.5,0.8-0.9C7.9,9.4,7.6,9,7,9C6.9,9,6.8,9,6.8,9V10.5z M6.5,9.2
              C6.5,9,6.4,9,6.3,9V8.9c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.5,0c0.7,0,1.1,0.5,1.1,1c0,0.3-0.2,1-1.2,1c-0.1,0-0.3,0-0.4,0
              c-0.1,0-0.2,0-0.4,0v-0.1c0.2,0,0.2,0,0.3-0.2V9.2z"
            fill={style.st1.fill}
          />
          <path
            d="M8.6,9.2C8.5,9.2,8.5,9.1,8.5,9c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1S8.7,9.2,8.6,9.2 M8.3,10.8
              L8.3,10.8c0.1,0,0.2,0,0.2-0.1V9.9c0-0.1,0-0.1-0.1-0.2v0c0.1,0,0.3-0.1,0.3-0.1c0,0,0,0,0.1,0c0,0,0,0,0,0v1.1
              c0,0.1,0.1,0.1,0.1,0.1h0v0.1c-0.1,0-0.2,0-0.3,0C8.5,10.9,8.4,10.9,8.3,10.8L8.3,10.8z"
            fill={style.st1.fill}
          />
          <path
            d="M9.2,10c0-0.1,0-0.1-0.2-0.2V9.7c0.1,0,0.2-0.1,0.4-0.1c0,0,0,0,0,0v0.2c0.2-0.1,0.3-0.2,0.5-0.2
              c0.2,0,0.3,0.2,0.3,0.4v0.7c0,0.1,0.1,0.1,0.1,0.1h0.1v0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0v-0.1h0c0.1,0,0.1,0,0.1-0.1V10
              c0-0.2-0.1-0.2-0.3-0.2c-0.1,0-0.2,0.1-0.3,0.1v0.8c0,0.1,0.1,0.1,0.1,0.1h0.1v0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0v-0.1H9
              c0.1,0,0.1,0,0.1-0.1V10z"
            fill={style.st1.fill}
          />
          <path
            d="M11.1,10C11.2,10,11.2,10,11.1,10c0.1-0.2,0-0.3-0.2-0.3c-0.2,0-0.3,0.1-0.3,0.3H11.1z M10.6,10.1
              c0,0,0,0.1,0,0.2c0,0.3,0.2,0.5,0.4,0.5c0.2,0,0.3-0.1,0.4-0.2l0,0c-0.1,0.2-0.3,0.3-0.5,0.3c-0.4,0-0.5-0.4-0.5-0.6
              c0-0.5,0.4-0.7,0.6-0.7c0.2,0,0.5,0.1,0.5,0.4c0,0,0,0,0,0.1l0,0H10.6z"
            fill={style.st1.fill}
          />
          <path
            d="M11.5,10.8L11.5,10.8c0.2,0,0.2,0,0.2-0.1V9.9c0-0.1-0.1-0.1-0.1-0.1v0c0.2-0.1,0.3-0.2,0.3-0.2c0,0,0,0,0,0
              v0.3h0c0.1-0.1,0.2-0.3,0.4-0.3c0.1,0,0.2,0,0.2,0.1c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.2,0.1-0.2,0.2v0.7
              c0,0.1,0.1,0.1,0.1,0.1h0.2v0.1c-0.2,0-0.3,0-0.4,0C11.7,10.9,11.6,10.9,11.5,10.8L11.5,10.8z"
            fill={style.st1.fill}
          />
          <path
            d="M12.5,10.5c0,0.2,0.1,0.3,0.4,0.3c0.2,0,0.2-0.1,0.2-0.2c0-0.3-0.6-0.2-0.6-0.7c0-0.2,0.1-0.4,0.4-0.4
              c0.1,0,0.2,0,0.3,0.1l0,0.3h-0.1c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.2,0.1-0.2,0.2c0,0.3,0.6,0.2,0.6,0.7c0,0.2-0.1,0.4-0.5,0.4
              c-0.1,0-0.2,0-0.3-0.1L12.5,10.5L12.5,10.5z"
            fill={style.st1.fill}
          />
          <path
            d="M15.9,9.4L15.9,9.4C15.8,9.1,15.5,9,15.2,9c-0.3,0-0.8,0.2-0.8,0.9c0,0.6,0.4,1,0.8,1c0.3,0,0.5-0.2,0.6-0.5
              l0.1,0l-0.1,0.4c-0.1,0.1-0.4,0.1-0.6,0.1c-0.7,0-1.1-0.4-1.1-1.1c0-0.6,0.5-1,1.1-1c0.2,0,0.4,0.1,0.7,0.1L15.9,9.4z"
            fill={style.st1.fill}
          />
          <path
            d="M16,10.8L16,10.8c0.1,0,0.2,0,0.2-0.1V9.1c0-0.2,0-0.2-0.1-0.2v0c0.1,0,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0
              c0,0,0,0,0,0v1.9c0,0.1,0.1,0.1,0.1,0.1h0v0.1c-0.1,0-0.2,0-0.3,0C16.2,10.9,16.1,10.9,16,10.8L16,10.8z"
            fill={style.st1.fill}
          />
          <path
            d="M17.8,10.7C17.8,10.8,17.8,10.8,17.8,10.7c0.1,0.1,0.2,0.1,0.2,0.1v0.1c-0.1,0-0.4,0.1-0.4,0.1l0,0v-0.2
              c-0.2,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.3-0.1-0.3-0.3V9.9c0-0.1,0-0.1-0.2-0.2V9.6c0.1,0,0.3,0,0.4,0c0,0,0,0,0,0.1v0.7
              c0,0.1,0,0.3,0.2,0.3c0.1,0,0.2-0.1,0.3-0.2V9.8c0-0.1-0.1-0.1-0.2-0.1V9.7c0.3,0,0.4,0,0.4,0c0,0,0,0,0,0.1V10.7z"
            fill={style.st1.fill}
          />
          <path
            d="M18.3,10.5c0,0.1,0.1,0.3,0.3,0.3c0.3,0,0.4-0.3,0.4-0.5c0-0.3-0.2-0.6-0.4-0.6c-0.1,0-0.2,0.1-0.3,0.1V10.5z
              M18.3,9.8c0.1-0.1,0.3-0.2,0.4-0.2c0.3,0,0.5,0.3,0.5,0.6c0,0.4-0.3,0.7-0.7,0.7c-0.2,0-0.3-0.1-0.4-0.1l-0.1,0.1l-0.1,0
              c0-0.2,0-0.3,0-0.5V9.1c0-0.2,0-0.2-0.1-0.2v0c0.1,0,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0V9.8z"
            fill={style.st1.fill}
          />
          <path
            d="M6.3,12.5L6.3,12.5c0.1,0,0.1,0,0.1-0.1v-0.8c0-0.1-0.1-0.1-0.1-0.1h0v0c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.2,0
              v0h0c-0.1,0-0.1,0-0.1,0.1v0.8c0,0.1,0.1,0.1,0.1,0.1h0v0c-0.1,0-0.2,0-0.2,0C6.4,12.5,6.3,12.5,6.3,12.5L6.3,12.5z"
            fill={style.st1.fill}
          />
          <path
            d="M6.7,12.5L6.7,12.5L6.7,12.5c-0.1,0-0.1,0-0.1-0.1v-0.8c0-0.1,0.1-0.1,0.1-0.1h0v0c-0.1,0-0.2,0-0.2,0
              c-0.1,0-0.2,0-0.2,0v0h0c0.1,0,0.1,0,0.1,0.1v0.8c0,0.1-0.1,0.1-0.1,0.1h0v0c0.1,0,0.1,0,0.2,0C6.6,12.5,6.6,12.5,6.7,12.5
              M6.7,12.5c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.2,0h0v0v0v0h0h0c0.1,0,0.1,0,0.1-0.1v-0.8c0-0.1-0.1-0.1-0.1-0.1h0v0v-0.1h0
              c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.2,0h0v0v0.1h0h0c-0.1,0-0.1,0-0.1,0.1v0.8C6.6,12.5,6.6,12.5,6.7,12.5L6.7,12.5L6.7,12.5
              L6.7,12.5L6.7,12.5z"
            fill={style.st1.fill}
          />
          <path
            d="M7.8,12.2L7.8,12.2l0-0.6c0-0.1-0.1-0.1-0.1-0.1h0v0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0v0h0
              c-0.1,0-0.1,0-0.1,0.2v0.7c0,0.1,0,0.1,0,0.2H7.8L7,11.7v0.6c0,0.1,0,0.2,0.1,0.2h0v0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0v0h0
              c0.1,0,0.1-0.1,0.1-0.2v-0.6c0-0.1-0.1-0.1-0.1-0.1h0v0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0L7.8,12.2z"
            fill={style.st1.fill}
          />
          <path
            d="M7.8,12.2L7.8,12.2L7.8,12.2L7.8,12.2z M7.8,12.2L7.8,12.2L7.8,12.2z M7.8,12.6L7.8,12.6c0,0,0-0.1,0-0.1
              v-0.7c0-0.2,0.1-0.2,0.1-0.2h0v0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0v0h0c0,0,0.1,0,0.1,0.1l0,0.6l0,0l0,0l-0.7-0.8
              c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0l0,0h0c0.1,0,0.1,0.1,0.1,0.1v0.6c0,0.1,0,0.2-0.1,0.2l0,0v0c0.1,0,0.1,0,0.2,0
              c0.1,0,0.1,0,0.2,0v0h0C7,12.5,7,12.4,7,12.3v-0.6L7.8,12.6z M7.9,12.6L7.9,12.6L7,11.7v0.6c0,0.1,0,0.2,0.1,0.2h0v0v0.1h0
              c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0h0v0v-0.1h0h0c0.1,0,0.1-0.1,0.1-0.2v-0.6c0-0.1-0.1-0.1-0.1-0.1h0v0v-0.1h0
              c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0l0.7,0.8v-0.6c0-0.1-0.1-0.1-0.1-0.1h0v0v-0.1h0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0h0v0v0.1
              h0h0c-0.1,0-0.1,0-0.1,0.2v0.7C7.9,12.5,7.9,12.5,7.9,12.6L7.9,12.6L7.9,12.6z"
            fill={style.st1.fill}
          />
          <path
            d="M8.2,11.5c-0.1,0-0.1,0-0.1,0.1h0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1h0C8.1,11.5,8.2,11.5,8.2,11.5L9,11.5
              c0,0,0.1,0,0.1,0l0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1l0,0c0-0.1,0-0.1-0.1-0.1H8.7v0.8c0,0.1,0.1,0.1,0.1,0.1h0v0
              c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.3,0v0h0c0.1,0,0.1,0,0.1-0.1v-0.8H8.2z"
            fill={style.st1.fill}
          />
          <path
            d="M8.8,12.5c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.3,0h0v0v-0.1h0h0c0.1,0,0.1,0,0.1-0.1v-0.8H8.2v0v0h0.3v0.8
              c0,0.1,0,0.1-0.1,0.1h0v0c0.1,0,0.2,0,0.3,0C8.7,12.5,8.8,12.5,8.8,12.5L8.8,12.5L8.8,12.5c-0.1,0-0.2-0.1-0.2-0.2v-0.8h0.3
              C9,11.5,9,11.6,9,11.7l0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1l0,0c0,0,0,0-0.1,0H8.2h0c0,0-0.1,0-0.1,0h0c0,0,0,0.1,0,0.1
              c0,0,0,0.1,0,0.1h0c0-0.1,0-0.1,0.1-0.1v0v0c-0.1,0-0.1,0-0.1,0.1l0,0h0H8l0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1v0h0h0l0,0
              c0,0,0,0,0.1,0h0H9c0,0,0.1,0,0.1,0l0,0l0,0l0,0l0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1v0l0,0l-0.1,0l0,0c0-0.1,0-0.1-0.1-0.1H8.7
              v0.8C8.7,12.5,8.7,12.5,8.8,12.5L8.8,12.5L8.8,12.5L8.8,12.5L8.8,12.5z"
            fill={style.st1.fill}
          />
          <path
            d="M9.2,12.5L9.2,12.5c0.1,0,0.1,0,0.1-0.1v-0.8c0-0.1-0.1-0.1-0.1-0.1h0v0c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0
              c0,0.1,0,0.2,0,0.2l0,0c0-0.1,0-0.2-0.2-0.2H9.5v0.4h0.2c0.1,0,0.1-0.1,0.1-0.1h0c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2l0,0
              c0-0.1,0-0.2-0.1-0.2H9.5v0.4c0,0.1,0.1,0.1,0.2,0.1c0.2,0,0.3,0,0.3-0.2l0,0c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.3,0-0.4,0
              C9.4,12.5,9.2,12.5,9.2,12.5L9.2,12.5z"
            fill={style.st1.fill}
          />
          <path
            d="M10,12.5c0-0.1,0-0.2,0-0.2l0,0c0,0.2-0.1,0.2-0.3,0.2c-0.1,0-0.2,0-0.2-0.1V12h0.2c0.1,0,0.1,0.1,0.1,0.1
              l0,0c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2h0c0,0.1,0,0.1-0.1,0.1H9.5v-0.4h0.2c0.2,0,0.2,0.1,0.2,0.2l0,0c0,0,0-0.1,0-0.1
              c0,0,0-0.1,0-0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0v0h0c0.1,0,0.1,0,0.1,0.1v0.8c0,0.1-0.1,0.1-0.1,0.1h0v0
              c0.1,0,0.3,0,0.4,0S9.9,12.5,10,12.5 M10,12.5c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0h0v0v0v0h0h0c0.1,0,0.1,0,0.1-0.1v-0.8
              c0-0.1-0.1-0.1-0.1-0.1h0v0v-0.1h0c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0h0v0c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2v0l0,0l-0.1,0l0,0
              c0-0.1,0-0.2-0.2-0.2H9.5v0.4h0.2c0.1,0,0.1,0,0.1-0.1l0,0h0h0.1l0,0c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2l0,0l0,0l-0.1,0l0,0
              c0-0.1,0-0.1-0.1-0.1H9.5v0.3c0,0.1,0.1,0.1,0.2,0.1c0.2,0,0.3,0,0.3-0.2l0,0l0,0l0,0l0,0C10,12.4,10,12.5,10,12.5L10,12.5
              L10,12.5z"
            fill={style.st1.fill}
          />
          <path
            d="M10.4,12L10.4,12c0.2,0,0.3-0.1,0.3-0.2c0-0.1-0.1-0.2-0.2-0.2c0,0-0.1,0-0.1,0V12z M10.2,11.6
              c0-0.1-0.1-0.1-0.1-0.1h0v0c0,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0c0.2,0,0.4,0.1,0.4,0.3c0,0.1-0.1,0.2-0.2,0.3l0.3,0.4
              c0,0.1,0.1,0.1,0.1,0.1v0c-0.1,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1-0.2-0.2-0.3-0.3-0.5h-0.1v0.3c0,0.1,0.1,0.1,0.1,0.1h0v0
              c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0v0h0c0.1,0,0.1,0,0.1-0.1V11.6z"
            fill={style.st1.fill}
          />
          <path
            d="M10.5,12c0.2,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c0,0-0.1,0-0.1,0L10.5,12L10.5,12z M10.4,12L10.4,12
              l0-0.5l0,0c0,0,0,0,0.1,0c0.1,0,0.2,0.1,0.2,0.2C10.7,11.9,10.6,12,10.4,12L10.4,12z M10.4,12h0.1l0,0c0.1,0.2,0.2,0.3,0.3,0.5
              c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0v0c-0.1,0-0.1,0-0.1-0.1L10.7,12l0,0c0.1,0,0.2-0.1,0.2-0.3c0-0.2-0.2-0.3-0.4-0.3
              c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.2,0v0h0c0,0,0.1,0,0.1,0.1v0.8c0,0.1-0.1,0.1-0.1,0.1h0v0c0.1,0,0.1,0,0.2,0
              c0.1,0,0.2,0,0.3,0v0h0c-0.1,0-0.1,0-0.1-0.1L10.4,12L10.4,12z M11.1,12.5c-0.1,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
              c-0.1-0.2-0.2-0.3-0.3-0.5h-0.1v0.3c0,0.1,0,0.1,0.1,0.1h0v0v0.1h0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0h0v0v-0.1h0h0
              c0.1,0,0.1,0,0.1-0.1v-0.8c0-0.1-0.1-0.1-0.1-0.1h0v0v-0.1h0c0,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0c0.2,0,0.4,0.1,0.4,0.3
              c0,0.1-0.1,0.2-0.2,0.3l0.3,0.4C11,12.5,11.1,12.5,11.1,12.5L11.1,12.5L11.1,12.5L11.1,12.5L11.1,12.5z"
            fill={style.st1.fill}
          />
          <path
            d="M12.2,12.2L12.2,12.2l0-0.6c0-0.1-0.1-0.1-0.1-0.1h0v0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0v0h0
              c-0.1,0-0.1,0-0.1,0.2v0.7c0,0.1,0,0.1,0,0.2h-0.1l-0.8-0.9v0.6c0,0.1,0,0.2,0.1,0.2h0v0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0v0
              h0c0.1,0,0.1-0.1,0.1-0.2v-0.6c0-0.1-0.1-0.1-0.1-0.1h0v0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0L12.2,12.2z"
            fill={style.st1.fill}
          />
          <path
            d="M12.2,12.2L12.2,12.2L12.2,12.2L12.2,12.2z M12.2,12.2L12.2,12.2L12.2,12.2z M12.2,12.6L12.2,12.6
              c0,0,0-0.1,0-0.1v-0.7c0-0.2,0.1-0.2,0.1-0.2h0v0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0v0h0c0,0,0.1,0,0.1,0.1l0,0.6l0,0l0,0
              l-0.7-0.8c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0v0h0c0.1,0,0.1,0.1,0.1,0.1v0.6c0,0.1,0,0.2-0.1,0.2l0,0v0c0.1,0,0.1,0,0.2,0
              c0.1,0,0.1,0,0.2,0v0h0c-0.1,0-0.1-0.1-0.2-0.2v-0.6L12.2,12.6z M12.3,12.6L12.3,12.6l-0.8-0.9v0.6c0,0.1,0,0.2,0.1,0.2h0v0v0.1h0
              c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0h0v0v-0.1h0h0c0.1,0,0.1-0.1,0.1-0.2v-0.6c0-0.1-0.1-0.1-0.1-0.1h0v0v-0.1h0
              c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0l0.7,0.8v-0.6c0-0.1-0.1-0.1-0.1-0.1h0v0v-0.1h0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0h0v0v0.1
              h0h0c-0.1,0-0.1,0-0.1,0.2v0.7C12.2,12.5,12.3,12.5,12.3,12.6L12.3,12.6L12.3,12.6z"
            fill={style.st1.fill}
          />
          <path
            d="M12.9,11.6L12.9,11.6l-0.1,0.4H13L12.9,11.6z M12.6,12.3c0,0.1,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1h0v0
              c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0v0h0c0.1,0,0.1,0,0.1-0.1l0.2-0.7c0-0.1,0-0.1,0.1-0.2c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0
              c0,0,0,0,0,0c0,0,0,0,0,0l0.3,0.8c0,0.1,0,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.1h0v0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0v0h0
              c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1L13,12.2h-0.3L12.6,12.3z"
            fill={style.st1.fill}
          />
          <path
            d="M12.9,11.6L12.9,11.6L12.9,11.6z M12.7,12.1H13l-0.1-0.4L12.7,12.1z M12.7,12.1l0.1-0.4h0h0v0l0,0l0,0v0h0
              l0.1,0.4H12.7z M13,12.5c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0v0h0c-0.1,0-0.1,0-0.1-0.1c0,0,0-0.1-0.1-0.2L13,11.5c0,0,0,0,0,0
              c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c0,0.1,0,0.1-0.1,0.2l-0.2,0.7c0,0.1-0.1,0.1-0.1,0.1h0v0c0.1,0,0.1,0,0.2,0
              c0.1,0,0.1,0,0.2,0v0h0c0,0-0.1,0-0.1-0.1c0,0,0-0.1,0-0.1l0,0l0,0l0.1-0.2H13l0.1,0.2c0,0,0,0.1,0,0.1
              C13.1,12.5,13.1,12.5,13,12.5L13,12.5L13,12.5z M13.5,12.5c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0l0,0v0v-0.1h0h0c0,0,0.1,0,0.1,0
              c0,0,0-0.1,0-0.1L13,12.2h-0.3l0,0.2c0,0.1,0,0.1,0,0.1c0,0,0.1,0,0.1,0h0v0v0.1l0,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0l0,0v0
              v-0.1h0h0c0.1,0,0.1,0,0.1-0.1l0.2-0.7c0-0.1,0-0.1,0.1-0.2c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0.3,0.8
              c0,0.1,0,0.1,0.1,0.2C13.4,12.5,13.4,12.5,13.5,12.5L13.5,12.5L13.5,12.5L13.5,12.5L13.5,12.5z"
            fill={style.st1.fill}
          />
          <path
            d="M13.5,11.5c-0.1,0-0.1,0-0.1,0.1h0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1h0C13.4,11.5,13.5,11.5,13.5,11.5l0.8-0.1
              c0,0,0.1,0,0.1,0l0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1l0,0c0-0.1,0-0.1-0.1-0.1H14v0.8c0,0.1,0.1,0.1,0.1,0.1h0v0
              c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.3,0v0h0c0.1,0,0.1,0,0.1-0.1v-0.8H13.5z"
            fill={style.st1.fill}
          />
          <path
            d="M14.1,12.5c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.3,0h0v0v-0.1h0h0c0.1,0,0.1,0,0.1-0.1l0-0.8h-0.2v0v0h0.3v0.8
              c0,0.1,0,0.1-0.1,0.1h0v0c0.1,0,0.2,0,0.3,0C14,12.5,14.1,12.5,14.1,12.5L14.1,12.5L14.1,12.5c-0.1,0-0.2-0.1-0.2-0.2v-0.8h0.3
              c0.1,0,0.1,0.1,0.1,0.1l0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1l0,0c0,0,0,0-0.1,0h-0.8h0c0,0-0.1,0-0.1,0h0c0,0,0,0.1,0,0.1
              c0,0,0,0.1,0,0.1h0c0-0.1,0-0.1,0.1-0.1v0v0c-0.1,0-0.1,0-0.1,0.1l0,0h0h-0.1l0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1v0h0h0l0,0
              c0,0,0,0,0.1,0c0,0,0,0,0,0h0.8c0,0,0.1,0,0.1,0l0,0l0,0l0,0l0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1v0l0,0l-0.1,0v0
              c0-0.1,0-0.1-0.1-0.1H14v0.8C14,12.5,14,12.5,14.1,12.5L14.1,12.5L14.1,12.5L14.1,12.5L14.1,12.5z"
            fill={style.st1.fill}
          />
          <path
            d="M14.5,12.5L14.5,12.5c0.1,0,0.1,0,0.1-0.1v-0.8c0-0.1-0.1-0.1-0.1-0.1h0v0c0.1,0,0.2,0,0.2,0
              c0.1,0,0.2,0,0.2,0v0h0c-0.1,0-0.1,0-0.1,0.1v0.8c0,0.1,0.1,0.1,0.1,0.1h0v0c-0.1,0-0.2,0-0.2,0C14.6,12.5,14.5,12.5,14.5,12.5
              L14.5,12.5z"
            fill={style.st1.fill}
          />
          <path
            d="M14.9,12.5L14.9,12.5L14.9,12.5c-0.1,0-0.1,0-0.1-0.1v-0.8c0-0.1,0.1-0.1,0.1-0.1h0v0c-0.1,0-0.2,0-0.2,0
              c-0.1,0-0.2,0-0.2,0v0h0c0.1,0,0.1,0,0.1,0.1v0.8c0,0.1-0.1,0.1-0.1,0.1h0v0c0.1,0,0.1,0,0.2,0C14.8,12.5,14.8,12.5,14.9,12.5
              M14.9,12.5c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.2,0h0v0v0v0h0h0c0.1,0,0.1,0,0.1-0.1v-0.8c0-0.1-0.1-0.1-0.1-0.1h0v0v-0.1h0
              c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.2,0h0v0v0.1h0h0c-0.1,0-0.1,0-0.1,0.1v0.8C14.8,12.5,14.8,12.5,14.9,12.5L14.9,12.5L14.9,12.5
              L14.9,12.5L14.9,12.5z"
            fill={style.st1.fill}
          />
          <path
            d="M15.5,12.5c0.3,0,0.3-0.3,0.3-0.5c0-0.2-0.1-0.5-0.4-0.5c-0.3,0-0.3,0.2-0.3,0.4
              C15.2,12.2,15.3,12.5,15.5,12.5 M15.5,11.5c0.3,0,0.6,0.2,0.6,0.5c0,0.4-0.3,0.6-0.6,0.6c-0.3,0-0.6-0.2-0.6-0.5
              C15,11.7,15.2,11.5,15.5,11.5"
            fill={style.st1.fill}
          />
          <path
            d="M15.2,12c0-0.2,0.1-0.4,0.4-0.4c0.3,0,0.4,0.3,0.4,0.5c0,0.2-0.1,0.5-0.4,0.5v0v0c0.3,0,0.3-0.3,0.3-0.5
              c0-0.2-0.1-0.5-0.4-0.5C15.3,11.5,15.2,11.8,15.2,12c0,0.3,0.1,0.5,0.4,0.5v0v0C15.3,12.5,15.2,12.2,15.2,12 M15,12
              c0-0.3,0.2-0.6,0.6-0.6v0v0C15.2,11.5,15,11.7,15,12c0,0.3,0.3,0.5,0.6,0.5c0.3,0,0.6-0.2,0.6-0.6c0-0.3-0.3-0.5-0.6-0.5v0v0
              c0.3,0,0.6,0.2,0.6,0.5c0,0.4-0.3,0.6-0.6,0.6C15.2,12.6,15,12.3,15,12"
            fill={style.st1.fill}
          />
          <path
            d="M17.1,12.2L17.1,12.2l0-0.6c0-0.1-0.1-0.1-0.1-0.1h0v0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0v0h0
              c-0.1,0-0.1,0-0.1,0.2v0.7c0,0.1,0,0.1,0,0.2h-0.1l-0.8-0.9v0.6c0,0.1,0,0.2,0.1,0.2h0v0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0v0
              h0c0.1,0,0.1-0.1,0.1-0.2v-0.6c0-0.1-0.1-0.1-0.1-0.1h0v0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0L17.1,12.2z"
            fill={style.st1.fill}
          />
          <path
            d="M17.1,12.2L17.1,12.2L17.1,12.2L17.1,12.2z M17.1,12.2L17.1,12.2L17.1,12.2z M17.2,12.6L17.2,12.6
              c0,0,0-0.1,0-0.1v-0.7c0-0.2,0.1-0.2,0.1-0.2h0v0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0v0h0c0,0,0.1,0,0.1,0.1l0,0.6l0,0l0,0
              l-0.7-0.8c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0v0h0c0.1,0,0.1,0.1,0.1,0.1v0.6c0,0.1,0,0.2-0.1,0.2l0,0v0c0.1,0,0.1,0,0.2,0
              c0.1,0,0.1,0,0.2,0v0h0c-0.1,0-0.1-0.1-0.1-0.2v-0.6L17.2,12.6z M17.2,12.6L17.2,12.6l-0.8-0.9v0.6c0,0.1,0,0.2,0.1,0.2h0v0v0.1h0
              c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0h0v0v-0.1h0h0c0.1,0,0.1-0.1,0.1-0.2v-0.6c0-0.1-0.1-0.1-0.1-0.1h0v0v-0.1h0
              c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0l0.7,0.8v-0.6c0-0.1-0.1-0.1-0.1-0.1h0v0v-0.1h0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0h0v0v0.1
              h0h0c-0.1,0-0.1,0-0.1,0.2v0.7C17.2,12.5,17.2,12.5,17.2,12.6L17.2,12.6L17.2,12.6z"
            fill={style.st1.fill}
          />
          <path
            d="M17.8,11.6L17.8,11.6l-0.1,0.4H18L17.8,11.6z M17.6,12.3c0,0.1,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1h0v0
              c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0v0h0c0.1,0,0.1,0,0.1-0.1l0.2-0.7c0-0.1,0-0.1,0.1-0.2c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0
              c0,0,0,0,0,0c0,0,0,0,0,0l0.3,0.8c0,0.1,0,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.1h0v0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0v0h0
              c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1L18,12.2h-0.3L17.6,12.3z"
            fill={style.st1.fill}
          />
          <path
            d="M17.8,11.6L17.8,11.6L17.8,11.6z M17.7,12.1H18l-0.1-0.4L17.7,12.1z M17.7,12.1l0.1-0.4h0h0v0l0,0l0,0v0h0
              l0.1,0.4H17.7z M18,12.5c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0l0,0h0c-0.1,0-0.1,0-0.1-0.1c0,0,0-0.1-0.1-0.2L18,11.5c0,0,0,0,0,0
              c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c0,0.1,0,0.1-0.1,0.2l-0.2,0.7c0,0.1-0.1,0.1-0.1,0.1h0v0c0.1,0,0.1,0,0.2,0
              c0.1,0,0.1,0,0.2,0v0h0c0,0-0.1,0-0.1-0.1c0,0,0-0.1,0-0.1l0,0l0,0l0.1-0.2H18l0.1,0.2c0,0,0,0.1,0,0.1C18.1,12.5,18,12.5,18,12.5
              L18,12.5L18,12.5z M18.4,12.5c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0l0,0v0v-0.1h0h0c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1L18,12.2
              h-0.3l0,0.2c0,0.1,0,0.1,0,0.1c0,0,0.1,0,0.1,0h0v0v0.1l0,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0l0,0v0v-0.1h0h0
              c0.1,0,0.1,0,0.1-0.1l0.2-0.7c0-0.1,0-0.1,0.1-0.2c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0.3,0.8
              c0,0.1,0,0.1,0.1,0.2C18.3,12.5,18.4,12.5,18.4,12.5L18.4,12.5L18.4,12.5L18.4,12.5L18.4,12.5z"
            fill={style.st1.fill}
          />
          <path
            d="M18.8,12.4C18.8,12.4,18.9,12.5,18.8,12.4c0.2,0.1,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.1-0.1c0,0,0-0.1,0-0.1h0
              c0,0.1,0,0.2-0.1,0.3c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0v0h0c0.1,0,0.1,0,0.1-0.1v-0.8c0-0.1-0.1-0.1-0.1-0.1h0v0
              c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.2,0v0h0c-0.1,0-0.1,0-0.1,0.1V12.4z"
            fill={style.st1.fill}
          />
          <path
            d="M19.3,12.5c0-0.1,0-0.2,0.1-0.2h0c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
              c0,0-0.1,0-0.1-0.1v-0.8c0-0.1,0.1-0.1,0.1-0.1h0v0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0v0h0c0.1,0,0.1,0,0.1,0.1v0.8
              c0,0.1-0.1,0.1-0.1,0.1h0v0c0.1,0,0.3,0,0.4,0S19.1,12.5,19.3,12.5 M19.3,12.5c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0h0v0v-0.1h0
              h0c0.1,0,0.1,0,0.1-0.1v-0.8c0-0.1-0.1-0.1-0.1-0.1h0v0v-0.1h0c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.2,0h0v0v0.1h0h0
              c-0.1,0-0.1,0-0.1,0.1v0.8c0,0.1,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1-0.1c0,0,0-0.1,0-0.1l0,0h0h0.1
              l0,0C19.3,12.4,19.3,12.5,19.3,12.5L19.3,12.5L19.3,12.5z"
            fill={style.st1.fill}
          />
        </g>
      </g>
    </svg>
  )
}

export const Elo = () => {
  const style = {
    st0: { fill: "#FFF100" },
    st1: { fill: "#00A3DF" },
    st2: { fill: "#EE4023" },
    st3: { fill: "#FFFFFF" }
  }
  return (
    <svg version="1.1" viewBox="0 0 25.6 16" x="0px" y="0px">
      <g>
        <path d="M25.6,15.1c0,0.5-0.4,0.9-0.9,0.9H0.9C0.4,16,0,15.6,0,15.1V0.9C0,0.4,0.4,0,0.9,0h23.9c0.5,0,0.9,0.4,0.9,0.9V15.1z" />
        <g>
          <g>
            <path
              d="M6.2,6.1C6.4,6,6.6,6,6.9,6c1,0,1.8,0.7,2,1.6l1.4-0.3C9.9,5.7,8.5,4.6,6.9,4.6c-0.4,0-0.7,0.1-1.1,0.2
                  L6.2,6.1z"
              fill={style.st0.fill}
            />
            <path
              d="M4.6,10.6l0.9-1.1C5.1,9.1,4.8,8.6,4.8,8c0-0.6,0.3-1.1,0.7-1.5L4.6,5.4C3.9,6.1,3.4,7,3.4,8
                  C3.4,9,3.9,9.9,4.6,10.6z"
              fill={style.st1.fill}
            />
            <path
              d="M8.8,8.4c-0.2,0.9-1,1.6-2,1.6c-0.2,0-0.4,0-0.6-0.1l-0.4,1.3c0.3,0.1,0.7,0.2,1.1,0.2c1.7,0,3-1.2,3.4-2.7
                  L8.8,8.4z"
              fill={style.st2.fill}
            />
          </g>
          <g>
            <g>
              <g>
                <path
                  d="M14.8,9.4c-0.2,0.2-0.5,0.4-0.9,0.4c-0.2,0-0.5-0.1-0.6-0.2l-0.5,0.7c0.3,0.2,0.7,0.3,1.1,0.3
                      c0.6,0,1.1-0.2,1.5-0.6L14.8,9.4z"
                  fill={style.st3.fill}
                />
                <path
                  d="M14,6.4c-1.2,0-2.1,0.9-2.1,2.1c0,0.4,0.1,0.8,0.3,1.2L16,8C15.8,7.1,15,6.4,14,6.4 M12.7,8.6
                      c0,0,0-0.1,0-0.1c0-0.7,0.6-1.2,1.3-1.2c0.4,0,0.7,0.2,0.9,0.4L12.7,8.6z"
                  fill={style.st3.fill}
                />
              </g>
              <path
                d="M17.2,5.4v4l0.7,0.3l-0.3,0.8l-0.7-0.3c-0.2-0.1-0.3-0.2-0.3-0.3c-0.1-0.1-0.1-0.3-0.1-0.5V5.4H17.2z"
                fill={style.st3.fill}
              />
            </g>
            <g>
              <path
                d="M19.7,7.3c0.1,0,0.3-0.1,0.4-0.1c0.6,0,1.1,0.4,1.2,1l0.8-0.2c-0.2-1-1-1.7-2.1-1.7c-0.2,0-0.5,0-0.7,0.1
                    L19.7,7.3z"
                fill={style.st3.fill}
              />
              <path
                d="M18.7,10l0.6-0.6c-0.3-0.2-0.4-0.6-0.4-0.9c0-0.4,0.2-0.7,0.4-0.9l-0.6-0.6C18.3,7.3,18,7.8,18,8.5
                    C18,9.1,18.3,9.7,18.7,10z"
                fill={style.st3.fill}
              />
              <path
                d="M21.3,8.7c-0.1,0.6-0.6,1-1.2,1c-0.1,0-0.3,0-0.4-0.1l-0.3,0.8c0.2,0.1,0.4,0.1,0.7,0.1
                    c1,0,1.9-0.7,2.1-1.7L21.3,8.7z"
                fill={style.st3.fill}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export const Laser = () => {
  const style = {
    st0: { fill: "#283A97" },
    st1: { fill: "#EE4799" },
    st2: { fill: "#FFFFFF" }
  }

  return (
    <svg version="1.1" viewBox="0 0 25.5 16" x="0px" y="0px">
      <g>
        <path
          d="M24.1,0H1.4C0.6,0,0,0.6,0,1.4v13.3C0,15.4,0.6,16,1.4,16h22.8c0.8,0,1.4-0.6,1.4-1.4V1.4
            C25.5,0.6,24.9,0,24.1,0z M7,2.5l11.6-0.3l-0.3,0.6L7,2.5L7,2.5z M9.2,13.4c0.2,0,0.3,0,0.4,0l-0.1,0.2H8.7v-1.4H9v1.1
            C9.2,13.4,9.2,13.4,9.2,13.4z M10.6,12.4l0.4,0l0.6,1.3h-0.4l-0.1-0.2h-0.5l-0.1,0.2h-0.4l0.5-1.1L10.6,12.4z M12.6,13.2l-0.2-0.1
            c-0.2-0.1-0.2-0.2-0.2-0.3c0-0.2,0.2-0.4,0.5-0.4c0.1,0,0.2,0,0.4,0.1L13,12.7c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.1,0.1
            c0,0,0,0.1,0.1,0.1l0.2,0.1c0.2,0.1,0.2,0.2,0.2,0.3c0,0.2-0.2,0.4-0.5,0.4c-0.1,0-0.2,0-0.4-0.1l0.1-0.2c0.1,0.1,0.2,0.1,0.3,0.1
            c0.1,0,0.2,0,0.2-0.1C12.7,13.3,12.7,13.2,12.6,13.2z M14.6,12.9c0.1,0,0.1,0,0.1,0l-0.1,0.2c-0.1,0-0.2,0-0.3,0h0v0.3
            c0.1,0,0.1,0,0.2,0c0.2,0,0.3,0,0.4,0l-0.1,0.2h-0.9v-1.3h0.9l-0.1,0.2c-0.2,0-0.3,0-0.4,0h0v0.3C14.4,12.9,14.4,12.9,14.6,12.9z
            M16.8,13.7h-0.4L16,13c0,0,0,0,0.1,0c0.2,0,0.3-0.1,0.3-0.2c0-0.1-0.1-0.2-0.2-0.2h0H16v1h-0.4v-1.3l0.5,0c0.4,0,0.6,0.1,0.6,0.4
            c0,0.2-0.1,0.3-0.2,0.3L16.8,13.7z M18.5,11.7h-5.6L12.7,12l-0.4-0.3l0,0H7v0h5.4l0,0l0.7-0.2l-0.1,0.3L18.5,11.7L18.5,11.7z
            M18.5,11.1h-5.3l-0.2,0.3L12,11.1l0,0H7v0h5l0,0l1.5-0.3L13.3,11L18.5,11.1L18.5,11.1z M18.5,10.4h-4.9l-0.2,0.3l-1.9-0.3l0,0H7v0
            h4.6l0,0l2.3-0.3l-0.2,0.3L18.5,10.4L18.5,10.4z M18.5,9.7H14L13.9,10l-2.7-0.3l0,0H7v0h4.2l0,0l3.1-0.3l-0.2,0.3L18.5,9.7
            L18.5,9.7z M18.5,9.1h-4.1l-0.2,0.3l-3.5-0.3l0,0H7v0h3.8l0,0l3.9-0.3l-0.2,0.3L18.5,9.1L18.5,9.1z M18.5,8.4h-3.7l-0.2,0.3
            l-4.3-0.3l0,0H7v0h3.4l0,0L15,8.1l-0.2,0.3L18.5,8.4L18.5,8.4z M18.5,7.8h-3.3L15.1,8l-5-0.3H7v0h3l0,0l5.5-0.3l-0.2,0.3L18.5,7.8
            L18.5,7.8z M18.5,7.1h-2.9l-0.2,0.3L9.6,7.1l0,0H7v0h2.6l0,0l6.3-0.3l-0.2,0.3L18.5,7.1L18.5,7.1z M18.5,6.5H16l-0.2,0.3L9.2,6.5
            l0,0H7v0h2.2l0,0l7-0.3L16,6.4L18.5,6.5L18.5,6.5z M18.5,5.8h-2.1l-0.2,0.3L8.8,5.8H7v0h1.8l0,0l7.8-0.3l-0.2,0.3L18.5,5.8
            L18.5,5.8z M18.5,5.2h-1.7l-0.2,0.3L8.3,5.2v0H7v0h1.4l0,0L17,4.8l-0.2,0.3L18.5,5.2L18.5,5.2z M18.5,4.5h-1.4L17,4.8L8,4.5l0,0H7
            v0h1l0,0l9.4-0.3l-0.2,0.3L18.5,4.5L18.5,4.5z M18.5,3.8h-1l-0.2,0.3L7.5,3.8H7v0h0.6l0,0l10.2-0.3l-0.2,0.3L18.5,3.8L18.5,3.8z
            M18.5,3.2H18l-0.2,0.3L7.3,3.2H7v0h0.2l0,0l11-0.3L18,3.2L18.5,3.2L18.5,3.2z"
          fill={style.st0.fill}
        />
        <polygon fill={style.st0.fill} points="10.9,12.9 10.7,13.2 11.1,13.2 	" />
        <polygon fill={style.st1.fill} points="18.2,2.8 18.5,2.2 7,2.5 7,2.5 	" />
        <polygon
          fill={style.st1.fill}
          points="12.9,11.7 13,11.4 12.3,11.7 12.3,11.7 7,11.7 7,11.7 12.3,11.7 12.3,11.7 12.7,12 12.9,11.7
            18.5,11.7 18.5,11.7 	"
        />
        <polygon
          fill={style.st1.fill}
          points="13.3,11 13.5,10.7 11.9,11 11.9,11 7,11 7,11.1 12,11.1 12,11.1 13.1,11.3 13.3,11.1 18.5,11.1
            18.5,11 	"
        />
        <polygon
          fill={style.st1.fill}
          points="13.7,10.4 13.8,10.1 11.6,10.4 11.6,10.4 7,10.4 7,10.4 11.6,10.4 11.6,10.4 13.5,10.7 13.7,10.4
            18.5,10.4 18.5,10.4 	"
        />
        <polygon
          fill={style.st1.fill}
          points="14.1,9.7 14.2,9.4 11.2,9.7 11.2,9.7 7,9.7 7,9.7 11.2,9.7 11.2,9.7 13.9,10 14,9.7 18.5,9.7
            18.5,9.7 	"
        />
        <polygon
          fill={style.st1.fill}
          points="14.5,9.1 14.6,8.8 10.8,9 10.8,9.1 7,9.1 7,9.1 10.8,9.1 10.8,9.1 14.3,9.4 14.4,9.1 18.5,9.1
            18.5,9.1 	"
        />
        <polygon
          fill={style.st1.fill}
          points="14.9,8.4 15,8.1 10.3,8.4 10.3,8.4 7,8.4 7,8.4 10.4,8.4 10.4,8.4 14.7,8.7 14.8,8.4 18.5,8.4
            18.5,8.4 	"
        />
        <polygon
          fill={style.st1.fill}
          points="15.2,7.7 15.4,7.5 9.9,7.7 9.9,7.7 7,7.7 7,7.8 10,7.8 15.1,8 15.2,7.8 18.5,7.8 18.5,7.7 	"
        />
        <polygon
          fill={style.st1.fill}
          points="15.6,7.1 15.8,6.8 9.5,7.1 9.5,7.1 7,7.1 7,7.1 9.6,7.1 9.6,7.1 15.4,7.4 15.6,7.1 18.5,7.1 18.5,7.1
              "
        />
        <polygon
          fill={style.st1.fill}
          points="16,6.4 16.2,6.2 9.1,6.4 9.2,6.4 7,6.4 7,6.5 9.2,6.5 9.2,6.5 15.8,6.7 16,6.5 18.5,6.5 18.5,6.4 	"
        />
        <polygon
          fill={style.st1.fill}
          points="16.4,5.8 16.6,5.5 8.7,5.8 8.7,5.8 7,5.8 7,5.8 8.8,5.8 16.2,6.1 16.4,5.8 18.5,5.8 18.5,5.8 	"
        />
        <polygon
          fill={style.st1.fill}
          points="16.8,5.1 17,4.8 8.3,5.1 8.3,5.1 7,5.1 7,5.2 8.3,5.2 8.3,5.2 16.6,5.4 16.8,5.2 18.5,5.2 18.5,5.1
            "
        />
        <polygon
          fill={style.st1.fill}
          points="17.2,4.5 17.3,4.2 7.9,4.5 7.9,4.5 7,4.5 7,4.5 8,4.5 8,4.5 17,4.8 17.2,4.5 18.5,4.5 18.5,4.5 	"
        />
        <polygon
          fill={style.st1.fill}
          points="17.6,3.8 17.7,3.5 7.5,3.8 7.5,3.8 7,3.8 7,3.8 7.5,3.8 17.4,4.1 17.6,3.8 18.5,3.8 18.5,3.8 	"
        />
        <polygon
          fill={style.st1.fill}
          points="18,3.2 18.1,2.9 7.1,3.1 7.1,3.2 7,3.2 7,3.2 7.3,3.2 17.8,3.5 18,3.2 18.5,3.2 18.5,3.2 	"
        />
        <path
          d="M14.3,12.6c0.1,0,0.2,0,0.4,0l0.1-0.2h-0.9v1.3h0.9l0.1-0.2c-0.2,0-0.3,0-0.4,0c0,0-0.1,0-0.2,0v-0.3h0
            c0.1,0,0.2,0,0.3,0l0.1-0.2c-0.1,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0L14.3,12.6L14.3,12.6z"
          fill={style.st2.fill}
        />
        <path
          d="M16.7,12.8c0-0.3-0.2-0.4-0.6-0.4l-0.5,0v1.3H16v-1h0.1h0c0.2,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.3,0.2
            c0,0,0,0-0.1,0l0.4,0.7h0.4l-0.4-0.5C16.6,13.1,16.7,13,16.7,12.8z"
          fill={style.st2.fill}
        />
        <path d="M8.7,12.3v1.4h0.9l0.1-0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.1,0-0.2,0v-1.1H8.7z" fill={style.st2.fill} />
        <path
          d="M10.6,13.7l0.1-0.2h0.5l0.1,0.2h0.4L11,12.4l-0.4,0l0.1,0.1l-0.5,1.1H10.6z M10.9,12.9l0.2,0.4h-0.3L10.9,12.9
            z"
          fill={style.st2.fill}
        />
        <path
          d="M12.6,13.5c-0.1,0-0.2,0-0.3-0.1l-0.1,0.2c0.2,0.1,0.2,0.1,0.4,0.1c0.3,0,0.5-0.2,0.5-0.4
            c0-0.1-0.1-0.2-0.2-0.3l-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.3,0.1l0.1-0.2
            c-0.1-0.1-0.3-0.1-0.4-0.1c-0.3,0-0.5,0.1-0.5,0.4c0,0.1,0.1,0.2,0.2,0.3l0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2
            C12.7,13.4,12.7,13.5,12.6,13.5z"
          fill={style.st2.fill}
        />
      </g>
    </svg>
  )
}

export const UnionPay = () => {
  const style = {
    st0: { fill: "#EFF0F1" },
    st1: { fill: "#01798A" },
    st2: { fill: "#024381" },
    st3: { fill: "#DD0228" },
    st4: { fill: "#FFFFFF" },
    st5: { fill: "#E02F41" },
    st6: { fill: "#2E4F7D" }
  }

  return (
    <svg version="1.1" viewBox="0 0 25.6 16" x="0px" y="0px">
      <g>
        <g>
          <path
            d="M24.8,0H0.9C0.4,0,0,0.4,0,0.8v14.3C0,15.6,0.4,16,0.9,16h23.9c0.5,0,0.9-0.4,0.9-0.9V0.8
              C25.6,0.4,25.2,0,24.8,0z"
            fill={style.st0.fill}
          />
        </g>
      </g>
      <g>
        <path
          d="M20.4,2.6l-4.1,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.6,0-1.3,0.5-1.4,1l-2,8.7c-0.1,0.6,0.2,1,0.8,1H18
            c0.6,0,1.1-0.5,1.2-1l2-8.7C21.3,3.1,21,2.6,20.4,2.6"
          fill={style.st1.fill}
          id="path22"
        />
        <path
          d="M12.9,12.3l2-8.7c0.1-0.6,0.8-1,1.4-1l-1.6,0l-3,0c-0.6,0-1.3,0.5-1.4,1l-2,8.7
            c-0.1,0.6,0.2,1,0.8,1h4.6C13.1,13.4,12.7,12.9,12.9,12.3"
          fill={style.st2.fill}
          id="path20"
        />
        <path
          d="M8.2,12.3l2-8.7c0.1-0.6,0.8-1,1.4-1l-3.8,0c-0.6,0-1.3,0.5-1.4,1l-2,8.7c0,0.1,0,0.1,0,0.2v0.2
            c0,0.4,0.4,0.7,0.8,0.7H9C8.5,13.4,8.1,12.9,8.2,12.3"
          fill={style.st3.fill}
          id="path16"
        />
        <path
          d="M11.7,8.9L11.7,8.9c0.1,0,0.2,0,0.2-0.1l0.2-0.3h0.5l-0.1,0.2h0.6L13,9.1h-0.7
            c-0.1,0.1-0.2,0.2-0.3,0.2h-0.4L11.7,8.9 M11.6,9.3h1.3l-0.1,0.3h-0.5l-0.1,0.3h0.5l-0.1,0.3h-0.5L12,10.7c0,0.1,0,0.1,0.1,0.1h0.4
            l-0.1,0.3h-0.8c-0.2,0-0.2-0.1-0.2-0.3l0.2-0.6h-0.3l0.1-0.3h0.3l0.1-0.3h-0.3L11.6,9.3z M13.7,8.6l0,0.2c0,0,0.2-0.2,0.5-0.2H15
            l-0.3,1.2c0,0.1-0.1,0.2-0.3,0.2h-0.9l-0.2,0.8c0,0,0,0.1,0.1,0.1h0.2l-0.1,0.3h-0.5c-0.2,0-0.3-0.1-0.2-0.2l0.6-2.3H13.7L13.7,8.6
            z M14.4,8.9h-0.7l-0.1,0.3c0,0,0.1-0.1,0.3-0.1c0.2,0,0.4,0,0.4,0L14.4,8.9z M14.1,9.6C14.2,9.7,14.2,9.6,14.1,9.6l0.1-0.2h-0.7
            l-0.1,0.2H14.1z M13.6,10h0.4l0,0.2h0.1c0.1,0,0.1,0,0.1-0.1l0-0.1h0.4l0,0.2c0,0.1-0.1,0.2-0.3,0.2h-0.2l0,0.3
            c0,0.1,0,0.1,0.1,0.1h0.2l-0.1,0.3h-0.5c-0.1,0-0.2-0.1-0.2-0.2L13.6,10"
          fill={style.st4.fill}
          id="path24"
        />
        <path
          d="M8.4,7.1C8.4,7.3,8.3,7.6,8,7.7C7.8,7.9,7.6,7.9,7.2,7.9c-0.3,0-0.5-0.1-0.7-0.2
            C6.5,7.6,6.4,7.5,6.4,7.3c0-0.1,0-0.1,0-0.2l0.3-1.7h0.5L7,7c0,0,0,0.1,0,0.1C7,7.3,7,7.3,7,7.4c0.1,0.1,0.2,0.1,0.3,0.1
            c0.2,0,0.3,0,0.4-0.1C7.8,7.3,7.9,7.2,7.9,7l0.3-1.7h0.5L8.4,7.1"
          fill={style.st4.fill}
          id="path28"
        />
        <path
          d="M10.7,6.4h0.4l-0.3,1.5h-0.4L10.7,6.4 M10.8,5.9h0.4l-0.1,0.4h-0.4L10.8,5.9"
          fill={style.st4.fill}
          id="path34"
        />
        <path
          d="M11.4,7.8c-0.1-0.1-0.2-0.2-0.2-0.4c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.2,0.2-0.4,0.3-0.6
            c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.2,0.4c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.2-0.2,0.4-0.3,0.6
            c-0.2,0.1-0.4,0.2-0.6,0.2C11.7,7.9,11.5,7.9,11.4,7.8 M12.2,7.5c0.1-0.1,0.1-0.2,0.2-0.4c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1
            c0-0.1,0-0.2-0.1-0.2c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.4c0,0,0,0,0,0.1c0,0,0,0,0,0.1
            c0,0.1,0,0.2,0.1,0.2c0,0.1,0.1,0.1,0.2,0.1C12.1,7.6,12.1,7.6,12.2,7.5"
          fill={style.st4.fill}
          id="path36"
        />
        <path d="M16.2,2.6l-1.7,0L16.2,2.6C16.2,2.6,16.2,2.6,16.2,2.6" fill={style.st5.fill} id="path14" />
        <path d="M14.6,2.6l-2.9,0c0,0,0,0,0,0L14.6,2.6" fill={style.st6.fill} id="path18" />
        <path
          d="M15.5,9l0.1-0.4h0.5l0,0.1c0,0,0.3-0.1,0.4-0.1c0.2,0,0.6,0,0.6,0L17,9h-0.1l-0.5,1.7h0.1
            l-0.1,0.3h-0.1l0,0.1h-0.5l0-0.1h-1l0.1-0.3h0.1L15.5,9L15.5,9 M16,9l-0.1,0.4c0,0,0.2-0.1,0.4-0.1c0-0.2,0.1-0.3,0.1-0.3H16z
             M15.8,9.6l-0.1,0.5c0,0,0.2-0.1,0.4-0.1c0-0.2,0.1-0.3,0.1-0.3H15.8z M15.9,10.6l0.1-0.3h-0.4l-0.1,0.3H15.9z M17.1,8.6h0.5l0,0.2
            c0,0,0,0.1,0.1,0.1h0.1l-0.1,0.3h-0.3c-0.1,0-0.2,0-0.2-0.2L17.1,8.6z M17,9.2h1.5l-0.1,0.3h-0.5l-0.1,0.3h0.5l-0.1,0.3h-0.5
            l-0.1,0.2h0.3l0.1,0.4c0,0,0,0.1,0.1,0.1h0.1L18,11.1h-0.3c-0.2,0-0.2,0-0.2-0.2l-0.1-0.3l-0.2,0.4c-0.1,0.1-0.1,0.1-0.3,0.1h-0.4
            l0.1-0.3h0.1c0.1,0,0.1,0,0.1-0.1l0.4-0.5h-0.5l0.1-0.3h0.5l0.1-0.3h-0.5L17,9.2"
          fill={style.st4.fill}
          id="path26"
        />
        <path
          d="M8.9,6.4h0.4l0,0.2l0.1-0.1c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.4
            l-0.2,1H9.6L9.7,7c0-0.1,0-0.2,0-0.2c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.2,0.1C9.3,6.8,9.2,6.9,9.2,7L9,7.9H8.6L8.9,6.4"
          fill={style.st4.fill}
          id="path30"
        />
        <path
          d="M13.2,6.4h0.4l0,0.2l0.1-0.1c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0,0.3,0.1
            c0.1,0.1,0.1,0.2,0.1,0.4l-0.2,1h-0.4L14,7c0-0.1,0-0.2,0-0.2c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.2,0.1c-0.1,0.1-0.1,0.1-0.1,0.3
            l-0.2,0.9h-0.4L13.2,6.4"
          fill={style.st4.fill}
          id="path32"
        />
        <path
          d="M15,5.5h1.1c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.2,0.2,0.2,0.4v0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
            c0,0.2-0.2,0.4-0.3,0.6C16.2,7,16,7,15.8,7h-0.6L15,7.9h-0.5L15,5.5 M15.3,6.6h0.5c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.1,0.1-0.3
            c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0-0.1,0-0.1-0.1-0.2c-0.1,0-0.2-0.1-0.3-0.1h-0.4L15.3,6.6"
          fill={style.st4.fill}
          id="path38"
        />
        <path
          d="M19,8.2c-0.2,0.3-0.3,0.5-0.4,0.6c-0.1,0.1-0.3,0.3-0.7,0.3l0-0.3c0.4-0.1,0.5-0.6,0.7-0.8
            l-0.1-1.6l0.3,0H19l0,1l0.4-1h0.4L19,8.2"
          fill={style.st4.fill}
          id="path40"
        />
        <path
          d="M17.8,6.5l-0.2,0.1c-0.2-0.1-0.3-0.2-0.7-0.1c-0.4,0.2-0.8,1.8,0.4,1.2l0.1,0.1l0.5,0l0.3-1.4
            L17.8,6.5 M17.5,7.3c-0.1,0.2-0.2,0.4-0.4,0.3C17,7.6,17,7.4,17,7.1c0.1-0.2,0.2-0.4,0.4-0.3C17.5,6.9,17.6,7.1,17.5,7.3"
          fill={style.st4.fill}
          id="path42"
        />
      </g>
    </svg>
  )
}

export const SlidesIcon = () => {
  const style = {
    st0: { fill: "#3389FF" }
  }
  return (
    <svg version="1.1" viewBox="0 0 37 37" x="0px" y="0px">
      <path
        d="M18.5,37C8.3,37,0,28.7,0,18.5C0,8.3,8.3,0,18.5,0S37,8.3,37,18.5C37,28.7,28.7,37,18.5,37z
          M18.5,1C8.9,1,1,8.9,1,18.5C1,28.1,8.9,36,18.5,36S36,28.1,36,18.5C36,8.9,28.1,1,18.5,1z"
        fill={style.st0.fill}
      />
      <g>
        <g>
          <g>
            <path
              d="M28.5,22.6h-1.9c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5H28V10.1H11.2v4.8
                c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5V9.6c0-0.3,0.2-0.5,0.5-0.5h17.8c0.3,0,0.5,0.2,0.5,0.5v12.5
                C29,22.4,28.8,22.6,28.5,22.6z"
              fill={style.st0.fill}
            />
            <path
              d="M28.5,12.5H10.7c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h17.8c0.3,0,0.5,0.2,0.5,0.5
                S28.8,12.5,28.5,12.5z"
              fill={style.st0.fill}
            />
          </g>
          <g>
            <path
              d="M26.3,27.9H8.5c-0.3,0-0.5-0.2-0.5-0.5V17.3c0-0.3,0.2-0.5,0.5-0.5h17.8
                c0.3,0,0.5,0.2,0.5,0.5v10.1C26.8,27.7,26.6,27.9,26.3,27.9z M9,26.9h16.8v-9.1H9V26.9z"
              fill={style.st0.fill}
            />
            <path
              d="M24,20.7h-5.1c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5H24c0.3,0,0.5,0.2,0.5,0.5
                S24.3,20.7,24,20.7z"
              fill={style.st0.fill}
              id="XMLID_23_"
            />
            <path
              d="M24,22.9h-5.1c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5H24c0.3,0,0.5,0.2,0.5,0.5
                S24.3,22.9,24,22.9z"
              fill={style.st0.fill}
              id="XMLID_22_"
            />
            <path
              d="M21.5,25.1h-2.5c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h2.5c0.3,0,0.5,0.2,0.5,0.5
                S21.8,25.1,21.5,25.1z"
              fill={style.st0.fill}
              id="XMLID_21_"
            />
            <path
              d="M26.3,17.8H8.5c-0.3,0-0.5-0.2-0.5-0.5v-2.4c0-0.3,0.2-0.5,0.5-0.5h17.8
                c0.3,0,0.5,0.2,0.5,0.5v2.4C26.8,17.6,26.6,17.8,26.3,17.8z M9,16.8h16.8v-1.4H9V16.8z"
              fill={style.st0.fill}
              id="XMLID_18_"
            />
            <path
              d="M16.7,25.1h-6c-0.3,0-0.5-0.2-0.5-0.5v-4.6c0-0.3,0.2-0.5,0.5-0.5h6c0.3,0,0.5,0.2,0.5,0.5
                v4.6C17.2,24.9,17,25.1,16.7,25.1z M11.3,24.1h5v-3.6h-5V24.1z"
              fill={style.st0.fill}
              id="XMLID_15_"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export const IconsIcon = () => {
  const style = {
    st0: { fill: "#3389FF" }
  }
  return (
    <svg version="1.1" viewBox="0 0 37 37" x="0px" y="0px">
      <path
        d="M18.5,37C8.3,37,0,28.7,0,18.5S8.3,0,18.5,0S37,8.3,37,18.5S28.7,37,18.5,37z M18.5,1
            C8.9,1,1,8.9,1,18.5S8.9,36,18.5,36S36,28.1,36,18.5S28.1,1,18.5,1z"
        fill={style.st0.fill}
      />
      <path
        d="M8.5,13c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7
            c0.4-0.4,1-0.4,1.4,0c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7C9,12.9,8.8,13,8.5,13z M24.5,13L24.5,13c0.5,0,1-0.4,1-1l0,0
            c0-0.5-0.5-1-1-1l0,0c-0.5,0-1,0.5-1,1C23.5,12.6,24,13,24.5,13z M20.5,13L20.5,13c0.5,0,1-0.4,1-1l0,0c0-0.5-0.5-1-1-1l0,0
            c-0.5,0-1,0.5-1,1C19.5,12.6,20,13,20.5,13z M16.5,13L16.5,13c0.5,0,1-0.4,1-1l0,0c0-0.5-0.5-1-1-1l0,0c-0.6,0-1,0.5-1,1
            C15.5,12.6,15.9,13,16.5,13z M12.5,13L12.5,13c0.5,0,1-0.4,1-1l0,0c0-0.5-0.5-1-1-1l0,0c-0.6,0-1,0.5-1,1C11.5,12.6,11.9,13,12.5,13
            z M29.2,12.7c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.4-0.4-1-0.4-1.4,0c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7
            c0.2,0.2,0.5,0.3,0.7,0.3S29,12.9,29.2,12.7z M29.2,16c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.4-0.4-1-0.4-1.4,0
            c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.3,0.7,0.3S29,16.1,29.2,16z M24.5,16.3L24.5,16.3c0.5,0,1-0.4,1-1
            l0,0c0-0.6-0.5-1-1-1l0,0c-0.5,0-1,0.4-1,1C23.5,15.8,24,16.3,24.5,16.3z M20.5,16.3L20.5,16.3c0.5,0,1-0.4,1-1l0,0c0-0.6-0.5-1-1-1
            l0,0c-0.5,0-1,0.4-1,1C19.5,15.8,20,16.3,20.5,16.3z M16.5,16.3L16.5,16.3c0.5,0,1-0.4,1-1l0,0c0-0.6-0.5-1-1-1l0,0
            c-0.6,0-1,0.4-1,1C15.5,15.8,15.9,16.3,16.5,16.3z M12.5,16.3L12.5,16.3c0.5,0,1-0.4,1-1l0,0c0-0.6-0.5-1-1-1l0,0c-0.6,0-1,0.4-1,1
            C11.5,15.8,11.9,16.3,12.5,16.3z M9.2,16c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.4-0.4-1-0.4-1.4,0
            c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3C8.8,16.3,9,16.1,9.2,16z M9.2,19.2
            c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.4-0.4-1.1-0.4-1.4,0c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7
            c0.2,0.2,0.4,0.3,0.7,0.3C8.8,19.5,9,19.4,9.2,19.2z M24.5,19.5L24.5,19.5c0.5,0,1-0.5,1-1l0,0c0-0.6-0.5-1-1-1l0,0
            c-0.5,0-1,0.4-1,1C23.5,19,24,19.5,24.5,19.5z M20.5,19.5L20.5,19.5c0.5,0,1-0.5,1-1l0,0c0-0.6-0.5-1-1-1l0,0c-0.5,0-1,0.4-1,1
            C19.5,19,20,19.5,20.5,19.5z M16.5,19.5L16.5,19.5c0.5,0,1-0.5,1-1l0,0c0-0.6-0.5-1-1-1l0,0c-0.6,0-1,0.4-1,1
            C15.5,19,15.9,19.5,16.5,19.5z M12.5,19.5L12.5,19.5c0.5,0,1-0.5,1-1l0,0c0-0.6-0.5-1-1-1l0,0c-0.6,0-1,0.4-1,1
            C11.5,19,11.9,19.5,12.5,19.5z M29.2,19.2c0.2-0.2,0.3-0.5,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.4-0.4-1-0.4-1.4,0
            c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.3,0.7,0.3S29,19.4,29.2,19.2z M9.2,22.5c0.2-0.2,0.3-0.4,0.3-0.7
            c0-0.3-0.1-0.5-0.3-0.7c-0.4-0.4-1-0.4-1.4,0c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3
            C8.8,22.8,9,22.6,9.2,22.5z M24.5,22.7L24.5,22.7c0.5,0,1-0.4,1-1l0,0c0-0.6-0.5-1-1-1l0,0c-0.5,0-1,0.4-1,1
            C23.5,22.3,24,22.7,24.5,22.7z M20.5,22.7L20.5,22.7c0.5,0,1-0.4,1-1l0,0c0-0.6-0.5-1-1-1l0,0c-0.5,0-1,0.4-1,1
            C19.5,22.3,20,22.7,20.5,22.7z M16.5,22.7L16.5,22.7c0.5,0,1-0.4,1-1l0,0c0-0.6-0.5-1-1-1l0,0c-0.6,0-1,0.4-1,1
            C15.5,22.3,15.9,22.7,16.5,22.7z M12.5,22.7L12.5,22.7c0.5,0,1-0.4,1-1l0,0c0-0.6-0.5-1-1-1l0,0c-0.6,0-1,0.4-1,1
            C11.5,22.3,11.9,22.7,12.5,22.7z M29.2,22.5c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.4-0.4-1-0.4-1.4,0
            c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.3,0.7,0.3S29,22.6,29.2,22.5z M9.2,25.7c0.2-0.2,0.3-0.4,0.3-0.7
            c0-0.3-0.1-0.5-0.3-0.7c-0.4-0.4-1.1-0.4-1.4,0c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7C8,25.9,8.2,26,8.5,26
            C8.8,26,9,25.9,9.2,25.7z M24.5,26L24.5,26c0.5,0,1-0.5,1-1l0,0c0-0.6-0.5-1-1-1l0,0c-0.5,0-1,0.4-1,1C23.5,25.5,24,26,24.5,26z
            M20.5,26L20.5,26c0.5,0,1-0.5,1-1l0,0c0-0.6-0.5-1-1-1l0,0c-0.5,0-1,0.4-1,1C19.5,25.5,20,26,20.5,26z M16.5,26L16.5,26
            c0.5,0,1-0.5,1-1l0,0c0-0.6-0.5-1-1-1l0,0c-0.6,0-1,0.4-1,1C15.5,25.5,15.9,26,16.5,26z M12.5,26L12.5,26c0.5,0,1-0.5,1-1l0,0
            c0-0.6-0.5-1-1-1l0,0c-0.6,0-1,0.4-1,1C11.5,25.5,11.9,26,12.5,26z M29.2,25.7c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7
            c-0.4-0.4-1-0.4-1.4,0c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.3,0.7,0.3S29,25.9,29.2,25.7z"
        fill={style.st0.fill}
      />
    </svg>
  )
}

export const PaintIcon = () => {
  const style = {
    st0: { fill: "#3389FF" }
  }
  return (
    <svg version="1.1" viewBox="0 0 37 37" x="0px" y="0px">
      <path
        d="M18.5,37C8.3,37,0,28.7,0,18.5C0,8.3,8.3,0,18.5,0S37,8.3,37,18.5C37,28.7,28.7,37,18.5,37z
            M18.5,1C8.9,1,1,8.9,1,18.5C1,28.1,8.9,36,18.5,36S36,28.1,36,18.5C36,8.9,28.1,1,18.5,1z"
        fill={style.st0.fill}
      />
      <path
        d="M26.6,28.1c-1.3,0-2.4-1.1-2.4-2.4c0-1.1,1.6-3.6,2-4.1c0.2-0.3,0.6-0.3,0.8,0
            c0.3,0.5,2,3,2,4.1C29,27,27.9,28.1,26.6,28.1z M26.6,22.8c-0.7,1.1-1.4,2.4-1.4,2.9c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4
            C28,25.2,27.3,23.9,26.6,22.8z M16.4,27.7c-0.4,0-0.7-0.1-1-0.4l-7-7c-0.6-0.6-0.6-1.5,0-2l4.6-4.6V9.2c0-1.3,1-2.3,2.3-2.3
            s2.3,1,2.3,2.3c0,0,0,0,0,0c0,0,0,0,0,0V15c0.9,0.2,1.5,1,1.5,1.9c0,1.1-0.9,2-2,2s-2-0.9-2-2c0-0.9,0.6-1.7,1.5-1.9V9.3
            c0,0,0,0,0,0c0,0,0,0,0,0c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v3.3l0.8-0.8c0.2-0.2,0.5-0.2,0.7,0c0.2,0.2,0.2,0.5,0,0.7
            l-5.2,5.3l7.6,7.6l6.9-6.9l-6-6c-0.2-0.2-0.2-0.5,0-0.7c0.2-0.2,0.5-0.2,0.7,0l6.3,6.3c0,0,0,0,0,0l0.6,0.6c0.2,0.2,0.2,0.5,0,0.7
            c-0.2,0.2-0.5,0.2-0.7,0l-0.3-0.3l-7.2,7.2c0,0,0,0,0,0c0,0,0,0,0,0l-0.8,0.8C17.2,27.5,16.8,27.7,16.4,27.7
            C16.4,27.7,16.4,27.7,16.4,27.7z M9.6,18.4l-0.5,0.5c-0.2,0.2-0.2,0.4,0,0.6l7,7c0.1,0.1,0.2,0.1,0.3,0.1c0,0,0,0,0,0
            c0.1,0,0.2,0,0.3-0.1l0.5-0.5L9.6,18.4z M17.2,16c-0.5,0-1,0.4-1,1s0.4,1,1,1s1-0.4,1-1S17.7,16,17.2,16z M26.6,26.6
            c-0.1,0-0.3,0-0.4-0.1l-0.3-0.3c-0.2-0.2-0.2-0.5,0-0.7c0.2-0.2,0.5-0.2,0.7,0l0.3,0.3c0.2,0.2,0.2,0.5,0,0.7
            C26.9,26.5,26.7,26.6,26.6,26.6z"
        fill={style.st0.fill}
      />
    </svg>
  )
}

export const NewSlidesIcon = () => {
  const style = {
    st0: { fill: "#3389FF" }
  }
  return (
    <svg version="1.1" viewBox="0 0 37 37" x="0px" y="0px">
      <path
        d="M18.5,37C8.3,37,0,28.7,0,18.5C0,8.3,8.3,0,18.5,0S37,8.3,37,18.5C37,28.7,28.7,37,18.5,37z
            M18.5,1C8.9,1,1,8.9,1,18.5C1,28.1,8.9,36,18.5,36S36,28.1,36,18.5C36,8.9,28.1,1,18.5,1z"
        fill={style.st0.fill}
      />
      <path
        d="M21.2,24.2H10.8v-1h10.2c0-0.3,0-0.7,0-1l-12,0v-9.1h16.8v6.1c0.4,0.1,0.7,0.3,1,0.5v-9.6
            c0-0.3-0.2-0.5-0.5-0.5H8.5C8.2,9.8,8,10,8,10.3v12.4c0,0.1,0.1,0.3,0.1,0.4s0.2,0.1,0.4,0.1h0l1.3,0v1.5c0,0.3,0.2,0.5,0.5,0.5
            h11.5C21.5,24.9,21.3,24.6,21.2,24.2z M9,10.8h16.8v1.4H9V10.8z"
        fill={style.st0.fill}
      />
      <path
        d="M18.9,18.2h2.5c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5h-2.5c-0.3,0-0.5,0.2-0.5,0.5
            S18.6,18.2,18.9,18.2z"
        fill={style.st0.fill}
      />
      <path
        d="M10.8,14.9c-0.3,0-0.5,0.2-0.5,0.5V20c0,0.3,0.2,0.5,0.5,0.5h5.9c0.3,0,0.5-0.2,0.5-0.5v-4.6
            c0-0.3-0.2-0.5-0.5-0.5H10.8z M16.2,19.5h-4.9v-3.6h4.9V19.5z"
        fill={style.st0.fill}
      />
      <path
        d="M24,15h-5.1c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5H24c0.3,0,0.5-0.2,0.5-0.5S24.2,15,24,15z"
        fill={style.st0.fill}
      />
      <path
        d="M24.5,27.2c-2.5,0-4.5-2-4.5-4.5s2-4.5,4.5-4.5s4.5,2,4.5,4.5S27,27.2,24.5,27.2z M24.5,19.2
            c-1.9,0-3.5,1.6-3.5,3.5s1.6,3.5,3.5,3.5s3.5-1.6,3.5-3.5S26.4,19.2,24.5,19.2z M24.5,21.2v3 M25,24.2v-3c0-0.3-0.2-0.5-0.5-0.5
            S24,20.9,24,21.2v3c0,0.3,0.2,0.5,0.5,0.5S25,24.5,25,24.2z M23,22.7l3,0 M26.5,22.7c0-0.3-0.2-0.5-0.5-0.5h-3
            c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h3C26.3,23.2,26.5,23,26.5,22.7z"
        fill={style.st0.fill}
      />
    </svg>
  )
}

export const ExamplesIcon = () => {
  const style = {
    st0: { fill: "#3389FF" }
  }
  return (
    <svg version="1.1" viewBox="0 0 37 37" x="0px" y="0px">
      <path
        d="M18.5,37C8.3,37,0,28.7,0,18.5C0,8.3,8.3,0,18.5,0S37,8.3,37,18.5C37,28.7,28.7,37,18.5,37z
            M18.5,1C8.9,1,1,8.9,1,18.5C1,28.1,8.9,36,18.5,36S36,28.1,36,18.5C36,8.9,28.1,1,18.5,1z"
        fill={style.st0.fill}
      />
      <path
        d="M9.9,27.2c-1,0-1.9-0.8-1.9-1.9s0.8-1.9,1.9-1.9s1.9,0.8,1.9,1.9S10.9,27.2,9.9,27.2z M9.9,24.5
            c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9S10.3,24.5,9.9,24.5z M28.5,25.9h-14c-0.3,0-0.5-0.2-0.5-0.5
            s0.2-0.5,0.5-0.5h14c0.3,0,0.5,0.2,0.5,0.5S28.8,25.9,28.5,25.9z M9.9,22.6c-1,0-1.9-0.8-1.9-1.9c0-1,0.8-1.9,1.9-1.9
            s1.9,0.8,1.9,1.9C11.7,21.8,10.9,22.6,9.9,22.6z M9.9,19.9c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9
            S10.3,19.9,9.9,19.9z M28.5,21.3h-14c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h14c0.3,0,0.5,0.2,0.5,0.5S28.8,21.3,28.5,21.3z
            M9.9,18.1c-1,0-1.9-0.8-1.9-1.9s0.8-1.9,1.9-1.9s1.9,0.8,1.9,1.9S10.9,18.1,9.9,18.1z M9.9,15.4c-0.5,0-0.9,0.4-0.9,0.9
            s0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9S10.3,15.4,9.9,15.4z M28.5,16.7h-14c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h14
            c0.3,0,0.5,0.2,0.5,0.5S28.8,16.7,28.5,16.7z M9.9,13.5c-1,0-1.9-0.8-1.9-1.9s0.8-1.9,1.9-1.9s1.9,0.8,1.9,1.9S10.9,13.5,9.9,13.5z
            M9.9,10.8c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9S10.3,10.8,9.9,10.8z M28.5,12.1h-14c-0.3,0-0.5-0.2-0.5-0.5
            s0.2-0.5,0.5-0.5h14c0.3,0,0.5,0.2,0.5,0.5S28.8,12.1,28.5,12.1z"
        fill={style.st0.fill}
      />
    </svg>
  )
}

export const EmailIcon = () => {
  const style = {
    st0: { fill: "#3389FF" }
  }
  return (
    <svg version="1.1" viewBox="0 0 37 37" x="0px" y="0px">
      <path
        d="M18.5,37C8.3,37,0,28.7,0,18.5C0,8.3,8.3,0,18.5,0S37,8.3,37,18.5C37,28.7,28.7,37,18.5,37z
            M18.5,1C8.9,1,1,8.9,1,18.5C1,28.1,8.9,36,18.5,36S36,28.1,36,18.5C36,8.9,28.1,1,18.5,1z"
        fill={style.st0.fill}
      />
      <path
        d="M28.5,25.5h-20c-0.1,0-0.1,0-0.2,0l0,0c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0l0,0
            c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0v0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0V12
            c0,0,0,0,0,0c0-0.1,0-0.2,0.1-0.3c0,0,0-0.1,0.1-0.1c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0,0,0,0h0c0,0,0,0,0,0h0c0,0,0,0,0,0h20h0
            c0,0,0,0,0,0h0c0,0,0,0,0,0l0,0c0.1,0,0.2,0,0.3,0.1c0,0,0.1,0.1,0.1,0.1C29,11.8,29,11.9,29,12c0,0,0,0,0,0v13c0,0,0,0,0,0l0,0
            c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.1-0.1,0.2l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0v0l0,0
            c0,0-0.1,0.1-0.1,0.1C28.6,25.5,28.6,25.5,28.5,25.5z M9.7,24.5h17.6l-6.1-6.1l-2.4,1.9c-0.2,0.1-0.4,0.1-0.6,0l-2.4-1.9L9.7,24.5z
            M9,13v10.7l6-6L9,13z M22,17.8l6,6V13L22,17.8z M9.9,12.5l8.6,6.8l8.6-6.8H9.9z"
        fill={style.st0.fill}
      />
    </svg>
  )
}

export const QuoteIcon = () => {
  const style = {
    st0: { fill: "#3389FF" }
  }
  return (
    <svg version="1.1" viewBox="0 0 30 24.6" x="0px" y="0px">
      <g>
        <path
          d="M0,15.3c0-4.4,1-7.9,3.1-10.4c2-2.4,4.8-4,8.5-4.9c0.3-0.1,0.6,0.2,0.6,0.5l0,3.6c0,0.2-0.1,0.4-0.3,0.5
            c-2.8,1-4.7,2.5-5.7,4.7c-0.5,1-0.7,2.1-0.7,3.2c0,0.3,0.2,0.5,0.5,0.5l5.5,0c0.7,0,0.9,0.2,0.9,0.5v10.6c0,0.3-0.2,0.5-0.5,0.5
            H0.5c-0.3,0-0.5-0.2-0.5-0.5V15.3z"
          fill={style.st0.fill}
        />
        <path
          d="M17.5,15.3c0-4.5,1.1-7.9,3.2-10.5c2-2.4,4.9-4,8.5-4.8c0.3-0.1,0.6,0.2,0.6,0.5l0,3.6c0,0.2-0.1,0.4-0.3,0.5
            c-2.8,0.9-4.6,2.5-5.6,4.8c-0.5,1.1-0.7,2.1-0.7,3.2c0,0.3,0.2,0.5,0.5,0.5l5,0c1,0,1.4,0.4,1.4,1v10.1c0,0.3-0.2,0.5-0.5,0.5H18
            c-0.3,0-0.5-0.2-0.5-0.5V15.3z"
          fill={style.st0.fill}
        />
      </g>
    </svg>
  )
}
