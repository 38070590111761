import { useDrop } from "react-dnd"
import { PropsWithChildren, useContext } from "react"
import { UserDataContext } from "@/hooks/UserDataHook"

interface DropSortBarProps {
  toIndex: number
  disableDrag?(): void
  reorder({ fromIndex, toIndex }: { fromIndex: number; toIndex: number }): void
}

const DropSortBar = ({ toIndex, children, disableDrag, reorder }: PropsWithChildren<DropSortBarProps>) => {
  const {
    user: { isEditModeActive }
  } = useContext(UserDataContext)

  const [{ highlight }, ref] = useDrop({
    accept: "SLIDE",
    drop: (item: any) => {
      if (!isEditModeActive || item.index === undefined || toIndex === item.index) return
      reorder({ fromIndex: item.index, toIndex: item.index < toIndex ? toIndex + 1 : toIndex })
    },
    collect: (monitor) => {
      const item: any = monitor.getItem()
      if (!!item && !item?.files) {
        if (disableDrag) {
          disableDrag()
        }
      }
      return {
        highlight:
          isEditModeActive &&
          item &&
          item.index !== undefined &&
          monitor.isOver() &&
          (item.index < toIndex ? "right" : "left")
      }
    }
  })

  return (
    <div
      className={`relative before:absolute before:w-[3px] before:h-full before:top-0 ${
        highlight === "right"
          ? "before:-right-[8px] before:bg-sc-blue"
          : highlight === "left"
          ? "before:-left-[8px] before:bg-sc-blue"
          : ""
      }`}
      ref={ref}
    >
      {children}
    </div>
  )
}

export default DropSortBar
