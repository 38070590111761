import { useMutation } from "@apollo/client"
import { useContext } from "react"
import { InputWrapper } from "../utils/forms"
import { NotificationSuccess, NotificationError } from "../utils/notifications"
import { setBillingData } from "../../graphql/mutations"
import { BillingInfoData } from "../../graphql/queries"
import { useForceUpdate } from "../../hooks/ForceUpdate"
import { useForm } from "../../hooks/FormHook"
import { emailValidator, isRequired } from "../utils/helpers"
import { NotificationContext } from "../../hooks/NotificationHook"

const InvoiceForm = ({ showInvoiceForm, initialValues }) => {
  const [_setBillingData, { loading }] = useMutation(setBillingData)
  const { open } = useContext(NotificationContext)

  const forceUpdate = useForceUpdate()
  const [values, onChange, validations, validate] = useForm([
    {
      name: "contactPerson",
      initialValue: initialValues.contactPerson,
      validators: [isRequired]
    },
    {
      name: "email",
      initialValue: initialValues.email,
      validators: [isRequired, emailValidator]
    },
    {
      name: "companyName",
      initialValue: initialValues.companyName,
      validators: [isRequired]
    },
    {
      name: "companyDetails",
      initialValue: initialValues.companyDetails
    }
  ])

  const submitForm = () => {
    if (!validate()) {
      forceUpdate()
      return
    }
    _setBillingData({
      variables: values,
      update: (
        proxy,
        {
          data: {
            session: {
              setBillingData: { billingData }
            }
          }
        }
      ) => {
        const data = proxy.readQuery({ query: BillingInfoData })
        const updateData = {
          ...data,
          session: {
            ...data.session,
            user: {
              ...data.session.user,
              billingData: { ...billingData }
            }
          }
        }
        proxy.writeQuery({ query: BillingInfoData, data: updateData })
      }
    })
      .then(() => {
        showInvoiceForm()
        open({
          type: "success",
          duration: 3,
          content: <NotificationSuccess message="Invoice Details Updated" title="Success" />
        })
      })
      .catch(() => {
        open({
          type: "error",
          duration: 3,
          content: <NotificationError message="Invoice Details Not Updated" title="Error" />
        })
      })
  }

  return (
    <form autoComplete="off" className="profile-invoice-form">
      <h1>Invoice details</h1>
      <InputWrapper error={validations.contactPerson} label="Contact person">
        <input
          disabled={loading}
          name="contactPerson"
          onChange={onChange}
          placeholder="Click here to insert your Contact's name"
          type="text"
          value={values.contactPerson}
        />
      </InputWrapper>
      <InputWrapper error={validations.email} label="Email address">
        <input
          disabled={loading}
          name="email"
          onChange={onChange}
          placeholder="Click here to insert your Contact's Email address"
          type="text"
          value={values.email}
        />
      </InputWrapper>
      <InputWrapper error={validations.companyName} label="Company Name">
        <input
          disabled={loading}
          name="companyName"
          onChange={onChange}
          placeholder="Click here to insert your Company's Name"
          type="text"
          value={values.companyName}
        />
      </InputWrapper>
      <InputWrapper error={validations.companyDetails} label="Company Details">
        <textarea
          disabled={loading}
          name="companyDetails"
          onChange={onChange}
          placeholder="Click here to insert your Company Details"
          rows={5}
          value={values.companyDetails}
        />
      </InputWrapper>
      <div className="profile-buttons-end">
        <button className="btn btn-gray-blue" onClick={showInvoiceForm} type="button">
          Cancel
        </button>
        <button className="btn btn-dark-blue" disabled={loading} onClick={submitForm} type="button">
          Update details
        </button>
      </div>
    </form>
  )
}

export default InvoiceForm
