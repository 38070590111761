import { useContext, useEffect } from "react"
import { InactiveAccount } from "./InactiveAccount"
import { Instructions } from "./Instructions"
import MoveSlideToCategory from "@/components/modals/MoveSlideToCategory"
import EditCategory from "./editCategory/EditCategoryWrapper"
import AddNewTeam from "./AddNewTeam"
import RemoveUserFromUnit from "./RemoveUserFromUnit"
import AddUserToUnit from "./AddUserToUnit"
import RemoveUnit from "./RemoveUnit"
import SharePresentationModal from "@/components/modals/share/SharePresentationModal"
import InviteFriendForm from "./InviteFriendForm"
import RemoveUserForm from "./RemoveUserForm"
import DeleteAllPresentations from "./DeleteAllPresentations"
import DeleteAllSlides from "./DeleteAllSlides"
import ChangeMemberRole from "./ChangeMemberRole"
import { MainModalContext } from "../../hooks/MainModalHook"
import { useLocation } from "react-router-dom"
import InvitationLink from "@/components/modals/InvitationLink"

const contents = {
  inactiveAccount: (props) => <InactiveAccount {...props} />,
  instructions: (props) => <Instructions {...props} />,
  addNewCategory: (props) => <EditCategory {...props} type="add" />,
  editCategory: (props) => <EditCategory {...props} type="edit" />,
  SharePresentationModal: (props) => <SharePresentationModal {...props} />,
  addNewTeam: (props) => <AddNewTeam {...props} />,
  addUserToUnit: (props) => <AddUserToUnit {...props} />,
  removeUserFromUnit: (props) => <RemoveUserFromUnit {...props} />,
  removeUnit: (props) => <RemoveUnit {...props} />,
  addUserToTeam: (props) => <InviteFriendForm {...props} />,
  removeUserFromTeam: (props) => <RemoveUserForm {...props} />,
  changeMemberRole: (props) => <ChangeMemberRole {...props} />,
  showInvitationLink: (props) => <InvitationLink {...props} />,
  deleteAllPresentations: (props) => <DeleteAllPresentations {...props} />,
  deleteAllSlides: (props) => <DeleteAllSlides {...props} />,
  moveSlideToCategory: (props) => <MoveSlideToCategory {...props} />
}

interface MainModalProps {
  isOpen: boolean
  content: string | null
  type: string
  closeable: boolean
  data: Record<string, any>
}

const MainModal = ({ isOpen, content, type, closeable, data }: MainModalProps) => {
  const { closeModal } = useContext(MainModalContext)
  const location = useLocation()

  useEffect(() => {
    if (isOpen) {
      closeModal()
    }
  }, [location.pathname])

  const renderFullScreen = () => (
    <div className=" ReactModal__Overlay ReactModal__Overlay--after-open fullscreen-modal-bg">
      <div className="ReactModal__Content ReactModal__Content--after-open fullscreen-modal-body">
        {content ? contents[content](data) : null}
      </div>
    </div>
  )

  const renderMain = () =>
    isOpen ? (
      <div className="sc-modal">
        <div className="ReactModal__Overlay ReactModal__Overlay--after-open sc-modal-bg">
          <div className="fixed top-0 left-0 z-50 overflow-hidden outline-0 ReactModal__Content ReactModal__Content--after-open sc-modal-body">
            <div className="sc-modal-content">
              {closeable && (
                <button className="close-modal" onClick={closeModal}>
                  <svg version="1.1" viewBox="0 0 14 14" x="0px" y="0px">
                    <path
                      className="close-icon-fill"
                      d="M7.7,7l6.1-6.1c0.2-0.2,0.2-0.5,0-0.7s-0.5-0.2-0.7,0L7,6.3L0.9,0.1C0.7,0,0.3,0,0.1,0.1
		            S0,0.7,0.1,0.9L6.3,7l-6.1,6.1c-0.2,0.2-0.2,0.5,0,0.7C0.2,14,0.4,14,0.5,14s0.3,0,0.4-0.1L7,7.7l6.1,6.1c0.1,0.1,0.2,0.1,0.4,0.1
		            s0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7L7.7,7z"
                    />
                  </svg>
                </button>
              )}
              {content ? contents[content](data) : null}
            </div>
          </div>
        </div>
      </div>
    ) : null

  return type === "fullscreen" ? renderFullScreen() : renderMain()
}

export default MainModal
