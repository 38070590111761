import { useContext, useState } from "react"
import SlideZoom from "@/components/slide/SlideZoom"
import { SlideModalContext } from "@/hooks/SlideModalHook"
import { Modal } from "@/components/modals/Modal"

interface SlideModalProps {
  showModal: boolean
}

const SlideModal = ({ showModal }: SlideModalProps) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const { closeModal } = useContext(SlideModalContext)

  const onClose = () => {
    setIsLoaded(false)
    closeModal()
  }

  const handleToggleLoad = (value: boolean) => {
    setIsLoaded(value)
  }

  return (
    <div>
      <Modal
        className="fade modal show modal-center-vh in h-full"
        isOpened={showModal}
        overlayClassName="sc-modal-bg"
        portalClassName="sc-modal"
      >
        <div className="modal-dialog relative">
          <div className="modal-content relative">
            <div className="modal-body">
              {showModal && (
                <button
                  aria-label="Close"
                  className="close right-[6px]"
                  data-dismiss="modal"
                  data-testid="close-slide-modal"
                  onClick={onClose}
                  type="button"
                >
                  <span aria-hidden="true">×</span>
                </button>
              )}
              {showModal && <SlideZoom handleToggleLoad={handleToggleLoad} isLoaded={isLoaded} />}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default SlideModal
