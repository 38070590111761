import { useContext } from "react"
import { PricingModalContext } from "../../../hooks/PricingModalHook"
import { Ellipsis } from "../../utils/tooltip"

const BillingInfoDetails = ({ showInvoiceForm, subscription, billingInfo }) => {
  const { openModal } = useContext(PricingModalContext)

  const { contactPerson, email, companyName, companyDetails } = billingInfo
  return (
    <div className="profile-billing-details">
      <div className="profile-billing-infobox">
        <h4>Invoice details</h4>
        {contactPerson + email + companyName + companyDetails !== "" ? (
          [
            <div className="profile-billing-infobox-details" key="details">
              <p className="profile-billing-name">
                <Ellipsis length={24} text={contactPerson} />
              </p>
              <p className="profile-billing-name">
                <Ellipsis length={24} text={email} />
              </p>
              <p className="profile-billing-name">
                <Ellipsis length={24} text={companyName} />{" "}
              </p>
            </div>,
            <div className="profile-billing-infobox-details" key="company_details">
              <p>{companyDetails}</p>
            </div>
          ]
        ) : (
          <div className="profile-billing-infobox-details profile-billing-infobox-empty">
            <p>Click Edit details to update Invoice Details</p>
          </div>
        )}
        <div className="profile-buttons-end">
          <button className="btn btn-dark-blue" onClick={showInvoiceForm}>
            Edit details
          </button>
          {subscription.subscriptionId.split("/")[0] === "stripe" && (
            <button
              className="btn btn-dark-blue"
              onClick={() => {
                openModal("update_card")
              }}
            >
              Change Card
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default BillingInfoDetails
