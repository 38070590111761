import { useQuery, gql } from "@apollo/client"
import { useState, Fragment } from "react"
import { ErrorPlaceholder, MainLoader } from "../utils/placeholders"
import InviteMultipleUsersToTeamLog from "./cards/InviteMultipleUsersToTeamLog"
import InviteUserToTeamLog from "./cards/InviteUserToTeamLog"
import BatchOpenedByShareTokenLog from "./cards/BatchOpenedByShareTokenLog"
import SearchPerformedLog from "./cards/SearchPerformedLog"
import DateHeader from "./cards/DateHeader"
import InfiniteScroll from "react-infinite-scroll-component"

export const activities = gql`
  query activities($limit: Float, $offset: Float) {
    activities(limit: $limit, offset: $offset) {
      records {
        ... on OpenBatchByShareToken {
          createdAt
          presentation {
            name
            thumbUrl
            slides {
              id
              thumbUrl
            }
          }
          duration
          log {
            duration
            slide {
              id
            }
          }
          viewerLocation {
            city
            region
            country
          }
          lastUsedAt
        }
        ... on SearchPerformed {
          createdAt
          user {
            firstName
            lastName
          }
          query
          group {
            id
          }
          slides {
            id
            name
            thumbUrl
          }
          collections {
            id
            name
            icon
          }
          presentations {
            id
            name
            thumbUrl
          }
        }
        ... on InviteNewUserToTeam {
          createdAt
          group {
            id
            name
          }
          user {
            id
            firstName
            lastName
            email
          }
          invitedUsers
          invitedUserEmail
        }
      }
      totalCount
      returnedCount
    }
  }
`

const limit = 20

const getRecordTime = (date) => {
  return new Date(date).getTime()
}

const LogsPage = () => {
  const [activeAccordionId, setActiveAccordionId] = useState(null)
  const { data, loading, error, fetchMore } = useQuery(activities, {
    context: { isUsingNewScApi: true },
    variables: { limit, offset: 0 },
    fetchPolicy: "network-only"
  })

  const fetchNext = async () => {
    try {
      await fetchMore({
        variables: {
          offset: data?.activities.returnedCount,
          limit
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          return {
            activities: {
              ...prev.activities,
              returnedCount: prev.activities.returnedCount + fetchMoreResult.activities.returnedCount,
              records: [...prev.activities.records, ...fetchMoreResult.activities.records]
            }
          }
        }
      })
    } catch (error) {
      console.error("Error fetching more data:", error)
    }
  }

  if (error) return <ErrorPlaceholder tryAgain={window.location.reload} />

  return (
    <div className="mb-[50px] w-full gap-6 mx-auto max-w-screen-4xl px-16 pt-8 text-[#0f2642]" id="scrollable-target">
      <h1 className="text-2xl font-bold mt-7 mb-2.5">Activity</h1>
      {data?.activities.records.length === 0 && !loading && (
        <div className="text-center">
          <h1>Nothing here yet</h1>
        </div>
      )}

      <InfiniteScroll
        dataLength={data?.activities.records.length || 0}
        hasMore={data?.activities.returnedCount < data?.activities.totalCount}
        loader={<MainLoader />}
        next={fetchNext}
      >
        {data?.activities.records.map((rec, index) => (
          <Fragment key={index}>
            {(index === 0 ||
              Math.floor(getRecordTime(data?.activities.records[index - 1].createdAt) / 86400000) !==
                Math.floor(getRecordTime(rec.createdAt) / 86400000)) && (
              <DateHeader date={getRecordTime(rec.createdAt)} />
            )}
            {rec.__typename === "InviteNewUserToTeam" &&
              (rec.invitedUsers ? (
                <InviteMultipleUsersToTeamLog
                  activeAccordionId={activeAccordionId}
                  index={index}
                  record={rec}
                  setActiveAccordionId={setActiveAccordionId}
                />
              ) : (
                <InviteUserToTeamLog record={rec} />
              ))}
            {rec.__typename === "OpenBatchByShareToken" && rec.presentation && (
              <BatchOpenedByShareTokenLog
                activeAccordionId={activeAccordionId}
                index={index}
                record={rec}
                setActiveAccordionId={setActiveAccordionId}
              />
            )}
            {rec.__typename === "SearchPerformed" && (
              <SearchPerformedLog
                activeAccordionId={activeAccordionId}
                index={index}
                record={rec}
                setActiveAccordionId={setActiveAccordionId}
              />
            )}
          </Fragment>
        ))}
      </InfiniteScroll>
    </div>
  )
}

export default LogsPage
