import { useEffect, useRef } from "react"

export const useClickOutside = (func, args) => {
  const ref = useRef()

  const handleClickOutside = (e) => {
    if (!ref.current.contains(e.target)) {
      func(args)
    }
  }

  useEffect(() => {
    document.body.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.body.removeEventListener("mousedown", handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return ref
}
