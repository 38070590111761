import { useMutation } from "@apollo/client"
import { useContext, useEffect, useRef, useState } from "react"
import Slide from "@/components/slide/Slide"
import { reorderSlides, ADD_SLIDE } from "@/graphql/mutations"
import GlobalDropzone from "@/components/utils/upload/GlobalDropzone"
import { fragments } from "@/graphql/fragments"
import { MainModalContext } from "@/hooks/MainModalHook"
import { reorderArr } from "@/components/utils/helpers"
import DropSortBar from "@/components/presentation/DropSortBar"
import { PresentationProps } from "@/components/presentation/types"
import { UserDataContext } from "@/hooks/UserDataHook"
import { HandleDropOptions } from "../utils/upload/types"
import { fileUpload } from "../utils/actions"
import { Slide as ISlide } from "@/graphql/types/queries"
import { useIsInsidePowerPoint } from "@/context/IsInsidePowerPointContext"

interface ExpandedAreaContainerProps {
  batch: PresentationProps["batch"]
  name: PresentationProps["name"]
  noActions: PresentationProps["noActions"]
  batchType?: string
  refetch: PresentationProps["refetch"]
  handleMergeJobStatus: (queueName: string, jobId: string) => void
  setThumbUrl?: (url: string) => void
  offsetLeft: number
}

const ExpandedAreaContainer = ({
  batchType,
  batch,
  name,
  noActions,
  refetch,
  handleMergeJobStatus,
  setThumbUrl,
  offsetLeft
}: ExpandedAreaContainerProps) => {
  const { openModal } = useContext(MainModalContext)

  const {
    user,
    user: { isEditModeActive },
    updateSlidesInCartFunction
  } = useContext(UserDataContext)
  const { cart } = user

  const [addSlide] = useMutation(ADD_SLIDE, { context: { isUsingNewScApi: true } })
  const [_reorderSlides] = useMutation(reorderSlides, { context: { isUsingNewScApi: true } })

  const [addAllToCartCalled, setAddAllToCartCalled] = useState<boolean>(false)
  const [isDropzoneVisible, setIsDropzoneVisible] = useState<boolean>(false)
  const filePickerInputRef = useRef<HTMLInputElement | null>(null)

  const isInsidePowerPoint = useIsInsidePowerPoint()

  const handleReorderSlides = ({ fromIndex, toIndex }: { fromIndex: number; toIndex: number }) => {
    _reorderSlides({
      variables: { id: batch.batchId, fromIndex, toIndex },
      update: (proxy) => {
        const newThumbUrl =
          toIndex === 0 ? batch.slides[fromIndex].thumbUrl : fromIndex === 0 ? batch.slides[1].thumbUrl : null
        proxy.writeFragment({
          id: batch.id,
          fragment: fragments.presentation,
          data: {
            ...batch,
            slides: reorderArr(batch?.slides, fromIndex, toIndex),
            thumbUrl: newThumbUrl
          }
        })
        if (setThumbUrl) setThumbUrl(newThumbUrl ?? "")
        if (refetch) refetch()
      }
    }).then(({ data }) => {
      const { queueName, id: jobId } = data.reorderSlides.job

      handleMergeJobStatus(queueName, jobId)
    })
  }

  const handleDeleteAll = () => openModal({ content: "deleteAllSlides", data: { batch, refetch } })

  const handleAddAllToCart = async () => {
    if (cart) {
      const items = [...(cart?.slides || []), ...batch.slides]
      const uniqItems = items.filter((v, i, a) => a.findIndex((t) => t.blueprintId === v.blueprintId) === i)
      return await updateSlidesInCartFunction(uniqItems)
    }

    const items = batch.slides
    await updateSlidesInCartFunction(items)
  }

  useEffect(() => {
    const batchSlidesId = (batch?.slides || []).map(({ blueprintId }) => blueprintId)
    const cartSlideIds = cart?.slides.map(({ blueprintId }: ISlide) => blueprintId) || []

    const isAllSlidesAddedToCart = batchSlidesId.every((elem) => cartSlideIds.includes(elem))

    setAddAllToCartCalled(isAllSlidesAddedToCart)
  }, [batch?.slides, cart?.slides])

  const handleDrop =
    ({
      getJob,
      getUploadFileUrl,
      setShowUploadProgress,
      setFilesToUpload,
      filesWithProgress,
      changeProgress
    }: HandleDropOptions) =>
    async (files: File[]) => {
      const filesInitialProgress = files.reduce((hash, elem: File) => {
        hash[elem.name] = 0
        return hash
      }, {} as Record<string, number>)

      setFilesToUpload(filesInitialProgress)
      setShowUploadProgress(true)

      const fileUrls = await Promise.all(
        files.map(() => getUploadFileUrl().then((res: any) => res.data.getUploadFileUrl))
      )
      const fileTokens = await Promise.all(
        files.map((file, index) => {
          const urlData = fileUrls[index]

          return fileUpload(file, urlData.url, changeProgress).then(() => urlData.token)
        })
      )

      const inputFiles = fileTokens.map((token, index) => {
        return { token, name: files[index].name.split(".")[0] }
      })

      await addSlide({ variables: { id: batch?.batchId, files: inputFiles, position: 0 } }).then(({ data }) => {
        const { id, queueName } = data.addSlide.job

        getJob({
          variables: {
            queueName,
            jobIds: [id]
          }
        })
      })
      setShowUploadProgress(false)
      setFilesToUpload({})
      filesWithProgress.current = {}
    }
  const handlePicker = () => filePickerInputRef.current?.click()

  return (
    <div className="w-[100vw]" style={{ marginLeft: `-${offsetLeft}px` }}>
      <div
        className={`mt-[46px] mb-[26px] cursor-default border-t border-t-sc-line flex justify-center bg-[#DCDDE2] mobile-xs:px-[24px] py-[43px] ${
          cart ? "tablet-xl:px-[60px] w-slides-w-sm min-[2050px]:w-slides-w-lg" : "tablet-sm:px-[60px]"
        }`}
        data-testid="expanded-slide-area-container"
        onDragEnter={(e) => {
          e.preventDefault()
          setIsDropzoneVisible(true)
        }}
        onDragLeave={(event) => {
          event.preventDefault()
          if (event.relatedTarget instanceof Node && event.currentTarget.contains(event.relatedTarget)) return
          setIsDropzoneVisible(false)
        }}
        onDrop={(e) => {
          e.preventDefault()
          setIsDropzoneVisible(false)
        }}
      >
        <div className="w-full desktop-big:w-[1812px]">
          <div className={"flex justify-between flex-wrap"}>
            <h2 className="text-base font-bold text-[#0E2642]" data-testid="presentation-name">
              {name}
            </h2>
            {isEditModeActive && batch.slides.length > 0 && !noActions && (
              <div
                className="flex gap-1 items-center text-[15px] cursor-pointer"
                data-testid="delete-all-slides-button"
                onClick={handleDeleteAll}
              >
                <svg viewBox="0 0 429.473 429.473" width="14px" xmlns="http://www.w3.org/2000/svg">
                  <g fill="currentColor">
                    <path d="m373.244 48.771h-79.936v-40.642c0-4.487-3.633-8.129-8.129-8.129h-142.689c-4.487 0-8.129 3.642-8.129 8.129v40.643h-78.132c-4.487 0-8.129 3.642-8.129 8.129v364.445c0 4.503 3.642 8.129 8.129 8.129h317.014c4.495 0 8.129-3.625 8.129-8.129v-364.446c0-4.487-3.633-8.129-8.128-8.129zm-222.626-32.514h126.432v32.514h-126.432zm214.497 396.959h-300.757v-312.625h300.757zm0-328.882h-300.757v-19.305h78.124 142.689 79.936v19.305z" />
                    <path d="m285.179 387.977c4.495 0 8.129-3.633 8.129-8.129v-243.857c0-4.487-3.633-8.129-8.129-8.129s-8.129 3.642-8.129 8.129v243.857c0 4.495 3.642 8.129 8.129 8.129z" />
                    <path d="m142.49 387.977c4.487 0 8.129-3.633 8.129-8.129v-243.857c0-4.487-3.642-8.129-8.129-8.129s-8.129 3.642-8.129 8.129v243.857c0 4.495 3.642 8.129 8.129 8.129z" />
                    <path d="m213.834 387.977c4.495 0 8.129-3.633 8.129-8.129v-243.857c0-4.487-3.633-8.129-8.129-8.129-4.487 0-8.129 3.642-8.129 8.129v243.857c.001 4.495 3.642 8.129 8.129 8.129z" />
                  </g>
                </svg>
                Delete all slides
              </div>
            )}
            {!batch.deleted && isEditModeActive && !noActions && (
              <div
                className="flex gap-1 items-center text-[15px] cursor-pointer"
                data-testid="upload-slides-button"
                onClick={handlePicker}
              >
                <svg fill="currentColor" version="1.1" viewBox="0 0 12 12" width="14px" x="0px" y="0px">
                  <path d="M11.5,5.5h-5v-5C6.5,0.2,6.3,0,6,0S5.5,0.2,5.5,0.5v5h-5C0.2,5.5,0,5.7,0,6s0.2,0.5,0.5,0.5h5v5C5.5,11.8,5.7,12,6,12s0.5-0.2,0.5-0.5v-5h5C11.8,6.5,12,6.3,12,6S11.8,5.5,11.5,5.5z" />
                </svg>
                Upload slides
              </div>
            )}
            {batch.slides.length > 0 && !isInsidePowerPoint && (
              <div
                className={`flex gap-1 items-center text-=[15px] cursor-pointer ${
                  addAllToCartCalled ? "text-[#C4C4C4]" : ""
                }`}
                data-testid={addAllToCartCalled ? "add-all-to-cart-disabled" : "add-all-to-cart"}
                onClick={handleAddAllToCart}
              >
                <svg
                  fill={addAllToCartCalled ? "#C4C4C4" : "currentColor"}
                  height="16px"
                  viewBox="0 0 12 12"
                  width="14px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m10.59375 2.8125h-1.429688c-.183593-1.582031-1.535156-2.8125-3.164062-2.8125s-2.980469 1.230469-3.164062 2.8125h-1.429688c-.257812 0-.46875.210938-.46875.46875v8.25c0 .257812.210938.46875.46875.46875h9.1875c.257812 0 .46875-.210938.46875-.46875v-8.25c0-.257812-.210938-.46875-.46875-.46875zm-4.59375-1.875c1.113281 0 2.039062.8125 2.21875 1.875h-4.4375c.179688-1.0625 1.105469-1.875 2.21875-1.875zm4.125 10.125h-8.25v-7.3125h.9375v1.40625c0 .257812.210938.46875.46875.46875s.46875-.210938.46875-.46875v-1.40625h4.5v1.40625c0 .257812.210938.46875.46875.46875s.46875-.210938.46875-.46875v-1.40625h.9375zm0 0" />
                </svg>
                Add all to cart
              </div>
            )}
          </div>
          <div
            className={`grid gap-[15px] mt-[25px] mobile-sm:grid-cols-1 ${
              cart
                ? "tablet-xl:grid-cols-2 desktop-md:grid-cols-3 desktop-2xl:grid-cols-4 desktop-big:grid-cols-5"
                : "mobile-md:grid-cols-2 tablet-lg:grid-cols-3 desktop-lg:grid-cols-4 desktop-2xl:grid-cols-5"
            }`}
          >
            {batch.slides.map((slide, index) => (
              <DropSortBar
                disableDrag={() => setIsDropzoneVisible(false)}
                key={slide.slideId}
                reorder={handleReorderSlides}
                toIndex={index}
              >
                <Slide
                  batch={batch}
                  batchType={batchType}
                  handleMergeJobStatus={handleMergeJobStatus}
                  index={index}
                  noActions={noActions}
                  refetch={refetch}
                  setPresentationThumbUrl={setThumbUrl}
                  slide={slide}
                />
              </DropSortBar>
            ))}
            {!batch.deleted && isEditModeActive && !noActions && (
              <div className={`${isDropzoneVisible ? "w-full h-full" : "w-0 h-0"} aspect-[344/193.5] p-[6.25px]`}>
                <GlobalDropzone
                  dragEnabled={isDropzoneVisible}
                  handleDrop={handleDrop}
                  handlePicker={handlePicker}
                  ref={filePickerInputRef}
                  refetch={refetch}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExpandedAreaContainer
