import axios from "axios"

export function fileUploadOld(formData, func) {
  return axios.post(`${import.meta.env.VITE_SC_API_GATEWAY_PATH}/file-upload`, formData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress:
      typeof func === "function" ? (progressEvent) => func(progressEvent.loaded, progressEvent.total) : () => {}
  })
}

export function fileUpload(file, link, changeProgress) {
  const config = {
    method: "PUT",
    url: link,
    headers: {
      "Content-Type": "application/octet-stream"
    },
    data: file,
    onUploadProgress:
      typeof changeProgress === "function"
        ? (progressEvent) => changeProgress(progressEvent.loaded, progressEvent.total, file.name)
        : () => {}
  }
  return axios(config)
}