import { useMutation } from "@apollo/client"
import { useContext } from "react"
import { MainModalContext } from "../../hooks/MainModalHook"
import { removeArchivedBatches } from "../../graphql/mutations"
import { Refetch } from "./types"

interface DeleteAllPresentationsProps {
  refetch: Refetch
}

const DeleteAllPresentations = ({ refetch }: DeleteAllPresentationsProps) => {
  const { closeModal } = useContext(MainModalContext)

  const [executeRemoveArchivedBatches] = useMutation(removeArchivedBatches)

  const handleRemove = async () => {
    await executeRemoveArchivedBatches()
    // TODO: BU: replece refetch() with data (update:) state (delete deleted presentation from state/query cache)
    // https://www.apollographql.com/docs/react/caching/cache-interaction/
    refetch()
    closeModal()
  }

  return (
    <div className="sc-modal-content">
      <div className="sc-modal-header">
        <h1>Delete all</h1>
      </div>
      <h2>
        Are you sure you want to remove <span>all archived categories</span> from your SlideCamp?
      </h2>
      <div className="sc-modal-buttons">
        <button className="btn-gray-blue" onClick={closeModal} type="button">
          Cancel
        </button>
        <button className="btn-dark-blue" onClick={handleRemove} type="button">
          Delete
        </button>
      </div>
    </div>
  )
}

export default DeleteAllPresentations
