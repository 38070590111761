const SlidePlaceholder = () => (
  <div className={"h-full w-full bg-white flex justify-center items-center rounded-[2px]"}>
    <svg
      preserveAspectRatio="xMidYMid"
      style={{ width: 50, height: 50 }}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="50"
        cy="50"
        data-testid="loading-spinner"
        fill="none"
        r="35"
        stroke="#919191"
        strokeDasharray="164.93361431346415 56.97787143782138"
        strokeWidth="4"
      >
        <animateTransform
          attributeName="transform"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          type="rotate"
          values="0 50 50;360 50 50"
        />
      </circle>
    </svg>
  </div>
)

export default SlidePlaceholder
