import { useState, createContext, useRef, MutableRefObject } from "react"
import { Slide } from "../graphql/types/queries"

interface ShowModalParams {
  position: number
  slides: Slide[]
  pId: number | null
  downloadSlide(): void
}

interface UseSlideModalContext {
  position: number
  slides: Slide[]
  pId: number | null
  showModal: boolean
  downloadSlide(): void
  openModal(params: ShowModalParams): void
  closeModal(): void
  changePosition(position: number): void
  updateSlide(slide: Slide): void
}

export const useSlideModal = (): UseSlideModalContext => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [position, setPosition] = useState<number>(0)
  const [slides, setSlides] = useState<Slide[]>([])
  const [pId, setPid] = useState<number | null>(null)
  // const [downloadSlideFunc, setDownloadSlideFunc] = useState(() => {})

  const downloadSlideFunc: MutableRefObject<() => void> = useRef(() => {})

  const openModal = ({ position, slides, pId, downloadSlide }: ShowModalParams) => {
    setPosition(position)
    setSlides(slides)
    setShowModal(true)
    setPid(pId)
    downloadSlideFunc.current = downloadSlide
  }

  const closeModal = () => {
    setShowModal(false)
    setSlides([])
    setPosition(0)
  }

  const changePosition = (position: number) => {
    setPosition(position)
  }

  const updateSlide = (data) => {
    const newSlides = [...slides]
    newSlides[position] = { ...newSlides[position], ...data }
    setSlides(newSlides)
  }

  return {
    showModal,
    position,
    slides,
    pId,
    downloadSlide: downloadSlideFunc.current,
    openModal,
    closeModal,
    changePosition,
    updateSlide
  }
}

export const SlideModalContext = createContext({} as UseSlideModalContext)
