import { useContext, useState } from "react"
import { NotificationContext } from "../../../hooks/NotificationHook"
import { NotificationError } from "../../utils/notifications"
import { dateToLLFormat } from "../../utils/dates"
import { Link } from "react-router-dom"
import { Modal } from "../../modals/Modal"
import SubscriptionQuestionnaireForm from "./SubscriptionQuestionnaireForm"
import SubscriptionRemoval from "./SubscriptionRemoval"

const Subscription = ({ subscription, subscription: { plan }, cancelSubscription }) => {
  const { open } = useContext(NotificationContext)
  const [modalIsOpened, setModalIsOpened] = useState(false)
  const [removalForm, setRemovalForm] = useState(true)
  const [busy, setBusy] = useState(false)

  const openModal = () => {
    setModalIsOpened(true)
  }

  const closeModal = () => {
    setModalIsOpened(false)
    setRemovalForm(true)
    setBusy(false)
  }

  const removeSubscription = () => {
    setBusy(true)
    cancelSubscription({
      variables: { subscriptionId: subscription.subscriptionId }
    })
      .then(() => {
        setRemovalForm(false)
        setBusy(false)
      })
      .catch(() => {
        closeModal()
        open({
          type: "error",
          duration: 3,
          content: <NotificationError message="Subscription has not been cancelled" title="Error" />
        })
      })
  }

  const pricingTrigger = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: "scPricingOpened", pricing: { trigger: "user" } })
  }

  const renderCurrentSubscription = () => {
    return (
      <div className="profile-sub">
        <div className="profile-sub-name">
          <p>
            Current Plan: <b>{plan.name}</b>
          </p>
          <p>
            {`Your next charge: ${new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            }).format(plan.amount / 100)} on ${dateToLLFormat(subscription.payedTo)}`}
          </p>
        </div>
        <div className="profile-sub-cancel">
          <button className="btn btn-dark-blue" onClick={openModal}>
            Cancel subscription
          </button>
        </div>
      </div>
    )
  }

  const renderCancelledSubscription = () => {
    return (
      <div className="profile-sub">
        <div className="profile-sub-name">
          <p>
            Current Plan: <b>{plan.name}</b>
          </p>
          <p>Subscription Status: Cancelled</p>
          <p>
            SlideCamp Plan Active Until: <b>{dateToLLFormat(subscription.payedTo)}</b>
          </p>
          <p>You can always renew/update your&nbsp;subscription.</p>
        </div>
        <div className="profile-sub-cancel">
          <Link className="btn btn-dark-blue" onClick={pricingTrigger} to="/pricing">
            UPGRADE NOW
          </Link>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Modal
        className="sc-modal-body sc-modal-profile-sub-cancel"
        isOpened={modalIsOpened}
        overlayClassName="sc-modal-bg"
        portalClassName="sc-modal"
      >
        <button aria-label="Close" className="close" onClick={closeModal}>
          <span aria-hidden="true">×</span>
        </button>
        {removalForm ? (
          <SubscriptionRemoval
            busy={busy}
            closeModal={closeModal}
            plan={plan}
            removeSubscription={removeSubscription}
            subscription={subscription}
          />
        ) : (
          <SubscriptionQuestionnaireForm closeModal={closeModal} subscription={subscription} />
        )}
      </Modal>
      {subscription.willBeCanceled ? renderCancelledSubscription() : renderCurrentSubscription()}
    </div>
  )
}

export default Subscription
