type IconName =
  | "Presentation"
  | "Agenda"
  | "Basics"
  | "Calendars"
  | "Charts"
  | "Images"
  | "Notebook"
  | "Processes"
  | "Data"
  | "Icons"
  | "Maps"
  | "Team"
  | "Smartphone"
  | "Timelines"
  | "Infographics"
  | "Backgrounds"
  | "Sections"
  | "FreeSlides"
  | "SocialMedia"
  | "Earth"
  | "Tablet"
  | "Photo"
  | "Web"
  | "Camera"
  | "IMac"
  | "QuotesAndTestimonials"
  | "Other"
  | "Intro"
  | "People"
  | "Food"

export interface IconType {
  name: IconName
  tags: string[]
  url: string
}

export const icons: IconType[] = [
  {
    name: "Presentation",
    tags: ["presentation", "slide show"],
    url: "https://www.streamlinehq.com/icons/download/projector-board--26749"
  },
  {
    name: "Agenda",
    tags: ["agenda", "list", "schedule", "plan"],
    url: "https://www.streamlinehq.com/icons/download/bullet-list--26749"
  },
  {
    name: "Basics",
    tags: ["basics", "standard", "slide"],
    url: "https://www.streamlinehq.com/icons/download/new-sticky-note--26749"
  },
  {
    name: "Calendars",
    tags: ["calendar", "day", "month", "year"],
    url: "https://www.streamlinehq.com/icons/download/blank-calendar--26749"
  },
  {
    name: "Charts",
    tags: ["charts", "data", "pie"],
    url: "https://www.streamlinehq.com/icons/download/pie-chart--26748"
  },
  {
    name: "Images",
    tags: ["images", "landscape", "mountain"],
    url: "https://www.streamlinehq.com/icons/download/orientation-landscape--26741"
  },
  {
    name: "Notebook",
    tags: ["notebook", "devices", "laptop"],
    url: "https://www.streamlinehq.com/icons/download/laptop-charging--26747"
  },
  {
    name: "Processes",
    tags: ["process", "cycle", "scheme"],
    url: "https://www.streamlinehq.com/icons/download/ai-redo-spark--26734"
  },
  { name: "Data", tags: ["data", "table"], url: "https://www.streamlinehq.com/icons/download/coins-stack--26748" },
  {
    name: "Icons",
    tags: ["icons", "human", "man"],
    url: "https://www.streamlinehq.com/icons/download/toilet-man--26739"
  },
  {
    name: "Maps",
    tags: ["maps", "pin", "coordinates"],
    url: "https://www.streamlinehq.com/icons/download/location-pin-3--26739"
  },
  { name: "Team", tags: ["team", "people"], url: "https://www.streamlinehq.com/icons/download/no-poverty--26737" },
  {
    name: "Smartphone",
    tags: ["smartphone", "devices", "iphone", "android", "mobile"],
    url: "https://www.streamlinehq.com/icons/download/phone-mobile-phone--26745"
  },
  {
    name: "Timelines",
    tags: ["timeline", "process"],
    url: "https://www.streamlinehq.com/icons/download/hierarchy-10--26749"
  },
  {
    name: "Infographics",
    tags: ["infographics", "scheme", "illustration"],
    url: "https://www.streamlinehq.com/icons/download/cone-shape--26749"
  },
  {
    name: "Backgrounds",
    tags: ["backgrounds", "layers"],
    url: "https://www.streamlinehq.com/icons/download/pathfinder-trim--26749"
  },
  {
    name: "Sections",
    tags: ["sections", "break", "division"],
    url: "https://www.streamlinehq.com/icons/download/layout-window-1--26749"
  },
  {
    name: "FreeSlides",
    tags: ["free", "gift", "present"],
    url: "https://www.streamlinehq.com/icons/download/gift-2--26748"
  },
  {
    name: "SocialMedia",
    tags: ["social media", "social", "thumb"],
    url: "https://www.streamlinehq.com/icons/download/like-1--26749"
  },
  {
    name: "Earth",
    tags: ["maps", "continent", "country", "earth", "globe"],
    url: "https://www.streamlinehq.com/icons/download/earth-1--26739"
  },
  {
    name: "Tablet",
    tags: ["tablet", "devices", "ipad"],
    url: "https://www.streamlinehq.com/icons/download/hand-held-tablet-drawing--26747"
  },
  {
    name: "Photo",
    tags: ["photo", "camera", "devices"],
    url: "https://www.streamlinehq.com/icons/download/camera-1--26741"
  },
  { name: "Web", tags: ["web", "browser", "window"], url: "https://www.streamlinehq.com/icons/download/web--26746" },
  {
    name: "Camera",
    tags: ["movie", "camera", "devices", "rolls", "video", "film"],
    url: "https://www.streamlinehq.com/icons/download/webcam-video--26747"
  },
  {
    name: "IMac",
    tags: ["iMac", "desktop", "devices", "computer", "screen"],
    url: "https://www.streamlinehq.com/icons/download/screen-1--26747"
  },
  {
    name: "QuotesAndTestimonials",
    tags: ["quotes", "testimonials"],
    url: "https://www.streamlinehq.com/icons/download/quotation-2--26749"
  },
  { name: "Other", tags: ["other", "folder"], url: "https://www.streamlinehq.com/icons/download/new-folder--26749" },
  {
    name: "Intro",
    tags: ["intro", "start", "beginning", "title slide"],
    url: "https://www.streamlinehq.com/icons/download/screen-2--26747"
  },
  {
    name: "People",
    tags: ["people", "humans", "persons", "man"],
    url: "https://www.streamlinehq.com/icons/download/user-multiple-group--26749"
  },
  { name: "Food", tags: ["food", "burger"], url: "https://www.streamlinehq.com/icons/download/burger--26743" }
]
