import { Route } from "react-router-dom"
import SignInForm from "./SignInForm"
// import SignUpForm from "./SignUpForm"
import ForgotPasswordForm from "./ForgotPasswordForm"
import ResetPasswordForm from "./ResetPasswordForm"

const LoggedOutWrapper = () => (
  <div>
    <div className="sc-auth">
      <Route component={SignInForm} path="/users/sign_in" />

      <Route component={ForgotPasswordForm} path="/users/forgot-password" />
      <Route component={ResetPasswordForm} path="/users/reset-password/:userId/:ott" />

      {/* <Route component={SignUpForm} path="/users/sign_up" /> */}

      <div className="sc-auth-opinion">
        <svg style={{ enableBackground: "new 0 0 26.5 21.7" }} version="1.1" viewBox="0 0 26.5 21.7" x="0px" y="0px">
          <path
            d="M10.3,11.5H5.4c-0.2,0-0.4-0.2-0.4-0.4c0-1,0.2-1.9,0.6-2.9c0.9-1.9,2.5-3.3,5-4.2
							c0.2-0.1,0.3-0.2,0.3-0.4V0.4c0-0.3-0.3-0.5-0.5-0.4C7,0.7,4.5,2.2,2.8,4.3
							C0.9,6.6,0,9.6,0,13.5v7.7c0,0.2,0.2,0.4,0.4,0.4h10.2
							c0.2,0,0.4-0.2,0.4-0.4v-9.3C11,11.7,10.9,11.5,10.3,11.5z"
            style={{ fill: "#FFFFFF" }}
          />
          <path
            d="M25.2,11.5h-4.4c-0.2,0-0.4-0.2-0.4-0.4c0-0.9,0.2-1.8,0.6-2.8c0.9-2,2.5-3.4,5-4.2
							c0.2-0.1,0.3-0.2,0.3-0.4V0.5C26.3,0.2,26,0,25.7,0c-3.2,0.7-5.7,2.2-7.5,4.3
							c-1.9,2.2-2.8,5.3-2.8,9.2v7.7c0,0.2,0.2,0.4,0.4,0.4
							H26c0.2,0,0.4-0.2,0.4-0.4v-8.9C26.5,11.9,26.1,11.5,25.2,11.5z"
            style={{ fill: "#FFFFFF" }}
          />
        </svg>
        <h2>
          "SlideCamp is boosting <br />
          the whole team's productivity. <br />
          An awesome concept, <br />
          start to finish."
        </h2>
        <p>Martin Zielinski</p>
        <p>COO of Naonhome</p>
      </div>
    </div>
  </div>
)

export default LoggedOutWrapper
