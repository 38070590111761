import { formatDateForLogs } from "../../utils/dates"

const DateHeader = ({ date }) => (
  <div className="logs-date-header">
    {(() => {
      if (Math.floor(Number(date) / 86400000) === Math.floor(new Date().getTime() / 86400000)) return "Today"
      if (Math.floor(new Date().getTime() / 86400000) - Math.floor(Number(date) / 86400000) === 1) return "Yesterday"
      return formatDateForLogs(Number(date))
    })()}
  </div>
)

export default DateHeader
