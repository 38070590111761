import { useQuery } from "@apollo/client"
import { PricingData } from "../../graphql/queries"
import PricingComponent from "./PricingComponent"
import { ErrorPlaceholder } from "../utils/placeholders"

const PricingContainer = () => {
  const { data, loading, error } = useQuery(PricingData, { fetchPolicy: "cache-and-network" })

  if (loading) return <div className="profile-loading" />
  if (error) return <ErrorPlaceholder />

  const { group } = data.session.user

  const plans = group.plans || []

  return (
    <div className="pricing-top">
      <div className="plans-container">
        {plans.map((plan) => (
          <PricingComponent data={plan} key={plan.planId} />
        ))}
      </div>
      <p>You can unsubscribe at any time.</p>
    </div>
  )
}

export default PricingContainer
