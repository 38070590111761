import { useMutation } from "@apollo/client"

import { fileUploadOld } from "../utils/actions"
import { autoCreateBatches } from "../../graphql/mutations"
import { useForceUpdate } from "../../hooks/ForceUpdate"
import { useForm } from "../../hooks/FormHook"
import { isRequired } from "../utils/helpers"
import { InputWrapper } from "../utils/forms"
import { UploadFormProps } from "./types"

const UploadLibraryForm = ({ groups, initialValues, wizard }: UploadFormProps) => {
  const [_autoCreateBatches, { loading }] = useMutation(autoCreateBatches)

  const forceUpdate = useForceUpdate()
  const [values, onChange, validations, validate] = useForm([
    {
      name: "group",
      validators: [isRequired],
      initialValue: initialValues ? initialValues.group : undefined
    },
    {
      name: "colors"
    }
  ])

  const submitForm = async () => {
    if (!validate()) {
      forceUpdate()
      return
    }
    const { group, colors } = values
    const files = Object.keys(colors).map((item) => ({ color: item, file: colors[item][0] }))

    const results = await Promise.all(
      files.map(async (file) => {
        const formData = new FormData()
        formData.append("file", file.file)
        const { data } = await fileUploadOld(formData)
        return { color: file.color, token: data.token }
      })
    )

    _autoCreateBatches({
      variables: {
        groupId: group,
        sets: results.map((item) => ({ uploadedFileToken: item.token }))
      }
    })
  }

  return (
    <div className="admin-upload-templates">
      <form>
        <div className="row">
          <div style={{ paddingLeft: "15px" }}>
            <div>
              <InputWrapper error={validations.group} label="Group">
                <select disabled={wizard} name="group" onChange={onChange} value={values.group}>
                  <option />
                  {groups.map((group) => (
                    <option key={group.groupId} value={group.groupId}>
                      {group.groupId}
                    </option>
                  ))}
                </select>
              </InputWrapper>
            </div>
          </div>
        </div>
        <div className="row" style={{ paddingLeft: "15px" }}>
          <div className="admin-button-submit">
            <button className="btn btn-dark-blue" disabled={loading} onClick={submitForm} type="button">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default UploadLibraryForm
