import { useRef, useEffect, useLayoutEffect, useCallback } from "react"
import { isFunction, noop } from "lodash"
export function useDebouncedLayoutEffect(callback, delay, props) {
  const timeoutRef = useRef(null)
  const callbackRef = useRef()
  // eslint-disable-next-line
  const callbackFn = useCallback(callback, props)

  useEffect(() => {
    callbackRef.current = callbackFn ?? noop
    // eslint-disable-next-line
  }, [callbackFn])

  useLayoutEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current)

    const timer = setTimeout(() => {
      if (isFunction(callbackRef.current)) {
        callbackRef.current()
      }
    }, delay)

    timeoutRef.current = timer

    return () => {
      if (timer) {
        clearTimeout(timer)
        timeoutRef.current = null
      }
    }
    // eslint-disable-next-line
  }, [delay, ...props])
}
