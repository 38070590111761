const Breadcrumbs = ({ title }) => {
  return (
    <div className="mb-[35px] border-b border-sc-line" data-testid="blue-section-name">
      <h2 className="relative py-[10px] w-fit font-bold text-sc-blue after:absolute after:-bottom-[1.5px] after:left-0 after:bg-sc-blue after:w-full after:h-[3px] after:rounded-sm">
        {title}
      </h2>
    </div>
  )
}

export default Breadcrumbs
