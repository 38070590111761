import { useContext } from "react"
import { Route } from "react-router-dom"
import AccountInfoForm from "./AccountInfoForm"
import ChangePasswordForm from "./ChangePasswordForm"
import BillingInfoWrapper from "./billing/BillingInfoWrapper"
import Users from "./Users"
import Teams from "./Teams"
import TeamDetails from "./TeamDetails"
import ProfileNavigation from "./ProfileNavigation"
import { UserDataContext } from "../../hooks/UserDataHook"

const ProfilePage = () => {
  const { user } = useContext(UserDataContext)

  const { firstName, lastName, email, hideBilling, subscription } = user

  return (
    <div className="profile-page">
      <div className="thumbnail-list">
        <div className="profile-card">
          <div className="profile-panel">
            <div className="profile-info">
              <div className="profile-initials-border">
                <div className="profile-initials">{firstName.charAt(0) + lastName.charAt(0)}</div>
              </div>
              <div>
                <h2 className="profile-name">{`${firstName} ${lastName}`}</h2>
                <h3 className="profile-last-login">{email}</h3>
              </div>
            </div>
            <ProfileNavigation
              permissions={{
                isSubscription: !hideBilling && !!subscription
              }}
            />
          </div>
          <div className="profile-body">
            <div className="profile-body-content">
              <Route component={AccountInfoForm} path="/myprofile/account-information" />
              <Route component={ChangePasswordForm} path="/myprofile/change-password" />
              <Route component={BillingInfoWrapper} path="/myprofile/billing-information" />
              <Route component={Users} path="/myprofile/myteam" />
              <Route component={Teams} path="/myprofile/teams" />
              <Route component={TeamDetails} path="/myprofile/team/:unitId" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfilePage
