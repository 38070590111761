import { ChangeEvent, MutableRefObject, useContext, useEffect, useRef, useState } from "react"
import * as JsSearch from "js-search"
import { icons, IconType } from "./icons"
import { stemmer } from "../../utils/stemmer"
import { MainModalContext } from "@/hooks/MainModalHook"
import { useForm } from "@/hooks/FormHook"
import { isRequired } from "../../utils/helpers"
import { RadioIconInput } from "../../utils/forms"

interface EditCategoryProps {
  initialName: string
  initialIcon: string
  type: string
  handleSubmit(args: { name: string; icon: string }): void
}

const EditCategory = ({ initialName, initialIcon, type, handleSubmit }: EditCategoryProps) => {
  const [display, setDisplay] = useState<string>("name")
  const [currentIcon, setCurrentIcon] = useState<string>(initialIcon)
  const [filter, setFilter] = useState<string>("")

  const { closeModal } = useContext(MainModalContext)
  const search: MutableRefObject<JsSearch.Search | undefined> = useRef()

  useEffect(() => {
    search.current = new JsSearch.Search("name")
    search.current.tokenizer = new JsSearch.StemmingTokenizer(stemmer, new JsSearch.SimpleTokenizer())
    search.current.addIndex("tags")
    search.current.addDocuments(icons)
  }, [])

  const [values, onChange, , validate] = useForm([
    {
      name: "name",
      initialValue: initialName,
      validators: [isRequired]
    },
    {
      name: "icon",
      initialValue: initialIcon
    }
  ])

  const searchHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value)
  }

  const filteredIcons: IconType[] = filter === "" ? icons : (search.current?.search(filter) as IconType[]) || []

  const submitForm = (e) => {
    e.preventDefault()
    if (!validate()) return
    handleSubmit({ name: values.name, icon: values.icon })
  }

  const handleCancelIcon = () => {
    setDisplay("name")
  }
  const handleSaveIcon = () => {
    setDisplay("name")
    onChange({ persist: () => {}, target: { name: "icon", value: currentIcon } })
  }

  // disable save buttons if the input for collection is empty or equals prev value
  const isInputEmpty = !values.name.trim()

  return (
    <div
      className="categories-manager"
      onKeyPress={(e) => {
        if (e.key === "Enter" && !isInputEmpty) {
          submitForm(e)
        }
      }}
    >
      <h1 className="type-cat-name">{type === "edit" ? "Edit collection" : "Add new"}</h1>
      <form className="categories-edit">
        <div className="categories-name" style={display === "name" ? {} : { display: "none" }}>
          <div
            aria-checked={true}
            className="categories-chosen-ico group"
            onClick={() => {
              setDisplay("icon")
            }}
          >
            <img
              alt={values.icon}
              className="w-full h-full p-4"
              src={`/icons/collectionIcons/blue/${values.icon}.svg`}
            />
            <div className="ico-edit">
              <svg version="1.1" viewBox="0 0 17.9 17.9" x="0px" y="0px">
                <path
                  d="M17.2,2.6l-1.9-1.9c-0.4-0.5-1-0.7-1.6-0.7c-0.6,0-1.2,0.2-1.6,0.7L0.7,12.1
                  c-0.4,0.4-0.7,1-0.7,1.6v2.8c0,0.8,0.6,1.4,1.4,1.4h2.8c0.6,0,1.2-0.2,1.6-0.7L17.2,5.8
                  C18.1,4.9,18.1,3.5,17.2,2.6z M5.1,16.5c-0.2,0.2-0.6,0.4-0.9,0.4H1.4c-0.2,0-0.4-0.2-0.4-0.4
                  v-2.8c0-0.3,0.1-0.7,0.4-0.9l8-8l3.7,3.7L5.1,16.5z M16.5,5.1l-2.7,2.7l-3.7-3.7l2.7-2.7
                  C13,1.1,13.4,1,13.7,1c0.4,0,0.7,0.1,0.9,0.4l1.9,1.9C17,3.8,17,4.6,16.5,5.1z"
                />
              </svg>
            </div>
          </div>
          <input name="name" onChange={onChange} placeholder="Collection name" type="text" value={values.name} />
        </div>
        <div className="categories-icons" style={display === "name" ? { display: "none" } : {}}>
          <input className="icons-search" onChange={searchHandler} placeholder="Search..." type="text" value={filter} />
          <svg className="search-icon" version="1.1" viewBox="0 0 12 12" x="0px" y="0px">
            <path
              d="M11.9,11.1L8.9,8.2c0.7-0.9,1.2-2,1.2-3.2c0-2.8-2.3-5-5-5S0,2.3,0,5c0,2.8,2.3,5,5,5
              c1.2,0,2.3-0.4,3.2-1.2l2.9,2.9c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1C12,11.7,12,11.3,11.9,11.1z
              M1,5c0-2.2,1.8-4,4-4c2.2,0,4,1.8,4,4s-1.8,4-4,4C2.8,9.1,1,7.3,1,5z"
            />
          </svg>
          {filteredIcons.map((icon, i) => (
            <RadioIconInput
              checked={icon.name === currentIcon}
              handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                setCurrentIcon(e.target.value)
              }}
              icon={icon.name}
              key={i}
              value={icon.name}
            />
          ))}
        </div>
        {display === "name" ? (
          <div className="sc-modal-buttons">
            <button className="btn btn-gray-blue" onClick={closeModal} type="button">
              Cancel
            </button>
            <button
              className={`btn text-sm  w-24 text-white border border-transparent rounded-14
                ${isInputEmpty ? "bg-gray-400 " : "px-50 py-1  bg-sc-blue hover:shadow-hover-confirm duration-300"}
              `}
              data-testid="confirm-button"
              disabled={isInputEmpty}
              onClick={(e) => submitForm(e)}
              type="button"
            >
              Save
            </button>
          </div>
        ) : (
          <div className="sc-modal-buttons">
            <button className="btn btn-gray-blue" onClick={handleCancelIcon} type="button">
              Cancel
            </button>
            <button className={"btn btn-lightblue"} onClick={handleSaveIcon} type="button">
              Save
            </button>
          </div>
        )}
      </form>
    </div>
  )
}

export default EditCategory
