import { gql } from "@apollo/client"

export const fragments = {
  presentation: gql`
    fragment fields on Presentation {
      id
      batchId
      name
      icon
      thumbUrl
      sharedPresentationLinks {
        isActive
        token
        isDownloadable
        _id
      }
      slides {
        id
        slideId
        blueprintId
        thumbUrl
        name
        tags
        isFavourite
        downloadUrl
      }
    }
  `,
  group: gql`
    fragment group on Group {
      __typename
      id
      groupId
      name
      custom
      plans {
        id
        planId
      }
      urlPrefix
      searchTags
      themes {
        id
      }
    }
  `,
  plan: gql`
    fragment plan on Plan {
      __typename
      id
      planId
      name
      amount
      interval
      teamMembers
      description
    }
  `,
  userFragment: gql`
    fragment teamOwner on User {
      id
      teamOwner
    }
  `,
  batchSmall: gql`
    fragment batch on UsersBatch {
      __typename
      id
      batchId
      name
      icon
      isFavourite
    }
  `,
  favouriteBatch: gql`
    fragment batch on Presentation {
      __typename
      id
      batchId
      name
      icon
      isFavourite
    }
  `,
  presentationSmall: gql`
    fragment batch on Presentation {
      __typename
      id
      batchId
      name
      icon
      isFavourite
    }
  `,
  presentationSlide: gql`
    fragment slideFields on Slide {
      id
      isFavourite
    }
  `,
  favouriteSlide: gql`
    fragment slideFields on Slide {
      id
      isFavourite
    }
  `
}
