import { Fragment, MouseEventHandler, ReactElement, useEffect } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { useLocation } from "react-router-dom"

interface IConfirmationModal {
  isOpen: boolean
  close: () => void
  confirmFunction: MouseEventHandler
  title: string
  context: ReactElement
  confirmButtonText: string
  isDisabled?: boolean
  mode?: "default" | "danger"
}

const ConfirmationModal = ({
  isOpen,
  close,
  confirmFunction,
  title,
  context,
  confirmButtonText,
  mode = "default",
  isDisabled = false
}: IConfirmationModal) => {
  const location = useLocation()

  useEffect(() => {
    if (isOpen) {
      close()
    }
  }, [location.pathname])

  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="fixed inset-0 z-20 overflow-y-auto bg-modal-back"
        onClose={close}
        onKeyDown={(e) => {
          e.stopPropagation()
          if (e.key === "Enter" && !isDisabled) {
            confirmFunction()
          }
        }}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/*This element is to trick the browser into centering the modal contents.*/}
          <span aria-hidden="true" className="inline-block h-screen align-middle">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="z-11 inline-block w-full max-w-lg overflow-hidden text-left align-middle transition-all transform bg-modal-body shadow-xl">
              <div className="text-gray-title bg-white shadow-modal-title px-6 py-4 flex items-center justify-between">
                {title && (
                  <Dialog.Title as="h3" className="text-15 font-medium leading-6">
                    {title}
                  </Dialog.Title>
                )}
                <svg
                  className="fill-gray-title cursor-pointer"
                  height="10"
                  onClick={close}
                  version="1.1"
                  viewBox="0 0 14 14"
                  width="10"
                  x="0px"
                  y="0px"
                >
                  <path
                    d="M7.7,7l6.1-6.1c0.2-0.2,0.2-0.5,0-0.7s-0.5-0.2-0.7,0L7,6.3L0.9,0.1C0.7,0,0.3,0,0.1,0.1
S0,0.7,0.1,0.9L6.3,7l-6.1,6.1c-0.2,0.2-0.2,0.5,0,0.7C0.2,14,0.4,14,0.5,14s0.3,0,0.4-0.1L7,7.7l6.1,6.1c0.1,0.1,0.2,0.1,0.4,0.1
s0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7L7.7,7z"
                  />
                </svg>
              </div>
              {context && (
                <Dialog.Description className="test py-40 px-30">
                  <span className="text-17 text-modal-description pt-2">{context}</span>
                </Dialog.Description>
              )}

              <div className="flex justify-end items-center gap-5 border-t px-30 py-4">
                <button
                  className="px-30 py-1 text-sm text-gray-400 bg-none border border-gray-400 rounded-14 duration-300"
                  onClick={close}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className={` px-50 py-1 text-sm text-white border border-transparent rounded-14
                  ${
                    isDisabled
                      ? "bg-gray-400 "
                      : mode === "danger"
                      ? "bg-modal-btn-danger"
                      : "bg-sc-blue hover:shadow-hover-confirm duration-300"
                  }
                `}
                  data-testid="confirm-button"
                  disabled={isDisabled}
                  onClick={confirmFunction}
                  type="button"
                >
                  {confirmButtonText}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default ConfirmationModal
