import LogEntry from "./LogEntry"

const InviteUserToTeamLog = ({ record }) => {
  const recordTime = new Date(record.createdAt).getTime()
  const headerContent = (
    <>
      <div className="text-left flex flex-col flex-grow overflow-hidden">
        <p className="text-sm" data-testid="activity-log-entry">
          <strong>{`${record?.user?.firstName} ${record?.user?.lastName}`}</strong> invited
          <strong> {record?.invitedUserEmail}</strong> to SlideCamp
        </p>
      </div>
    </>
  )

  return (
    <LogEntry headerContent={headerContent} iconName="log-invite" index={record?.user?.id} recordTime={recordTime} />
  )
}

export default InviteUserToTeamLog
