import { ApolloClient, ApolloLink, HttpLink } from "@apollo/client"
import { InMemoryCache } from "@apollo/client/cache"
import { onError } from "@apollo/client/link/error"
import { RetryLink } from "@apollo/client/link/retry"

// TODO: BU: remove this cache and refactor it at some point for the new sc-api
const cache = new InMemoryCache({
  dataIdFromObject: (object) => object.id,
  typePolicies: {
    Session: {
      merge: true
    },
    Category: {
      fields: {
        presentations: {
          merge(_, incoming) {
            return incoming
          }
        },
        collections: {
          merge(_, incoming) {
            return incoming
          }
        }
      }
    },
    SetOfUsersSlides: {
      fields: {
        slides: {
          merge(_, incoming) {
            return incoming
          }
        }
      }
    },
    UsersBatch: {
      fields: {
        sharedForUnits: {
          merge(_, incoming) {
            return incoming
          }
        }
      }
    },
    Mutation: {
      fields: {
        session: {
          merge: true
        }
      }
    },
    Query: {
      fields: {
        myBatches: {
          merge(_, incoming) {
            return incoming
          }
        }
      }
    }
  }
})

const createClient = (errorHandler) => {
  const httpLink = new HttpLink({ uri: `${import.meta.env.VITE_SC_API_GATEWAY_PATH}/graphql` })
  const httpLinkNew = new HttpLink({ uri: `${import.meta.env.VITE_SC_API_PATH}/graphql` })

  const retryLinkOptions = {
    attempts: {
      max: 3,
      retryIf: (error, operation) => {
        operation.setContext(({ retryCount = 1 }) => ({
          retryCount: retryCount + 1
        }))
        return !!error
      }
    },
    delay: (count) => count * 500
  }

  const retryLink = new RetryLink(retryLinkOptions)

  const middlewareLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken") || "NONE"}`,
        "screen-dimensions": `${window.screen.width}x${window.screen.height}`,
        timezone: new Date().getTimezoneOffset() / 60
      }
    })
    return forward(operation)
  })
  const afterwareLink = onError((error) => {
    const retryCount = (error.operation && error.operation.getContext().retryCount) || 1

    if (retryCount >= retryLinkOptions.attempts.max) {
      errorHandler(error)
    }
  })

  // Apply RetryLink to the entire chain
  const httpLinkWithMiddleware = ApolloLink.from([retryLink, middlewareLink, afterwareLink, httpLink])
  const httpLinkWithMiddlewareNew = ApolloLink.from([retryLink, middlewareLink, afterwareLink, httpLinkNew])

  const link = ApolloLink.split(
    (operation) => operation.getContext().isUsingNewScApi, // if useQuery(SOME_QUERY, { context: { isUsingNewScApi: true } }) then use new sc-api graphql endpoint
    httpLinkWithMiddlewareNew,
    httpLinkWithMiddleware
  )

  const runningInDevelopment = import.meta.env.DEV // enable devtools only in development

  return new ApolloClient({ link, cache, addTypeName: true, connectToDevTools: runningInDevelopment })
}

export default createClient
