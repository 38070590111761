import { Link } from "react-router-dom"
import { NotFoundSvg } from "@/svg/NotFoundSvg"

export const NotFoundPage = () => {
  return (
    <div className="not-found">
      <NotFoundSvg />
      <div className="not-found-info">
        <div>
          <h1>PAGE NOT FOUND</h1>
          <p>We’ve been notified about this issue and we’ll take a look at it shortly.</p>
        </div>
        <Link className="btn-lightblue" to="/dashboard">
          Back Home
        </Link>
      </div>
    </div>
  )
}
