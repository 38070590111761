import { gql, useMutation } from "@apollo/client"
import { useContext } from "react"
import { removeUnit } from "../../graphql/mutations"
import { MainModalContext } from "../../hooks/MainModalHook"

const ORGANIZATION_UNITS_DATA = gql`
  query organization($_id: String!) {
    organization(_id: $_id) {
      units {
        _id
        name
        description
        members {
          _id
          firstName
          lastName
        }
      }
    }
  }
`
const RemoveUnit = ({ unit, organizationId }) => {
  const unitName = unit.name
  const unitId = unit._id
  const { closeModal } = useContext(MainModalContext)
  const [_removeUnit] = useMutation(removeUnit)

  const remove = () => {
    _removeUnit({
      variables: {
        unitId
      },
      context: { isUsingNewScApi: true },
      update: (cache) => {
        const existData = cache.readQuery({
          query: ORGANIZATION_UNITS_DATA,
          variables: { _id: organizationId }
        })
        cache.writeQuery({
          query: ORGANIZATION_UNITS_DATA,
          data: {
            organization: {
              units: existData.organization.units.filter((unit) => unit._id !== unitId)
            }
          },
          variables: { _id: organizationId }
        })
      }
    }).then(() => {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "scRemoveUnit",
        removedUnit: { unitId: unitId }
      })
    })
    closeModal()
  }

  return (
    <div className="sc-modal-member-user-remove">
      <h2>
        Are you sure you want to remove <span>{unitName}</span> team?
      </h2>
      <div className="profile-buttons-ctr">
        <button className="btn btn-gray-blue" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn btn-dark-blue" onClick={remove}>
          Confirm
        </button>
      </div>
    </div>
  )
}

export default RemoveUnit
