import { useContext, useEffect } from "react"
import { withRouter } from "react-router"
import { AuthContext } from "../../hooks/AuthHook"
import { getUrlParameter } from "../utils/helpers"

const Redirect = ({ location, history }) => {
  const { checkCurrentUser } = useContext(AuthContext)
  useEffect(() => {
    localStorage.setItem("jwtToken", getUrlParameter(location.search, "token"))
    checkCurrentUser()
    history.push("/dashboard")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default withRouter(Redirect)
