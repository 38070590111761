import { useMutation } from "@apollo/client"
import { useContext } from "react"
import { initGodMode } from "../../graphql/mutations"
import { AuthContext } from "../../hooks/AuthHook"
import { useForceUpdate } from "../../hooks/ForceUpdate"
import { useForm } from "../../hooks/FormHook"
import { isRequired } from "../utils/helpers"
import { InputWrapper } from "../utils/forms"

const GodMode = () => {
  const { logout } = useContext(AuthContext)
  const [_initGodMode, { loading }] = useMutation(initGodMode)

  const forceUpdate = useForceUpdate()
  const [values, onChange, validations, validate] = useForm([
    {
      name: "emailOrId",
      validators: [isRequired]
    }
  ])

  const submitForm = () => {
    if (!validate()) {
      forceUpdate()
      return
    }
    _initGodMode({
      variables: {
        emailOrId: values.emailOrId
      }
    }).then((res) => {
      const token = res.data.admin.createSession
      if (!token) {
        return
      }
      const array = document.domain.split(".")
      const prefix = document.domain.split(".")[0]
      array.shift()
      const targetUrl = array.join(".")
      logout()
      window.location.replace(
        `${window.location.protocol}//${prefix}.${targetUrl}:${window.location.port}/user/redirect?token=${token}`
      )
    })
  }

  return (
    <div>
      <form>
        <div className="row">
          <div>
            <div>
              <InputWrapper error={validations.emailOrId} label="Email or Id">
                <input
                  name="emailOrId"
                  onChange={onChange}
                  placeholder="Email or Id"
                  type="text"
                  value={values.emailOrId}
                />
              </InputWrapper>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="admin-button-submit">
            <button className="btn btn-dark-blue" disabled={loading} onClick={submitForm} type="button">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default GodMode
