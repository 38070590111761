import { useLazyQuery, useMutation } from "@apollo/client"
import { useContext } from "react"
import { withRouter, Redirect } from "react-router-dom"
import { UserData } from "../../graphql/queries"
import { executePayPalSubscription } from "../../graphql/mutations"
import { PricingModalContext } from "../../hooks/PricingModalHook"
import { getUrlParameter } from "../utils/helpers"

const PayPalRedirect = ({ location, history }) => {
  const { openModal, setPaymentStage, closeModal } = useContext(PricingModalContext)
  const [_executePayPalSubscription] = useMutation(executePayPalSubscription)

  const [getUserData] = useLazyQuery(UserData)

  const getUrlSearchParams = () => ({
    acceptPaypalSubscription: getUrlParameter(location.search, "acceptPaypalSubscription"),
    token: getUrlParameter(location.search, "token"),
    payerId: getUrlParameter(location.search, "PayerID")
  })

  const paypalRedirect = () => {
    const { acceptPaypalSubscription, token, payerId } = getUrlSearchParams()

    if (acceptPaypalSubscription !== "") {
      openModal("payresponse")
      _executePayPalSubscription({
        variables: { subscriptionId: acceptPaypalSubscription, token, payerId }
      })
        .then(
          () => {
            setPaymentStage("success")
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({ event: "scSuccessfulSubscription", payment: { method: "paypal" } })
            getUserData({
              query: UserData,
              fetchPolicy: "network-only"
            })
            history.push("/dashboard")
          },
          () => {
            setPaymentStage("error")
          }
        )
        .then(() => {
          setTimeout(() => {
            closeModal()
          }, 1500)
        })
    }
  }

  const { acceptPaypalSubscription } = getUrlSearchParams()
  if (acceptPaypalSubscription !== "") {
    paypalRedirect()
    return null
  } else if (location.pathname === "/") {
    return <Redirect to="/dashboard" />
  }
  return null
}

export default withRouter(PayPalRedirect)
