import { gql, useQuery } from "@apollo/client"
import { useContext } from "react"
import { Link } from "react-router-dom"
import { withRouter } from "react-router"
import { TeamEmptySvg } from "../../svg/EmptyContainerSvgs"
import { MainModalContext } from "../../hooks/MainModalHook"
import { ErrorPlaceholder } from "../utils/placeholders"
import UnitUser from "./UnitUser"
import TeamPresentation from "./TeamPresentation"

const UNIT_DATA = gql`
  query unit($id: String!) {
    unit(id: $id) {
      _id
      name
      description
      members {
        _id
        firstName
        lastName
        email
      }
      accessibleBatches {
        collections {
          batchId
          name
          thumbUrl
          type
          icon
        }
        presentations {
          batchId
          name
          thumbUrl
          type
        }
      }
    }
  }
`

const TeamDetails = ({ match }) => {
  const { openModal } = useContext(MainModalContext)
  const { data, loading, error, refetch } = useQuery(UNIT_DATA, {
    fetchPolicy: "cache-and-network",
    variables: { id: match.params.unitId },
    context: { isUsingNewScApi: true }
  })

  if (error) return <ErrorPlaceholder />
  if (loading) return <div className="profile-loading" />

  const unit = data && data.unit

  return (
    <div className="profile-team">
      <Link className="team-details-back" to="/myprofile/teams">
        <span />
      </Link>
      <h1>{`${unit.name} Team`}</h1>
      {data && unit.members.length === 0 ? (
        <div className="profile-team-first">
          <div className="profile-team-first-img">
            <TeamEmptySvg />
          </div>
          <div className="profile-team-first-add">
            <h2>Add Team Members!</h2>
            <p className="profile-team-business">
              Every plan has at least two free invites for you to send to your team. To find out how many invites you
              have, check the plan you're on. You can do this by clicking on the Change Plan option on the drop down
              where your name is.
            </p>
            <div className="profile-buttons">
              <button
                className="btn btn-dark-blue"
                onClick={() => openModal({ content: "addUserToUnit", data: { unit, refetch } })}
              >
                Add member
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="profile-team-add">
          <div className="profile-team-members">
            <div className="profile-team-members-list">
              {Array.from(unit.members).map((unitMember) => (
                <UnitUser
                  key={unitMember._id}
                  removeButton={unit._id !== "all"}
                  unitId={unit._id}
                  unitMember={unitMember}
                />
              ))}
            </div>
            <div className="profile-team-member-info profile-team-member-add">
              {unit._id !== "all" && (
                <button
                  className="btn"
                  onClick={() => {
                    openModal({ content: "addUserToUnit", data: { unit, refetch } })
                  }}
                >
                  <svg id="Layer_1" version="1.1" viewBox="0 0 62.7 62.7" x="0px" y="0px">
                    <circle className="profile-team-member-add-circle" cx="31.3" cy="31.3" r="31.3" />
                    <path
                      className="profile-team-member-add-plus"
                      d="M45.2,30.3H32.3V17.5c0-0.6-0.4-1-1-1s-1,0.4-1,1v12.9H17.5c-0.6,0-1,0.4-1,1s0.4,1,1,1h12.9v12.9
                      c0,0.6,0.4,1,1,1s1-0.4,1-1V32.3h12.9c0.6,0,1-0.4,1-1S45.8,30.3,45.2,30.3z"
                    />
                  </svg>
                  <p>Add team member</p>
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col gap-[25px]">
        {unit?.accessibleBatches?.collections.length > 0 && (
          <div>
            <h1 className="pb-[20px]">{`${unit.name} Team collections`}</h1>
            <div className="grid w-full min-[500px]:grid-cols-2 min-[768px]:grid-cols-3 gap-[20px]">
              {unit.accessibleBatches.collections.map((sharedBatch) => (
                <TeamPresentation
                  batchId={sharedBatch.batchId}
                  icon={sharedBatch.icon}
                  key={sharedBatch.batchId}
                  name={sharedBatch.name}
                  refetch={refetch}
                  thumbUrl={sharedBatch.thumbUrl}
                  type={sharedBatch.type}
                  unitId={unit._id}
                />
              ))}
            </div>
          </div>
        )}

        {unit?.accessibleBatches?.presentations.length > 0 && (
          <div>
            <h1 className="pb-[20px]">{`${unit.name} Team presentations`}</h1>
            <div className="grid w-full min-[768px]:grid-cols-2 gap-[20px]">
              {unit.accessibleBatches.presentations.map((sharedBatch) => (
                <TeamPresentation
                  batchId={sharedBatch.batchId}
                  key={sharedBatch.batchId}
                  name={sharedBatch.name}
                  refetch={refetch}
                  thumbUrl={sharedBatch.thumbUrl}
                  type={sharedBatch.type}
                  unitId={unit._id}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default withRouter(TeamDetails)
