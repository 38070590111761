import { useContext, useState } from "react"
import PreviewDropdown from "../presentation/PreviewDropdown"

import CartDropdownList from "./CartDropdownList"
import { UserDataContext } from "@/hooks/UserDataHook"

interface ICartHeaderProps {
  canSave: boolean
  openForm(): void
  closeCart: () => void
}

const CartHeader = ({ canSave, openForm, closeCart }: ICartHeaderProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
  const {
    user: { cart }
  } = useContext(UserDataContext)

  return (
    <div
      className="flex items-center bg-white text-[#8696a3] text-[13px] pl-[15px] min-h-[52px] justify-between border-b border-[#D1D3D4] shadow-[0_2px_28px_#00000026]"
      data-testid="cart-header"
    >
      <p className="text-sc-text-dark min-[2050px]:text-base" onClick={openForm}>
        {cart?.name || "Cart"}
      </p>
      <PreviewDropdown
        classCategory={false}
        classFixed={true}
        isOpen={isDropdownOpen}
        openDropdown={() => setIsDropdownOpen(true)}
      >
        <CartDropdownList canSave={canSave} closeDropdown={() => setIsDropdownOpen(false)} openForm={openForm} />
      </PreviewDropdown>
      <button className="close-icon" onClick={closeCart}>
        <svg version="1.1" viewBox="0 0 14 14" x="0px" y="0px">
          <path
            className="close-icon-fill"
            d="M7.7,7l6.1-6.1c0.2-0.2,0.2-0.5,0-0.7s-0.5-0.2-0.7,0L7,6.3L0.9,0.1C0.7,0,0.3,0,0.1,0.1
                S0,0.7,0.1,0.9L6.3,7l-6.1,6.1c-0.2,0.2-0.2,0.5,0,0.7C0.2,14,0.4,14,0.5,14s0.3,0,0.4-0.1L7,7.7l6.1,6.1c0.1,0.1,0.2,0.1,0.4,0.1
                s0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7L7.7,7z"
          />
        </svg>
      </button>
    </div>
  )
}

export default CartHeader
