import { MouseEvent, ReactElement } from "react"

interface PreviewDropdownProps {
  classFixed: boolean
  classCategory: boolean
  isOpen: boolean
  children: ReactElement
  header?: ReactElement
  tooltipClassName?: string
  openDropdown(): void
}

const PreviewDropdown = ({
  classFixed,
  openDropdown,
  isOpen,
  children,
  header,
  tooltipClassName
}: PreviewDropdownProps) => {
  const handleDropdownOpen = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    openDropdown()
  }

  return (
    <div className={"w-full absolute top-0 left-0 z-10 bg-yellow-200"}>
      <div
        className={`absolute w-[11.6%] h-[20px] top-[13px] right-[13px] flex justify-center items-center cursor-pointer hover:!text-[#3493fb] transition ease-in-out duration-[400ms] ${
          classFixed && !isOpen ? "!text-[#8696a3] right-[17%]" : classFixed ? "right-[17%]" : "text-white"
        } ${isOpen ? "!text-[#3493fb]" : ""}`}
        data-testid="slide-context-menu-button"
        onClick={handleDropdownOpen}
      >
        {header || (
          <svg fill="currentColor" height="6px" version="1.1" viewBox="0 0 22.3 5" width="67.4%" x="0px" y="0px">
            <circle cx="2.5" cy="2.5" r="2.5" />
            <circle cx="11.1" cy="2.5" r="2.5" />
            <circle cx="19.8" cy="2.5" r="2.5" />
          </svg>
        )}
      </div>
      {isOpen && (
        <div
          className={`w-[200px] absolute top-[40px] right-0 shadow-[0_0_12px_2px_#0000001c] ${tooltipClassName}`}
          data-testid="slide-context-menu"
        >
          <div
            className="text-left bg-white rounded-[2px] py-[10px] text-[#9aacb6] text-[12px]"
            data-testid="rc-tooltip-inner"
          >
            {children}
          </div>
        </div>
      )}
    </div>
  )
}

export default PreviewDropdown
