import { createContext, ReactElement, useState } from "react"

interface UseNotificationContext {
  type: OpenNotification["type"]
  content: OpenNotification["content"]
  open({ type, content, duration }: OpenNotification): void
}

interface OpenNotification {
  type: string | null
  content: ReactElement | null
  duration: number
}

export const useNotification = (): UseNotificationContext => {
  const [type, setType] = useState<OpenNotification["type"]>(null)
  const [content, setContent] = useState<OpenNotification["content"]>(null)

  const open = ({ type, content, duration }: OpenNotification) => {
    setType(type)
    setContent(content)
    setTimeout(() => {
      setType(null)
      setContent(null)
    }, duration * 1000)
  }

  return {
    type,
    content,
    open
  }
}

export const NotificationContext = createContext({} as UseNotificationContext)
