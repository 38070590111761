import { useMutation } from "@apollo/client"
import { useContext, useState } from "react"
import { AuthContext } from "../../hooks/AuthHook"
import {
  acceptOrganizationMemberInvitation,
  acceptOrganizationOwnerInvitation,
  login,
  signUp
} from "../../graphql/mutations"
import { useForceUpdate } from "../../hooks/ForceUpdate"
import { useForm } from "../../hooks/FormHook"
import { isRequired } from "../utils/helpers"
import { ErrorPlaceholder } from "../utils/placeholders"
import { InputWrapper } from "../utils/forms"
import { withRouter } from "react-router-dom"

const AcceptInvitationForm = ({ type, submitParams, history, initialValues }) => {
  const [error, setError] = useState(false)
  const { checkCurrentUser } = useContext(AuthContext)

  const [_login, { loading: loginLoading }] = useMutation(login, {
    context: { isUsingNewScApi: true }
  })
  const [_signUp, { loading: signUpLoading }] = useMutation(signUp, {
    context: { isUsingNewScApi: true }
  })
  const [_acceptOrganizationOwnerInvitation] = useMutation(acceptOrganizationOwnerInvitation, {
    context: { isUsingNewScApi: true }
  })
  const [_acceptOrganizationMemberInvitation] = useMutation(acceptOrganizationMemberInvitation, {
    context: { isUsingNewScApi: true }
  })

  const forceUpdate = useForceUpdate()
  const [values, onChange, validations, validate] = useForm([
    {
      name: "firstName",
      validators: [isRequired]
    },
    {
      name: "lastName",
      validators: [isRequired]
    },
    {
      name: "email",
      initialValue: initialValues.email
    },
    {
      name: "password",
      validators: [isRequired]
    },
    {
      name: "confirmPassword",
      validators: [isRequired, (val) => (values.password === val ? false : "Passwords do not match")]
    }
  ])

  const handleTeamOwnerInvitation = () => {
    _acceptOrganizationOwnerInvitation({
      variables: {
        token: submitParams.member,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName
      }
    }).then((response) => {
      localStorage.setItem("jwtToken", response.data.acceptOrganizationOwnerInvitation)
      checkCurrentUser()
      history.push("/dashboard")
    })
  }

  const handleTeamMemberInvitation = () => {
    const { email, password, firstName, lastName } = values
    _signUp({ variables: { email, password, firstName, lastName } })
      .then(() =>
        _acceptOrganizationMemberInvitation({
          variables: {
            invitationId: submitParams.member,
            token: submitParams.ott
          }
        })
      )
      .then(() => _login({ variables: { email, password } }))
      .then((response) => {
        localStorage.setItem("jwtToken", response.data.login)
        checkCurrentUser()
        history.push("/dashboard")
      })
  }

  const submitForm = () => {
    if (!validate()) {
      forceUpdate()
      return
    }
    if (type === "team-members") {
      handleTeamMemberInvitation()
    } else {
      handleTeamOwnerInvitation()
    }
  }

  const onKeyDown = (e) => {
    if (e.key === "Enter" && !(signUpLoading || loginLoading)) {
      submitForm()
    }
  }

  return (
    <div className="sc-auth-panel">
      <ErrorPlaceholder
        tryAgain={() => {
          setError(false)
        }}
        visible={error}
      />
      <div className="sc-register-panel" onKeyDown={onKeyDown} style={error ? { display: "none" } : {}}>
        <h2>
          Join SlideCamp <b>Team</b>.
        </h2>
        <form className="sc-authentic-form">
          <InputWrapper error={validations.firstName} label="First Name">
            <input
              disabled={signUpLoading || loginLoading}
              name="firstName"
              onChange={onChange}
              type="text"
              value={values.firstName}
            />
          </InputWrapper>
          <InputWrapper error={validations.lastName} label="Last Name">
            <input
              disabled={signUpLoading || loginLoading}
              name="lastName"
              onChange={onChange}
              type="text"
              value={values.lastName}
            />
          </InputWrapper>
          <InputWrapper label="Email">
            <input disabled name="email" type="text" value={values.email} />
          </InputWrapper>
          <InputWrapper error={validations.password} label="Password">
            <input
              disabled={signUpLoading || loginLoading}
              name="password"
              onChange={onChange}
              type="password"
              value={values.password}
            />
          </InputWrapper>
          <InputWrapper error={validations.confirmPassword} label="Confirm Password">
            <input
              disabled={signUpLoading || loginLoading}
              name="confirmPassword"
              onChange={onChange}
              type="password"
              value={values.confirmPassword}
            />
          </InputWrapper>
          <div className="sc-auth-links">
            <button
              className="btn btn-lightblue"
              disabled={signUpLoading || loginLoading}
              onClick={submitForm}
              type="button"
            >
              Create account
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default withRouter(AcceptInvitationForm)
