const AddPresentationButton = ({ handleAddPresentation }) => {
  return (
    <button
      className="w-full aspect-[490/357] text-sm flex justify-center items-center gap-[12px] cursor-pointer rounded-[3px] border-[#D1D5DB] border transition ease-in-out hover:bg-sc-hover-dark"
      data-testid="add-presentation-button"
      onClick={handleAddPresentation}
    >
      <svg fill="none" height="11" viewBox="0 0 11 11" width="11" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2136_5487)">
          <path
            d="M5.50333 1.1001L5.50338 1.1001L5.50316 1.09843C5.4731 0.877005 5.28331 0.706445 5.05369 0.706445C4.80322 0.706445 4.60023 0.909626 4.60029 1.16012L4.60097 5.27806H0.484184V5.278L0.482517 5.27811L0.42437 5.282L0.424366 5.28194L0.422673 5.28217C0.201282 5.31223 0.0306641 5.50199 0.0306641 5.73165C0.0306641 5.98214 0.233704 6.18523 0.484184 6.18523H4.60098L4.60212 10.3029H4.60206L4.60217 10.3046L4.60611 10.3628L4.60606 10.3628L4.60629 10.3645C4.63634 10.5859 4.82613 10.7564 5.05575 10.7564C5.30623 10.7564 5.50922 10.5532 5.50916 10.3027L5.50802 6.18523H9.62714V6.18529L9.62881 6.18518L9.68697 6.18129L9.68698 6.18135L9.68867 6.18112C9.91002 6.15106 10.0807 5.9613 10.0807 5.73165C10.0807 5.48115 9.87761 5.27806 9.62714 5.27806H5.50801L5.50733 1.15994H5.50738L5.50727 1.15826L5.50333 1.1001Z"
            fill="#6B6F7A"
            stroke="#6B6F7A"
            strokeWidth="0.05"
          />
        </g>
        <defs>
          <clipPath id="clip0_2136_5487">
            <rect fill="white" height="10" transform="translate(0.0556641 0.731445)" width="10" />
          </clipPath>
        </defs>
      </svg>
      <span>Add Presentation</span>
    </button>
  )
}

export default AddPresentationButton
