const cancelScrollEvent = (e) => {
  e.stopImmediatePropagation()
  e.preventDefault()
  e.returnValue = false
  return false
}

const addScrollEventListener = (elem, handler) => {
  elem.addEventListener("wheel", handler, false)
}

const removeScrollEventListener = (elem, handler) => {
  elem.removeEventListener("wheel", handler, false)
}

const ScrollLockUtils = {
  scrollLock(elem) {
    elem &&
      addScrollEventListener(elem, (e) => {
        this.onScrollHandler(e, elem)
      })
  },

  scrollRelease(elem) {
    elem = elem || this.scrollElem
    elem &&
      removeScrollEventListener(elem, (e) => {
        this.onScrollHandler(e, elem)
      })
  },

  onScrollHandler(e, elem) {
    const scrollTop = elem.scrollTop
    const scrollHeight = elem.scrollHeight
    const height = elem.clientHeight
    const wheelDelta = e.deltaY
    const isDeltaPositive = wheelDelta > 0
    if (isDeltaPositive && wheelDelta > scrollHeight - height - scrollTop) {
      elem.scrollTop = scrollHeight
      cancelScrollEvent(e)
    } else if (!isDeltaPositive && -wheelDelta > scrollTop) {
      elem.scrollTop = 0
      cancelScrollEvent(e)
    }
  }
}

export default ScrollLockUtils
