import React, { useState } from "react"
import { QueueIcon } from "../../svg/SvgIcons"
import { useHistory } from "react-router-dom"

const RecentSearches = ({ setSearchInputValue }) => {
  const [recentSearches, setRecentSearches] = useState([
    // { id: "1", query: "Backgrounds" },
    // { id: "2", query: "Lifestyle" },
    // { id: "3", query: "Boundless Travel" }
  ])
  const history = useHistory()

  const handleSearch = (query) => {
    history.push(`/search?utf8=✓&query=${query}`)
    setSearchInputValue(query)
  }

  return (
    <div className="px-[20px] py-[15px]">
      <ul className="text-[12px] font-semibold text-sc-text-dark">
        {recentSearches.map((item) => (
          <li
            className="py-[8px] flex gap-[15px] items-cente cursor-pointer"
            key={item.id}
            onClick={() => handleSearch(item.query)}
          >
            <QueueIcon />
            <span>{item.query}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default RecentSearches
