import { gql, useMutation } from "@apollo/client"
import { useState } from "react"
import { ErrorPlaceholder } from "@/components/utils/placeholders"
import { Batch } from "@/graphql/types/queries"
import { copyToClipboard } from "@/components/utils/clipboard"
import { Listbox } from "@headlessui/react"
import { ArrowDownIcon, ArrowUpIcon, CheckMarkIcon } from "@/svg/SvgIcons"

const SET_SHARE_WITH_LINK = gql`
  mutation setShareWithLink($batchId: String!, $isActive: Boolean!) {
    setShareWithLink(batchId: $batchId, isActive: $isActive) {
      code
      success
      message
      sharedPresentationLinks {
        isActive
        token
        isDownloadable
        _id
      }
    }
  }
`

const sharedPresentationLinksBatch = gql`
  fragment batch on Presentation {
    __typename
    id
    sharedPresentationLinks {
      isActive
      token
      isDownloadable
      _id
    }
  }
`

const SET_DOWNLOADABLE = gql`
  mutation setLinkIsDownloadable($id: String!, $isDownloadable: Boolean!) {
    setLinkIsDownloadable(id: $id, isDownloadable: $isDownloadable) {
      code
      success
      message
    }
  }
`

interface ILinkOption {
  id: number
  name: string
  description: string
  isDownloadable: boolean
}

const linkOptions: ILinkOption[] = [
  {
    id: 1,
    name: "Can view only",
    description: "Users can see the presentation without keeping",
    isDownloadable: false
  },
  {
    id: 2,
    name: "Can view and download",
    description: "Users can see the presentation and save it to disk",
    isDownloadable: true
  }
]

const ShareWithOthers = ({ batch }: { batch: Batch }) => {
  const [_setShareWithLink, { error }] = useMutation(SET_SHARE_WITH_LINK)
  const [_setSharingLinkDownloadable, { error: setDownloadableError }] = useMutation(SET_DOWNLOADABLE)
  const [copied, setCopied] = useState<boolean>(false)
  const [batchData, setBatchData] = useState<Batch>(batch)
  const [selectedLinkOption, setSelectedLinkOption] = useState<ILinkOption>(
    batchData?.sharedPresentationLinks?.isDownloadable ? linkOptions[1] : linkOptions[0]
  )

  if (error || setDownloadableError) return <ErrorPlaceholder />

  const toggleShareWithLink = () => {
    _setShareWithLink({
      variables: {
        batchId: batchData.id,
        isActive: !batchData?.sharedPresentationLinks?.isActive
      },
      context: { isUsingNewScApi: true },
      update: (cache, { data: { setShareWithLink } }) => {
        if (setShareWithLink) {
          const updated = { ...batchData, sharedPresentationLinks: setShareWithLink.sharedPresentationLinks }
          setBatchData(updated)

          cache.writeFragment({
            id: batchData.id,
            fragment: sharedPresentationLinksBatch,
            data: updated
          })
        }
      }
    })
  }
  const handleCopyClick = () => {
    if (!batchData?.sharedPresentationLinks?.isActive) return

    copyToClipboard(`${window.location.origin}/show/${batchData?.sharedPresentationLinks?.token}`)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  const handleSelectLinkOption = (option: ILinkOption) => {
    setSelectedLinkOption(option)
    _setSharingLinkDownloadable({
      variables: { id: batchData?.sharedPresentationLinks?._id, isDownloadable: option.isDownloadable },
      context: { isUsingNewScApi: true }
    })
  }

  return (
    <>
      <div className="share-others">
        <div className="share-label-switch">
          <label>Share with others:</label>
          <label className="button-switch">
            <input
              checked={batchData?.sharedPresentationLinks?.isActive}
              onChange={toggleShareWithLink}
              type="checkbox"
            />
            <span className="slider round" />
          </label>
        </div>
        <div className={`share-link ${batchData?.sharedPresentationLinks?.isActive ? "link-active" : ""}`}>
          <div className={`share-link-view ${batchData?.sharedPresentationLinks?.isActive ? "" : "disabled-copy"}`}>
            {`${window.location.origin}/show/${
              batchData?.sharedPresentationLinks?.isActive ? batchData?.sharedPresentationLinks?.token : "..."
            }`}
          </div>

          <span
            className={`share-link-copy ${batchData?.sharedPresentationLinks?.isActive ? "copy-active" : ""}`}
            onClick={handleCopyClick}
          >
            {copied ? "Copied" : "Copy"}
          </span>
        </div>
        <div
          className={`flex mx-[30px] mt-[12px] gap-4 relative transition-colors ease-in-out duration-200 text-[14px] ${
            batchData?.sharedPresentationLinks?.isActive ? "text-[#93A3AA]" : "text-[#C2C4C4]"
          }`}
        >
          <span>People with the link</span>
          <div>
            <Listbox
              disabled={!batchData?.sharedPresentationLinks?.isActive}
              onChange={handleSelectLinkOption}
              value={selectedLinkOption}
            >
              {({ open }) => {
                return (
                  <>
                    <Listbox.Button
                      className={`${
                        batchData?.sharedPresentationLinks?.isActive ? "text-sc-font-gray" : "text-inherit"
                      }`}
                    >
                      {selectedLinkOption.name} {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
                    </Listbox.Button>
                    <Listbox.Options className="absolute left-0 shadow-lg border rounded-[8px] py-2">
                      {linkOptions.map((option) => (
                        <Listbox.Option className="py-2 px-4 hover:bg-sc-hover-dark" key={option.id} value={option}>
                          {({ selected }) => (
                            <div className="cursor-pointer flex gap-1">
                              <CheckMarkIcon visible={selected} />
                              <div>
                                <b>{option.name}</b>
                                <p>{option.description}</p>
                              </div>
                            </div>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </>
                )
              }}
            </Listbox>
          </div>
        </div>
      </div>
    </>
  )
}

export default ShareWithOthers
