import { useMutation, gql } from "@apollo/client"
import { useContext, useState } from "react"
import { BiCog, BiEnvelope, BiUserCheck } from "react-icons/bi"

import { MainModalContext } from "../../hooks/MainModalHook"
import UsersProfileDropDown from "./UsersProfileDropDown"

export const ADD_MEMBER_TO_ORGANIZATION = gql`
  mutation addMemberToOrganization($emails: [String!]!) {
    addMemberToOrganization(emails: $emails) {
      code
      success
      message
    }
  }
`

export default function TeamMember({
  // userId - user id of this team member
  // viewerID - user id of logged in user
  // TODO: BU: fix this confusions
  teamMember: { id, _id: userId, firstName, lastName, email, role, invitationLink },
  viewerId,
  teamMemberDropDown
}) {
  const { openModal } = useContext(MainModalContext)
  const [sendInvitations] = useMutation(ADD_MEMBER_TO_ORGANIZATION, {
    context: { isUsingNewScApi: true }
  })

  const [invitationSent, setinvItationSent] = useState(false)

  const handleResendInvitation = () => {
    sendInvitations({
      variables: { emails: [email] }
    })
    setinvItationSent(true)
  }

  const nameInModal = firstName ? `${firstName} ${lastName}` : email

  const roleOptions = ["Change role", "Remove from team"]

  return (
    <>
      <div className="flex items-center flex-wrap justify-between mb-6" data-testid="team-member">
        <div className="flex flex-row items-center">
          <div className="w-[60px] h-[60px] bg-[#e9eaed] text-[#ababab] text-2xl mr-3 rounded-full justify-center flex items-center">
            {userId ? <BiUserCheck /> : <BiEnvelope />}
          </div>
          <div>
            <div className="text-xl mb-0">
              {userId ? `${firstName} ${lastName}` : "Invited user"}
              {role === "admin" && (
                <span className="text-white bg-[#17a2b8] px-[.6em] py-[.15em] rounded-[10rem] text-[11px] font-bold align-text-top ml-2">
                  admin
                </span>
              )}
            </div>
            <small className="font-light">
              {email} {invitationSent && <strong>(invitation sent)</strong>}
            </small>
          </div>
        </div>

        <div className="block md:flex items-center">
          <UsersProfileDropDown
            counts={{}}
            filter={teamMemberDropDown}
            handleResendInvitation={handleResendInvitation}
            invitationLink={invitationLink}
            invitationSent={invitationSent}
            inviteeId={!userId ? id : null}
            isDisabled={viewerId === userId}
            nameInModal={nameInModal}
            openModal={openModal}
            options={roleOptions}
            setFilter={handleResendInvitation}
            title={<BiCog />}
            type="teamMember"
            userId={userId}
          />
        </div>
      </div>
    </>
  )
}
