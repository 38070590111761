import { useQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { AdminUserData } from "../../graphql/queries"
import { ErrorPlaceholder, LoadingPlaceholder } from "../utils/placeholders"
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table"

const columnHelper = createColumnHelper()

const defaultColumns = [
  columnHelper.accessor("firstName", {
    header: "First Name"
  }),
  columnHelper.accessor("lastName", {
    header: "Last Name"
  }),
  columnHelper.accessor("email", {
    header: "Email"
  }),
  columnHelper.accessor("group.groupId", {
    header: "Group"
  }),
  columnHelper.accessor("teamOwner", {
    header: "Team Owner",
    cell: (info) => info.getValue().toString()
  }),
  columnHelper.accessor("allowUploads", {
    header: "Can Upload",
    cell: (info) => info.getValue().toString()
  }),
  columnHelper.accessor("subscription", {
    header: "Pay Now",
    cell: (info) => (
      <p>
        {info.getValue() && info.getValue().active
          ? `${info.getValue().plan.amount / 100} ${info.getValue().willBeCanceled ? "(ending)" : "(still paying)"}`
          : 0}
      </p>
    )
  })
]

const Users = () => {
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [pages, setPages] = useState(-1)
  const [query, setQuery] = useState("")
  const [users, setUsers] = useState([])

  const table = useReactTable({
    columns: defaultColumns,
    data: users,
    getCoreRowModel: getCoreRowModel()
  })

  const { data, loading, error, fetchMore } = useQuery(AdminUserData, {
    options: () => ({
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        limit: 10,
        offset: 0
      }
    })
  })

  useEffect(() => {
    if (data) {
      setUsers(data.admin.users.list)
    }
  }, [data])

  if (loading) return <LoadingPlaceholder />
  if (error) return <ErrorPlaceholder />

  const onSearch = (e) => {
    e.preventDefault()
    fetchMore({
      variables: {
        limit: pageSize,
        offset: page * pageSize,
        phrase: query
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult
        }
        return fetchMoreResult
      }
    }).then(() => {
      setPages(Math.ceil(data.admin.users.totalCount / pageSize))
    })
  }

  const inputOnChange = (e) => {
    setQuery(e.target.value)
  }

  return (
    <div className="profile-page">
      <Link to="/admin-panel">Back</Link>
      <form onSubmit={onSearch}>
        <input name="search" onChange={inputOnChange} type="text" value={query} />
        <input type="submit" />
      </form>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </div>
  )
}

export default Users
