import { useApolloClient, useMutation } from "@apollo/client"
import { useState } from "react"
import { startTrial } from "../../graphql/mutations"
import { AdminUserData } from "../../graphql/queries"
import { useForceUpdate } from "../../hooks/ForceUpdate"
import { useForm } from "../../hooks/FormHook"
import { emailValidator } from "../utils/helpers"
import { InputWrapper } from "../utils/forms"

const Trial = ({ plans, wizard }) => {
  const client = useApolloClient()
  const [_startTrial, { loading }] = useMutation(startTrial)
  const [success, setSuccess] = useState(false)

  const forceUpdate = useForceUpdate()
  const [values, onChange, validations, validate] = useForm([
    {
      name: "userId"
    },
    {
      name: "email",
      validators: [emailValidator]
    },
    {
      name: "endDate",
      initialValue: new Date(Date.now() + 12096e5)
    },
    {
      name: "plan"
    }
  ])

  const handleSubmitForm = async () => {
    if (!validate()) {
      forceUpdate()
      return
    }
    const res = await client.query({ query: AdminUserData, variables: { phrase: values.email } })
    _startTrial({
      variables: {
        userId: res.data.admin.users.list[0].userId,
        endDate: values.endDate,
        planId: values.plan
      }
    }).then(() => {
      setSuccess(true)
    })
  }

  return (
    <div className="w-full">
      <div>Trials</div>
      <form>
        <h1>{"Start trial"}</h1>
        <div className="row flex justify-between">
          <div className="col-md-6 admin-upload-fields">
            <div style={{ display: "none" }}>
              <InputWrapper error={validations.userId} label="User">
                <input
                  disabled={wizard}
                  name="userId"
                  onChange={onChange}
                  placeholder="User id"
                  type="text"
                  value={values.userId}
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper error={validations.email} label="User Email">
                <input
                  disabled={wizard}
                  name="email"
                  onChange={onChange}
                  placeholder="User Email"
                  type="text"
                  value={values.email}
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper error={validations.endDate} label="End date">
                <input name="endDate" onChange={onChange} placeholder="End date" type="text" value={values.endDate} />
              </InputWrapper>
            </div>
            <div className="row">
              <div className="admin-button-submit-fixed">
                <button className="btn btn-dark-blue" disabled={loading} onClick={handleSubmitForm} type="button">
                  Submit
                </button>
              </div>
              {success && <div>{"Trial started "}</div>}
            </div>
          </div>
          <div className="h-[360px] overflow-scroll px-6">
            <ul className="admin-plan-list">
              {plans.map((plan) => (
                <li className="w-full" key={plan.planId}>
                  <input
                    checked={values.plan === plan.planId}
                    className="peer hidden"
                    id={`plan-${plan.planId}`}
                    name="plan"
                    onChange={() => {
                      onChange({
                        persist: () => {},
                        target: {
                          name: "plan",
                          value: plan.planId
                        }
                      })
                    }}
                    type="checkbox"
                  />
                  <label
                    className="inline-flex items-center justify-between w-full p-2 px-4 text-gray-500 bg-white border-2 border-gray-200 rounded cursor-pointer peer-checked:border-[#3390ff] hover:text-gray-600 peer-checked:text-gray-600"
                    htmlFor={`plan-${plan.planId}`}
                  >
                    <div className="block">
                      <div className="w-full text-sm">
                        {plan.name} - ${plan.amount / 100} - {plan.interval}
                      </div>
                    </div>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Trial
