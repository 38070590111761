import { useContext } from "react"
import useEmailInputs from "../../hooks/InviteFriendFormHook"
import { MainModalContext } from "../../hooks/MainModalHook"
import { emailRegex } from "../utils/helpers"
import { ErrorPlaceholder } from "../utils/placeholders"

import CheckBlueIcon from "../../assets/img/icons/svg/check_blue.svg"
import DeleteIcon from "../../assets/img/icons/svg/delete.svg"
import AddIcon from "../../assets/img/icons/svg/add.svg"

export default function InviteFriendForm() {
  const {
    isMultiple,
    singleInputs,
    singleInputsRefs,
    multiInput,
    multiInputValidation,
    handleInputAdd,
    handleInputRemove,
    handleSingleInputChange,
    validateSingleInputChange,
    handleEnterPress,
    handleMultiInputChange,
    handleSwitchToMulti,
    handleSwitchToSingle,
    handleSingleSubmit,
    handleMultiSubmit,
    loading,
    error,
    setError,
    emailsSent
  } = useEmailInputs()
  const { closeModal } = useContext(MainModalContext)

  const handleSubmit = async () => {
    try {
      isMultiple ? await handleMultiSubmit() : await handleSingleSubmit()
    } catch (e) {
      console.log(e)
      setError(true)
    }
  }

  const renderEmailsSent = () => (
    <div className="modal-profile-invite">
      <p className="modal-profile-invite-header">
        {emailsSent.length > 0
          ? `Your invitations were successfully sent: ${emailsSent.join(", ")}`
          : "All your invitations were already sent previously"}
      </p>
      <div className="button-wrapper">
        <button className="btn btn-dark-blue" onClick={closeModal} type="button">
          Close
        </button>
      </div>
    </div>
  )

  const renderSingleInput = () => (
    <>
      <div className="container-inner">
        <p className="invite-form-inputs-header invite-form-inputs-header__single">Enter email address</p>
        {singleInputs.map((input, index) => (
          <div className="input-wrapper-outer" key={index}>
            <div className="input-wrapper">
              <div className="input-wrapper-inner">
                <input
                  className="invite-form-input"
                  onBlur={() => {
                    validateSingleInputChange(index)
                  }}
                  onChange={(e) => {
                    handleSingleInputChange(e.target.value, index)
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") handleEnterPress(index)
                  }}
                  placeholder="name@company.com"
                  ref={(ref) => (singleInputsRefs.current[index] = ref)}
                  value={input.value}
                />
                {emailRegex.test(input.value) && (
                  <div className="input-mark-ok">
                    <img alt="check" src={CheckBlueIcon} />
                  </div>
                )}
              </div>
              <button
                className={`btn btn-hide-invite ${singleInputs.length === 1 ? "hide" : "pl-2"}`}
                disabled={singleInputs.length === 1}
                onClick={() => {
                  handleInputRemove(index)
                }}
                type="button"
              >
                <img alt="delete" src={DeleteIcon} style={{ width: "10px" }} />
              </button>
            </div>
            <div className={`validation-error validation-error__input ${singleInputs.length > 1 ? "slide-right" : ""}`}>
              {!input.validation && "Invalid email address"}
            </div>
          </div>
        ))}
      </div>
      <div className="flex self-start items-center">
        <button
          className="btn btn-link btn-link-invite-form flex items-center"
          data-testid="add-another-user-button"
          onClick={handleInputAdd}
          type="button"
        >
          <img alt="add" src={AddIcon} style={{ width: "10px", margin: "0 5px", padding: "0 0 3px 0" }} />
          Add another
        </button>
        <div className="or">or</div>
        <button
          className="btn btn-link btn-link-invite-form"
          data-testid="add-multiple-users-button"
          onClick={handleSwitchToMulti}
          type="button"
        >
          add many at once
        </button>
      </div>
    </>
  )

  const renderMultipleInput = () => (
    <>
      <p className="invite-form-inputs-header">Enter multiple email addresses separated by commas</p>
      <textarea
        className="invite-form-input invite-form-input__textarea"
        onChange={handleMultiInputChange}
        placeholder="name@company.com, name@company.com, name@company.com"
        rows={5}
        value={multiInput}
      />
      <div className="validation-error">{!multiInputValidation && "Please enter multiple valid email addresses"}</div>
    </>
  )

  const renderError = () => (
    <div className="sc-modal-profile-invite modal-error">
      <ErrorPlaceholder
        tryAgain={() => {
          setError(false)
        }}
      />
    </div>
  )

  if (error) return renderError()
  if (emailsSent) return renderEmailsSent()

  return (
    <div className="modal-profile-invite">
      <p className="modal-profile-invite-header">Invite your SlideCamp team</p>
      <p className="modal-profile-invite-subheader">
        Fill in the email addresses of the people
        <br /> you&apos;d like to invite
      </p>
      <>
        {isMultiple ? renderMultipleInput() : renderSingleInput()}
        <div className="submit-buttons-wrapper">
          <button
            className="btn btn-gray-blue btn-invite-friend-form"
            onClick={isMultiple ? handleSwitchToSingle : closeModal}
            type="button"
          >
            Cancel
          </button>
          <button
            className="btn btn-dark-blue btn-invite-friend-form"
            disabled={loading}
            onClick={handleSubmit}
            type="button"
          >
            {loading ? (
              <span>
                sending
                <span className="saving">
                  <span>. </span>
                  <span>. </span>
                  <span>.</span>
                </span>
              </span>
            ) : (
              "Invite"
            )}
          </button>
        </div>
      </>
    </div>
  )
}
