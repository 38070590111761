import SlidePlaceholder from "@/components/slide/SlidePlaceholder"

const PresentationPlaceholder = () => (
  <div className="flex flex-col aspect-[490/357] border border-sc-line">
    <div className="h-[76%] bg-white">
      <SlidePlaceholder />
    </div>
    <div className="w-full bg-white pres-name p-[28px] py-[6%] flex-1"></div>
  </div>
)

export default PresentationPlaceholder
