import { useMutation } from "@apollo/client"
import { useContext } from "react"
import { InputWrapper } from "../utils/forms"
import { NotificationSuccess, NotificationError } from "../utils/notifications"
import { setData } from "../../graphql/mutations"
import { UserData } from "../../graphql/queries"
import { useForceUpdate } from "../../hooks/ForceUpdate"
import { emailValidator, isRequired } from "../utils/helpers"
import { useForm } from "../../hooks/FormHook"
import { NotificationContext } from "../../hooks/NotificationHook"
import { UserDataContext } from "../../hooks/UserDataHook"

const AccountInfoForm = () => {
  const { open } = useContext(NotificationContext)
  const data = useContext(UserDataContext)
  const { user } = data

  const [_setData, { loading: mutationLoading }] = useMutation(setData)

  const forceUpdate = useForceUpdate()
  const [values, onChange, validations, validate] = useForm([
    {
      name: "firstName",
      validators: [isRequired],
      initialValue: user?.firstName || undefined
    },
    {
      name: "lastName",
      validators: [isRequired],
      initialValue: user?.lastName || undefined
    },
    {
      name: "email",
      validators: [isRequired, emailValidator],
      initialValue: user?.email || undefined
    }
  ])

  const submitForm = () => {
    if (!validate()) {
      forceUpdate()
      return
    }
    _setData({
      variables: values,
      update: (
        proxy,
        {
          data: {
            session: { setData }
          }
        }
      ) => {
        const newData = {
          session: {
            ...data,
            user: {
              ...data.user,
              ...setData
            }
          }
        }
        proxy.writeQuery({ query: UserData, data: newData })
      }
    })
      .then(() => {
        open({
          type: "success",
          duration: 3,
          content: <NotificationSuccess message="Account Information Updated" title="Success" />
        })
      })
      .catch((e) => {
        console.log(e)
        open({
          type: "error",
          duration: 3,
          content: <NotificationError message="Account Information Not Updated" title="Error" />
        })
      })
  }

  const untouched =
    values.firstName === user?.firstName && values.lastName === user?.lastName && values.email === user?.email

  const onKeyDown = (e) => {
    if (e.key === "Enter" && !(mutationLoading || untouched)) {
      submitForm()
    }
  }

  return (
    <form autoComplete="off" onKeyDown={onKeyDown}>
      <h1>Account information</h1>
      <InputWrapper error={validations.firstName} label="First Name">
        <input
          disabled={mutationLoading}
          name="firstName"
          onChange={onChange}
          placeholder="Click here to insert your First Name"
          type="text"
          value={values.firstName}
        />
      </InputWrapper>
      <InputWrapper error={validations.lastName} label="Last Name">
        <input
          disabled={mutationLoading}
          name="lastName"
          onChange={onChange}
          placeholder="Click here to insert your Last Name"
          type="text"
          value={values.lastName}
        />
      </InputWrapper>
      <InputWrapper error={validations.email} label="Email">
        <input
          disabled={mutationLoading}
          name="email"
          onChange={onChange}
          placeholder="Click here to insert your Email"
          type="text"
          value={values.email}
        />
      </InputWrapper>
      <div className="profile-buttons-end">
        <button
          className="btn btn-dark-blue"
          disabled={mutationLoading || untouched}
          onClick={submitForm}
          type="button"
        >
          Update settings
        </button>
      </div>
      <button
        onClick={() => {
          throw new Error("Sentry Test Error")
        }}
        type="button"
      >
        .
      </button>
    </form>
  )
}

export default AccountInfoForm
