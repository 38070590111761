import { useApolloClient, useMutation, gql } from "@apollo/client"
import { useContext, useEffect, useState } from "react"
import { Ellipsis } from "@/components/utils/tooltip"
import { SlideModalContext } from "@/hooks/SlideModalHook"
import SlidePreview from "./SlidePreview"
import InputTagRest from "@/components/restComponent/InputTagRest"
import { UserDataContext } from "@/hooks/UserDataHook"
import { fragments } from "@/graphql/fragments"

const SET_SLIDE_FAVOURITE = gql`
  mutation setSlideFavourite($slideId: String!, $state: Boolean!) {
    setSlideFavourite(slideId: $slideId, state: $state) {
      id
      slideId
      isFavourite
    }
  }
`

const SlideZoom = ({ handleToggleLoad, isLoaded }) => {
  const [isDownloading, setIsDownloading] = useState(false)
  const { user, updateSlidesInCartFunction } = useContext(UserDataContext)
  const { cart } = user
  const { slides, position, changePosition, pId, downloadSlide, updateSlide } = useContext(SlideModalContext)

  const slideData = slides[position]

  const [isAddToCartDisabled, setIsAddToCartDisabled] = useState(
    cart && cart.slides.some((slide) => slide.blueprintId === slideData.slideId)
  )
  const client = useApolloClient()

  const [setSlideFavourite] = useMutation(SET_SLIDE_FAVOURITE, {
    context: { isUsingNewScApi: true }
  })

  useEffect(() => {
    setIsAddToCartDisabled(cart && cart.slides.some((slide) => slide.blueprintId === slideData.id))
  }, [slideData])

  const handlePreviousSlide = () => {
    handleToggleLoad(false)
    changePosition(position - 1)
  }

  const handleNextSlide = () => {
    handleToggleLoad(false)
    changePosition(position + 1)
  }

  const handleDownloadSlide = () => {
    setIsDownloading(true)
    downloadSlide()
    setIsDownloading(false)
  }

  const addSlideToCart = async (e) => {
    e.preventDefault()
    const { slideId, name } = slideData

    setIsAddToCartDisabled(true)
    if (cart && cart.slides.length > 0) {
      const items = Array.from(cart.slides)
      items.push(slideData)

      await updateSlidesInCartFunction(items)
    } else {
      const items = []
      items.push(slideData)

      await updateSlidesInCartFunction(items)
    }
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "scAddSlideToCart",
      addedSlide: { id: slideId, name, source: "slide modal" }
    })
  }

  const handleLike = async () => {
    await setSlideFavourite({
      variables: {
        slideId: slideData.blueprintId,
        state: !slideData.isFavourite
      },
      update: (cache, { data: { setSlideFavourite } }) => {
        if (!setSlideFavourite) {
          return
        }
        const slide = client.readFragment({
          id: slideData.blueprintId,
          fragment: fragments.presentationSlide
        })
        updateSlide({
          isFavourite: setSlideFavourite.isFavourite
        })
        if (!slide || !slide.id) {
          return
        }
        const updatedSlide = {
          ...slide,
          isFavourite: setSlideFavourite.isFavourite
        }
        client.writeFragment({
          id: slideData.blueprintId,
          fragment: fragments.presentationSlide,
          data: updatedSlide
        })
      },
      optimisticResponse: {
        setSlideFavourite: {
          __typename: "SetSlideFavouriteResponse",
          slideId: slideData.slideId,
          id: slideData.id,
          isFavourite: !slideData.isFavourite
        }
      }
    })
  }

  return (
    <div data-testid="slide-preview-modal">
      <div className="modal-slide">
        <SlidePreview handleToggleLoad={handleToggleLoad} isLoaded={isLoaded} slide={slideData} />
      </div>
      <div className="modal-slide-info">
        <h1>
          <Ellipsis length={45} text={slideData.name} tooltip={false} />
        </h1>
        <hr />
        <InputTagRest slide={{ ...slideData, pId }} />
        <div className="prev-modal-buttons">
          <button
            className="btn-lightblue"
            data-testid="btn-add-to-cart"
            disabled={isAddToCartDisabled}
            onClick={addSlideToCart}
          >
            {isAddToCartDisabled ? (
              <span>
                <svg version="1.1" viewBox="0 0 16 15.8" x="0px" y="0px">
                  <path
                    className="add-cart-icon"
                    d="M15.5,0H3.3C3,0,2.8,0.2,2.8,0.5v2H0.5C0.2,2.5,0,2.8,0,3v12.2c0,0.3,0.2,0.5,0.5,0.5h12.2
										c0.3,0,0.5-0.2,0.5-0.5v-2h2.3c0.3,0,0.5-0.2,0.5-0.5V0.5C16,0.2,15.8,0,15.5,0z
										M12.2,14.8H1V3.5h11.2V14.8z M15,12.2h-1.8V3c0-0.3-0.2-0.5-0.5-0.5h-9V1H15V12.2z"
                  />
                  <path
                    className="add-cart-icon"
                    d="M8.8,6.7l-2.7,3.9L4.3,8.7c-0.2-0.2-0.5-0.2-0.7,0c-0.2,0.2-0.2,0.5,0,0.7l2.2,2.3
										c0.1,0.1,0.2,0.1,0.4,0.1c0,0,0,0,0,0c0.1,0,0.3-0.1,0.4-0.2l3.1-4.4C9.8,7,9.8,6.7,9.5,6.6
										C9.3,6.4,9,6.5,8.8,6.7z"
                  />
                </svg>
                <span>In cart</span>
              </span>
            ) : (
              <span>
                <svg version="1.1" viewBox="0 0 16 15.8" x="0px" y="0px">
                  <path
                    className="add-cart-icon"
                    d="M10.1,8.7h-3v-3c0-0.3-0.2-0.5-0.5-0.5S6.1,5.3,6.1,5.6v3h-3c-0.3,0-0.5,0.2-0.5,0.5
												s0.2,0.5,0.5,0.5h3v3c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5v-3h3c0.3,0,0.5-0.2,0.5-0.5S10.4,8.7,10.1,8.7z"
                  />
                  <path
                    className="add-cart-icon"
                    d="M15.5,0H3.3C3,0,2.8,0.2,2.8,0.5v2H0.5C0.2,2.5,0,2.8,0,3v12.2c0,0.3,0.2,0.5,0.5,0.5h12.2
												c0.3,0,0.5-0.2,0.5-0.5v-2h2.3c0.3,0,0.5-0.2,0.5-0.5V0.5C16,0.2,15.8,0,15.5,0z M12.2,14.8H1V3.5h11.2V14.8z M15,12.2h-1.8V3
												c0-0.3-0.2-0.5-0.5-0.5h-9V1H15V12.2z"
                  />
                </svg>
                <span>Add to cart</span>
              </span>
            )}
          </button>
          <button className="btn-dwnl-slide" onClick={handleDownloadSlide}>
            <span>
              {isDownloading ? (
                <svg className="dwnl-icon" version="1.1" viewBox="0 0 24.2 16" x="0px" y="0px">
                  <path
                    d="M19.4,16l-7.9,0l-6.8,0C2.1,16,0,13.9,0,11.2c0-2.4,1.9-4.5,4.3-4.7C5,2.8,8.3,0,12.1,0
											s7.1,2.8,7.8,6.5c2.4,0.3,4.3,2.3,4.3,4.7C24.2,13.9,22.1,16,19.4,16z M11.5,15l7.9,0c2.1,0,3.8-1.7,3.8-3.8c0-2-1.7-3.7-3.7-3.8
											c-0.2,0-0.5-0.2-0.5-0.4c-0.5-3.4-3.5-6-6.9-6C8.7,1,5.7,3.6,5.2,7c0,0.2-0.2,0.4-0.5,0.4c-2,0-3.7,1.7-3.7,3.8
											C1,13.3,2.7,15,4.8,15L11.5,15z"
                  />
                  <path
                    className="arrow"
                    d="M12.1,12.7C12.1,12.7,12.1,12.7,12.1,12.7L12.1,12.7L12.1,12.7c-0.1,0-0.3-0.1-0.3-0.1L8.2,9
											C8.1,8.9,8.1,8.8,8.1,8.7c0-0.1,0-0.3,0.1-0.3c0.2-0.2,0.5-0.2,0.7,0l2.7,2.6V5c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v6l2.7-2.6
											c0.2-0.2,0.5-0.2,0.7,0c0.2,0.2,0.2,0.5,0,0.7l-3.5,3.5c0,0,0,0,0,0C12.4,12.6,12.3,12.6,12.1,12.7C12.2,12.7,12.1,12.7,12.1,12.7z"
                  />
                </svg>
              ) : (
                <svg className="dwnl-icon" version="1.1" viewBox="0 0 24.2 16" x="0px" y="0px">
                  <path
                    d="M19.4,16l-7.9,0l-6.8,0C2.1,16,0,13.9,0,11.2c0-2.4,1.9-4.5,4.3-4.7C5,2.8,8.3,0,12.1,0
											s7.1,2.8,7.8,6.5c2.4,0.3,4.3,2.3,4.3,4.7C24.2,13.9,22.1,16,19.4,16z M11.5,15l7.9,0c2.1,0,3.8-1.7,3.8-3.8c0-2-1.7-3.7-3.7-3.8
											c-0.2,0-0.5-0.2-0.5-0.4c-0.5-3.4-3.5-6-6.9-6C8.7,1,5.7,3.6,5.2,7c0,0.2-0.2,0.4-0.5,0.4c-2,0-3.7,1.7-3.7,3.8
											C1,13.3,2.7,15,4.8,15L11.5,15z"
                  />
                  <path
                    d="M12.1,12.7C12.1,12.7,12.1,12.7,12.1,12.7L12.1,12.7L12.1,12.7c-0.1,0-0.3-0.1-0.3-0.1L8.2,9
											C8.1,8.9,8.1,8.8,8.1,8.7c0-0.1,0-0.3,0.1-0.3c0.2-0.2,0.5-0.2,0.7,0l2.7,2.6V5c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v6l2.7-2.6
											c0.2-0.2,0.5-0.2,0.7,0c0.2,0.2,0.2,0.5,0,0.7l-3.5,3.5c0,0,0,0,0,0C12.4,12.6,12.3,12.6,12.1,12.7C12.2,12.7,12.1,12.7,12.1,12.7z"
                  />
                </svg>
              )}
            </span>
          </button>
          <button
            className={`btn-heart ${slideData.isFavourite ? "heart-beat" : ""}`}
            data-testid="btn-heart-preview"
            onClick={handleLike}
          >
            <span>
              <svg
                className="heart-icon"
                data-testid={`${slideData.isFavourite ? "heart-beat-preview" : "heart-unbeat-preview"}`}
                viewBox="0 0 18 15.4"
              >
                <path
                  d="M2,1.7C2.3,1.3,2.8,1,3.3,0.8C5.5-0.1,8,0.9,9,3c1-2.1,3.5-3.1,5.7-2.2c2.3,0.9,3.4,3.5,2.5,5.8
											c-0.5,1.1-2.4,3.1-4.3,4.8L12.3,12c-1.6,1.5-3.1,2.7-3.3,2.9c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0s0,0,0,0c-0.4-0.3-7.3-6-8.2-8.2
											C0.2,5.1,0.5,3.5,1.4,2.2L2,1.7z"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
      {position !== 0 && slides.length !== 1 && <button className="btn-prev" onClick={handlePreviousSlide} />}
      {position !== slides.length - 1 && <button className="btn-next" onClick={handleNextSlide} />}
    </div>
  )
}

export default SlideZoom
