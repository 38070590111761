import { useMutation } from "@apollo/client"
import { useContext, useState } from "react"
import { InputWrapper } from "../utils/forms"
import { NotificationSuccess, NotificationError } from "../utils/notifications"
import { setPassword } from "../../graphql/mutations"
import { isRequired } from "../utils/helpers"
import { useForm } from "../../hooks/FormHook"
import { useForceUpdate } from "../../hooks/ForceUpdate"
import { NotificationContext } from "../../hooks/NotificationHook"

const ChangePasswordForm = () => {
  const [_setPassword] = useMutation(setPassword)
  const { open } = useContext(NotificationContext)

  const forceUpdate = useForceUpdate()
  const [submitting, setSubmitting] = useState(false)
  const [values, onChange, validations, validate, reset] = useForm([
    {
      name: "currentPassword",
      validators: [isRequired]
    },
    {
      name: "newPassword",
      validators: [isRequired]
    },
    {
      name: "confirmPassword",
      validators: [isRequired, (val) => (values.newPassword === val ? false : "Passwords do not match")]
    }
  ])

  const submitForm = () => {
    if (!validate()) {
      forceUpdate()
      return
    }
    setSubmitting(true)
    return _setPassword({
      variables: { oldPassword: values.currentPassword, newPassword: values.newPassword }
    })
      .then((res) => {
        if (res.data.session.setPassword) {
          open({
            type: "success",
            duration: 3,
            content: <NotificationSuccess message="Password Changed Successfully" title="Success" />
          })
        } else {
          open({
            type: "error",
            duration: 3,
            content: <NotificationError message="Error Changing Password" title="Error" />
          })
        }
        setSubmitting(false)
        reset()
      })
      .catch(() => {
        open({
          type: "error",
          duration: 3,
          content: <NotificationError message="Error Changing Password" title="Error" />
        })
        setSubmitting(false)
        reset()
      })
  }

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      submitForm()
    }
  }

  return (
    <form onKeyDown={onKeyDown}>
      <h1>Change Password</h1>
      <InputWrapper error={validations.currentPassword} label="Current Password">
        <input
          disabled={submitting}
          name="currentPassword"
          onChange={onChange}
          placeholder="Click here to insert your Current Password"
          type="password"
          value={values.currentPassword}
        />
      </InputWrapper>
      <InputWrapper error={validations.newPassword} label="New Password">
        <input
          disabled={submitting}
          name="newPassword"
          onChange={onChange}
          placeholder="Click here to insert your New Password"
          type="password"
          value={values.newPassword}
        />
      </InputWrapper>
      <InputWrapper error={validations.confirmPassword} label="Confirm New Password">
        <input
          disabled={submitting}
          name="confirmPassword"
          onChange={onChange}
          placeholder="Click here to confirm your New Password"
          type="password"
          value={values.confirmPassword}
        />
      </InputWrapper>
      <div className="profile-buttons-end">
        <button className="btn btn-dark-blue" disabled={submitting} onClick={submitForm} type="button">
          Change Password
        </button>
      </div>
    </form>
  )
}

export default ChangePasswordForm
