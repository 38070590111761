import { useMutation } from "@apollo/client"
import { useState } from "react"
import { deleteUser } from "../../graphql/mutations"
import { useForm } from "../../hooks/FormHook"
import { isRequired } from "../utils/helpers"
import { useForceUpdate } from "../../hooks/ForceUpdate"
import { InputWrapper } from "../utils/forms"

const ErrorDisplayer = ({ status }) =>
  status === "ok" ? (
    <div className="alert alert-success">User deleted</div>
  ) : status === "error" ? (
    <div className="alert alert-danger">User not deleted. Please check if email or id exists.</div>
  ) : null

const DeleteUser = () => {
  const [responseStatus, setResponseStatus] = useState(null)
  const [_deleteUser, { loading }] = useMutation(deleteUser)

  const forceUpdate = useForceUpdate()
  const [values, onChange, validations, validate] = useForm([
    {
      name: "userEmailOrId",
      validators: [isRequired]
    }
  ])

  const submitForm = () => {
    if (!validate()) {
      forceUpdate()
      return
    }
    setResponseStatus(null)
    _deleteUser({ variables: { userEmailOrId: values.userEmailOrId } }).then((res) =>
      setResponseStatus(res.data.admin.deleteUser ? "ok" : "error")
    )
  }

  return (
    <div className="delete-user-wrapper">
      <form>
        <div className="row">
          <ErrorDisplayer status={responseStatus} />
          <div>
            <InputWrapper error={validations.userEmailOrId} label="Type user email or id">
              <input
                className="delete-user-input"
                name="userEmailOrId"
                onChange={onChange}
                placeholder="User email or id"
                type="text"
                value={values.userEmailOrId}
              />
            </InputWrapper>
          </div>
        </div>
        <div className="row">
          <div className="admin-button-submit">
            <button className="btn btn-dark-blue" disabled={loading} onClick={submitForm} type="button">
              Delete user
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default DeleteUser
