import { gql } from "@apollo/client"

export const uploadStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 1)"
  },
  content: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: 0,
    background: "#fff",
    overflow: "hidden",
    WebkitOverflowScrolling: "touch",
    borderRadius: 0,
    outline: "none",
    padding: 0
  }
}

export const GET_UPLOAD_FILE_URL = gql`
  mutation {
    getUploadFileUrl {
      code
      success
      message
      url
      token
    }
  }
`
