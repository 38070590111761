import LogEntry from "./LogEntry"

const InviteMultipleUsersToTeamLog = ({ record, index, activeAccordionId, setActiveAccordionId }) => {
  const recordTime = new Date(record.createdAt).getTime()
  const isInvitedMore = record.invitedUsers.length > 3

  const headerContent = (
    <>
      <div className="text-left flex flex-col flex-grow overflow-hidden">
        <p className="text-sm" data-testid="activity-log-entry">
          <strong>{`${record?.user?.firstName} ${record?.user?.lastName}`}</strong> invited
          <strong> {record.invitedUsers[0]} </strong>
          {record.invitedUsers[1] && <strong>, {record.invitedUsers[1]} </strong>}
          {record.invitedUsers[2] && <strong>, {record.invitedUsers[2]} </strong>}
          {record.invitedUsers[3] && (
            <>
              and <strong>{record.invitedUsers.length - 3} more </strong>
            </>
          )}
          to SlideCamp
        </p>
      </div>
    </>
  )

  const detailsContent = isInvitedMore && (
    <div className="bg-[#f6f7f8] border">
      <div className="p-8 pl-20 pt-4">
        {record.invitedUsers[3] && (
          <ul className={`columns-3 gap-[120px] p-0 ${record.invitedUsers.length < 6 ? "one-column" : ""}`}>
            {record.invitedUsers.slice(3).map((email) => (
              <li
                className="text-[#0f2642] text-[12px] max-w-[190px] overflow-hidden py-[2px] truncate"
                key={`${email}-${Math.random()}`}
              >
                {email}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )

  return (
    <LogEntry
      activeAccordionId={activeAccordionId}
      detailsContent={detailsContent}
      headerContent={headerContent}
      iconName="log-invite"
      index={index}
      isMoreContentAvailable={isInvitedMore}
      recordTime={recordTime}
      setActiveAccordionId={setActiveAccordionId}
    />
  )
}

export default InviteMultipleUsersToTeamLog
