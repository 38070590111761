import { NotActiveAccountSvg } from "../../svg/NotActiveAccountSvg"

export const InactiveAccount = () => (
  <div className="not-active-container">
    <NotActiveAccountSvg />
    <h1>YOUR ACCOUNT IS NOT ACTIVE</h1>
    <p>
      Please contact your SlideCamp team owner <br />
      about your subscription details.
    </p>
  </div>
)
