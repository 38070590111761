//these utility functions below were implemented to remove huge 'moment' lib

const _MS_PER_DAY = 1000 * 60 * 60 * 24
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

const monthFullNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
]

export const formatDateForLogs = (date) =>
  `${monthFullNames[new Date(date).getMonth()]} ${new Date(date).getDate()}, ${new Date(date).getFullYear()}`

export const timeAgo = (date) =>
  Math.floor(date / 86400000) === Math.floor(new Date().getTime() / 86400000)
    ? Math.floor((new Date().getTime() - date) / 3600000) <= 0
      ? Math.floor((new Date().getTime() - date) / 60000) <= 1
        ? "Just now"
        : `${Math.floor((new Date().getTime() - date) / 60000)} minutes ago`
      : Math.floor((new Date().getTime() - date) / 3600000) === 1
      ? "An hour ago"
      : `${Math.floor((new Date().getTime() - date) / 3600000)} hours ago`
    : `${new Date(date).getHours() === 0 ? 12 : new Date(date).getHours() % 12}:${new Date(date).getMinutes()}
	${new Date(date).getHours() > 11 ? "PM" : "AM"}`

export const dateDiffInDays = (a, b) => {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

  return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}

export const dateToLLFormat = (date) => {
  const formattedDate = new Date(date)

  return formattedDate.toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })
}

const formatTime = (date) => {
  const hours = date.getHours()
  return `${hours % 12 === 0 ? 12 : hours % 12}:${date.getMinutes()}${hours < 12 ? "AM" : "PM"}`
}

export const formatDate = (timestamp) => {
  const date = new Date(Number(timestamp))
  return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()} at ${formatTime(date)}`
}

export const convertSecondsToTime = (totalSeconds) => {
  let minutes = Math.floor(totalSeconds / 60)
  let hours = Math.floor(minutes / 60)
  let seconds = Math.round((totalSeconds % 60) * 100) / 100

  minutes = minutes % 60
  hours = hours % 24

  const format = []

  if (hours > 0) {
    format.push(`${hours}h`)
  }
  if (minutes > 0) {
    hours > 0 && format.push(" ")
    format.push(`${minutes}m`)
  }
  if (seconds > 0) {
    minutes > 0 && format.push(" ")
    format.push(`${seconds}s`)
  }

  if (!format.length) {
    format.push("0s")
  }

  return format.join("")
}
