import { useApolloClient, useMutation } from "@apollo/client"
import { Fragment, useContext, useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import { acceptOrganizationMemberInvitation } from "@/graphql/mutations"
import { checkOrganizationOwnerInvitationStatus } from "@/graphql/queries"
import { AuthContext } from "@/hooks/AuthHook"
import AcceptInvitationForm from "./AcceptInvitationForm"

const AcceptInvitationPage = ({ match, history }) => {
  const [registerForm, setRegisterForm] = useState(false)
  const [notAllowed, setNotAllowed] = useState(false)
  const [email, setEmail] = useState("")
  const { logout } = useContext(AuthContext)
  const client = useApolloClient()

  const [_acceptOrganizationMemberInvitation] = useMutation(acceptOrganizationMemberInvitation, {
    context: { isUsingNewScApi: true }
  })

  useEffect(() => {
    const variables = { invitationId: match.params.memberId, token: match.params.ott }
    logout()
    if (match.path === "/accept_invitation/:memberId/:ott") {
      _acceptOrganizationMemberInvitation({ variables }).then(({ data }) => {
        if (data.acceptOrganizationMemberInvitation.status === "registrationRequired") {
          setRegisterForm(true)
          setEmail(data.acceptOrganizationMemberInvitation.email)
        } else if (data.acceptOrganizationMemberInvitation.status === "notAllowed") {
          setNotAllowed(true)
        } else {
          history.push("/users/sign_in")
        }
      })
    } else {
      client
        .query({
          query: checkOrganizationOwnerInvitationStatus,
          variables: { token: variables.invitationId },
          context: { isUsingNewScApi: true }
        })
        .then(({ data }) => {
          if (data.checkOrganizationOwnerInvitationStatus.status === "pending") {
            setRegisterForm(true)
            setEmail(data.checkOrganizationOwnerInvitationStatus.email)
          } else {
            history.push("/users/sign_in")
          }
        })
    }
  }, [])

  const type = match.path === "/accept_invitation/:memberId/:ott" ? "team-members" : "invitations"
  return (
    <div className="sc-auth">
      {registerForm && email && (
        <Fragment>
          <AcceptInvitationForm
            initialValues={{ email }}
            submitParams={{ member: match.params.memberId, ott: match.params.ott }}
            type={type}
          />
          <div className="sc-auth-opinion">
            <svg
              style={{ enableBackground: "new 0 0 26.5 21.7" }}
              version="1.1"
              viewBox="0 0 26.5 21.7"
              x="0px"
              y="0px"
            >
              <path
                d="M10.3,11.5H5.4c-0.2,0-0.4-0.2-0.4-0.4c0-1,0.2-1.9,0.6-2.9c0.9-1.9,2.5-3.3,5-4.2
                  c0.2-0.1,0.3-0.2,0.3-0.4V0.4c0-0.3-0.3-0.5-0.5-0.4C7,0.7,4.5,2.2,2.8,4.3
                  C0.9,6.6,0,9.6,0,13.5v7.7c0,0.2,0.2,0.4,0.4,0.4h10.2
                  c0.2,0,0.4-0.2,0.4-0.4v-9.3C11,11.7,10.9,11.5,10.3,11.5z"
                style={{ fill: "#FFFFFF" }}
              />
              <path
                d="M25.2,11.5h-4.4c-0.2,0-0.4-0.2-0.4-0.4c0-0.9,0.2-1.8,0.6-2.8c0.9-2,2.5-3.4,5-4.2
                  c0.2-0.1,0.3-0.2,0.3-0.4V0.5C26.3,0.2,26,0,25.7,0c-3.2,0.7-5.7,2.2-7.5,4.3
                  c-1.9,2.2-2.8,5.3-2.8,9.2v7.7c0,0.2,0.2,0.4,0.4,0.4
                  H26c0.2,0,0.4-0.2,0.4-0.4v-8.9C26.5,11.9,26.1,11.5,25.2,11.5z"
                style={{ fill: "#FFFFFF" }}
              />
            </svg>
            <h2>
              "SlideCamp is boosting <br />
              the whole team's productivity. <br />
              An awesome concept, <br />
              start to finish."
            </h2>
            <p>Martin Zielinski</p>
            <p>COO of Naonhome</p>
          </div>
        </Fragment>
      )}
      {notAllowed && (
        <div className="viewer-error">
          <div className="svg-poof" />
          <h2>YOUR INVITATION HAS BEEN DISABLED</h2>
          <p>Please contact your SlideCamp administrator.</p>
        </div>
      )}
    </div>
  )
}

export default withRouter(AcceptInvitationPage)
