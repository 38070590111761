import React, { useEffect, useState } from "react"

const DialogBox = () => {
  const [updatedSlides, setUpdatedSlides] = useState([])

  const handleUpdatedSlides = (messageEvent) => {
    setUpdatedSlides(JSON.parse(messageEvent.message))
  }

  useEffect(() => {
    Office.context.ui.messageParent(JSON.stringify({ dialogReady: true }))
    Office.context.ui.addHandlerAsync(Office.EventType.DialogParentMessageReceived, handleUpdatedSlides)
    return () => {
      Office.context.document.removeHandlerAsync(Office.EventType.DocumentSelectionChanged, {
        handler: handleUpdatedSlides
      })
    }
  }, [])

  return (
    <div className="p-[20px]">
      <h2 className="text-[9px] text-[#717171] uppercase mb-[15px]">Updated Slides</h2>
      <div className="flex flex-col gap-[15px]">
        {updatedSlides.map((slide) => {
          const imageSource = slide.thumbUrl.replace("{width}", 208)
          const imageSourceSet = slide.thumbUrl.replace("{width}", 416)

          return (
            <div
              className={
                "text-[12px] grid mobile-sm:grid-cols-1 w-full gap-[10px] tablet-md:grid-cols-2 desktop-lg:grid-cols-3 desktop-2xl:grid-cols-4"
              }
              key={slide.id}
            >
              <picture className="shadow-[0_0_12px_1px_#7a7f8733]">
                <img
                  alt={slide.name}
                  className={"w-full h-full object-cover aspect-[427/240.5] rounded-[7px]"}
                  data-testid="slide-thumbnail"
                  src={imageSource}
                  srcSet={imageSourceSet}
                />
              </picture>
              <div className="flex flex-col gap-[4px]">
                <span>Slide N {slide.index + 1}</span>
                <span>
                  Last updated:{" "}
                  {new Date(slide.updatedAt).toLocaleDateString("en-US", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric"
                  })}
                </span>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default DialogBox
