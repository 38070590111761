import { useContext, useEffect, useRef } from "react"
import CartItem from "./CartItem"
import { UserDataContext } from "@/hooks/UserDataHook"

interface CartItemsListProps {
  setCartError(error: boolean): void
}

const CartItemsList = ({ setCartError }: CartItemsListProps) => {
  const {
    user: { cart }
  } = useContext(UserDataContext)

  const prevCartSlides = useRef(cart?.slides || [])

  useEffect(() => {
    if (JSON.stringify(prevCartSlides.current) !== JSON.stringify(cart?.slides || [])) {
      setCartError(false)
    }
  }, [prevCartSlides.current, cart])

  return (
    <ul className="list-slides">
      {cart &&
        cart.slides.map((slide, index) => (
          <CartItem key={`${slide.slideId}-${index}`} position={index + 1} slide={slide} />
        ))}
    </ul>
  )
}

export default CartItemsList
