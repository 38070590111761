import { useContext, useEffect } from "react"
import { gql, useMutation, useQuery } from "@apollo/client"
import { ShareUploadData } from "@/graphql/queries"
import { useForm } from "@/hooks/FormHook"
import { isRequired } from "@/components/utils/helpers"
import { useForceUpdate } from "@/hooks/ForceUpdate"
import { TagWrapper } from "@/components/utils/forms"
import { UserDataContext } from "@/hooks/UserDataHook"
import { Unit } from "@/graphql/types/queries/units"
// import UnitsAndTeamMembersInput from "@/components/modals/share/UnitsAndTeamMembersInput"

const UPDATE_PRESENTATION = gql`
  mutation updatePresentation($id: String!, $name: String!, $labels: [String!]!) {
    updatePresentation(id: $id, name: $name, labels: $labels) {
      code
      success
      message
      presentation {
        _id
        id
        thumbUrl
        name
        batchId
        mode
        icon
        isFavourite
        sharedPresentationLinks {
          isActive
          token
          isDownloadable
          _id
        }
        urlToken
        labels
        accessibleByUsers {
          _id
        }
        slides {
          id
          slideId
          blueprintId
          name
          tags
          downloadUrl
          linksDataHeight
          linksDataWidth
          state
          isFavourite
        }
        category {
          _id
          parent {
            _id
          }
        }
      }
    }
  }
`
interface PresentationFormProps {
  id: string
  initialValues: {
    name: string
    labels: string[]
  }
  upload?: boolean
  closeModal(): void
}

// const setShareSettings = gql`
//   mutation setBatchSharing(
//     $batchId: String!
//     $data: BatchUpdateInput!
//     $addedUnitIds: [String!] = []
//     $removedUnitIds: [String!] = []
//     $addedMemberIds: [String!] = []
//     $removedMemberIds: [String!] = []
//   ) {
//     session {
//       addedBatchSharing: setBatchSharing(
//         unitIds: $addedUnitIds
//         memberIds: $addedMemberIds
//         batchId: $batchId
//         state: true
//       )
//       removedBatchSharing: setBatchSharing(
//         unitIds: $removedUnitIds
//         memberIds: $removedMemberIds
//         batchId: $batchId
//         state: false
//       )
//       shareExternally: updateBatch(batchId: $batchId, data: $data) {
//         id
//         batchId
//         shareWithLink
//         sharedForMembers {
//           id
//           memberId
//           firstName
//           lastName
//           accepted
//           email
//         }
//         sharedForUnits {
//           id
//           unitId
//           name
//         }
//       }
//     }
//   }
// `

const PresentationForm = ({ id, closeModal, initialValues, upload = false }: PresentationFormProps) => {
  useEffect(() => {
    // import.meta.env.DEV && console.log("♻️ PresentationForm")
  })

  const forceUpdate = useForceUpdate()

  const { data, loading } = useQuery(ShareUploadData, { fetchPolicy: "network-only" })
  const [_updatePresentation, { loading: updatePresentationLoading }] = useMutation(UPDATE_PRESENTATION, {
    context: { isUsingNewScApi: true }
  })
  // const [_setShareSettings, { loading: setShareSettingsLoading }] = useMutation(setShareSettings)

  const { user } = useContext(UserDataContext)
  const { teamOwner: isTeamOwner } = user

  const unitAll = isTeamOwner ? data?.session.user.team.units.find((entity: Unit) => entity.unitId === "all") : null
  const initialEntities = unitAll ? [{ ...unitAll, label: unitAll.name, value: unitAll.unitId }] : []

  const [values, onChange, , validate] = useForm([
    {
      name: "name",
      validators: [isRequired],
      initialValue: initialValues.name
    },
    {
      name: "labels",
      initialValue: initialValues.labels
    },
    {
      name: "entities",
      initialValue: initialEntities
    }
  ])

  if (loading) {
    return null
  }

  const onSubmit = () => {
    if (!validate()) {
      forceUpdate()
      return
    }
    const { name, labels } = values

    if (!isTeamOwner) {
      return _updatePresentation({ variables: { id, name, labels } }).then(() => {
        closeModal()
      })
    }

    Promise.all([
      _updatePresentation({ variables: { id, name, labels } })
      // ...(isTeamOwner && upload
      //   ? [
      //       _setShareSettings({
      //         variables: {
      //           batchId: id,
      //           data: {
      //             shareWithLink: false
      //           },
      //           addedMemberIds: addedAndRemovedIds?.addedMemberIds,
      //           addedUnitIds: addedAndRemovedIds?.addedUnitIds
      //         }
      //       })
      //     ]
      //   : [])
    ]).then(() => {
      closeModal()
    })
  }

  return (
    <div className="sc-adding-slides-wrap">
      <div className="sc-adding-slides-container">
        <button className="sc-adding-close" onClick={closeModal}>
          <svg version="1.1" viewBox="0 0 14 14" x="0px" y="0px">
            <path
              d="M13.9,13.1c0.2,0.2,0.2,0.5,0,0.7C13.8,14,13.6,14,13.5,14s-0.3,0-0.4-0.1L7,7.7l-6.1,6.1
              C0.8,14,0.6,14,0.5,14s-0.3,0-0.4-0.1c-0.2-0.2-0.2-0.5,0-0.7L6.3,7L0.1,0.9C0,0.7,0,0.3,0.1,0.1s0.5-0.2,0.7,0L7,6.3l6.1-6.1
              c0.2-0.2,0.5-0.2,0.7,0s0.2,0.5,0,0.7L7.7,7L13.9,13.1z"
              id="XMLID_1_"
              style={{ fill: "#98A2A5" }}
            />
          </svg>
        </button>
        <h1>Name and tag your presentation</h1>
        <div className="sc-adding-slides">
          <form>
            <div className="sc-uploading-desc">
              <div className="upload-name-tags">
                <label>Name</label>
                <input name="name" onChange={onChange} placeholder="Name" type="text" value={values.name} />
                {/*{isTeamOwner && upload && (*/}
                {/*  <div className="w-full max-w-md mb-3">*/}
                {/*    <label htmlFor="teamMembersAndUnits">Share with:</label>*/}
                {/*    <UnitsAndTeamMembersInput*/}
                {/*      formatOptionLabel={formatOptionLabel}*/}
                {/*      handleOptionChange={handleOptionChange}*/}
                {/*      options={[...options.units, ...options.members]}*/}
                {/*      selectedOptions={selectedOptions}*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*)}*/}
                <label>Labels (max 5)</label>
                <TagWrapper
                  name="labels"
                  onChange={onChange}
                  placeholder="Add labels, if you'd like"
                  value={values.labels}
                />
              </div>
            </div>
            <div className="upload-next-wrapper">
              <button
                className="btn btn-lightblue"
                disabled={updatePresentationLoading || !values.name.trim()} // || setShareSettingsLoading
                onClick={onSubmit}
                type="button"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default PresentationForm
