import { useCallback, useState, useEffect, useContext } from "react"
import { useQuery, gql } from "@apollo/client"

import { ReactComponent as MemberSVG } from "@/svg2/member.svg"
import { ReactComponent as TeamSVG } from "@/svg2/team.svg"
import { UserDataContext } from "@/hooks/UserDataHook"
import { Batch } from "@/graphql/types/queries"

const ORGANIZATION_USERS_AND_UNITS_DATA = gql`
  query organization($_id: String!) {
    organization(_id: $_id) {
      users {
        _id
        firstName
        lastName
      }
      units {
        _id
        name
      }
    }
  }
`

interface ISelectOption {
  type: string
  value: string
  label: string
}

const createOptionsObj = (data) => {
  if (!data.organization) return { units: [], members: [] }

  return {
    // teams normalized to option format + sorted by name/label
    units: data.organization.units
      .map((unit) => ({
        value: unit._id,
        label: unit.name,
        type: "team"
      }))
      .sort((a, b) => (a.label < b.label ? 1 : -1)),

    // members normalized to option format + sorted by name/label
    members: data.organization.users
      .map((member) => ({
        value: member._id,
        label: `${member.firstName} ${member.lastName}`,
        type: "member"
      }))
      .sort((a, b) => (a.label < b.label ? 1 : -1))
  }
}

// Define the icon components for each option type
const iconComponents = {
  team: TeamSVG,
  member: MemberSVG
}

// Create a custom label component with an icon and label
const formatOptionLabel = ({ label, type }: Omit<ISelectOption, "value">) => {
  const Icon = iconComponents[type]

  return (
    <div>
      {Icon && <Icon className="pb-0.5 h-4" />}
      <span className="pl-1">{label}</span>
    </div>
  )
}

const useUnitsAndTeamMembersInputLogic = (batch: Batch) => {
  const { user } = useContext(UserDataContext)
  const { group } = user
  // original information from database
  const [originalOptions, setOriginalOptions] = useState<ISelectOption[]>([])
  // currently selected options from dropdown
  const [selectedOptions, setSelectedOptions] = useState<ISelectOption[]>([])
  // original information from database
  const [options, setOptions] = useState({ units: [], members: [] })

  // added and removed ids
  const [addedAndRemovedIds, setAddedAndRemovedIds] = useState<{
    addedUserIds: string[]
    addedUnitIds: string[]
    removedUserIds: string[]
    removedUnitIds: string[]
  }>()

  // Fetch units and team members
  if ((batch && !batch.category) || (user.role !== "owner" && user.role !== "admin")) {
    return {
      options,
      selectedOptions,
      handleOptionChange: () => {},
      formatOptionLabel,
      addedAndRemovedIds
    }
  }

  const {
    data: unitsAndMembersData,
    loading: loadingUnitsAndMembersData,
    error: errorUnitsAndMembersData
  } = useQuery(ORGANIZATION_USERS_AND_UNITS_DATA, {
    variables: { _id: group._id },
    context: { isUsingNewScApi: true }
  })

  // Create options object from units and team members
  useEffect(() => {
    if (unitsAndMembersData) {
      setOptions(createOptionsObj(unitsAndMembersData))
    }
  }, [unitsAndMembersData])

  // Calculate added and removed ids
  useEffect(() => {
    const addedOptions: ISelectOption[] = selectedOptions.filter(
      (option) => !originalOptions.some((original) => original.value === option.value)
    )
    const removedOptions: ISelectOption[] = originalOptions.filter(
      (option) => !selectedOptions.some((selected) => selected.value === option.value)
    )

    setAddedAndRemovedIds({
      addedUserIds: addedOptions.filter((item) => item.type === "member").map((item) => item.value),
      addedUnitIds: addedOptions
        .filter((item: ISelectOption) => item.type === "team" && item.value !== "all")
        .map((item) => item.value),
      removedUserIds: removedOptions.filter((item) => item.type === "member").map((item) => item.value),
      removedUnitIds: removedOptions
        .filter((item) => item.type === "team" && item.value !== "all")
        .map((item) => item.value)
    })
  }, [selectedOptions])

  // Create options for selected elements
  useEffect(() => {
    if (batch && batch.accessibleByUsers && batch.accessibleByUnits) {
      const sharedWith = [
        ...batch.accessibleByUsers.map((user) => ({
          value: user._id,
          label: `${user.firstName} ${user.lastName}`,
          type: "member"
        })),
        ...batch.accessibleByUnits.map((unit) => ({
          value: unit._id,
          label: unit.name,
          type: "team"
        })),
        ...(batch.accessibleByAll ? [{ value: "all", label: "all", type: "team" }] : [])
      ]

      setOriginalOptions(sharedWith)
      setSelectedOptions(sharedWith)
    }
  }, [batch])

  // Option change function using useCallback
  const handleOptionChange = useCallback((selectedOptions: ISelectOption[]) => {
    setSelectedOptions(selectedOptions)
  }, [])

  // Combine loading and error states
  const loading = loadingUnitsAndMembersData
  const error = errorUnitsAndMembersData

  // Return necessary data and functions
  return {
    loading,
    error,
    options,
    selectedOptions,
    handleOptionChange,
    formatOptionLabel,
    addedAndRemovedIds
  }
}

export default useUnitsAndTeamMembersInputLogic
