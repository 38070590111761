import { useState } from "react"

export const useForm = (fields) => {
  const initialValues = fields.reduce((acc, cur) => Object.assign({ [cur.name]: cur.initialValue || "" }, acc), {})
  const initialValidations = fields.reduce((acc, cur) => Object.assign({ [cur.name]: false }, acc), {})

  const [values, setValues] = useState(initialValues)
  const [validations, setValidations] = useState(initialValidations)

  const validate = () => {
    const _validations = initialValidations
    for (let field of fields) {
      if (field.validators) {
        for (let validator of field.validators) {
          const validationRes = validator(values[field.name])
          if (validationRes) {
            _validations[field.name] = validationRes
            break
          }
        }
      }
    }
    setValidations(_validations)
    return !Object.keys(_validations).reduce((acc, cur) => acc || _validations[cur], false)
  }

  const onChange = (e) => {
    e.persist()
    setValues((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))
    setValidations((prevState) => ({ ...prevState, [e.target.name]: false }))
  }

  const reset = () => {
    setValues(initialValues)
    setValidations(initialValidations)
  }

  return [values, onChange, validations, validate, reset]
}
