import { ChangeEvent, FormEvent, useContext, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { UserDataContext } from "../../../hooks/UserDataHook"
import getBackgroundClassName from "./getBackgroundClassName"

import SearchLoupe from "../../../assets/img/icons/search-loupe.png"

export const DashboardTop = () => {
  const { user } = useContext(UserDataContext)
  const { push } = useHistory()
  const [query, setQuery] = useState<string>("")

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
  }

  const handleSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    push(`/search?utf8=✓&query=${query}`)
  }

  const { group } = user

  return (
    <div className={getBackgroundClassName(group.groupId)} id="dashboardTop">
      <div className="thumbnail-list">
        <h1>Pick the slides for&nbsp;your next&nbsp;presentation</h1>
        <div id="search-box">
          <form className="search-center" onSubmit={handleSearch}>
            <input
              className="search-input text-slate-500"
              name="search"
              onChange={handleInputChange}
              placeholder="Type your search here"
              required
              type="text"
              value={query}
            />
            <button className="btn search-btn" type="submit">
              <img alt="Search loupe" src={SearchLoupe} srcSet={SearchLoupe} />
            </button>
          </form>
        </div>
        {group.searchTags && group.searchTags.length > 0 ? (
          <p>
            Try quick searches:&nbsp;
            {group.searchTags.map((tag: string, i: number) => (
              <span key={i}>
                <Link to={`/search?utf8=✓&query=${tag}`}>{tag}</Link>
                {i !== group.searchTags.length - 1 ? ", " : null}
              </span>
            ))}
          </p>
        ) : null}
      </div>
    </div>
  )
}

export default DashboardTop
