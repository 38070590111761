import { Slide } from "@/graphql/types/queries"

interface SlidePreviewProps {
  slide: Slide
  isLoaded: boolean
  handleToggleLoad(val: boolean): void
}

const SlidePreview = ({ slide, isLoaded, handleToggleLoad }: SlidePreviewProps) => (
  <div className="thumb-preview">
    <picture>
      <img
        alt="preview"
        onLoad={handleToggleLoad.bind(this, true)}
        src={slide.thumbUrl.replace("{width}", "463")}
        srcSet={`${slide.thumbUrl.replace("{width}", "927")} 2x`}
        style={{ display: "block", margin: "0 auto", opacity: isLoaded ? "1" : "0" }}
      />
    </picture>
    {!isLoaded && <div className="loading" />}
  </div>
)

export default SlidePreview
