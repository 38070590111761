/* eslint-disable react/no-unknown-property */

export const NotFoundSvg = () => (
  <svg id="Layer_1" version="1.1" viewBox="0 0 536.1 200.3" x="0px" y="0px">
    <g>
      <g>
        <g>
          <path
            d="M464.5,142.9c7.5,0,13.6-6,13.6-13.5h0v-16.6c0-8.5,5.7-15.7,13.5-17.9l-0.1,0c2.5-0.9,1.5-7.1,1.5-7.1
                        c4.2,1.7,3.8,6.3,3.8,6.3c10.3,0,18.6,8.3,18.6,18.6v17c0,17.7-14.4,32.1-32.1,32.1h0c-16.9,0-30.7-13-32-29.6
                        C452.6,138.4,458,142.9,464.5,142.9z"
            fill={"#FFFFFF"}
          />
          <path
            d="M483.2,162.9c-17.2,0-31.7-13.4-33-30.5c0-0.5,0.3-1,0.8-1.1c0.5-0.1,1,0.3,1.1,0.8
                        c1.3,5.8,6.4,9.8,12.3,9.8c6.9,0,12.6-5.6,12.6-12.5c0,0,0,0,0-0.1v-16.5c0-8.6,5.7-16.3,13.9-18.8c0,0,0.1,0,0.1,0
                        c1.1-0.4,1.2-3.7,0.8-5.9c-0.1-0.4,0.1-0.7,0.4-1c0.3-0.2,0.7-0.3,1-0.1c3.2,1.3,4.2,4.2,4.4,6.3c10.4,0.5,18.6,9.1,18.6,19.6v17
                        C516.3,148.1,501.5,162.9,483.2,162.9z M453.5,139c4,12.7,15.9,22,29.7,22c17.2,0,31.1-14,31.1-31.1v-17
                        c0-9.7-7.9-17.6-17.6-17.6c-0.3,0-0.5-0.1-0.7-0.3s-0.3-0.5-0.3-0.8c0-0.1,0.2-2.6-1.6-4.3c0.1,2-0.1,4.8-1.9,5.9
                        c-0.1,0.1-0.2,0.2-0.4,0.2c-7.5,2.2-12.8,9.1-12.8,16.9v16.6c0,0,0,0.1,0,0.1c-0.1,8-6.7,14.4-14.6,14.4
                        C460.1,143.9,456.2,142,453.5,139z"
            fill={"#829FC0"}
          />
        </g>
        <g>
          <g>
            <circle cx="509.1" cy="115.3" fill={"#FFFFFF"} r="2.3" />
            <path
              d="M509.1,118.5c-1.8,0-3.3-1.5-3.3-3.3s1.5-3.3,3.3-3.3s3.3,1.5,3.3,3.3S510.9,118.5,509.1,118.5z M509.1,114
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3S509.8,114,509.1,114z"
              fill={"#829FC0"}
            />
          </g>
          <g>
            <circle cx="490.5" cy="115.3" fill={"#FFFFFF"} r="2.3" />
            <path
              d="M490.5,118.5c-1.8,0-3.3-1.5-3.3-3.3s1.5-3.3,3.3-3.3s3.3,1.5,3.3,3.3S492.2,118.5,490.5,118.5z M490.5,114
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3S491.1,114,490.5,114z"
              fill={"#829FC0"}
            />
          </g>
        </g>
        <path
          d="M486.9,125.2h4.5c1.2,0,2.1-0.9,2.1-2.1l0,0c0-1.2-0.9-2.1-2.1-2.1h-4.5c-1.2,0-2.1,0.9-2.1,2.1l0,0
                    C484.9,124.2,485.8,125.2,486.9,125.2z"
          fill={"#829FC0"}
          opacity={"0.2"}
        />
        <path
          d="M510.5,125.2h4.5c1.2,0,2.1-0.9,2.1-2.1l0,0c0-1.2-0.9-2.1-2.1-2.1h-4.5c-1.2,0-2.1,0.9-2.1,2.1l0,0
                    C508.4,124.2,509.3,125.2,510.5,125.2z"
          fill={"#829FC0"}
          opacity={"0.2"}
        />
      </g>
      <path
        d="M488.1,140.6c0-5.6-4.3-10.2-10-10.4c-0.6,7-6.4,12.5-13.6,12.5c-6.5,0-11.9-4.5-13.3-10.6
                c0.8,10.5,6.7,19.6,15.3,24.9v-6.1h11.1C483.6,150.9,488.1,146.4,488.1,140.6z"
        fill={"#829FC0"}
        opacity={"0.2"}
      />
      <g>
        <g>
          <path
            d="M119.9,87.6c-7.5,0-13.6-6-13.6-13.5h0V57.5c0-8.5-5.7-15.7-13.5-17.9l0.1,0c-2.5-0.9-1.5-7.1-1.5-7.1
                        c-4.2,1.7-3.8,6.3-3.8,6.3C77.4,38.8,69,47.2,69,57.5v17c0,17.7,14.4,32.1,32.1,32.1h0c16.9,0,30.7-13,32-29.6
                        C131.8,83,126.4,87.6,119.9,87.6z"
            fill={"#FFFFFF"}
          />
          <path
            d="M101.1,107.6C82.9,107.6,68,92.7,68,74.4v-17c0-10.5,8.3-19.1,18.6-19.6c0.2-2,1.2-4.9,4.4-6.3
                        c0.3-0.1,0.7-0.1,1,0.1c0.3,0.2,0.4,0.6,0.4,1c-0.4,2.2-0.3,5.5,0.8,5.9c0,0,0.1,0,0.1,0c8.2,2.5,13.9,10.2,13.9,18.8V74
                        c0,0,0,0,0,0.1c0.1,6.9,5.7,12.5,12.6,12.5c5.9,0,11-4,12.3-9.8c0.1-0.5,0.6-0.8,1.1-0.8c0.5,0.1,0.9,0.5,0.8,1.1
                        C132.8,94.2,118.3,107.6,101.1,107.6z M90.2,34.5c-1.7,1.7-1.6,4.1-1.6,4.3c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.7,0.3
                        c-9.7,0-17.6,7.9-17.6,17.6v17c0,17.2,14,31.1,31.1,31.1c13.8,0,25.8-9.2,29.7-22c-2.7,3.1-6.6,5-11,5c-8,0-14.5-6.5-14.6-14.4
                        c0,0,0-0.1,0-0.1V57.5c0-7.8-5.2-14.8-12.8-16.9c-0.1,0-0.3-0.1-0.4-0.2C90.3,39.3,90.1,36.5,90.2,34.5z"
            fill={"#829FC0"}
          />
        </g>
        <g>
          <g>
            <circle cx="75.3" cy="59.9" fill={"#FFFFFF"} r="2.3" />
            <path
              d="M75.3,63.2c-1.8,0-3.3-1.5-3.3-3.3s1.5-3.3,3.3-3.3s3.3,1.5,3.3,3.3S77,63.2,75.3,63.2z M75.3,58.7
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3S75.9,58.7,75.3,58.7z"
              fill={"#829FC0"}
            />
          </g>
          <g>
            <circle cx="93.9" cy="59.9" fill={"#FFFFFF"} r="2.3" />
            <path
              d="M93.9,63.2c-1.8,0-3.3-1.5-3.3-3.3s1.5-3.3,3.3-3.3s3.3,1.5,3.3,3.3S95.7,63.2,93.9,63.2z M93.9,58.7
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3S94.6,58.7,93.9,58.7z"
              fill={"#829FC0"}
            />
          </g>
        </g>
        <path
          d="M97.4,69.8h-4.5c-1.2,0-2.1-0.9-2.1-2.1l0,0c0-1.2,0.9-2.1,2.1-2.1h4.5c1.2,0,2.1,0.9,2.1,2.1l0,0
                    C99.5,68.9,98.5,69.8,97.4,69.8z"
          fill={"#829FC0"}
          opacity={"0.2"}
        />
        <path
          d="M73.9,69.8h-4.5c-1.2,0-2.1-0.9-2.1-2.1l0,0c0-1.2,0.9-2.1,2.1-2.1h4.5c1.2,0,2.1,0.9,2.1,2.1l0,0
                    C76,68.9,75,69.8,73.9,69.8z"
          fill={"#829FC0"}
          opacity={"0.2"}
        />
      </g>
      <path
        d="M80.9,99.5c5.5,4.4,12.5,7.1,20.1,7.1h0c0.4,0,0.9,0,1.3,0l15.8-19.1c-6.7-0.8-12-6.5-12-13.4h0v-4.5
                L80.9,99.5z"
        fill={"#829FC0"}
        opacity={"0.2"}
      />
      <g>
        <path
          d="M271.7,71.8L271.7,71.8c-19.7,0-35.8,16-35.8,35.7v51.9v5.6v29.1c0,2.8,2.3,5.1,5.1,5.1h0
                    c2.8,0,5.1-2.3,5.1-5.1v-6.7c0-2.8,2.3-5.1,5.1-5.1h0c2.8,0,5.1,2.3,5.1,5.1v6.7c0,2.8,2.3,5.1,5.1,5.1h0c2.8,0,5.1-2.3,5.1-5.1
                    v-6.8c0-2.8,2.3-5.1,5.1-5.1h0c2.8,0,5.1,2.3,5.1,5.1v6.8c0,2.8,2.3,5.1,5.1,5.1h0.1c2.8,0,5.1-2.3,5.1-5.1v-6.8
                    c0-2.8,2.3-5.1,5.1-5.1s5.1,2.3,5.1,5.1v6.8c0,2.8,2.3,5.1,5.1,5.1h0c2.8,0,5.1-2.3,5.1-5.1v-29.2v-5.6v-51.9
                    C307.5,87.8,291.5,71.8,271.7,71.8z"
          fill={"#FFFFFF"}
        />
        <path
          d="M302.4,200.3c-3.4,0-6.1-2.7-6.1-6.1v-6.8c0-2.3-1.8-4.1-4.1-4.1c-2.3,0-4.1,1.8-4.1,4.1v6.8
                    c0,3.4-2.7,6.1-6.1,6.1c-3.4,0-6.2-2.7-6.2-6.1v-6.8c0-2.3-1.8-4.1-4.1-4.1c-2.3,0-4.1,1.8-4.1,4.1v6.8c0,3.4-2.7,6.1-6.1,6.1
                    c-3.4,0-6.1-2.7-6.1-6.1v-6.7c0-2.3-1.8-4.1-4.1-4.1s-4.1,1.8-4.1,4.1v6.7c0,3.4-2.7,6.1-6.1,6.1c-3.4,0-6.1-2.7-6.1-6.1v-86.6
                    c0-20.3,16.5-36.8,36.8-36.8s36.8,16.5,36.8,36.8v86.7C308.5,197.6,305.7,200.3,302.4,200.3z M292.2,181.4c3.4,0,6.1,2.7,6.1,6.1
                    v6.8c0,2.3,1.8,4.1,4.1,4.1c2.3,0,4.1-1.8,4.1-4.1v-86.7c0-19.2-15.6-34.8-34.8-34.8S237,88.4,237,107.6v86.6
                    c0,2.3,1.8,4.1,4.1,4.1s4.1-1.8,4.1-4.1v-6.7c0-3.4,2.7-6.1,6.1-6.1c3.4,0,6.1,2.7,6.1,6.1v6.7c0,2.3,1.8,4.1,4.1,4.1
                    c2.3,0,4.1-1.8,4.1-4.1v-6.8c0-3.4,2.7-6.1,6.1-6.1c3.4,0,6.1,2.7,6.1,6.1v6.8c0,2.3,1.8,4.1,4.1,4.1c2.3,0,4.2-1.8,4.2-4.1v-6.8
                    C286.1,184.1,288.8,181.4,292.2,181.4z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <path
          d="M130.3,142.6H66c-6.8,0-12.3-4.9-12.3-11.9c0-4.9,1.8-9,5.5-13.3l67.5-80.1c4.5-5.3,8-8.2,15.4-8.2
                    c6.8,0,12.3,5.3,12.3,12.1v80.7h11.1c6,0,10.5,4.7,10.5,10.5c0,5.7-4.5,10.3-10.5,10.3h-11.1V164c0,6.8-5.3,11.9-12.1,11.9
                    c-6.8,0-12.1-5.1-12.1-11.9V142.6z M130.3,121.9V65l-47.2,56.9H130.3z"
          fill={"#C8D7E3"}
        />
        <path
          d="M142.4,176.9c-7.4,0-13.1-5.7-13.1-12.9v-20.3H66c-7.6,0-13.3-5.5-13.3-12.9c0-5,1.8-9.3,5.8-14l67.5-80
                    c4.8-5.7,8.6-8.6,16.2-8.6c7.3,0,13.3,5.9,13.3,13.1v79.7h10.1c6.4,0,11.5,5,11.5,11.5c0,6.3-5,11.3-11.5,11.3h-10.1V164
                    C155.5,171.2,149.8,176.9,142.4,176.9z M142.2,30.1c-6.8,0-10,2.4-14.6,7.9L60,118c-3.7,4.3-5.3,8.2-5.3,12.7
                    c0,6.2,4.9,10.9,11.3,10.9h65.2V164c0,6.1,4.9,10.9,11.1,10.9s11.1-4.8,11.1-10.9v-22.3h12.1c5.4,0,9.5-4,9.5-9.3
                    c0-5.3-4.2-9.5-9.5-9.5h-12.1V41.2C153.5,35.1,148.4,30.1,142.2,30.1z M131.3,122.9H80.9l50.3-60.6V122.9z M85.2,120.9h44.1V67.8
                    L85.2,120.9z"
          fill={"#829FC0"}
        />
      </g>
      <path
        d="M306.9,165.1v-5.6v-10.2c-9.3,8.9-21.9,14.4-35.7,14.4h0c-13.9,0-26.5-5.5-35.7-14.4v10.2v5.6v12.3
                c4.1,2.3,8.5,4.1,13,5.6c0.7-0.4,1.5-0.6,2.3-0.6h0c1.8,0,3.4,0.9,4.3,2.4c3.6,0.8,7.4,1.3,11.2,1.6c0.5-2.3,2.5-3.9,4.9-3.9h0
                c2.4,0,4.4,1.7,4.9,3.9c3.8-0.3,7.6-0.8,11.2-1.6c0.9-1.4,2.5-2.3,4.3-2.3c0.8,0,1.6,0.2,2.3,0.6c4.5-1.5,8.9-3.3,13-5.6V165.1z"
        fill={"#829FC0"}
        opacity={"0.2"}
      />
      <g>
        <path
          d="M442.3,142.6h-64.2c-6.8,0-12.3-4.9-12.3-11.9c0-4.9,1.8-9,5.5-13.3l67.5-80.1c4.5-5.3,8-8.2,15.4-8.2
                    c6.8,0,12.3,5.3,12.3,12.1v80.7h11.1c6,0,10.5,4.7,10.5,10.5c0,5.7-4.5,10.3-10.5,10.3h-11.1V164c0,6.8-5.3,11.9-12.1,11.9
                    c-6.8,0-12.1-5.1-12.1-11.9V142.6z M442.3,121.9V65l-47.2,56.9H442.3z"
          fill={"#C8D7E3"}
        />
        <path
          d="M454.4,176.9c-7.4,0-13.1-5.7-13.1-12.9v-20.3h-63.2c-7.6,0-13.3-5.5-13.3-12.9c0-5,1.8-9.3,5.8-14l67.5-80
                    c4.8-5.7,8.6-8.6,16.2-8.6c7.3,0,13.3,5.9,13.3,13.1v79.7h10.1c6.4,0,11.5,5,11.5,11.5c0,6.3-5,11.3-11.5,11.3h-10.1V164
                    C467.5,171.2,461.8,176.9,454.4,176.9z M454.2,30.1c-6.8,0-10,2.4-14.6,7.9L372,118c-3.7,4.3-5.3,8.2-5.3,12.7
                    c0,6.2,4.9,10.9,11.3,10.9h65.2V164c0,6.1,4.9,10.9,11.1,10.9c6.2,0,11.1-4.8,11.1-10.9v-22.3h12.1c5.4,0,9.5-4,9.5-9.3
                    c0-5.3-4.2-9.5-9.5-9.5h-12.1V41.2C465.5,35.1,460.4,30.1,454.2,30.1z M443.3,122.9H393l50.3-60.6V122.9z M397.2,120.9h44.1V67.8
                    L397.2,120.9z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <path
          d="M271.7,28.7L271.7,28.7c-41,0-74.3,33.3-74.3,74.3v0c0,41,33.3,74.3,74.3,74.3h0c41,0,74.3-33.3,74.3-74.3v0
                    C346,62,312.8,28.7,271.7,28.7z M323.2,103c0,28.5-23.1,51.5-51.5,51.5h0c-28.5,0-51.5-23.1-51.5-51.5v0
                    c0-28.5,23.1-51.5,51.5-51.5h0C300.2,51.5,323.2,74.6,323.2,103L323.2,103z"
          fill={"#C8D7E3"}
        />
        <path
          d="M271.7,178.3c-41.5,0-75.3-33.8-75.3-75.3s33.8-75.3,75.3-75.3h0c41.5,0,75.3,33.8,75.3,75.3
                    S313.2,178.3,271.7,178.3z M271.7,29.7c-40.4,0-73.3,32.9-73.3,73.3s32.9,73.3,73.3,73.3c40.4,0,73.3-32.9,73.3-73.3
                    S312.1,29.7,271.7,29.7z M271.7,155.5c-29,0-52.5-23.6-52.5-52.5s23.6-52.5,52.5-52.5s52.5,23.6,52.5,52.5
                    S300.7,155.5,271.7,155.5z M271.7,52.5c-27.9,0-50.5,22.7-50.5,50.5s22.7,50.5,50.5,50.5s50.5-22.7,50.5-50.5
                    S299.6,52.5,271.7,52.5z"
          fill={"#829FC0"}
        />
      </g>
      <path d="M308.4,144.2v12.2c0,3.7-3,6.7-6.7,6.7l0,0c-3.7,0-6.7-3-6.7-6.7v-12.9" fill={"#829FC0"} opacity={"0.2"} />
      <g opacity={"0.2"}>
        <path
          d="M165.6,121.6h-11.1V40.9c0-6.8-5.5-12.1-12.3-12.1c-2.5,0-4.6,0.3-6.4,1c4.3,1.9,7.4,6.1,7.4,11.1v80.7h11.1
                    c6,0,10.5,4.7,10.5,10.5c0,5.7-4.5,10.3-10.5,10.3h-11.1v21.3c0,4.7-2.6,8.6-6.4,10.6c1.7,0.9,3.6,1.3,5.7,1.3
                    c6.8,0,12.1-5.1,12.1-11.9v-21.3h11.1c6,0,10.5-4.5,10.5-10.3C176.1,126.3,171.5,121.6,165.6,121.6z"
          fill={"#829FC0"}
        />
        <polygon fill={"#829FC0"} points="130.3,65.1 130.3,51.5 71.7,121.6 83.1,121.6 		" />
      </g>
      <path d="M248.5,144.2v12.2c0,3.7-3,6.7-6.7,6.7h0c-3.7,0-6.7-3-6.7-6.7v-21.9" fill={"#829FC0"} opacity={"0.2"} />
      <g opacity={"0.2"}>
        <path
          d="M478.1,121.6H467V40.9c0-6.8-5.5-12.1-12.3-12.1c-2.5,0-4.6,0.3-6.4,1c4.3,1.9,7.4,6.1,7.4,11.1v80.7h11.1
                    c6,0,10.5,4.7,10.5,10.5c0,5.7-4.5,10.3-10.5,10.3h-11.1v21.3c0,4.7-2.6,8.6-6.4,10.6c1.7,0.9,3.6,1.3,5.7,1.3
                    c6.8,0,12.1-5.1,12.1-11.9v-21.3h11.1c6,0,10.5-4.5,10.5-10.3C488.5,126.3,484,121.6,478.1,121.6z"
          fill={"#829FC0"}
        />
        <polygon fill={"#829FC0"} points="442.8,65.1 442.8,51.5 384.2,121.6 395.5,121.6 		" />
      </g>
      <g opacity={"0.2"}>
        <path
          d="M266.1,51c-1.9-0.2-3.8-0.3-5.7-0.3h0c-28.5,0-51.5,23.1-51.5,51.5v0c0,28.5,23.1,51.5,51.5,51.5h0
                    c1.9,0,3.8-0.1,5.7-0.3c-25.8-2.8-45.9-24.7-45.9-51.2v0C220.2,75.6,240.3,53.8,266.1,51z"
          fill={"#829FC0"}
        />
        <path
          d="M271.7,27.9L271.7,27.9c-1.9,0-3.8,0.1-5.7,0.2c38.4,2.9,68.6,34.9,68.6,74.1v0c0,39.1-30.3,71.2-68.6,74.1
                    c1.9,0.1,3.8,0.2,5.7,0.2h0c41,0,74.3-33.3,74.3-74.3v0C346,61.1,312.8,27.9,271.7,27.9z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <path d="M247.5,143v10.4c0,3.2-2.6,5.8-5.8,5.8h0c-3.2,0-5.8-2.6-5.8-5.8v-18.7" fill={"#FFFFFF"} />
        <path
          d="M241.7,160.2c-3.7,0-6.8-3-6.8-6.8v-18.7c0-0.6,0.4-1,1-1s1,0.4,1,1v18.7c0,2.6,2.1,4.8,4.8,4.8
                    s4.8-2.1,4.8-4.8V143c0-0.6,0.4-1,1-1s1,0.4,1,1v10.4C248.5,157.1,245.5,160.2,241.7,160.2z"
          fill={"#829FC0"}
        />
      </g>
      <path
        d="M85.7,179c-0.9,5-4.9,8.9-9.9,9.9c-0.2,0-0.2,0.4,0,0.4c5,0.9,8.9,4.9,9.9,9.9c0,0.2,0.4,0.2,0.4,0
                c0.9-5,4.9-8.9,9.9-9.9c0.2,0,0.2-0.4,0-0.4c-5-0.9-8.9-4.9-9.9-9.9C86.1,178.8,85.7,178.8,85.7,179z"
        fill={"#829FC0"}
        opacity={"0.2"}
      />
      <path
        d="M364.9,29.3c-0.9,5-4.9,8.9-9.9,9.9c-0.2,0-0.2,0.4,0,0.4c5,0.9,8.9,4.9,9.9,9.9c0,0.2,0.4,0.2,0.4,0
                c0.9-5,4.9-8.9,9.9-9.9c0.2,0,0.2-0.4,0-0.4c-5-0.9-8.9-4.9-9.9-9.9C365.3,29.1,365,29.1,364.9,29.3z"
        fill={"#829FC0"}
        opacity={"0.2"}
      />
      <g>
        <path d="M295.9,143v10.4c0,3.2,2.6,5.8,5.8,5.8l0,0c3.2,0,5.8-2.6,5.8-5.8v-18.7" fill={"#FFFFFF"} />
        <path
          d="M301.7,160.2c-3.7,0-6.8-3-6.8-6.8V143c0-0.6,0.4-1,1-1s1,0.4,1,1v10.4c0,2.6,2.1,4.8,4.8,4.8
                    s4.8-2.1,4.8-4.8v-18.7c0-0.6,0.4-1,1-1s1,0.4,1,1v18.7C308.5,157.1,305.4,160.2,301.7,160.2z"
          fill={"#829FC0"}
        />
      </g>
      <path
        d="M400.2,167.2c-0.9,5-4.9,8.9-9.9,9.9c-0.2,0-0.2,0.4,0,0.4c5,0.9,8.9,4.9,9.9,9.9c0,0.2,0.4,0.2,0.4,0
                c0.9-5,4.9-8.9,9.9-9.9c0.2,0,0.2-0.4,0-0.4c-5-0.9-8.9-4.9-9.9-9.9C400.6,167,400.2,167,400.2,167.2z"
        fill={"#829FC0"}
        opacity={"0.2"}
      />
      <path
        d="M186.1,3.8c-0.9,5-4.9,8.9-9.9,9.9c-0.2,0-0.2,0.4,0,0.4c5,0.9,8.9,4.9,9.9,9.9c0,0.2,0.4,0.2,0.4,0
                c0.9-5,4.9-8.9,9.9-9.9c0.2,0,0.2-0.4,0-0.4c-5-0.9-8.9-4.9-9.9-9.9C186.5,3.6,186.2,3.6,186.1,3.8z"
        fill={"#829FC0"}
        opacity={"0.2"}
      />
      <circle cx="28.8" cy="76.3" fill={"#829FC0"} opacity={"0.2"} r="3.6" />
      <circle cx="186.3" cy="173.7" fill={"#829FC0"} opacity={"0.2"} r="3.6" />
      <circle cx="313.2" cy="3.6" fill={"#829FC0"} opacity={"0.2"} r="3.6" />
      <circle cx="515.4" cy="59.2" fill={"#829FC0"} opacity={"0.2"} r="3.6" />
      <g opacity={"0.2"}>
        <path d="M91.4,12.8H70.3c-0.6,0-1-0.4-1-1s0.4-1,1-1h21.1c0.6,0,1,0.4,1,1S92,12.8,91.4,12.8z" fill={"#829FC0"} />
        <path
          d="M110.8,12.8h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S111.4,12.8,110.8,12.8z M104.8,12.8h-2
                    c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S105.4,12.8,104.8,12.8z M98.8,12.8h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2
                    c0.6,0,1,0.4,1,1S99.4,12.8,98.8,12.8z M92.8,12.8h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S93.4,12.8,92.8,12.8z"
          fill={"#829FC0"}
        />
        <path
          d="M22.1,168.8H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h21.1c0.6,0,1,0.4,1,1S22.7,168.8,22.1,168.8z"
          fill={"#829FC0"}
        />
        <path
          d="M41.5,168.8h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S42.1,168.8,41.5,168.8z M35.5,168.8h-2
                    c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S36.1,168.8,35.5,168.8z M29.5,168.8h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2
                    c0.6,0,1,0.4,1,1S30.1,168.8,29.5,168.8z M23.5,168.8h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S24.1,168.8,23.5,168.8z"
          fill={"#829FC0"}
        />
        <path
          d="M416.2,19.2h-21.1c-0.6,0-1-0.4-1-1s0.4-1,1-1h21.1c0.6,0,1,0.4,1,1S416.8,19.2,416.2,19.2z"
          fill={"#829FC0"}
        />
        <path
          d="M435.6,19.2h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S436.1,19.2,435.6,19.2z M429.6,19.2h-2
                    c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S430.1,19.2,429.6,19.2z M423.6,19.2h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2
                    c0.6,0,1,0.4,1,1S424.1,19.2,423.6,19.2z M417.6,19.2h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S418.1,19.2,417.6,19.2z"
          fill={"#829FC0"}
        />
        <path
          d="M515.7,190.1h-21.1c-0.6,0-1-0.4-1-1s0.4-1,1-1h21.1c0.6,0,1,0.4,1,1S516.3,190.1,515.7,190.1z"
          fill={"#829FC0"}
        />
        <path
          d="M535.1,190.1h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S535.6,190.1,535.1,190.1z M529.1,190.1h-2
                    c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S529.6,190.1,529.1,190.1z M523.1,190.1h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2
                    c0.6,0,1,0.4,1,1S523.6,190.1,523.1,190.1z M517.1,190.1h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1
                    S517.6,190.1,517.1,190.1z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <g>
          <circle cx="252" cy="109.6" fill={"#FFFFFF"} r="3.1" />
          <path
            d="M252,113.7c-2.3,0-4.1-1.8-4.1-4.1s1.8-4.1,4.1-4.1s4.1,1.8,4.1,4.1S254.3,113.7,252,113.7z M252,107.5
                        c-1.2,0-2.1,0.9-2.1,2.1c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1C254.1,108.5,253.2,107.5,252,107.5z"
            fill={"#829FC0"}
          />
        </g>
        <g>
          <circle cx="291.4" cy="109.6" fill={"#FFFFFF"} r="3.1" />
          <path
            d="M291.4,113.7c-2.3,0-4.1-1.8-4.1-4.1s1.8-4.1,4.1-4.1s4.1,1.8,4.1,4.1S293.7,113.7,291.4,113.7z
                        M291.4,107.5c-1.2,0-2.1,0.9-2.1,2.1c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1C293.5,108.5,292.6,107.5,291.4,107.5z"
            fill={"#829FC0"}
          />
        </g>
      </g>
      <circle cx="249.4" cy="122.6" fill={"#E8EEF3"} r="4.7" />
      <circle cx="293.8" cy="122.6" fill={"#E8EEF3"} r="4.7" />
      <path
        d="M244.7,104.2c-0.2,0-0.3,0-0.5-0.1c-0.5-0.3-0.7-0.9-0.4-1.3c0.8-1.5,3.4-4.1,7.5-3.5c0.5,0.1,0.9,0.6,0.8,1.1
                c-0.1,0.5-0.6,0.9-1.1,0.8c-3.7-0.6-5.3,2.3-5.4,2.4C245.4,104,245.1,104.2,244.7,104.2z"
        fill={"#E4EBF1"}
      />
      <path
        d="M298.5,104.2c-0.4,0-0.7-0.2-0.9-0.5l0,0c-0.1-0.1-1.7-3-5.4-2.4c-0.5,0.1-1.1-0.3-1.1-0.8
                c-0.1-0.5,0.3-1.1,0.8-1.1c4.1-0.7,6.7,2,7.5,3.5c0.3,0.5,0.1,1.1-0.4,1.4C298.8,104.2,298.6,104.2,298.5,104.2z"
        fill={"#E4EBF1"}
      />
      <g>
        <line fill={"#FFFFFF"} x1="266.3" x2="277.2" y1="116.3" y2="116.3" />
        <path
          d="M277.2,117.3h-10.9c-0.6,0-1-0.4-1-1s0.4-1,1-1h10.9c0.6,0,1,0.4,1,1S277.7,117.3,277.2,117.3z"
          fill={"#829FC0"}
        />
      </g>
      <path
        d="M295.9,91c-0.3,0-0.6-0.1-0.8-0.4c-0.4-0.5-0.8-1-1.3-1.5c-0.4-0.4-0.3-1,0.1-1.4c0.4-0.4,1-0.3,1.4,0.1
                c0.5,0.5,0.9,1,1.3,1.6c0.3,0.4,0.3,1.1-0.2,1.4C296.4,90.9,296.2,91,295.9,91z M291.8,86.7c-0.2,0-0.5-0.1-0.6-0.2
                c-0.5-0.4-1-0.8-1.5-1.2c-0.4-0.3-0.5-1-0.2-1.4c0.3-0.4,1-0.5,1.4-0.2c0.6,0.4,1.1,0.8,1.6,1.3c0.4,0.4,0.5,1,0.1,1.4
                C292.3,86.6,292.1,86.7,291.8,86.7z M286.9,83.3c-0.2,0-0.3,0-0.5-0.1c-0.6-0.3-1.1-0.6-1.7-0.9c-0.5-0.2-0.7-0.8-0.5-1.3
                c0.2-0.5,0.8-0.7,1.3-0.5c0.6,0.3,1.2,0.6,1.8,1c0.5,0.3,0.7,0.9,0.4,1.4C287.5,83.1,287.2,83.3,286.9,83.3z M281.4,80.9
                c-0.1,0-0.2,0-0.3-0.1c-0.6-0.2-1.2-0.4-1.9-0.5c-0.5-0.1-0.9-0.7-0.7-1.2s0.7-0.9,1.2-0.7c0.7,0.2,1.3,0.4,2,0.6
                c0.5,0.2,0.8,0.7,0.6,1.3C282.2,80.6,281.8,80.9,281.4,80.9z M275.5,79.6c0,0-0.1,0-0.1,0c-0.6-0.1-1.3-0.1-1.9-0.2
                c-0.6,0-1-0.5-0.9-1.1c0-0.6,0.5-1,1.1-0.9c0.7,0,1.4,0.1,2.1,0.2c0.5,0.1,0.9,0.6,0.9,1.1C276.5,79.2,276,79.6,275.5,79.6z"
        fill={"#C8D7E3"}
        opacity={"0.5"}
      />
    </g>
  </svg>
)
