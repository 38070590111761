import { Fragment, useContext, useState } from "react"
import { User } from "@/graphql/types/queries"
import UserMenuList from "./UserMenuList"
import { AuthContext } from "@/hooks/AuthHook"

interface UserInfoTooltipProps {
  user: User
}

const UserInfoTooltip = ({ user }: UserInfoTooltipProps) => {
  const { hasAdminPowers } = useContext(AuthContext)

  const [isVisible, setIsVisible] = useState(false)

  const toggleDropdown = () => {
    setIsVisible(!isVisible)
  }

  const closeDropdown = () => {
    setIsVisible(false)
  }

  const pricingTrigger = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: "scPricingOpened", pricing: { trigger: "user" } })
  }

  return (
    <Fragment>
      <li className="flex flex-row text-[13px] font-normal ml-[14px] my-0 " onClick={toggleDropdown}>
        <button
          className="whitespace-nowrap bg-transparent p-0 border-none text-[#ffffff80] text-[13px] font-bold mt-[1px] hover:bg-transparent hover:text-[#fff] hover:border-none"
          data-testid="user-dropdown-menu-button"
          tabIndex={0}
        >
          {user.firstName}
          <div className="border-l-[4px] border-l-transparent border-t-[4px] border-t-[#ffffff80] hover:border-t-[#fff] border-r-[4px] border-r-transparent border-solid h-0 ml-3 inline-block w-0 align-middle"></div>
        </button>
      </li>
      <div className="top-[50px] w-[332px]" style={{ position: "absolute", right: "50px", zIndex: 1000 }}>
        {isVisible && (
          <div>
            <div className="absolute right-[-83px] max-w-[332px]" data-testid="user-dropdown-menu">
              <div
                className={`absolute ${
                  hasAdminPowers ? "top-[-10px] right-[169px]" : "top-[-11px] right-6"
                } w-0 h-0 border-l-[12px] border-l-transparent border-b-[11px] border-b-[#fff] border-r-[12px] border-r-transparent`}
              />
              <div className="bg-white border-none rounded-none shadow-[-1px_0_12px_2px_rgba(122,127,135,0.14)] block p-0">
                <UserMenuList closeDropdown={closeDropdown} pricingTrigger={pricingTrigger} user={user} />
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default UserInfoTooltip
