import { useContext } from "react"
import { NavLink } from "react-router-dom"
import { UserDataContext } from "../../hooks/UserDataHook"

const NavigationLink = ({ path, label }) => (
  <li>
    <NavLink activeClassName="active" to={path}>
      {label}
    </NavLink>
  </li>
)

const ProfileNavigation = ({ permissions }) => {
  const { user } = useContext(UserDataContext)
  return (
    <ul className="profile-panel-links">
      <NavigationLink label="Account information" path="/myprofile/account-information" />
      <NavigationLink label="Change password" path="/myprofile/change-password" />
      {permissions.isSubscription && (
        <NavigationLink label="Billing information" path="/myprofile/billing-information" />
      )}
      {user.isEditModeActive && <NavigationLink label="Users" path="/myprofile/myteam" />}
      {user.isEditModeActive && <NavigationLink label="Teams" path="/myprofile/teams" />}
    </ul>
  )
}

export default ProfileNavigation
