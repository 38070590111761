const AddCollectionButton = ({ handleAddCollection }) => {
  return (
    <button
      className={
        "w-full aspect-[181/192] text-sm flex justify-center items-center gap-[12px] cursor-pointer rounded-[3px] border-[#D1D5DB] border transition ease-in-out hover:bg-sc-hover-dark"
      }
      data-testid="add-collection-button"
      onClick={handleAddCollection}
    >
      <svg fill="none" height="11" viewBox="0 0 11 11" width="11" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2136_7381)">
          <path
            d="M6.43106 1.23926L6.43112 1.23926L6.43089 1.23759C6.40083 1.01616 6.21104 0.845605 5.98143 0.845605C5.73095 0.845605 5.52796 1.04879 5.52802 1.29928L5.5287 5.41722H1.41192V5.41716L1.41025 5.41727L1.3521 5.42116L1.3521 5.4211L1.35041 5.42133C1.12902 5.45139 0.958398 5.64115 0.958398 5.87081C0.958398 6.1213 1.16144 6.32439 1.41192 6.32439H5.52871L5.52985 10.4421H5.52979L5.52991 10.4438L5.53385 10.5019L5.53379 10.5019L5.53402 10.5036C5.56408 10.7251 5.75387 10.8956 5.98348 10.8956C6.23396 10.8956 6.43695 10.6924 6.43689 10.4419L6.43575 6.32439H10.5549V6.32445L10.5565 6.32434L10.6147 6.32045L10.6147 6.32051L10.6164 6.32028C10.8378 6.29022 11.0084 6.10046 11.0084 5.87081C11.0084 5.62031 10.8053 5.41722 10.5549 5.41722H6.43574L6.43506 1.2991H6.43512L6.435 1.29742L6.43106 1.23926Z"
            fill="#6B6F7A"
            stroke="#6B6F7A"
            strokeWidth="0.05"
          />
        </g>
        <defs>
          <clipPath id="clip0_2136_7381">
            <rect fill="white" height="10" transform="translate(0.983398 0.870605)" width="10" />
          </clipPath>
        </defs>
      </svg>
      <span>Add Collection</span>
    </button>
  )
}

export default AddCollectionButton
