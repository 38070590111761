import { useContext, useEffect } from "react"
import { useMutation, gql } from "@apollo/client"
import { MainModalContext } from "@/hooks/MainModalHook"
import { ErrorPlaceholder } from "@/components/utils/placeholders"
import UnitsAndTeamMembersInput from "@/components/modals/share/UnitsAndTeamMembersInput"
import useUnitsAndTeamMembersInputLogic from "@/hooks/useUnitsAndTeamMembersInputLogic"

const ADD_USERS_TO_UNIT = gql`
  mutation addUnitMembers($unitId: String!, $memberIds: [String!]!) {
    addUnitMembers(unitId: $unitId, memberIds: $memberIds) {
      code
      success
      message
    }
  }
`

const AddUserToUnit = ({ unit, refetch }) => {
  useEffect(() => {
    // import.meta.env.DEV && console.log("♻️ AddMemberToUnit")
  })

  const { closeModal } = useContext(MainModalContext)

  const [addUsersToUnit, { error }] = useMutation(ADD_USERS_TO_UNIT)

  const { options, selectedOptions, handleOptionChange, formatOptionLabel, addedAndRemovedIds } =
    useUnitsAndTeamMembersInputLogic()

  const selectInputOptions = options.members.filter(
    (member) => !unit.members.find((unitMember) => unitMember._id === member.value)
  )

  const handleSave = async () => {
    await addUsersToUnit({
      variables: {
        unitId: unit._id,
        memberIds: addedAndRemovedIds.addedUserIds
      },
      context: { isUsingNewScApi: true },
      onCompleted: () => {
        refetch()
      }
    })

    closeModal()
  }

  if (error) {
    return (
      <div className="modal-error sc-modal-add-team-member">
        <ErrorPlaceholder />
      </div>
    )
  }

  return (
    <div>
      <div className="sc-modal-header">
        <h1>Add team member</h1>
      </div>
      <div className="svg-add-member" />
      <form className="team-share add-team-member">
        <label htmlFor="teamMembersAndUnits">Invite users:</label>
        <UnitsAndTeamMembersInput
          formatOptionLabel={formatOptionLabel}
          handleOptionChange={handleOptionChange}
          options={selectInputOptions}
          selectedOptions={selectedOptions}
        />
        <div className="sc-modal-buttons">
          <button className="btn-gray-blue" onClick={closeModal} type="button">
            Cancel
          </button>
          <button className="btn-dark-blue" disabled={selectedOptions.length < 1} onClick={handleSave} type="button">
            Save
          </button>
        </div>
      </form>
    </div>
  )
}

export default AddUserToUnit
