export default function getBackgroundClassName(groupId) {
  if (groupId === "mcdonalds") {
    return "mcdDashboardTop"
  }
  if (groupId === "ing") {
    return "ingDashboardTop"
  }
  if (groupId === "king") {
    return "kingDashboardTop"
  }
  if (groupId === "chain") {
    return "rocheDashboardTop"
  }
  if (groupId === "dudnyk") {
    return "dudnykDashboardTop"
  }
  if (groupId === "nuxeo") {
    return "nuxeoDashboardTop"
  }
  if (groupId === "ipc") {
    return "ipcDashboardTop"
  }
  if (groupId === "tagrisso") {
    return "tagrissoDashboardTop"
  }
  if (groupId === "lynparza") {
    return "lynparzaDashboardTop"
  }
  if (groupId === "imfinzi") {
    return "imfinziDashboardTop"
  }
  return ""
}
