import { BiSearch } from "react-icons/bi"
import UsersProfileDropDown from "./UsersProfileDropDown"

export default function TeamSearchForm({
  setSearchFilter,
  statusFilter,
  setStatusFilter,
  roleFilter,
  setRoleFilter,
  activeTeamMembersCount,
  inactiveTeamMembersCount
}) {
  const roleOptions = ["everyone", "admin", "member"]
  const statusOptions = ["everyone", "active", "inactive"]

  const counts = {
    everyone: activeTeamMembersCount + inactiveTeamMembersCount,
    active: activeTeamMembersCount,
    inactive: inactiveTeamMembersCount
  }

  return (
    <>
      <div className="flex sm:flex-row gap-7">
        <div className="flex items-center justify-end gap-2 col-span-5 text-right">
          <UsersProfileDropDown
            counts={counts}
            filter={roleFilter}
            options={roleOptions}
            setFilter={setRoleFilter}
            title="Role"
          />
          <UsersProfileDropDown
            counts={counts}
            filter={statusFilter}
            options={statusOptions}
            setFilter={setStatusFilter}
            title="Status"
          />
        </div>

        <div className="flex items-center justify-end gap-2 col-span-5 text-right">
          <div className="relative rounded flex pr-[15px]">
            <div className="flex items-center text-base py-1.5 px-3 pointer-events-none bg-[#e9ecef] rounded-l border-r-0 border border-solid border-[#ced4da]">
              <BiSearch />
            </div>
            <input
              className="w-full py-1.5 px-3 leading-normal sm:text-base rounded rounded-bl-none rounded-tl-none focus:ring-[#80bdff] focus:border-[#80bdff]"
              id="inlineFormInputGroup"
              onChange={(e) => setSearchFilter(e.target.value)}
              placeholder="Find a member..."
              type="text"
            />
          </div>
        </div>
      </div>
    </>
  )
}
