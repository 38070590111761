const LinkIndicator = ({ value }) => (
  <div className={`w-4 flex flex-none ${value ? "opacity-100" : "opacity-0"}`} data-testid="presentation-link-icon">
    <svg className="w-full" version="1.1" viewBox="0 0 11.2 11.2" x="0px" y="0px">
      <g className="st0">
        <path
          d="M4.4,6.7c0.5,0.5,1.3,0.8,2,0.8s1.4-0.3,2-0.8l2-2c0.5-0.5,0.8-1.2,0.8-2c0-0.7-0.3-1.4-0.8-2
          c-1.1-1.1-2.9-1.1-3.9,0L5.2,2C5,2.2,5,2.6,5.2,2.8S5.8,3,6,2.8l1.2-1.2C7.8,1,8.9,1,9.6,1.6c0.3,0.3,0.5,0.7,0.5,1.2
          S9.9,3.7,9.6,4l-2,2C6.9,6.6,5.9,6.6,5.2,6C5,5.7,4.6,5.7,4.4,6S4.2,6.5,4.4,6.7z"
          style={{ fill: "#3390FF", opacity: "0.8" }}
        />
        <path
          d="M0,8.4c0,0.7,0.3,1.4,0.8,2c0.5,0.5,1.2,0.8,2,0.8s1.4-0.3,2-0.8L6,9.1c0.2-0.2,0.2-0.6,0-0.8s-0.6-0.2-0.8,0
          L4,9.6c-0.6,0.6-1.7,0.6-2.4,0C1.3,9.2,1.1,8.8,1.1,8.4c0-0.4,0.2-0.9,0.5-1.2l2-2c0.7-0.7,1.7-0.7,2.4,0c0.2,0.2,0.6,0.2,0.8,0
          C7,5,7,4.6,6.7,4.4c-1.1-1.1-2.9-1.1-3.9,0l-2,2C0.3,6.9,0,7.6,0,8.4z"
          style={{ fill: "#3390FF", opacity: "0.8" }}
        />
      </g>
    </svg>
  </div>
)

export default LinkIndicator
