const SubscriptionPlaceholder = () => (
  <div className="profile-sub-free">
    <div className="profile-sub-free-name">
      <p>
        You are currently on a <b>Free</b> plan
      </p>
    </div>
    <div className="profile-sub-cancel">
      <button
        className="btn btn-dark-blue"
        onClick={() => {
          window.location.href = "/pricing?source=Menu"
        }}
      >
        Upgrade
      </button>
    </div>
  </div>
)

export default SubscriptionPlaceholder
