import { ChangeEvent, useEffect, useState, useRef, useCallback } from "react"
import { debounce } from "lodash"
import * as JsSearch from "js-search"
import { stemmer } from "../utils/stemmer"

interface AllSlidesContainerProps {
  title: string
  slides: any
  toggleShowAllSlides(showAllSlides: boolean): void
  changeSlide(newSlideNumber: number): void
}

const AllSlidesContainer = ({ title, slides, toggleShowAllSlides, changeSlide }: AllSlidesContainerProps) => {
  const [filter, setFilter] = useState("")
  const search = useRef<any>()

  const handleShowAllSlides = () => {
    toggleShowAllSlides(false)
  }

  const handleClickSlide = (slide: number) => {
    changeSlide(slide)
    toggleShowAllSlides(false)
  }

  const getSlidesWithNumbers = useCallback(() => {
    return slides.map((slide: any, i: number) => ({ ...slide, _index: i }))
  }, [slides])

  useEffect(() => {
    search.current = new JsSearch.Search("name")
    search.current.tokenizer = new JsSearch.StemmingTokenizer(stemmer, new JsSearch.SimpleTokenizer())
    search.current.addIndex("name")
    search.current.addIndex("tags")
    search.current.addIndex("texts")
    search.current.addDocuments(getSlidesWithNumbers())
  }, [getSlidesWithNumbers])

  const debouncedSearch = debounce((val: string) => {
    if (val === "") {
      return
    }
    const searchedSlides = search.current.search(val).map((slide: any) => ({ id: slide.slideId, name: slide.name }))
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "scSlideShowSearch",
      slideShowSearch: {
        phrase: val,
        searchedSlides
      }
    })
  }, 1000)

  const searchHandler = (e: ChangeEvent<HTMLInputElement>) => {
    e.persist()
    setFilter(e.target.value)
    debouncedSearch(e.target.value)
  }

  const filteredSlides = filter === "" ? getSlidesWithNumbers() : search.current.search(filter)
  return (
    <div className="viewer-all-slides-container">
      <div className="viewer-all-slides-top">
        <h1>{title}</h1>
        <input
          className="slideshow-search"
          onChange={searchHandler}
          placeholder="Search..."
          type="text"
          value={filter}
        />
        <svg className="search-icon" version="1.1" viewBox="0 0 12 12" x="0px" y="0px">
          <path
            d="M11.9,11.1L8.9,8.2c0.7-0.9,1.2-2,1.2-3.2c0-2.8-2.3-5-5-5S0,2.3,0,5c0,2.8,2.3,5,5,5
            c1.2,0,2.3-0.4,3.2-1.2l2.9,2.9c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1C12,11.7,12,11.3,11.9,11.1z M1,5c0-2.2,1.8-4,4-4
            c2.2,0,4,1.8,4,4s-1.8,4-4,4C2.8,9.1,1,7.3,1,5z"
          />
        </svg>
      </div>
      <div className="viewer-all-slides">
        {filteredSlides.map((slide: any, i: number) => (
          <div className="viewer-slide-small-container" key={i}>
            <div className="viewer-slide-small">
              <img
                alt="thumbnail"
                key={slide.thumbUrl}
                onClick={() => handleClickSlide(slide._index)}
                src={slide.thumbUrl.replace("{width}", 173)}
                srcSet={`${slide.thumbUrl.replace("{width}", 346)} 2x`}
              />
              <p style={{ opacity: filter === "" ? 1 : 0 }}>{i + 1}</p>
            </div>
          </div>
        ))}
      </div>
      <button className="viewer-all-slides-close" onClick={handleShowAllSlides}>
        <span>
          <svg version="1.1" viewBox="0 0 14 14" x="0px" y="0px">
            <path
              d="M13.5,14c-0.1,0-0.3,0-0.4-0.1l-13-13C0,0.7,0,0.3,0.1,0.1s0.5-0.2,0.7,0l13,13
                c0.2,0.2,0.2,0.5,0,0.7C13.8,14,13.6,14,13.5,14z"
            />
            <path
              d="M0.5,14c-0.1,0-0.3,0-0.4-0.1c-0.2-0.2-0.2-0.5,0-0.7l13-13c0.2-0.2,0.5-0.2,0.7,0
                s0.2,0.5,0,0.7l-13,13C0.8,14,0.6,14,0.5,14z"
            />
          </svg>
        </span>
      </button>
    </div>
  )
}

export default AllSlidesContainer
