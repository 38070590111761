/* eslint-disable react/no-unknown-property */

export const SharedEmptySvg = () => (
  <svg
    className="w-[244px] desktop-2xl:w-[325px] mb-[50px] desktop-2xl:mb-[60px]"
    version="1.1"
    viewBox="0 0 242 166"
    x="0px"
    y="0px"
  >
    <path
      d="M210.1,166H31.9c-6.4,0-11.5-5.2-11.5-11.5v-27.2h201.3v27.2C221.6,160.9,216.5,166,210.1,166z"
      fill={"#829FC0"}
      opacity={"0.1"}
    />
    <g>
      <path
        d="M198.2,143.8H43.8c-2.3,0-4.1-1.8-4.1-4.1V38.3c0-2.3,1.8-4.1,4.1-4.1h154.4
                c2.3,0,4.1,1.8,4.1,4.1v101.4C202.3,142,200.5,143.8,198.2,143.8z"
        fill={"#FFFFFF"}
      />
      <path
        d="M198.2,144.8H43.8c-2.8,0-5.1-2.3-5.1-5.1V38.3c0-2.8,2.3-5.1,5.1-5.1h154.4
                c2.8,0,5.1,2.3,5.1,5.1v101.4C203.3,142.5,201,144.8,198.2,144.8z M43.8,35.2c-1.7,0-3.1,1.4-3.1,3.1v101.4c0,1.7,1.4,3.1,3.1,3.1
                h154.4c1.7,0,3.1-1.4,3.1-3.1V38.3c0-1.7-1.4-3.1-3.1-3.1H43.8z"
        fill={"#829FC0"}
      />
    </g>
    <path
      d="M109.8,73.6H57.1c-1.5,0-2.7-1.2-2.7-2.7V69c0-1.5,1.2-2.7,2.7-2.7h52.7c1.5,0,2.7,1.2,2.7,2.7v1.9
            C112.5,72.4,111.3,73.6,109.8,73.6z"
      fill={"#0B4496"}
      opacity={"0.1"}
    />
    <path
      d="M109.8,62.1H57.1c-1.5,0-2.7-1.2-2.7-2.7v-1.9c0-1.5,1.2-2.7,2.7-2.7h52.7c1.5,0,2.7,1.2,2.7,2.7v1.9
            C112.5,60.8,111.3,62.1,109.8,62.1z"
      fill={"#0B4496"}
      opacity={"0.1"}
    />
    <path
      d="M183.7,73.6H131c-1.5,0-2.7-1.2-2.7-2.7V69c0-1.5,1.2-2.7,2.7-2.7h52.7c1.5,0,2.7,1.2,2.7,2.7v1.9
            C186.4,72.4,185.2,73.6,183.7,73.6z"
      fill={"#0B4496"}
      opacity={"0.1"}
    />
    <path
      d="M183.7,62.1H131c-1.5,0-2.7-1.2-2.7-2.7v-1.9c0-1.5,1.2-2.7,2.7-2.7h52.7c1.5,0,2.7,1.2,2.7,2.7v1.9
            C186.4,60.8,185.2,62.1,183.7,62.1z"
      fill={"#0B4496"}
      opacity={"0.1"}
    />
    <g>
      <line fill={"#FFFFFF"} x1="1" x2="39.7" y1="127.3" y2="127.3" />
      <g>
        <path d="M7,128.3H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S7.6,128.3,7,128.3z" fill={"#829FC0"} />
        <path
          d="M24.8,128.3h-8.9c-0.6,0-1-0.4-1-1s0.4-1,1-1h8.9c0.6,0,1,0.4,1,1S25.4,128.3,24.8,128.3z"
          fill={"#829FC0"}
        />
        <path d="M39.7,128.3h-6c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S40.3,128.3,39.7,128.3z" fill={"#829FC0"} />
      </g>
    </g>
    <g>
      <line fill={"#FFFFFF"} x1="202.3" x2="241" y1="127.3" y2="127.3" />
      <g>
        <path
          d="M208.3,128.3h-6c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S208.8,128.3,208.3,128.3z"
          fill={"#829FC0"}
        />
        <path
          d="M226.1,128.3h-8.9c-0.6,0-1-0.4-1-1s0.4-1,1-1h8.9c0.6,0,1,0.4,1,1S226.7,128.3,226.1,128.3z"
          fill={"#829FC0"}
        />
        <path d="M241,128.3h-6c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S241.6,128.3,241,128.3z" fill={"#829FC0"} />
      </g>
    </g>
    <g>
      <path
        d="M209,38.8H33c-1.7,0-3.1-1.4-3.1-3.1V23.6c0-1.7,1.4-3.1,3.1-3.1H209c1.7,0,3.1,1.4,3.1,3.1
                v12.1C212.1,37.4,210.7,38.8,209,38.8z"
        fill={"#C8D7E3"}
      />
      <path
        d="M209,39.8H33c-2.2,0-4.1-1.8-4.1-4.1V23.6c0-2.2,1.8-4.1,4.1-4.1H209c2.2,0,4.1,1.8,4.1,4.1
                v12.1C213.1,38,211.3,39.8,209,39.8z M33,21.6c-1.1,0-2.1,0.9-2.1,2.1v12.1c0,1.1,0.9,2.1,2.1,2.1H209c1.1,0,2.1-0.9,2.1-2.1V23.6
                c0-1.1-0.9-2.1-2.1-2.1H33z"
        fill={"#829FC0"}
      />
    </g>
    <path
      d="M209,20.6h-5.4v9.2c0,1.7-1.4,3.1-3.1,3.1H29.9v2.9c0,1.7,1.4,3.1,3.1,3.1H209c1.7,0,3.1-1.4,3.1-3.1V23.6
            C212.1,21.9,210.7,20.6,209,20.6z"
      fill={"#829FC0"}
      opacity={"0.2"}
    />
    <g>
      <path
        d="M209,152.6H33c-1.7,0-3.1-1.4-3.1-3.1v-6.7c0-1.7,1.4-3.1,3.1-3.1H209c1.7,0,3.1,1.4,3.1,3.1
                v6.7C212.1,151.2,210.7,152.6,209,152.6z"
        fill={"#C8D7E3"}
      />
      <path
        d="M209,153.6H33c-2.2,0-4.1-1.8-4.1-4.1v-6.7c0-2.2,1.8-4.1,4.1-4.1H209c2.2,0,4.1,1.8,4.1,4.1
                v6.7C213.1,151.8,211.3,153.6,209,153.6z M33,140.8c-1.1,0-2.1,0.9-2.1,2.1v6.7c0,1.1,0.9,2.1,2.1,2.1H209c1.1,0,2.1-0.9,2.1-2.1
                v-6.7c0-1.1-0.9-2.1-2.1-2.1H33z"
        fill={"#829FC0"}
      />
    </g>
    <g>
      <line fill={"#C8D7E3"} x1="121" x2="121" y1="19.6" y2="3" />
      <path
        d="M121,20.6c-0.6,0-1-0.4-1-1V3c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1v16.6
                C122,20.1,121.6,20.6,121,20.6z"
        fill={"#829FC0"}
      />
    </g>
    <path
      d="M209,139.4h-5.4v4.6c0,1.7-1.4,3.1-3.1,3.1H29.9v2.9c0,1.7,1.4,3.1,3.1,3.1H209c1.7,0,3.1-1.4,3.1-3.1v-7.5
            C212.1,140.8,210.7,139.4,209,139.4z"
      fill={"#829FC0"}
      opacity={"0.2"}
    />
    <path
      d="M75.3,102.2c-2.6,0-4.7-2.1-4.7-4.7s2.1-4.7,4.7-4.7s4.7,2.1,4.7,4.7S77.9,102.2,75.3,102.2z M75.3,94.8
            c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7s2.7-1.2,2.7-2.7S76.8,94.8,75.3,94.8z"
      fill={"#829FC0"}
    />
    <g>
      <path
        d="M164.3,102.2c-2.6,0-4.7-2.1-4.7-4.7s2.1-4.7,4.7-4.7s4.7,2.1,4.7,4.7S166.9,102.2,164.3,102.2z M164.3,94.8
                c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7c1.5,0,2.7-1.2,2.7-2.7S165.8,94.8,164.3,94.8z"
        fill={"#829FC0"}
      />
    </g>
    <path
      d="M119.8,102.3c3.3,0,6.3,1.9,7.6,4.9c0.2,0.5,0,1.1-0.5,1.3c-0.5,0.2-1.1,0-1.3-0.5c-1-2.3-3.3-3.8-5.9-3.8
            c-2.6,0-4.9,1.5-5.9,3.9c-0.2,0.5-0.8,0.7-1.3,0.5c-0.5-0.2-0.7-0.8-0.5-1.3C113.5,104.3,116.5,102.3,119.8,102.3z"
      fill={"#829FC0"}
    />
    <path
      d="M71.7,86.4c0,0,0.1,0,0.1,0c0.5,0.1,0.9,0.6,0.8,1.1c-0.3,1.8-1.1,3.4-2.4,4.7c-1,1-2.1,1.7-3.4,2.1
            c-0.5,0.2-1.1-0.1-1.2-0.6c-0.2-0.5,0.1-1.1,0.6-1.2c1-0.3,1.9-0.8,2.6-1.6c1-1,1.6-2.2,1.8-3.6C70.8,86.7,71.2,86.4,71.7,86.4z"
      fill={"#E4EBF1"}
    />
    <path
      d="M168.5,86.4c0.5,0,0.9,0.3,1,0.8c0.2,1.4,0.8,2.6,1.8,3.6c0.7,0.7,1.6,1.3,2.6,1.6c0.5,0.2,0.8,0.7,0.6,1.2
            c-0.2,0.5-0.7,0.8-1.2,0.6c-1.3-0.4-2.5-1.1-3.4-2.1c-1.3-1.3-2.1-2.9-2.4-4.7c-0.1-0.5,0.3-1,0.8-1.1
            C168.4,86.4,168.4,86.4,168.5,86.4z"
      fill={"#E4EBF1"}
    />
    <path
      d="M77.4,123.1H65.9c-2,0-3.7-1.7-3.7-3.7v0c0-2,1.7-3.7,3.7-3.7h11.6c2,0,3.7,1.7,3.7,3.7v0
            C81.1,121.5,79.5,123.1,77.4,123.1z"
      fill={"#E4EBF1"}
    />
    <rect fill={"#829FC0"} height="6.7" opacity={"0.2"} width="163.8" x="39.7" y="38.8" />
    <path
      d="M209,20.6h-49.2c-1.1,3.5-1.7,7.3-1.7,11.2c0,21.3,17.2,38.5,38.5,38.5c1.9,0,3.8-0.1,5.6-0.4V38.8h6.7
            c1.7,0,3.1-1.4,3.1-3.1V23.6C212.1,21.9,210.7,20.6,209,20.6z"
      fill={"#829FC0"}
      opacity={"0.2"}
    />
    <path
      d="M172.6,123.1h-11.6c-2,0-3.7-1.7-3.7-3.7v0c0-2,1.7-3.7,3.7-3.7h11.6c2,0,3.7,1.7,3.7,3.7v0
            C176.3,121.5,174.7,123.1,172.6,123.1z"
      fill={"#E4EBF1"}
    />
    <g>
      <circle cx="196.7" cy="31.8" fill={"#FFFFFF"} r="30.8" />
      <path
        d="M196.7,63.5c-17.5,0-31.8-14.3-31.8-31.8S179.1,0,196.7,0s31.8,14.3,31.8,31.8
                S214.2,63.5,196.7,63.5z M196.7,2c-16.4,0-29.8,13.4-29.8,29.8s13.4,29.8,29.8,29.8s29.8-13.4,29.8-29.8S213.1,2,196.7,2z"
        fill={"#829FC0"}
      />
    </g>
    <path
      d="M189.4,58.1c-3.4,0-6.7-0.6-9.8-1.6c5,3.8,11.2,6,18,6c16.5,0,29.9-13.4,29.9-29.9c0-11.1-6.1-20.9-15.2-26
            c4.9,5.5,8,12.7,8,20.7C220.2,44.3,206.4,58.1,189.4,58.1z"
      fill={"#829FC0"}
      opacity={"0.2"}
    />
    <g>
      <path
        d="M203.6,43.8c-0.2,0-0.4,0-0.5-0.2L186.2,33c-0.3-0.2-0.5-0.5-0.5-0.8s0.2-0.7,0.5-0.8
                l16.9-10.7c0.5-0.3,1.1-0.2,1.4,0.3c0.3,0.5,0.2,1.1-0.3,1.4l-15.6,9.9l15.6,9.9c0.5,0.3,0.6,0.9,0.3,1.4
                C204.3,43.7,204,43.8,203.6,43.8z"
        fill={"#829FC0"}
      />
      <g>
        <circle cx="204.6" cy="20.7" fill={"#FFFFFF"} r="3.8" />
        <path
          d="M204.6,25.5c-2.7,0-4.8-2.2-4.8-4.8c0-2.7,2.2-4.8,4.8-4.8s4.8,2.2,4.8,4.8
                    C209.4,23.4,207.3,25.5,204.6,25.5z M204.6,17.9c-1.6,0-2.8,1.3-2.8,2.8s1.3,2.8,2.8,2.8s2.8-1.3,2.8-2.8S206.2,17.9,204.6,17.9z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <circle cx="186.7" cy="32.1" fill={"#FFFFFF"} r="3.8" />
        <path
          d="M186.7,36.9c-2.7,0-4.8-2.2-4.8-4.8s2.2-4.8,4.8-4.8s4.8,2.2,4.8,4.8S189.4,36.9,186.7,36.9z
                    M186.7,29.3c-1.6,0-2.8,1.3-2.8,2.8s1.3,2.8,2.8,2.8s2.8-1.3,2.8-2.8S188.3,29.3,186.7,29.3z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <circle cx="204.6" cy="42.8" fill={"#FFFFFF"} r="3.8" />
        <path
          d="M204.6,47.6c-2.7,0-4.8-2.2-4.8-4.8c0-2.7,2.2-4.8,4.8-4.8s4.8,2.2,4.8,4.8
                    C209.4,45.5,207.3,47.6,204.6,47.6z M204.6,40c-1.6,0-2.8,1.3-2.8,2.8s1.3,2.8,2.8,2.8s2.8-1.3,2.8-2.8S206.2,40,204.6,40z"
          fill={"#829FC0"}
        />
      </g>
    </g>
  </svg>
)

export const SharedTeamOwnerEmptySvg = () => (
  <svg version="1.1" viewBox="0 0 206.9 202.3" x="0px" y="0px">
    <g>
      <g opacity={"0.3"}>
        <circle cx="81.2" cy="11" fill={"#829FC0"} r="11" />
        <circle cx="11" cy="180.1" fill={"#829FC0"} r="11" />
        <circle cx="199.3" cy="111.9" fill={"#829FC0"} r="7.6" />
        <g>
          <path
            d="M80.8,12.6c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.4-0.9c0.1-0.2,0.3-0.4,0.5-0.4
                        c0.2-0.1,0.5,0,0.7,0.2l0.8,0.6c0.3,0.3,0.4,0.7,0.1,1.1c-0.3,0.3-0.7,0.4-1,0.1C81.4,12.5,81.1,12.6,80.8,12.6z"
            fill={"#829FC0"}
          />
          <path
            d="M12.9,176.3c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4
                        c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8C13.4,176.1,13.2,176.3,12.9,176.3z M15.1,170.8c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1
                        l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8C15.7,170.7,15.4,170.8,15.1,170.8z M17.4,165.4
                        c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8
                        C18,165.2,17.7,165.4,17.4,165.4z M19.7,159.9c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4
                        c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8C20.2,159.8,20,159.9,19.7,159.9z M21.9,154.5c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1
                        l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8C22.5,154.3,22.2,154.5,21.9,154.5z M24.2,149
                        c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8
                        C24.8,148.8,24.5,149,24.2,149z M26.5,143.6c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4
                        c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8C27,143.4,26.8,143.6,26.5,143.6z M28.7,138.1c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1
                        l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8C29.3,137.9,29,138.1,28.7,138.1z M31,132.7c-0.1,0-0.2,0-0.3-0.1
                        c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8C31.6,132.5,31.3,132.7,31,132.7z
                        M33.3,127.2c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8
                        C33.8,127,33.6,127.2,33.3,127.2z M35.5,121.7c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4
                        c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8C36.1,121.6,35.8,121.7,35.5,121.7z M37.8,116.3c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1
                        l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8C38.4,116.1,38.1,116.3,37.8,116.3z M40.1,110.8
                        c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8
                        C40.6,110.7,40.3,110.8,40.1,110.8z M42.3,105.4c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4
                        c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8C42.9,105.2,42.6,105.4,42.3,105.4z M44.6,99.9c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1
                        l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8C45.2,99.8,44.9,99.9,44.6,99.9z M46.9,94.5c-0.1,0-0.2,0-0.3-0.1
                        c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1L47.5,94C47.4,94.3,47.1,94.5,46.9,94.5z M49.1,89
                        c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8
                        C49.7,88.8,49.4,89,49.1,89z M51.4,83.6c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4
                        c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8C52,83.4,51.7,83.6,51.4,83.6z M53.7,78.1c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.8
                        c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8C54.2,77.9,53.9,78.1,53.7,78.1z M55.9,72.6c-0.1,0-0.2,0-0.3-0.1
                        c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8C56.5,72.5,56.2,72.6,55.9,72.6z
                        M58.2,67.2c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8
                        C58.8,67,58.5,67.2,58.2,67.2z M60.5,61.7c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4
                        c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8C61,61.6,60.7,61.7,60.5,61.7z M62.7,56.3c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.8
                        c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8C63.3,56.1,63,56.3,62.7,56.3z M65,50.8c-0.1,0-0.2,0-0.3-0.1
                        c-0.4-0.2-0.6-0.6-0.4-1L65,48c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8C65.6,50.7,65.3,50.8,65,50.8z M67.2,45.4
                        c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8
                        C67.8,45.2,67.5,45.4,67.2,45.4z M69.5,39.9c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4
                        c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8C70.1,39.7,69.8,39.9,69.5,39.9z M71.8,34.5c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1
                        l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1L72.5,34C72.4,34.3,72.1,34.5,71.8,34.5z M74,29c-0.1,0-0.2,0-0.3-0.1
                        c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8C74.6,28.8,74.3,29,74,29z M76.3,23.5
                        c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1L77,23.1
                        C76.9,23.4,76.6,23.5,76.3,23.5z M78.6,18.1c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.8c0.2-0.4,0.6-0.6,1-0.4
                        c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.8C79.2,17.9,78.9,18.1,78.6,18.1z"
            fill={"#829FC0"}
          />
          <path
            d="M11,180.9c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.2-0.3-0.5-0.1-0.8l0.4-0.9c0.2-0.4,0.6-0.6,1-0.4
                        c0.2,0.1,0.4,0.3,0.4,0.5c0.2,0.1,0.4,0.2,0.5,0.5c0.1,0.4-0.1,0.8-0.5,1l-0.9,0.3C11.1,180.8,11.1,180.9,11,180.9z"
            fill={"#829FC0"}
          />
          <path
            d="M15.7,179.1c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5
                        c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C15.9,179.1,15.8,179.1,15.7,179.1z M21.4,177.1c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1
                        l1.9-0.7c0.4-0.1,0.8,0.1,1,0.4c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C21.6,177.1,21.5,177.1,21.4,177.1z M27.1,175
                        c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7
                        C27.3,175,27.2,175,27.1,175z M32.8,172.9c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5
                        c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C33,172.9,32.9,172.9,32.8,172.9z M38.5,170.9c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1
                        l1.9-0.7c0.4-0.1,0.8,0.1,1,0.4c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C38.7,170.9,38.6,170.9,38.5,170.9z M44.2,168.8
                        c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7
                        C44.4,168.8,44.3,168.8,44.2,168.8z M50,166.7c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5
                        c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C50.1,166.7,50,166.7,50,166.7z M55.7,164.7c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1
                        l1.9-0.7c0.4-0.1,0.8,0.1,1,0.4c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C55.8,164.7,55.7,164.7,55.7,164.7z M61.4,162.6
                        c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7
                        C61.5,162.6,61.5,162.6,61.4,162.6z M67.1,160.5c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5
                        c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C67.2,160.5,67.2,160.5,67.1,160.5z M72.8,158.5c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1
                        l1.9-0.7c0.4-0.1,0.8,0.1,1,0.4c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C73,158.5,72.9,158.5,72.8,158.5z M78.5,156.4
                        c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7
                        C78.7,156.4,78.6,156.4,78.5,156.4z M84.2,154.3c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5
                        c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C84.4,154.3,84.3,154.3,84.2,154.3z M89.9,152.3c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1
                        l1.9-0.7c0.4-0.1,0.8,0.1,1,0.4c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C90.1,152.3,90,152.3,89.9,152.3z M95.6,150.2
                        c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7
                        C95.8,150.2,95.7,150.2,95.6,150.2z M101.3,148.1c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5
                        c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C101.5,148.1,101.4,148.1,101.3,148.1z M107,146.1c-0.3,0-0.6-0.2-0.7-0.5
                        c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.4c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C107.2,146.1,107.1,146.1,107,146.1z
                        M112.7,144c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.4,1L113,144
                        C112.9,144,112.8,144,112.7,144z M118.4,141.9c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5
                        c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C118.6,141.9,118.5,141.9,118.4,141.9z M124.2,139.9c-0.3,0-0.6-0.2-0.7-0.5
                        c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.4c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C124.3,139.9,124.2,139.9,124.2,139.9z
                        M129.9,137.8c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7
                        C130,137.8,129.9,137.8,129.9,137.8z M135.6,135.7c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.5-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5
                        c0.1,0.4-0.1,0.8-0.5,1l-1.9,0.7C135.7,135.7,135.6,135.7,135.6,135.7z M141.3,133.7c-0.3,0-0.6-0.2-0.7-0.5
                        c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.4c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C141.4,133.7,141.4,133.7,141.3,133.7z
                        M147,131.6c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.5-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.5,1l-1.9,0.7
                        C147.2,131.6,147.1,131.6,147,131.6z M152.7,129.5c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.5-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5
                        c0.1,0.4-0.1,0.8-0.5,1l-1.9,0.7C152.9,129.5,152.8,129.5,152.7,129.5z M158.4,127.5c-0.3,0-0.6-0.2-0.7-0.5
                        c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.4c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C158.6,127.5,158.5,127.5,158.4,127.5z
                        M164.1,125.4c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.5-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.5,1l-1.9,0.7
                        C164.3,125.4,164.2,125.4,164.1,125.4z M169.8,123.3c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.5-1l1.9-0.7
                        c0.4-0.1,0.8,0.1,1,0.5s-0.1,0.8-0.5,1l-1.9,0.7C170,123.3,169.9,123.3,169.8,123.3z M175.5,121.3c-0.3,0-0.6-0.2-0.7-0.5
                        c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.4c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C175.7,121.3,175.6,121.3,175.5,121.3z
                        M181.2,119.2c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.4c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7
                        C181.4,119.2,181.3,119.2,181.2,119.2z M186.9,117.1c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.5-1l1.9-0.7
                        c0.4-0.1,0.8,0.1,1,0.5s-0.1,0.8-0.5,1l-1.9,0.7C187.1,117.1,187,117.1,186.9,117.1z M192.6,115.1c-0.3,0-0.6-0.2-0.7-0.5
                        c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.4c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C192.8,115.1,192.7,115.1,192.6,115.1z"
            fill={"#829FC0"}
          />
          <path
            d="M198.3,113c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.3,0-0.7,0.2-0.9c-0.2-0.3-0.2-0.6,0.1-0.9
                        c0.3-0.3,0.7-0.3,1.1-0.1l0.8,0.6c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.2,0.5-0.5,0.6l-0.9,0.3C198.5,113,198.4,113,198.3,113z"
            fill={"#829FC0"}
          />
          <path
            d="M195.5,109.4c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1
                        c0.3-0.3,0.7-0.3,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C195.9,109.3,195.7,109.4,195.5,109.4z M191,105.5
                        c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.3,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1
                        C191.4,105.5,191.2,105.5,191,105.5z M186.4,101.7c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1
                        c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C186.8,101.6,186.6,101.7,186.4,101.7z M181.9,97.8
                        c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.3,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1
                        C182.3,97.7,182.1,97.8,181.9,97.8z M177.3,93.9c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1
                        c0.3-0.3,0.7-0.3,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C177.8,93.8,177.5,93.9,177.3,93.9z M172.8,90c-0.2,0-0.3-0.1-0.5-0.2
                        l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.3,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C173.2,89.9,173,90,172.8,90z
                        M168.3,86.1c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3
                        c0.3,0.3,0.4,0.7,0.1,1.1C168.7,86,168.5,86.1,168.3,86.1z M163.7,82.2c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3
                        c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C164.1,82.2,163.9,82.2,163.7,82.2z
                        M159.2,78.4c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3
                        c0.3,0.3,0.4,0.7,0.1,1.1C159.6,78.3,159.4,78.4,159.2,78.4z M154.6,74.5c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3
                        c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C155.1,74.4,154.8,74.5,154.6,74.5z
                        M150.1,70.6c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3
                        c0.3,0.3,0.4,0.7,0.1,1.1C150.5,70.5,150.3,70.6,150.1,70.6z M145.6,66.7c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3
                        c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C146,66.6,145.8,66.7,145.6,66.7z M141,62.8
                        c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1
                        C141.4,62.7,141.2,62.8,141,62.8z M136.5,59c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1
                        l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C136.9,58.9,136.7,59,136.5,59z M131.9,55.1c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3
                        c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C132.4,55,132.1,55.1,131.9,55.1z
                        M127.4,51.2c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3
                        c0.3,0.3,0.4,0.7,0.1,1.1C127.8,51.1,127.6,51.2,127.4,51.2z M122.9,47.3c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3
                        c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C123.3,47.2,123.1,47.3,122.9,47.3z
                        M118.3,43.4c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3
                        c0.3,0.3,0.4,0.7,0.1,1.1C118.7,43.3,118.5,43.4,118.3,43.4z M113.8,39.5c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3
                        c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C114.2,39.5,114,39.5,113.8,39.5z
                        M109.2,35.7c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3
                        c0.3,0.3,0.4,0.7,0.1,1.1C109.7,35.6,109.4,35.7,109.2,35.7z M104.7,31.8c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3
                        c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C105.1,31.7,104.9,31.8,104.7,31.8z
                        M100.2,27.9c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3
                        c0.3,0.3,0.4,0.7,0.1,1.1C100.6,27.8,100.4,27.9,100.2,27.9z M95.6,24c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1
                        c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C96,23.9,95.8,24,95.6,24z M91.1,20.1c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3
                        c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C91.5,20,91.3,20.1,91.1,20.1z M86.5,16.2
                        c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1
                        C87,16.2,86.7,16.2,86.5,16.2z"
            fill={"#829FC0"}
          />
        </g>
      </g>
      <g>
        <circle cx="98" cy="114.5" fill={"#E4EBF1"} r="80.9" />
        <path
          d="M98,196.4c-45.1,0-81.9-36.7-81.9-81.9c0-45.1,36.7-81.9,81.9-81.9
                    c45.1,0,81.9,36.7,81.9,81.9C179.9,159.7,143.1,196.4,98,196.4z M98,34.7c-44,0-79.9,35.8-79.9,79.9S54,194.4,98,194.4
                    c44,0,79.9-35.8,79.9-79.9S142,34.7,98,34.7z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <circle cx="98" cy="114.5" fill={"#FFFFFF"} r="69.6" />
        <path
          d="M98,185.1c-38.9,0-70.6-31.7-70.6-70.6c0-38.9,31.7-70.6,70.6-70.6
                    c38.9,0,70.6,31.7,70.6,70.6C168.6,153.5,136.9,185.1,98,185.1z M98,45.9c-37.8,0-68.6,30.8-68.6,68.6c0,37.8,30.8,68.6,68.6,68.6
                    c37.8,0,68.6-30.8,68.6-68.6C166.6,76.7,135.8,45.9,98,45.9z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <path
          d="M111.1,143.6L111.1,143.6l0-9.2H85v9c-14,3-26.2,11-34.6,22C62.8,177,79.6,184.1,98,184.1
                    c18.1,0,34.6-6.9,46.9-18.2C136.8,154.9,124.9,146.8,111.1,143.6z"
          fill={"#FFFFFF"}
        />
        <path
          d="M98,185.1c-18,0-35.2-6.8-48.3-19.1c-0.4-0.4-0.4-0.9-0.1-1.3c8.6-11.2,20.7-19.1,34.4-22.2
                    v-8.2c0-0.6,0.4-1,1-1h26.1c0.6,0,1,0.4,1,1v8.5c13.4,3.4,25.4,11.4,33.7,22.6c0.3,0.4,0.2,1-0.1,1.3
                    C132.6,178.6,115.7,185.1,98,185.1z M51.7,165.2c12.7,11.6,29.1,17.9,46.3,17.9c16.9,0,33-6.2,45.6-17.3
                    c-8.2-10.7-19.7-18.2-32.7-21.3c-0.5-0.1-0.8-0.5-0.8-1l0-8.2H86v8c0,0.5-0.3,0.9-0.8,1C72,147.1,60.1,154.5,51.7,165.2z"
          fill={"#829FC0"}
        />
      </g>
      <path
        d="M111,140.7V134H85v6.8c4,1.5,8.4,2.4,13,2.4h0C102.6,143.2,107,142.3,111,140.7z"
        fill={"#829FC0"}
        opacity={"0.2"}
      />
      <g>
        <path
          d="M98,137.8L98,137.8c-20.1,0-36.5-16.3-36.5-36.5V74.7c0-20.1,16.3-36.5,36.5-36.5h0
                    c20.1,0,36.5,16.3,36.5,36.5v26.7C134.5,121.5,118.1,137.8,98,137.8z"
          fill={"#FFFFFF"}
        />
        <path
          d="M98,138.8c-20.7,0-37.5-16.8-37.5-37.5V74.7C60.5,54,77.3,37.2,98,37.2s37.5,16.8,37.5,37.5
                    v26.7C135.5,122,118.6,138.8,98,138.8z M98,39.2c-19.6,0-35.5,15.9-35.5,35.5v26.7c0,19.6,15.9,35.5,35.5,35.5
                    c19.6,0,35.5-15.9,35.5-35.5V74.7C133.5,55.1,117.5,39.2,98,39.2z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <path
          d="M101,104h-6c-1.8,0-3.1-1.8-2.5-3.5l3.2-9.1c0.8-2.4,4.2-2.3,4.9,0.1l2.8,9.1
                    C104,102.3,102.8,104,101,104z"
          fill={"#C8D7E3"}
        />
        <path
          d="M101,105h-6c-1.2,0-2.3-0.6-2.9-1.5c-0.7-1-0.8-2.2-0.4-3.3l3.2-9.1c0.5-1.5,1.9-2.4,3.5-2.4
                    c1.6,0,2.9,1,3.4,2.5l2.8,9.1c0.3,1.1,0.1,2.3-0.5,3.2C103.2,104.5,102.2,105,101,105z M98.2,90.7c-0.6,0-1.2,0.3-1.5,1.1
                    l-3.2,9.1c-0.2,0.5-0.1,1,0.2,1.5c0.3,0.4,0.8,0.7,1.3,0.7h6c0.5,0,1-0.2,1.3-0.7c0.3-0.4,0.4-0.9,0.2-1.4l-2.8-9.1
                    C99.5,91,98.8,90.7,98.2,90.7C98.2,90.7,98.2,90.7,98.2,90.7z"
          fill={"#829FC0"}
        />
      </g>
      <path
        d="M103.5,100.6l-2.8-9.1c-0.7-2.1-3.4-2.4-4.6-0.8c0.2,0.2,0.3,0.5,0.4,0.8l2.8,9.1c0.5,1.7-0.7,3.4-2.5,3.4h4.2
                C102.8,104,104,102.3,103.5,100.6z"
        fill={"#829FC0"}
        opacity={"0.2"}
      />
      <g>
        <path d="M72,96.3c0-3.5,2.9-6.4,6.4-6.4s6.4,2.9,6.4,6.4" fill={"#FFFFFF"} />
        <path
          d="M84.7,97.3c-0.6,0-1-0.4-1-1c0-3-2.4-5.4-5.4-5.4S73,93.3,73,96.3c0,0.6-0.4,1-1,1
                    s-1-0.4-1-1c0-4.1,3.3-7.4,7.4-7.4s7.4,3.3,7.4,7.4C85.7,96.8,85.3,97.3,84.7,97.3z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <path d="M110.3,96.3c0-3.5,2.9-6.4,6.4-6.4s6.4,2.9,6.4,6.4" fill={"#FFFFFF"} />
        <path
          d="M123.1,97.3c-0.6,0-1-0.4-1-1c0-3-2.4-5.4-5.4-5.4c-3,0-5.4,2.4-5.4,5.4c0,0.6-0.4,1-1,1
                    s-1-0.4-1-1c0-4.1,3.3-7.4,7.4-7.4s7.4,3.3,7.4,7.4C124.1,96.8,123.6,97.3,123.1,97.3z"
          fill={"#829FC0"}
        />
      </g>
      <circle cx="72.7" cy="111.9" fill={"#E8EEF3"} r="6.7" />
      <circle cx="122.3" cy="111.9" fill={"#E8EEF3"} r="6.7" />
      <g>
        <path d="M61.5,101.3H58c-3.4,0-6.2-2.8-6.2-6.2v0c0-3.4,2.8-6.2,6.2-6.2h3.5V101.3z" fill={"#FFFFFF"} />
        <path
          d="M61.5,102.3H58c-4,0-7.2-3.2-7.2-7.2c0-4,3.2-7.2,7.2-7.2h3.5c0.6,0,1,0.4,1,1v12.5
                    C62.5,101.9,62.1,102.3,61.5,102.3z M58,89.8c-2.9,0-5.2,2.4-5.2,5.2s2.4,5.2,5.2,5.2h2.5V89.8H58z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <path d="M58.5,97.1c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2" fill={"#FFFFFF"} />
        <path
          d="M58.5,98.1c-1.7,0-3.2-1.4-3.2-3.2c0-1.7,1.4-3.2,3.2-3.2c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1
                    c-0.6,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.2,1.2,1.2c0.6,0,1,0.4,1,1S59.1,98.1,58.5,98.1z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <path d="M134.5,88.8h3.5c3.4,0,6.2,2.8,6.2,6.2v0c0,3.4-2.8,6.2-6.2,6.2h-3.5V88.8z" fill={"#FFFFFF"} />
        <path
          d="M137.9,102.3h-3.5c-0.6,0-1-0.4-1-1V88.8c0-0.6,0.4-1,1-1h3.5c4,0,7.2,3.2,7.2,7.2
                    C145.2,99.1,141.9,102.3,137.9,102.3z M135.5,100.3h2.5c2.9,0,5.2-2.4,5.2-5.2s-2.3-5.2-5.2-5.2h-2.5V100.3z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <path d="M137.4,93.1c1.2,0,2.2,1,2.2,2.2c0,1.2-1,2.2-2.2,2.2" fill={"#FFFFFF"} />
        <path
          d="M137.4,98.4c-0.6,0-1-0.4-1-1s0.4-1,1-1c0.6,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2
                    c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1c1.7,0,3.2,1.4,3.2,3.2C140.6,97,139.2,98.4,137.4,98.4z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <path
          d="M98.9,121.7h-1.8c-6.6,0-11.9-5.3-11.9-11.9v0h25.6v0C110.8,116.4,105.5,121.7,98.9,121.7z"
          fill={"#E4EBF1"}
        />
        <path
          d="M98.9,122.7h-1.8c-7.1,0-12.9-5.8-12.9-12.9c0-0.6,0.4-1,1-1h25.6c0.6,0,1,0.4,1,1
                    C111.8,116.9,106,122.7,98.9,122.7z M86.2,110.8c0.5,5.5,5.2,9.9,10.8,9.9h1.8c5.7,0,10.3-4.3,10.8-9.9H86.2z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <path d="M86.2,114.6h23.6c0.6-1.5,1-3,1-4.7H85.2C85.2,111.5,85.6,113.1,86.2,114.6z" fill={"#FFFFFF"} />
        <path
          d="M109.8,115.6H86.2c-0.4,0-0.8-0.2-0.9-0.6c-0.7-1.6-1.1-3.4-1.1-5.1c0-0.6,0.4-1,1-1h25.6
                    c0.6,0,1,0.4,1,1c0,1.8-0.4,3.5-1.1,5.1C110.5,115.4,110.2,115.6,109.8,115.6z M86.9,113.6h22.2c0.3-0.9,0.5-1.8,0.6-2.7H86.2
                    C86.3,111.8,86.5,112.7,86.9,113.6z"
          fill={"#829FC0"}
        />
      </g>
      <path
        d="M106.1,115.5c-0.6,0-1-0.4-1-1v-2.1c0-0.6,0.4-1,1-1s1,0.4,1,1v2.1
                C107.1,115.1,106.6,115.5,106.1,115.5z"
        fill={"#829FC0"}
      />
      <path
        d="M103.6,109.8L103.6,109.8c0,5.6-3.9,10.3-9.2,11.6c0.9,0.2,1.7,0.3,2.7,0.3h1.8c6.6,0,11.9-5.3,11.9-11.9l0,0
                H103.6z"
        fill={"#829FC0"}
        opacity={"0.2"}
      />
      <g>
        <line fill={"#FFFFFF"} x1="85.2" x2="85.2" y1="135.5" y2="149.1" />
        <path
          d="M85.2,150.1c-0.6,0-1-0.4-1-1v-13.6c0-0.6,0.4-1,1-1s1,0.4,1,1v13.6
                    C86.2,149.7,85.8,150.1,85.2,150.1z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <path
          d="M120.6,146.7c-3,9.5-12,16.4-22.5,16.4c-10.7,0-19.8-7.1-22.7-16.9c-10,4-18.6,10.7-25,19.1
                    C62.8,177,79.6,184.1,98,184.1c18.1,0,34.6-6.9,46.9-18.2C138.7,157.5,130.3,150.8,120.6,146.7z"
          fill={"#C8D7E3"}
        />
        <path
          d="M98,185.1c-18,0-35.2-6.8-48.3-19.1l-0.7-0.6l0.5-0.7c6.6-8.7,15.4-15.4,25.5-19.4l1-0.4
                    l0.3,1.1c2.8,9.5,11.7,16.2,21.7,16.2c9.9,0,18.6-6.3,21.6-15.7l0.3-1l1,0.4c9.8,4.2,18.4,11,24.7,19.6l0.5,0.7l-0.7,0.6
                    C132.6,178.6,115.7,185.1,98,185.1z M51.7,165.2c12.7,11.6,29.1,17.9,46.3,17.9c16.9,0,33-6.2,45.6-17.3
                    c-5.9-7.7-13.6-13.8-22.4-17.7c-3.6,9.7-12.7,16.1-23.1,16.1c-10.5,0-19.9-6.8-23.3-16.6C65.8,151.4,57.8,157.5,51.7,165.2z"
          fill={"#829FC0"}
        />
      </g>
      <path
        d="M148.4,143.5c-17.5,0-31.7,14.2-31.7,31.7c0,5.8,1.6,11.3,4.4,16c20.9-6.3,38.2-20.9,48.1-40
                C163.6,146.4,156.3,143.5,148.4,143.5z"
        fill={"#829FC0"}
        opacity={"0.2"}
      />
      <g>
        <line fill={"#FFFFFF"} x1="111.1" x2="111.1" y1="135.5" y2="149.1" />
        <path
          d="M111.1,150.1c-0.6,0-1-0.4-1-1v-13.6c0-0.6,0.4-1,1-1s1,0.4,1,1v13.6
                    C112.1,149.7,111.6,150.1,111.1,150.1z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <circle cx="148.4" cy="175.2" fill={"#FFFFFF"} r="26.1" />
        <path
          d="M148.4,202.3c-14.9,0-27.1-12.2-27.1-27.1s12.2-27.1,27.1-27.1s27.1,12.2,27.1,27.1
                    S163.3,202.3,148.4,202.3z M148.4,150.1c-13.8,0-25.1,11.3-25.1,25.1s11.3,25.1,25.1,25.1s25.1-11.3,25.1-25.1
                    S162.2,150.1,148.4,150.1z"
          fill={"#829FC0"}
        />
      </g>
      <path
        d="M141.5,196.8c-2.9,0-5.7-0.5-8.3-1.4c4.2,3.2,9.5,5.1,15.2,5.1c14,0,25.3-11.4,25.3-25.3
                c0-9.4-5.2-17.7-12.9-22c4.2,4.6,6.7,10.8,6.7,17.5C167.6,185.1,155.9,196.8,141.5,196.8z"
        fill={"#829FC0"}
        opacity={"0.3"}
      />
      <g>
        <line fill={"#FFFFFF"} x1="66" x2="66" y1="169.9" y2="176.3" />
        <path
          d="M66,177.3c-0.6,0-1-0.4-1-1v-6.4c0-0.6,0.4-1,1-1s1,0.4,1,1v6.4C67,176.9,66.5,177.3,66,177.3
                    z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <path
          d="M117.4,51.2c10.3,6.5,17.1,18,17.1,31v-7.5c0-20.1-16.3-36.5-36.5-36.5h0
                    c-20.1,0-36.5,16.3-36.5,36.5v7.5c0-13,6.8-24.4,17.1-30.9c3,7.8,10.5,13.4,19.4,13.4s16.4-5.6,19.4-13.4L117.4,51.2"
          fill={"#C8D7E3"}
        />
        <path
          d="M134.5,83.2c-0.6,0-1-0.4-1-1c0-11.9-5.8-22.9-15.6-29.5c-3.4,7.8-11.3,13-19.9,13
                    c-8.6,0-16.4-5.1-19.9-12.9c-9.8,6.6-15.6,17.5-15.6,29.4c0,0.6-0.4,1-1,1s-1-0.4-1-1v-7.5C60.5,54,77.3,37.2,98,37.2
                    s37.5,16.8,37.5,37.5v7.5C135.5,82.8,135,83.2,134.5,83.2z M98,39.2c-17.5,0-32.1,12.8-35,29.5c2.9-7.4,8.1-13.8,15-18.2
                    c0.3-0.2,0.6-0.2,0.9-0.1c0.3,0.1,0.5,0.3,0.6,0.6c2.9,7.6,10.3,12.7,18.5,12.7c8.1,0,15.5-5.1,18.4-12.6c0-0.3,0.2-0.6,0.6-0.8
                    c0.3-0.2,0.7-0.1,1,0c7,4.4,12.1,10.7,15,18.2C130,51.9,115.5,39.2,98,39.2z"
          fill={"#829FC0"}
        />
      </g>
      <path
        d="M114.9,42l0,0.1c-3,7.8-10.5,13.4-19.4,13.4c-3.8,0-7.3-1-10.3-2.8c3.6,3,8.2,4.8,13.3,4.8
                c8.9,0,16.4-5.6,19.4-13.4l0-0.1C116.9,43.4,115.9,42.7,114.9,42z"
        fill={"#829FC0"}
        opacity={"0.2"}
      />
      <path
        d="M107.7,38.7L107.7,38.7c-1.9,5.1-6.8,8.7-12.5,8.7c-2.4,0-4.7-0.6-6.6-1.8c2.3,1.9,5.3,3.1,8.5,3.1
                c5.7,0,10.6-3.6,12.5-8.6l0-0.1C109,39.6,108.4,39.1,107.7,38.7z"
        fill={"#829FC0"}
        opacity={"0.2"}
      />
      <path
        d="M69.1,87.5c-0.2,0-0.3,0-0.5-0.1c-0.5-0.3-0.7-0.9-0.4-1.4c0.8-1.6,3.5-4.3,7.8-3.6
                c0.5,0.1,0.9,0.6,0.8,1.1c-0.1,0.5-0.6,0.9-1.1,0.8C71.8,83.8,70,87,70,87C69.8,87.3,69.5,87.5,69.1,87.5z"
        fill={"#E4EBF1"}
      />
      <path
        d="M125.8,87.5c-0.4,0-0.7-0.2-0.9-0.5c-0.1-0.1-1.8-3.2-5.8-2.6c-0.5,0.1-1.1-0.3-1.1-0.8
                c-0.1-0.5,0.3-1.1,0.8-1.1c4.3-0.7,7,2.1,7.8,3.6c0.3,0.5,0.1,1.1-0.4,1.4C126.1,87.5,125.9,87.5,125.8,87.5z"
        fill={"#E4EBF1"}
      />
      <rect fill={"#829FC0"} height="12.5" opacity={"0.2"} width="4.1" x="134.5" y="88.8" />
      <rect fill={"#829FC0"} height="12.5" opacity={"0.2"} width="4.1" x="57.5" y="88.8" />
      <path
        d="M99.5,131.8c-0.6,0-1.1-0.2-1.6-0.5c-0.4,0.3-1,0.5-1.6,0.5c-1.5,0-2.7-1.2-2.7-2.7
                c0-0.6,0.4-1,1-1s1,0.4,1,1c0,0.4,0.3,0.7,0.7,0.7c0.3,0,0.5-0.2,0.6-0.4c0.1-0.4,0.5-0.7,0.9-0.7c0,0,0,0,0,0
                c0.4,0,0.8,0.3,0.9,0.7c0.1,0.2,0.3,0.4,0.6,0.4c0.4,0,0.7-0.3,0.7-0.7c0-0.6,0.4-1,1-1s1,0.4,1,1
                C102.2,130.6,101,131.8,99.5,131.8z"
        fill={"#E4EBF1"}
      />
      <polygon fill={"#829FC0"} opacity={"0.2"} points="72.2,179.1 66.5,169.4 66,175.7 	" />
      <g>
        <path
          d="M156.2,187c-0.2,0-0.4,0-0.5-0.2L138.2,176c-0.3-0.2-0.5-0.5-0.5-0.9c0-0.3,0.2-0.7,0.5-0.9
                    l17.3-10.5c0.5-0.3,1.1-0.1,1.4,0.3s0.1,1.1-0.3,1.4l-15.9,9.7l16.1,10c0.5,0.3,0.6,0.9,0.3,1.4C156.9,186.8,156.5,187,156.2,187z
                    "
          fill={"#829FC0"}
        />
        <g>
          <circle cx="139.9" cy="175.1" fill={"#FFFFFF"} r="2.9" />
          <path
            d="M139.9,179.1c-2.2,0-3.9-1.8-3.9-3.9c0-2.2,1.8-3.9,3.9-3.9s3.9,1.8,3.9,3.9
                        C143.8,177.3,142.1,179.1,139.9,179.1z M139.9,173.2c-1.1,0-1.9,0.9-1.9,1.9s0.9,1.9,1.9,1.9s1.9-0.9,1.9-1.9
                        S141,173.2,139.9,173.2z"
            fill={"#829FC0"}
          />
        </g>
        <g>
          <circle cx="154.9" cy="165.1" fill={"#FFFFFF"} r="2.9" />
          <path
            d="M154.9,169.1c-2.2,0-3.9-1.8-3.9-3.9c0-2.2,1.8-3.9,3.9-3.9s3.9,1.8,3.9,3.9
                        C158.8,167.3,157.1,169.1,154.9,169.1z M154.9,163.2c-1.1,0-1.9,0.9-1.9,1.9s0.9,1.9,1.9,1.9s1.9-0.9,1.9-1.9
                        S156,163.2,154.9,163.2z"
            fill={"#829FC0"}
          />
        </g>
        <g>
          <circle cx="154.9" cy="185.3" fill={"#FFFFFF"} r="2.9" />
          <path
            d="M154.9,189.2c-2.2,0-3.9-1.8-3.9-3.9s1.8-3.9,3.9-3.9s3.9,1.8,3.9,3.9
                        S157.1,189.2,154.9,189.2z M154.9,183.3c-1.1,0-1.9,0.9-1.9,1.9s0.9,1.9,1.9,1.9s1.9-0.9,1.9-1.9S156,183.3,154.9,183.3z"
            fill={"#829FC0"}
          />
        </g>
      </g>
    </g>
  </svg>
)

export const CollectionEmptySvg = () => (
  <svg
    className="w-[244px] desktop-2xl:w-[325px] mb-[50px] desktop-2xl:mb-[60px]"
    version="1.1"
    viewBox="0 0 242 172.3"
    x="0px"
    y="0px"
  >
    <g>
      <path
        d="M182.2,97.7H59.8C57.7,97.7,56,96,56,94V14c0-2.1,1.7-3.8,3.8-3.8h122.4c2.1,0,3.8,1.7,3.8,3.8
                v80C186,96,184.3,97.7,182.2,97.7z"
        fill={"#C8D7E3"}
      />
      <path
        d="M182.2,98.7H59.8c-2.6,0-4.8-2.1-4.8-4.8V14c0-2.6,2.1-4.8,4.8-4.8h122.4
                c2.6,0,4.8,2.1,4.8,4.8v80C187,96.6,184.8,98.7,182.2,98.7z M59.8,11.2c-1.5,0-2.8,1.2-2.8,2.8v80c0,1.5,1.2,2.8,2.8,2.8h122.4
                c1.5,0,2.8-1.2,2.8-2.8V14c0-1.5-1.2-2.8-2.8-2.8H59.8z"
        fill={"#829FC0"}
      />
    </g>
    <g>
      <path
        d="M190.9,121.2H51.1c-2.4,0-4.3-1.9-4.3-4.3V25.5c0-2.4,1.9-4.3,4.3-4.3h139.8
                c2.4,0,4.3,1.9,4.3,4.3v91.4C195.2,119.3,193.3,121.2,190.9,121.2z"
        fill={"#E4EBF1"}
      />
      <path
        d="M190.9,122.2H51.1c-2.9,0-5.3-2.4-5.3-5.3V25.5c0-2.9,2.4-5.3,5.3-5.3h139.8
                c2.9,0,5.3,2.4,5.3,5.3v91.4C196.2,119.8,193.8,122.2,190.9,122.2z M51.1,22.2c-1.8,0-3.3,1.5-3.3,3.3v91.4c0,1.8,1.5,3.3,3.3,3.3
                h139.8c1.8,0,3.3-1.5,3.3-3.3V25.5c0-1.8-1.5-3.3-3.3-3.3H51.1z"
        fill={"#829FC0"}
      />
    </g>
    <path
      d="M215.2,172.3H26.8c-6.7,0-12.2-5.5-12.2-12.2v-28.7h212.9v28.7C227.4,166.8,222,172.3,215.2,172.3z"
      fill={"#829FC0"}
      opacity={"0.1"}
    />
    <g>
      <path
        d="M202,148.8H40c-2.8,0-5-2.2-5-5V37.9c0-2.8,2.2-5,5-5H202c2.8,0,5,2.2,5,5v105.9
                C207,146.5,204.7,148.8,202,148.8z"
        fill={"#FFFFFF"}
      />
      <path
        d="M202,149.8H40c-3.3,0-6-2.7-6-6V37.9c0-3.3,2.7-6,6-6H202c3.3,0,6,2.7,6,6v105.9
                C208,147.1,205.3,149.8,202,149.8z M40,33.9c-2.2,0-4,1.8-4,4v105.9c0,2.2,1.8,4,4,4H202c2.2,0,4-1.8,4-4V37.9c0-2.2-1.8-4-4-4H40z
                "
        fill={"#829FC0"}
      />
    </g>
    <path
      d="M109.1,71.7H52.7c-1.6,0-2.9-1.3-2.9-2.9v-2c0-1.6,1.3-2.9,2.9-2.9h56.4c1.6,0,2.9,1.3,2.9,2.9v2
            C112,70.4,110.7,71.7,109.1,71.7z"
      fill={"#0B4496"}
      opacity={"0.1"}
    />
    <path
      d="M109.1,59.4H52.7c-1.6,0-2.9-1.3-2.9-2.9v-2c0-1.6,1.3-2.9,2.9-2.9h56.4c1.6,0,2.9,1.3,2.9,2.9v2
            C112,58.1,110.7,59.4,109.1,59.4z"
      fill={"#0B4496"}
      opacity={"0.1"}
    />
    <path
      d="M188.1,71.7h-56.4c-1.6,0-2.9-1.3-2.9-2.9v-2c0-1.6,1.3-2.9,2.9-2.9h56.4c1.6,0,2.9,1.3,2.9,2.9v2
            C191,70.4,189.7,71.7,188.1,71.7z"
      fill={"#0B4496"}
      opacity={"0.1"}
    />
    <path
      d="M188.1,59.4h-56.4c-1.6,0-2.9-1.3-2.9-2.9v-2c0-1.6,1.3-2.9,2.9-2.9h56.4c1.6,0,2.9,1.3,2.9,2.9v2
            C191,58.1,189.7,59.4,188.1,59.4z"
      fill={"#0B4496"}
      opacity={"0.1"}
    />
    <g>
      <line fill={"#FFFFFF"} x1="1" x2="35" y1="131.8" y2="131.8" />
      <g>
        <path d="M7,132.8H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S7.6,132.8,7,132.8z" fill={"#829FC0"} />
        <path
          d="M21.7,132.8h-7.3c-0.6,0-1-0.4-1-1s0.4-1,1-1h7.3c0.6,0,1,0.4,1,1S22.2,132.8,21.7,132.8z"
          fill={"#829FC0"}
        />
        <path d="M35,132.8h-6c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S35.6,132.8,35,132.8z" fill={"#829FC0"} />
      </g>
    </g>
    <g>
      <line fill={"#FFFFFF"} x1="207" x2="241" y1="131.8" y2="131.8" />
      <g>
        <path d="M213,132.8h-6c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S213.5,132.8,213,132.8z" fill={"#829FC0"} />
        <path
          d="M227.7,132.8h-7.3c-0.6,0-1-0.4-1-1s0.4-1,1-1h7.3c0.6,0,1,0.4,1,1S228.2,132.8,227.7,132.8z"
          fill={"#829FC0"}
        />
        <path d="M241,132.8h-6c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S241.6,132.8,241,132.8z" fill={"#829FC0"} />
      </g>
    </g>
    <g>
      <path
        d="M75.3,102.6c-2.6,0-4.7-2.1-4.7-4.7c0-2.6,2.1-4.7,4.7-4.7s4.7,2.1,4.7,4.7C80,100.5,77.9,102.6,75.3,102.6z
                M75.3,95.2c-1.5,0-2.7,1.2-2.7,2.7c0,1.5,1.2,2.7,2.7,2.7s2.7-1.2,2.7-2.7C78.1,96.4,76.8,95.2,75.3,95.2z"
        fill={"#829FC0"}
      />
      <g>
        <path
          d="M164.3,102.6c-2.6,0-4.7-2.1-4.7-4.7c0-2.6,2.1-4.7,4.7-4.7s4.7,2.1,4.7,4.7
                    C169,100.5,166.9,102.6,164.3,102.6z M164.3,95.2c-1.5,0-2.7,1.2-2.7,2.7c0,1.5,1.2,2.7,2.7,2.7c1.5,0,2.7-1.2,2.7-2.7
                    C167,96.4,165.8,95.2,164.3,95.2z"
          fill={"#829FC0"}
        />
      </g>
      <path
        d="M119.8,102.8c3.3,0,6.3,1.9,7.6,4.9c0.2,0.5,0,1.1-0.5,1.3c-0.5,0.2-1.1,0-1.3-0.5c-1-2.3-3.3-3.8-5.9-3.8
                c-2.6,0-4.9,1.5-5.9,3.9c-0.2,0.5-0.8,0.7-1.3,0.5c-0.5-0.2-0.7-0.8-0.5-1.3C113.5,104.8,116.5,102.8,119.8,102.8z"
        fill={"#829FC0"}
      />
      <path
        d="M71.7,86.8c0,0,0.1,0,0.1,0c0.5,0.1,0.9,0.6,0.8,1.1c-0.3,1.8-1.1,3.4-2.4,4.7c-1,1-2.1,1.7-3.4,2.1
                c-0.5,0.2-1.1-0.1-1.2-0.6c-0.2-0.5,0.1-1.1,0.6-1.2c1-0.3,1.9-0.8,2.6-1.6c1-1,1.6-2.2,1.8-3.6C70.8,87.2,71.2,86.8,71.7,86.8z"
        fill={"#E4EBF1"}
      />
      <path
        d="M168.5,86.8c0.5,0,0.9,0.3,1,0.8c0.2,1.4,0.8,2.6,1.8,3.6c0.7,0.7,1.6,1.3,2.6,1.6c0.5,0.2,0.8,0.7,0.6,1.2
                c-0.2,0.5-0.7,0.8-1.2,0.6c-1.3-0.4-2.5-1.1-3.4-2.1c-1.3-1.3-2.1-2.9-2.4-4.7c-0.1-0.5,0.3-1,0.8-1.1
                C168.4,86.8,168.4,86.8,168.5,86.8z"
        fill={"#E4EBF1"}
      />
      <path
        d="M77.4,123.6H65.9c-2,0-3.7-1.7-3.7-3.7v0c0-2,1.7-3.7,3.7-3.7h11.6c2,0,3.7,1.7,3.7,3.7v0
                C81.1,121.9,79.5,123.6,77.4,123.6z"
        fill={"#E4EBF1"}
      />
      <path
        d="M172.6,123.6h-11.6c-2,0-3.7-1.7-3.7-3.7v0c0-2,1.7-3.7,3.7-3.7h11.6c2,0,3.7,1.7,3.7,3.7v0
                C176.3,121.9,174.7,123.6,172.6,123.6z"
        fill={"#E4EBF1"}
      />
    </g>
    <rect fill={"#829FC0"} height="5.3" opacity={"0.2"} width="148.1" x="46.8" y="27.6" />
    <path
      d="M202,32.9h-6.8v-7.4c0-2.4-1.9-4.3-4.3-4.3H186V14c0-2.1-1.7-3.8-3.8-3.8h-12.1c-4.2,6.1-6.7,13.6-6.7,21.6
            c0,21.1,17.1,38.3,38.3,38.3c1.8,0,3.6-0.1,5.3-0.4V37.9C207,35.1,204.7,32.9,202,32.9z"
      fill={"#829FC0"}
      opacity={"0.2"}
    />
    <rect fill={"#829FC0"} height="5.3" opacity={"0.2"} width="129.7" x="56.3" y="16" />
    <g>
      <circle cx="201.7" cy="31.8" fill={"#FFFFFF"} r="30.8" />
      <path
        d="M201.7,63.5c-17.5,0-31.8-14.3-31.8-31.8S184.1,0,201.7,0s31.8,14.3,31.8,31.8
                S219.2,63.5,201.7,63.5z M201.7,2c-16.4,0-29.8,13.4-29.8,29.8c0,16.4,13.4,29.8,29.8,29.8c16.4,0,29.8-13.4,29.8-29.8
                C231.4,15.4,218.1,2,201.7,2z"
        fill={"#829FC0"}
      />
    </g>
    <path
      d="M194.4,58.1c-3.4,0-6.7-0.6-9.8-1.6c5,3.8,11.2,6,18,6c16.5,0,29.9-13.4,29.9-29.9c0-11.1-6.1-20.9-15.2-26
            c4.9,5.5,8,12.7,8,20.7C225.2,44.3,211.4,58.1,194.4,58.1z"
      fill={"#829FC0"}
      opacity={"0.2"}
    />
    <g>
      <g>
        <path
          d="M199,41.9c-0.6,0-1-0.4-1-1V27.5c0-0.6,0.4-1,1-1s1,0.4,1,1v13.4C200,41.5,199.6,41.9,199,41.9
                    z"
          fill={"#829FC0"}
        />
        <path
          d="M205.7,35.2h-13.4c-0.6,0-1-0.4-1-1s0.4-1,1-1h13.4c0.6,0,1,0.4,1,1S206.3,35.2,205.7,35.2z"
          fill={"#829FC0"}
        />
      </g>
      <path
        d="M210.7,46.8h-23.3c-0.6,0-1-0.4-1-1V22.6c0-0.6,0.4-1,1-1h23.3c0.6,0,1,0.4,1,1v23.3
                C211.7,46.4,211.2,46.8,210.7,46.8z M188.4,44.8h21.3V23.6h-21.3V44.8z"
        fill={"#829FC0"}
      />
      <path
        d="M215.9,42h-5.3c-0.6,0-1-0.4-1-1s0.4-1,1-1h4.3V18.7h-21.3v3.8c0,0.6-0.4,1-1,1s-1-0.4-1-1v-4.8
                c0-0.6,0.4-1,1-1h23.3c0.6,0,1,0.4,1,1V41C216.9,41.5,216.5,42,215.9,42z"
        fill={"#829FC0"}
      />
    </g>
  </svg>
)

export const FavoritesEmptySvg = () => (
  <svg
    className="w-[244px] desktop-2xl:w-[325px] mb-[50px] desktop-2xl:mb-[60px]"
    version="1.1"
    viewBox="0 0 272 154.9"
    x="0px"
    y="0px"
  >
    <g>
      <path
        d="M226.2,129.1c-1.3,9-9.1,16-18.5,16v0H64.5H64c-9.3,0-17-7-18.1-16H23.6c0,14.2,11.5,25.8,25.8,25.8h175.1
                c14.2,0,25.8-11.5,25.8-25.8H226.2z"
        fill={"#829FC0"}
        opacity={"0.1"}
      />
      <path
        d="M70.8,68.4v58c0,8.2-5.3,15.2-12.7,17.7c1.9,0.6,3.8,1,5.9,1h0.5c10.3,0,18.7-8.4,18.7-18.7V56
                c0-2.1,1.7-3.9,3.9-3.9h0C78.1,52.1,70.8,59.4,70.8,68.4z"
        fill={"#0B4496"}
        opacity={"0.1"}
      />
      <g>
        <path
          d="M126.4,14.4l-4.8-9c-1.5-2.7-4.3-4.5-7.5-4.5H49.6c-2.1,0-3.9,1.7-3.9,3.9v14v108
                    c0,10.1,8.2,18.2,18.2,18.2h143.7V22.7c0-2.1-1.7-3.9-3.9-3.9h-70C130.8,18.9,127.9,17.2,126.4,14.4z"
          fill={"#C8D7E3"}
        />
        <path
          d="M207.7,146.1H64c-10.6,0-19.2-8.6-19.2-19.2V4.9c0-2.7,2.2-4.9,4.9-4.9h64.5
                    c3.5,0,6.7,1.9,8.4,5l4.8,9l0,0c1.3,2.4,3.8,3.9,6.6,3.9h70c2.7,0,4.9,2.2,4.9,4.9v122.4C208.7,145.7,208.3,146.1,207.7,146.1z
                    M49.6,2c-1.6,0-2.9,1.3-2.9,2.9v122.1c0,9.5,7.7,17.2,17.2,17.2h142.7V22.7c0-1.6-1.3-2.9-2.9-2.9h-70c-3.5,0-6.7-1.9-8.4-5
                    l-4.8-9c-1.3-2.4-3.8-3.9-6.6-3.9H49.6z"
          fill={"#829FC0"}
        />
      </g>
      <path
        d="M208.7,146.1H64c-10.6,0-19.2-8.6-19.2-19.2V4.9c0-2.7,2.2-4.9,4.9-4.9h64.5
                c3.5,0,6.7,1.9,8.4,5l4.8,9c1.3,2.4,3.8,3.9,6.6,3.9h70c2.7,0,4.9,2.2,4.9,4.9V146.1z M49.6,2c-1.6,0-2.9,1.3-2.9,2.9v122.1
                c0,9.5,7.7,17.2,17.2,17.2h142.7V22.7c0-1.6-1.3-2.9-2.9-2.9h-70c-3.5,0-6.7-1.9-8.4-5l-4.8-9c-1.3-2.4-3.8-3.9-6.6-3.9H49.6z"
        fill={"#829FC0"}
      />
      <path
        d="M105.1,11.2H58.2c-1.2,0-2.2-1-2.2-2.2v0c0-1.2,1-2.2,2.2-2.2h46.9c1.2,0,2.2,1,2.2,2.2v0
                C107.3,10.2,106.3,11.2,105.1,11.2z"
        fill={"#829FC0"}
        opacity={"0.2"}
      />
      <path
        d="M133.9,19.9h-12c-0.6,0-1-0.4-1-1s0.4-1,1-1h12c0.6,0,1,0.4,1,1S134.5,19.9,133.9,19.9z
                M109.9,19.9h-12c-0.6,0-1-0.4-1-1s0.4-1,1-1h12c0.6,0,1,0.4,1,1S110.5,19.9,109.9,19.9z M85.9,19.9h-12c-0.6,0-1-0.4-1-1
                s0.4-1,1-1h12c0.6,0,1,0.4,1,1S86.5,19.9,85.9,19.9z"
        fill={"#829FC0"}
      />
      <g>
        <path
          d="M87.1,44.5c-2.1,0-3.9,1.7-3.9,3.9v78c0,10.3-8.4,18.7-18.7,18.7h143.2v0
                    c10.3,0,18.7-8.4,18.7-18.7v-78c0-2.1-1.7-3.9-3.9-3.9H87.1z"
          fill={"#FFFFFF"}
        />
        <path
          d="M207.7,146.1H64.5c-0.6,0-1-0.4-1-1s0.4-1,1-1c9.8,0,17.7-8,17.7-17.7v-78
                    c0-2.7,2.2-4.9,4.9-4.9h135.5c2.7,0,4.9,2.2,4.9,4.9v78C227.4,137.2,218.6,146.1,207.7,146.1C207.8,146.1,207.7,146.1,207.7,146.1
                    z M73.2,144.1h134.5c0,0,0,0,0.1,0c9.8,0,17.7-8,17.7-17.7v-78c0-1.6-1.3-2.9-2.9-2.9H87.1c-1.6,0-2.9,1.3-2.9,2.9v78
                    C84.2,134.2,79.7,140.9,73.2,144.1z"
          fill={"#829FC0"}
        />
      </g>
      <path
        d="M203.9,18.9h-12.4c2.1,0,3.9,1.7,3.9,3.9v21.8h12.4V22.7C207.7,20.6,206,18.9,203.9,18.9z"
        fill={"#0B4496"}
        opacity={"0.1"}
      />
      <path
        d="M207.7,129.1L207.7,129.1l-124.7,0c-1.3,9.1-9.1,16.1-18.5,16.1h143.2v0c10.3,0,18.7-8.4,18.7-18.7v-16.1
                C226.4,120.7,218,129,207.7,129.1z"
        fill={"#829FC0"}
        opacity={"0.2"}
      />
      <g>
        <circle cx="45.8" cy="70" fill={"#FFFFFF"} r="23.3" />
        <path
          d="M45.8,94.3c-13.4,0-24.3-10.9-24.3-24.3c0-13.4,10.9-24.3,24.3-24.3S70.1,56.6,70.1,70
                    C70.1,83.4,59.2,94.3,45.8,94.3z M45.8,47.7c-12.3,0-22.3,10-22.3,22.3c0,12.3,10,22.3,22.3,22.3s22.3-10,22.3-22.3
                    C68.1,57.7,58.1,47.7,45.8,47.7z"
          fill={"#829FC0"}
        />
      </g>
      <path
        d="M58,60.5c-0.7-0.6-1.4-1.1-2.3-1.4c-3.8-1.6-8.1,0.1-9.9,3.8c-1.8-3.7-6.1-5.4-9.9-3.8
                c-4,1.6-5.9,6.1-4.3,10.1c0.8,1.9,4.1,5.3,7.4,8.3l1,0.9c2.9,2.6,5.4,4.7,5.7,5c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0s0,0,0,0
                c0.7-0.6,12.6-10.4,14.1-14.2c1.1-2.6,0.6-5.5-1-7.6L58,60.5z"
        fill={"#C8D7E3"}
      />
      <path
        d="M42.7,77.5c-0.2,0-0.5-0.1-0.6-0.2c-0.6-0.5-1.7-1.4-2.9-2.5c-0.4-0.4-0.4-1-0.1-1.4
                c0.4-0.4,1-0.4,1.4-0.1c1.2,1.1,2.3,2,2.9,2.5c0.4,0.4,0.5,1,0.1,1.4C43.3,77.3,43,77.5,42.7,77.5z"
        fill={"#E5EBF2"}
      />
      <path
        d="M37.7,72.9c-0.3,0-0.5-0.1-0.7-0.3c-2.3-2.3-2.9-3.3-3.1-3.9c-0.6-1.4-0.6-2.9,0-4.3
                c0.6-1.4,1.7-2.5,3.1-3.1c1.2-0.5,2.6-0.6,3.8-0.2c0.5,0.2,0.8,0.7,0.7,1.3c-0.2,0.5-0.7,0.8-1.3,0.7c-0.8-0.3-1.7-0.2-2.5,0.1
                c-0.9,0.4-1.6,1.1-2,2c-0.4,0.9-0.4,1.9,0,2.8c0.1,0.3,0.7,1.2,2.7,3.2c0.4,0.4,0.4,1,0,1.4C38.2,72.8,37.9,72.9,37.7,72.9z"
        fill={"#E5EBF2"}
      />
      <g>
        <g>
          <path
            d="M199.6,112.2c-0.9-0.4-1.9-0.7-2.9-0.8c-4.5-0.4-8.6,2.8-9.2,7.3c-3.1-3.3-8.2-3.7-11.7-0.8
                        c-3.7,3-4.2,8.4-1.2,12.1c1.5,1.8,6.1,4.3,10.5,6.4l1.4,0.7c3.9,1.8,7.3,3.2,7.7,3.4c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0s0,0,0,0
                        c0.6-0.9,9.9-15.1,10.3-19.7c0.3-3.2-1.2-6.1-3.6-7.8L199.6,112.2z"
            fill={"#FFFFFF"}
          />
          <path
            d="M194.2,141.4c0,0-0.1,0-0.1,0c-0.2,0-0.3-0.1-0.4-0.1c-0.8-0.3-4-1.7-7.6-3.3l-1.4-0.7
                        c-6-2.9-9.5-5-10.9-6.7c-3.3-4.1-2.7-10.1,1.3-13.5c3.5-2.8,8.3-2.9,11.8-0.3c1.4-4.1,5.5-6.8,9.9-6.4c1.1,0.1,2.2,0.4,3.2,0.9
                        c0,0,0.1,0,0.1,0l1.3,0.8c2.8,2,4.4,5.3,4.1,8.7c-0.4,4.7-9.1,18.1-10.4,20c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.3,0.2
                        C194.4,141.4,194.3,141.4,194.2,141.4z M181.2,117c-1.7,0-3.4,0.6-4.8,1.7c-3.2,2.7-3.7,7.4-1.1,10.7c0.6,0.8,2.8,2.6,10.2,6.1
                        l1.4,0.7c2.9,1.4,5.6,2.5,6.9,3c3.5-5.3,9.4-15.2,9.7-18.5c0.2-2.7-1-5.3-3.2-6.9l-1.2-0.7c-0.8-0.4-1.6-0.6-2.5-0.7
                        c-4-0.3-7.6,2.5-8.2,6.4c-0.1,0.4-0.3,0.7-0.7,0.8c-0.4,0.1-0.8,0-1-0.3C185.3,117.8,183.2,117,181.2,117z"
            fill={"#829FC0"}
          />
        </g>
        <path
          d="M199.6,112.2c-0.9-0.4-1.9-0.7-2.9-0.8c-4.5-0.4-8.6,2.8-9.2,7.3c-3.1-3.3-8.2-3.7-11.7-0.8
                    c-3.7,3-4.2,8.4-1.2,12.1c1.5,1.8,6.1,4.3,10.5,6.4l1.4,0.7c3.9,1.8,7.3,3.2,7.7,3.4c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0s0,0,0,0
                    c0.6-0.9,9.9-15.1,10.3-19.7c0.3-3.2-1.2-6.1-3.6-7.8L199.6,112.2z"
          fill={"#829FC0"}
          opacity={"0.2"}
        />
        <path
          d="M188.7,135.1c-0.1,0-0.3,0-0.4-0.1c-0.9-0.4-2.3-1-3.9-1.7c-0.5-0.2-0.7-0.8-0.5-1.3
                    c0.2-0.5,0.8-0.7,1.3-0.5c1.5,0.7,2.9,1.3,3.8,1.7c0.5,0.2,0.7,0.8,0.5,1.3C189.4,134.9,189.1,135.1,188.7,135.1z"
          fill={"#C8D7E3"}
        />
        <path
          d="M181.8,131.9c-0.2,0-0.3,0-0.5-0.1c-2.4-1.2-3.9-2.2-4.5-3c-2.2-2.6-1.8-6.5,0.9-8.7
                    c1.1-0.9,2.5-1.4,3.9-1.4c0,0,0,0,0,0c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1h0c0,0,0,0,0,0c-1,0-1.9,0.3-2.6,0.9
                    c-1.8,1.5-2,4.1-0.6,5.9c0.3,0.3,1.1,1.1,3.9,2.5c0.5,0.3,0.7,0.9,0.4,1.3C182.5,131.8,182.2,131.9,181.8,131.9z"
          fill={"#C8D7E3"}
        />
      </g>
      <g>
        <g>
          <circle cx="182.9" cy="19.1" fill={"#FFFFFF"} r="15.5" />
          <path
            d="M182.9,35.6c-9.1,0-16.5-7.4-16.5-16.5s7.4-16.5,16.5-16.5s16.5,7.4,16.5,16.5
                        S192,35.6,182.9,35.6z M182.9,4.6c-8,0-14.5,6.5-14.5,14.5s6.5,14.5,14.5,14.5s14.5-6.5,14.5-14.5S190.9,4.6,182.9,4.6z"
            fill={"#829FC0"}
          />
        </g>
        <g>
          <path
            d="M191,12.8c-0.4-0.4-0.9-0.7-1.5-1c-2.5-1-5.4,0.1-6.6,2.5c-1.2-2.4-4.1-3.6-6.6-2.5
                        c-2.6,1.1-3.9,4.1-2.8,6.7c0.5,1.3,2.7,3.5,4.9,5.5l0.7,0.6c1.9,1.7,3.6,3.1,3.8,3.3c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0s0,0,0,0
                        c0.5-0.4,8.4-6.9,9.4-9.5c0.7-1.8,0.4-3.7-0.7-5.1L191,12.8z"
            fill={"#C8D7E3"}
          />
          <path
            d="M191,12.8c-0.4-0.4-0.9-0.7-1.5-1c-2.5-1-5.4,0.1-6.6,2.5c-1.2-2.4-4.1-3.6-6.6-2.5
                        c-2.6,1.1-3.9,4.1-2.8,6.7c0.5,1.3,2.7,3.5,4.9,5.5l0.7,0.6c1.9,1.7,3.6,3.1,3.8,3.3c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0s0,0,0,0
                        c0.5-0.4,8.4-6.9,9.4-9.5c0.7-1.8,0.4-3.7-0.7-5.1L191,12.8z"
            fill={"#C8D7E3"}
            opacity={"0.2"}
          />
        </g>
      </g>
      <g>
        <path
          d="M133.2,86.8c0-1.9,1.5-3.4,3.4-3.4s3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4
                    S133.2,88.7,133.2,86.8"
          fill={"#FFFFFF"}
        />
        <path
          d="M136.6,91.2c-2.4,0-4.4-2-4.4-4.4s2-4.4,4.4-4.4s4.4,2,4.4,4.4S139,91.2,136.6,91.2z
                    M136.6,84.4c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4s2.4-1.1,2.4-2.4C139,85.5,137.9,84.4,136.6,84.4z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <circle cx="199" cy="86.8" fill={"#FFFFFF"} r="3.4" />
        <path
          d="M199,91.2c-2.4,0-4.4-2-4.4-4.4s2-4.4,4.4-4.4s4.4,2,4.4,4.4S201.5,91.2,199,91.2z M199,84.4
                    c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4C201.4,85.5,200.4,84.4,199,84.4z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <path d="M178.1,89.5c0,3.7-3,6.8-6.8,6.8c-3.7,0-6.8-3-6.8-6.8H178.1z" fill={"#FFFFFF"} />
        <path
          d="M171.3,97.3c-4.3,0-7.8-3.5-7.8-7.8c0-0.6,0.4-1,1-1h13.6c0.6,0,1,0.4,1,1
                    C179.1,93.8,175.6,97.3,171.3,97.3z M165.6,90.5c0.5,2.7,2.9,4.8,5.7,4.8s5.2-2.1,5.7-4.8H165.6z"
          fill={"#829FC0"}
        />
      </g>
      <path
        d="M128.6,83.9c0,0-0.1,0-0.1,0c-0.5-0.1-0.9-0.6-0.8-1.1c0.2-1.7,1-3.2,2.2-4.4
                c0.9-0.9,2-1.6,3.2-1.9c0.5-0.2,1.1,0.1,1.3,0.7c0.2,0.5-0.1,1.1-0.7,1.3c-0.9,0.3-1.7,0.8-2.4,1.4c-0.9,0.9-1.5,2-1.6,3.2
                C129.5,83.6,129.1,83.9,128.6,83.9z"
        fill={"#E5EBF2"}
      />
      <path
        d="M207.6,83.9c-0.5,0-0.9-0.4-1-0.9c-0.2-1.2-0.7-2.4-1.6-3.2c-0.7-0.7-1.5-1.2-2.4-1.4
                c-0.5-0.2-0.8-0.7-0.7-1.3c0.2-0.5,0.7-0.8,1.3-0.7c1.2,0.4,2.3,1,3.2,1.9c1.2,1.2,2,2.7,2.2,4.4c0.1,0.5-0.3,1.1-0.8,1.1
                C207.7,83.9,207.6,83.9,207.6,83.9z"
        fill={"#E5EBF2"}
      />
      <path
        d="M136.6,103.3H126c-1.9,0-3.4-1.5-3.4-3.4v0c0-1.9,1.5-3.4,3.4-3.4h10.6c1.9,0,3.4,1.5,3.4,3.4v0
                C140,101.8,138.5,103.3,136.6,103.3z"
        fill={"#E5EBF2"}
      />
      <path
        d="M209.7,103.3H199c-1.9,0-3.4-1.5-3.4-3.4v0c0-1.9,1.5-3.4,3.4-3.4h10.6c1.9,0,3.4,1.5,3.4,3.4v0
                C213.1,101.8,211.5,103.3,209.7,103.3z"
        fill={"#E5EBF2"}
      />
      <g>
        <path d="M7,130.1H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S7.6,130.1,7,130.1z" fill={"#829FC0"} />
        <path
          d="M28.7,130.1H17.8c-0.6,0-1-0.4-1-1s0.4-1,1-1h10.8c0.6,0,1,0.4,1,1S29.2,130.1,28.7,130.1z"
          fill={"#829FC0"}
        />
        <path d="M45.5,130.1h-6c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S46.1,130.1,45.5,130.1z" fill={"#829FC0"} />
      </g>
      <g>
        <path
          d="M232.5,130.1h-6c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S233,130.1,232.5,130.1z"
          fill={"#829FC0"}
        />
        <path
          d="M254.2,130.1h-10.8c-0.6,0-1-0.4-1-1s0.4-1,1-1h10.8c0.6,0,1,0.4,1,1S254.7,130.1,254.2,130.1
                    z"
          fill={"#829FC0"}
        />
        <path d="M271,130.1h-6c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S271.6,130.1,271,130.1z" fill={"#829FC0"} />
      </g>
      <g>
        <path
          d="M171.3,96.3c1.2,0,2.4-0.3,3.4-0.9c-1.3-2.1-3.6-3.5-6.3-3.5c-1.1,0-2.2,0.3-3.2,0.7
                    C166.4,94.8,168.7,96.3,171.3,96.3z"
          fill={"#FFFFFF"}
        />
        <path
          d="M171.3,97.3c-2.9,0-5.6-1.6-6.9-4.2c-0.1-0.2-0.1-0.5-0.1-0.8s0.3-0.5,0.5-0.6
                    c1.1-0.5,2.3-0.8,3.6-0.8c2.9,0,5.5,1.5,7.1,3.9c0.1,0.2,0.2,0.5,0.1,0.8s-0.2,0.5-0.5,0.6C174,96.9,172.6,97.3,171.3,97.3z
                    M166.8,93.1c1.1,1.4,2.7,2.2,4.5,2.2c0.6,0,1.3-0.1,1.8-0.3c-1.2-1.3-2.9-2.1-4.7-2.1C167.9,92.9,167.3,93,166.8,93.1z"
          fill={"#829FC0"}
        />
      </g>
      <path
        d="M160.4,135c-13.6,0-24.9-3.2-33.6-9.5c-14.8-10.7-16.9-26.4-16.9-27.1c-0.1-0.5,0.3-1,0.9-1.1
                c0.6-0.1,1,0.3,1.1,0.9c0,0.2,2,15.5,16.2,25.7c12.8,9.2,31.2,11.4,54.7,6.6c0.5-0.1,1.1,0.2,1.2,0.8c0.1,0.5-0.2,1.1-0.8,1.2
                C174.9,134.2,167.4,135,160.4,135z"
        fill={"#829FC0"}
      />
      <path
        d="M198.6,129.9c-0.5,0-0.9-0.4-1-0.9c-0.1-0.5,0.3-1.1,0.9-1.1c25.3-3.5,32.2-10,33.6-14.9
                c1.9-6.9-6.2-13.6-6.3-13.6c-0.4-0.3-0.5-1-0.1-1.4c0.3-0.4,1-0.5,1.4-0.1c0.4,0.3,9.2,7.6,7,15.7c-2.2,7.9-14,13.4-35.2,16.3
                C198.7,129.9,198.7,129.9,198.6,129.9z"
        fill={"#829FC0"}
      />
      <path
        d="M171.3,96.3c1.2,0,2.4-0.3,3.4-0.9c-1.3-2.1-3.6-3.5-6.3-3.5c-1.1,0-2.2,0.3-3.2,0.7
                C166.4,94.8,168.7,96.3,171.3,96.3z"
        fill={"#829FC0"}
        opacity={"0.2"}
      />
    </g>
  </svg>
)

export const UploadsEmptySvg = () => (
  <svg version="1.1" viewBox="0 0 242 166" x="0px" y="0px">
    <g>
      <path
        d="M210.1,166H31.9c-6.4,0-11.5-5.2-11.5-11.5v-27.2h201.3v27.2C221.6,160.9,216.5,166,210.1,166z"
        fill={"#829FC0"}
        opacity={"0.1"}
      />
      <g>
        <path
          d="M198.2,143.8H43.8c-2.3,0-4.1-1.8-4.1-4.1V38.3c0-2.3,1.8-4.1,4.1-4.1h154.4
                    c2.3,0,4.1,1.8,4.1,4.1v101.4C202.3,142,200.5,143.8,198.2,143.8z"
          fill={"#FFFFFF"}
        />
        <path
          d="M198.2,144.8H43.8c-2.8,0-5.1-2.3-5.1-5.1V38.3c0-2.8,2.3-5.1,5.1-5.1h154.4
                    c2.8,0,5.1,2.3,5.1,5.1v101.4C203.3,142.5,201,144.8,198.2,144.8z M43.8,35.2c-1.7,0-3.1,1.4-3.1,3.1v101.4c0,1.7,1.4,3.1,3.1,3.1
                    h154.4c1.7,0,3.1-1.4,3.1-3.1V38.3c0-1.7-1.4-3.1-3.1-3.1H43.8z"
          fill={"#829FC0"}
        />
      </g>
      <path
        d="M109.8,73.6H57.1c-1.5,0-2.7-1.2-2.7-2.7V69c0-1.5,1.2-2.7,2.7-2.7h52.7c1.5,0,2.7,1.2,2.7,2.7v1.9
                C112.5,72.4,111.3,73.6,109.8,73.6z"
        fill={"#0B4496"}
        opacity={"0.1"}
      />
      <path
        d="M109.8,62.1H57.1c-1.5,0-2.7-1.2-2.7-2.7v-1.9c0-1.5,1.2-2.7,2.7-2.7h52.7c1.5,0,2.7,1.2,2.7,2.7v1.9
                C112.5,60.8,111.3,62.1,109.8,62.1z"
        fill={"#0B4496"}
        opacity={"0.1"}
      />
      <path
        d="M183.7,73.6H131c-1.5,0-2.7-1.2-2.7-2.7V69c0-1.5,1.2-2.7,2.7-2.7h52.7c1.5,0,2.7,1.2,2.7,2.7v1.9
                C186.4,72.4,185.2,73.6,183.7,73.6z"
        fill={"#0B4496"}
        opacity={"0.1"}
      />
      <path
        d="M183.7,62.1H131c-1.5,0-2.7-1.2-2.7-2.7v-1.9c0-1.5,1.2-2.7,2.7-2.7h52.7c1.5,0,2.7,1.2,2.7,2.7v1.9
                C186.4,60.8,185.2,62.1,183.7,62.1z"
        fill={"#0B4496"}
        opacity={"0.1"}
      />
      <g>
        <line fill={"#FFFFFF"} x1="1" x2="39.7" y1="127.3" y2="127.3" />
        <g>
          <path d="M7,128.3H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S7.6,128.3,7,128.3z" fill={"#829FC0"} />
          <path
            d="M24.8,128.3h-8.9c-0.6,0-1-0.4-1-1s0.4-1,1-1h8.9c0.6,0,1,0.4,1,1S25.4,128.3,24.8,128.3z"
            fill={"#829FC0"}
          />
          <path
            d="M39.7,128.3h-6c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S40.3,128.3,39.7,128.3z"
            fill={"#829FC0"}
          />
        </g>
      </g>
      <g>
        <line fill={"#FFFFFF"} x1="202.3" x2="241" y1="127.3" y2="127.3" />
        <g>
          <path
            d="M208.3,128.3h-6c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S208.8,128.3,208.3,128.3z"
            fill={"#829FC0"}
          />
          <path
            d="M226.1,128.3h-8.9c-0.6,0-1-0.4-1-1s0.4-1,1-1h8.9c0.6,0,1,0.4,1,1S226.7,128.3,226.1,128.3z
                        "
            fill={"#829FC0"}
          />
          <path
            d="M241,128.3h-6c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S241.6,128.3,241,128.3z"
            fill={"#829FC0"}
          />
        </g>
      </g>
      <g>
        <path
          d="M209,38.8H33c-1.7,0-3.1-1.4-3.1-3.1V23.6c0-1.7,1.4-3.1,3.1-3.1H209c1.7,0,3.1,1.4,3.1,3.1
                    v12.1C212.1,37.4,210.7,38.8,209,38.8z"
          fill={"#C8D7E3"}
        />
        <path
          d="M209,39.8H33c-2.2,0-4.1-1.8-4.1-4.1V23.6c0-2.2,1.8-4.1,4.1-4.1H209c2.2,0,4.1,1.8,4.1,4.1
                    v12.1C213.1,38,211.3,39.8,209,39.8z M33,21.6c-1.1,0-2.1,0.9-2.1,2.1v12.1c0,1.1,0.9,2.1,2.1,2.1H209c1.1,0,2.1-0.9,2.1-2.1V23.6
                    c0-1.1-0.9-2.1-2.1-2.1H33z"
          fill={"#829FC0"}
        />
      </g>
      <path
        d="M209,20.6h-5.4v9.2c0,1.7-1.4,3.1-3.1,3.1H29.9v2.9c0,1.7,1.4,3.1,3.1,3.1H209c1.7,0,3.1-1.4,3.1-3.1V23.6
                C212.1,21.9,210.7,20.6,209,20.6z"
        fill={"#829FC0"}
        opacity={"0.2"}
      />
      <g>
        <path
          d="M209,152.6H33c-1.7,0-3.1-1.4-3.1-3.1v-6.7c0-1.7,1.4-3.1,3.1-3.1H209c1.7,0,3.1,1.4,3.1,3.1
                    v6.7C212.1,151.2,210.7,152.6,209,152.6z"
          fill={"#C8D7E3"}
        />
        <path
          d="M209,153.6H33c-2.2,0-4.1-1.8-4.1-4.1v-6.7c0-2.2,1.8-4.1,4.1-4.1H209c2.2,0,4.1,1.8,4.1,4.1
                    v6.7C213.1,151.8,211.3,153.6,209,153.6z M33,140.8c-1.1,0-2.1,0.9-2.1,2.1v6.7c0,1.1,0.9,2.1,2.1,2.1H209c1.1,0,2.1-0.9,2.1-2.1
                    v-6.7c0-1.1-0.9-2.1-2.1-2.1H33z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <line fill={"#C8D7E3"} x1="121" x2="121" y1="19.6" y2="3" />
        <path
          d="M121,20.6c-0.6,0-1-0.4-1-1V3c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1v16.6
                    C122,20.1,121.6,20.6,121,20.6z"
          fill={"#829FC0"}
        />
      </g>
      <path
        d="M209,139.4h-5.4v4.6c0,1.7-1.4,3.1-3.1,3.1H29.9v2.9c0,1.7,1.4,3.1,3.1,3.1H209c1.7,0,3.1-1.4,3.1-3.1v-7.5
                C212.1,140.8,210.7,139.4,209,139.4z"
        fill={"#829FC0"}
        opacity={"0.2"}
      />
      <path
        d="M75.3,102.2c-2.6,0-4.7-2.1-4.7-4.7s2.1-4.7,4.7-4.7s4.7,2.1,4.7,4.7S77.9,102.2,75.3,102.2z M75.3,94.8
                c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7s2.7-1.2,2.7-2.7S76.8,94.8,75.3,94.8z"
        fill={"#829FC0"}
      />
      <g>
        <path
          d="M164.3,102.2c-2.6,0-4.7-2.1-4.7-4.7s2.1-4.7,4.7-4.7s4.7,2.1,4.7,4.7S166.9,102.2,164.3,102.2z M164.3,94.8
                    c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7c1.5,0,2.7-1.2,2.7-2.7S165.8,94.8,164.3,94.8z"
          fill={"#829FC0"}
        />
      </g>
      <path
        d="M119.8,108.8c-3.3,0-6.3-1.9-7.6-4.9c-0.2-0.5,0-1.1,0.5-1.3c0.5-0.2,1.1,0,1.3,0.5c1,2.3,3.3,3.8,5.9,3.8
                c2.6,0,4.9-1.5,5.9-3.9c0.2-0.5,0.8-0.7,1.3-0.5c0.5,0.2,0.7,0.8,0.5,1.3C126.2,106.8,123.2,108.8,119.8,108.8z"
        fill={"#829FC0"}
      />
      <path
        d="M66.6,94.3c0,0-0.1,0-0.1,0c-0.5-0.1-0.9-0.6-0.8-1.1c0.3-1.8,1.1-3.4,2.4-4.7c1-1,2.1-1.7,3.4-2.1
                c0.5-0.2,1.1,0.1,1.2,0.6c0.2,0.5-0.1,1.1-0.6,1.2c-1,0.3-1.9,0.8-2.6,1.6c-1,1-1.6,2.2-1.8,3.6C67.5,93.9,67.1,94.3,66.6,94.3z"
        fill={"#E4EBF1"}
      />
      <path
        d="M173.6,94.3c-0.5,0-0.9-0.3-1-0.8c-0.2-1.4-0.8-2.6-1.8-3.6c-0.7-0.7-1.6-1.3-2.6-1.6
                c-0.5-0.2-0.8-0.7-0.6-1.2c0.2-0.5,0.7-0.8,1.2-0.6c1.3,0.4,2.5,1.1,3.4,2.1c1.3,1.3,2.1,2.9,2.4,4.7c0.1,0.5-0.3,1-0.8,1.1
                C173.7,94.3,173.6,94.3,173.6,94.3z"
        fill={"#E4EBF1"}
      />
      <path
        d="M77.4,123.1H65.9c-2,0-3.7-1.7-3.7-3.7v0c0-2,1.7-3.7,3.7-3.7h11.6c2,0,3.7,1.7,3.7,3.7v0
                C81.1,121.5,79.5,123.1,77.4,123.1z"
        fill={"#E4EBF1"}
      />
      <rect fill={"#829FC0"} height="6.7" opacity={"0.2"} width="163.8" x="39.7" y="38.8" />
      <path
        d="M209,20.6h-49.2c-1.1,3.5-1.7,7.3-1.7,11.2c0,21.3,17.2,38.5,38.5,38.5c1.9,0,3.8-0.1,5.6-0.4V38.8h6.7
                c1.7,0,3.1-1.4,3.1-3.1V23.6C212.1,21.9,210.7,20.6,209,20.6z"
        fill={"#829FC0"}
        opacity={"0.2"}
      />
      <path
        d="M172.6,123.1h-11.6c-2,0-3.7-1.7-3.7-3.7v0c0-2,1.7-3.7,3.7-3.7h11.6c2,0,3.7,1.7,3.7,3.7v0
                C176.3,121.5,174.7,123.1,172.6,123.1z"
        fill={"#E4EBF1"}
      />
      <g>
        <circle cx="196.7" cy="31.8" fill={"#FFFFFF"} r="30.8" />
        <path
          d="M196.7,63.5c-17.5,0-31.8-14.3-31.8-31.8S179.1,0,196.7,0s31.8,14.3,31.8,31.8
                    S214.2,63.5,196.7,63.5z M196.7,2c-16.4,0-29.8,13.4-29.8,29.8s13.4,29.8,29.8,29.8s29.8-13.4,29.8-29.8S213.1,2,196.7,2z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <g>
          <line fill={"#C8D7E3"} x1="196.7" x2="196.7" y1="15.4" y2="48.1" />
          <path
            d="M196.7,49.1c-0.6,0-1-0.4-1-1V15.4c0-0.6,0.4-1,1-1s1,0.4,1,1v32.7
                        C197.7,48.7,197.2,49.1,196.7,49.1z"
            fill={"#829FC0"}
          />
        </g>
        <g>
          <line fill={"#C8D7E3"} x1="213" x2="180.3" y1="31.8" y2="31.8" />
          <path
            d="M213,32.8h-32.7c-0.6,0-1-0.4-1-1s0.4-1,1-1H213c0.6,0,1,0.4,1,1S213.6,32.8,213,32.8z"
            fill={"#829FC0"}
          />
        </g>
      </g>
      <path
        d="M189.4,58.1c-3.4,0-6.7-0.6-9.8-1.6c5,3.8,11.2,6,18,6c16.5,0,29.9-13.4,29.9-29.9c0-11.1-6.1-20.9-15.2-26
                c4.9,5.5,8,12.7,8,20.7C220.2,44.3,206.4,58.1,189.4,58.1z"
        fill={"#829FC0"}
        opacity={"0.2"}
      />
    </g>
  </svg>
)

export const SearchEmptySvg = () => (
  <svg
    className="w-[244px] desktop-2xl:w-[325px] mb-[50px] desktop-2xl:mb-[60px]"
    version="1.1"
    viewBox="0 0 278.4 158.9"
    x="0px"
    y="0px"
  >
    <g>
      <path
        d="M231.5,132.2c-1.4,9.2-9.3,16.4-18.9,16.4v0H66h-0.5c-9.5,0-17.4-7.2-18.5-16.4H24.2
                c0,14.6,11.8,26.4,26.4,26.4h179.3c14.6,0,26.4-11.8,26.4-26.4H231.5z"
        fill={"#829FC0"}
        opacity={"0.1"}
      />
      <path
        d="M72.5,70v59.4c0,8.4-5.5,15.6-13,18.2c1.9,0.7,3.9,1,6.1,1H66c10.6,0,19.2-8.6,19.2-19.2V57.3
                c0-2.2,1.8-3.9,3.9-3.9h0C79.9,53.3,72.5,60.8,72.5,70z"
        fill={"#0B4496"}
        opacity={"0.1"}
      />
      <g>
        <path
          d="M129.4,14.7l-4.9-9.2C123,2.8,120,1,116.8,1h-66c-2.2,0-3.9,1.8-3.9,3.9v14.4v110.6
                    c0,10.3,8.3,18.6,18.6,18.6h147.1V23.3c0-2.2-1.8-3.9-3.9-3.9h-71.6C133.9,19.3,130.9,17.6,129.4,14.7z"
          fill={"#C8D7E3"}
        />
        <path
          d="M212.6,149.5H65.5c-10.8,0-19.6-8.8-19.6-19.6V4.9c0-2.7,2.2-4.9,4.9-4.9h66
                    c3.6,0,6.8,2,8.5,5.1l4.9,9.2l0,0c1.3,2.5,3.9,4,6.8,4h71.6c2.7,0,4.9,2.2,4.9,4.9v125.3C213.6,149.1,213.2,149.5,212.6,149.5z
                    M50.8,2c-1.6,0-2.9,1.3-2.9,2.9v125c0,9.7,7.9,17.6,17.6,17.6h146.1V23.3c0-1.6-1.3-2.9-2.9-2.9h-71.6c-3.6,0-6.8-2-8.5-5.1
                    L123.6,6c-1.3-2.5-3.9-4-6.8-4H50.8z"
          fill={"#3390FF"}
        />
      </g>
      <path
        d="M213.6,149.5H65.5c-10.8,0-19.6-8.8-19.6-19.6V4.9c0-2.7,2.2-4.9,4.9-4.9h66
                c3.6,0,6.8,2,8.5,5.1l4.9,9.2c1.3,2.5,3.9,4,6.8,4h71.6c2.7,0,4.9,2.2,4.9,4.9V149.5z M50.8,2c-1.6,0-2.9,1.3-2.9,2.9v125
                c0,9.7,7.9,17.6,17.6,17.6h146.1V23.3c0-1.6-1.3-2.9-2.9-2.9h-71.6c-3.6,0-6.8-2-8.5-5.1L123.6,6c-1.3-2.5-3.9-4-6.8-4H50.8z"
        fill={"#829FC0"}
      />
      <path
        d="M107.6,11.5h-48c-1.3,0-2.3-1-2.3-2.3v0c0-1.3,1-2.3,2.3-2.3h48c1.3,0,2.3,1,2.3,2.3v0
                C109.8,10.5,108.8,11.5,107.6,11.5z"
        fill={"#829FC0"}
        opacity={"0.2"}
      />
      <path
        d="M137.1,20.3h-12c-0.6,0-1-0.4-1-1s0.4-1,1-1h12c0.6,0,1,0.4,1,1S137.6,20.3,137.1,20.3z
                M113.1,20.3h-12c-0.6,0-1-0.4-1-1s0.4-1,1-1h12c0.6,0,1,0.4,1,1S113.6,20.3,113.1,20.3z M89.1,20.3h-12c-0.6,0-1-0.4-1-1
                s0.4-1,1-1h12c0.6,0,1,0.4,1,1S89.6,20.3,89.1,20.3z"
        fill={"#829FC0"}
      />
      <g>
        <path
          d="M89.1,45.5c-2.2,0-3.9,1.8-3.9,3.9v79.9c0,10.6-8.6,19.2-19.2,19.2h146.6v0
                    c10.6,0,19.1-8.6,19.1-19.2V49.5c0-2.2-1.8-3.9-3.9-3.9H89.1z"
          fill={"#FFFFFF"}
        />
        <path
          d="M212.6,149.5H66c-0.6,0-1-0.4-1-1s0.4-1,1-1c10,0,18.2-8.2,18.2-18.2V49.5
                    c0-2.7,2.2-4.9,4.9-4.9h138.7c2.7,0,4.9,2.2,4.9,4.9v79.9C232.7,140.4,223.8,149.5,212.6,149.5
                    C212.7,149.5,212.7,149.5,212.6,149.5z M74.8,147.5h137.8c0,0,0,0,0.1,0c10,0,18.1-8.2,18.1-18.2V49.5c0-1.6-1.3-2.9-2.9-2.9H89.1
                    c-1.6,0-2.9,1.3-2.9,2.9v79.9C86.2,137.4,81.5,144.3,74.8,147.5z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <circle cx="199.2" cy="88.7" fill={"#FFFFFF"} r="5.4" />
        <path
          d="M199.2,95.2c-3.5,0-6.4-2.9-6.4-6.4c0-3.5,2.9-6.4,6.4-6.4c3.5,0,6.4,2.9,6.4,6.4
                    C205.6,92.3,202.7,95.2,199.2,95.2z M199.2,84.3c-2.4,0-4.4,2-4.4,4.4s2,4.4,4.4,4.4s4.4-2,4.4-4.4S201.6,84.3,199.2,84.3z"
          fill={"#829FC0"}
        />
      </g>
      <path
        d="M208.7,19.3H196c2.2,0,3.9,1.8,3.9,3.9v22.3h12.7V23.3C212.6,21.1,210.9,19.3,208.7,19.3z"
        fill={"#829FC0"}
        opacity={"0.2"}
      />
      <path
        d="M212.6,132.1L212.6,132.1l-127.6,0c-1.3,9.3-9.3,16.4-19,16.4h146.6v0c10.6,0,19.1-8.6,19.1-19.2v-16.4
                C231.7,123.5,223.2,132.1,212.6,132.1z"
        fill={"#829FC0"}
        opacity={"0.2"}
      />
      <g>
        <path
          d="M130,89.5c0-1.9,1.6-3.5,3.5-3.5s3.5,1.6,3.5,3.5c0,1.9-1.6,3.5-3.5,3.5S130,91.4,130,89.5"
          fill={"#FFFFFF"}
        />
        <path
          d="M133.5,94c-2.5,0-4.5-2-4.5-4.5s2-4.5,4.5-4.5c2.5,0,4.5,2,4.5,4.5S135.9,94,133.5,94z
                    M133.5,87.1c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5C135.9,88.2,134.8,87.1,133.5,87.1z"
          fill={"#829FC0"}
        />
      </g>
      <path
        d="M133.5,106.4h-10.9c-1.9,0-3.5-1.6-3.5-3.5v0c0-1.9,1.6-3.5,3.5-3.5h10.9c1.9,0,3.5,1.6,3.5,3.5v0
                C136.9,104.9,135.4,106.4,133.5,106.4z"
        fill={"#E5EBF2"}
      />
      <path
        d="M214.6,111h-15.7c-2.8,0-5-2.2-5-5v0c0-2.8,2.2-5,5-5h15.7c2.8,0,5,2.2,5,5v0C219.6,108.7,217.4,111,214.6,111
                z"
        fill={"#E5EBF2"}
      />
      <g>
        <path d="M7,133.2H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S7.6,133.2,7,133.2z" fill={"#829FC0"} />
        <path
          d="M29.4,133.2H18.2c-0.6,0-1-0.4-1-1s0.4-1,1-1h11.2c0.6,0,1,0.4,1,1S29.9,133.2,29.4,133.2z"
          fill={"#829FC0"}
        />
        <path d="M46.6,133.2h-6c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S47.1,133.2,46.6,133.2z" fill={"#829FC0"} />
      </g>
      <g>
        <path
          d="M237.8,133.2h-6c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S238.4,133.2,237.8,133.2z"
          fill={"#829FC0"}
        />
        <path
          d="M260.2,133.2H249c-0.6,0-1-0.4-1-1s0.4-1,1-1h11.2c0.6,0,1,0.4,1,1S260.8,133.2,260.2,133.2z
                    "
          fill={"#829FC0"}
        />
        <path
          d="M277.4,133.2h-6c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S278,133.2,277.4,133.2z"
          fill={"#829FC0"}
        />
      </g>
      <path
        d="M232.1,106.2c-0.4,0-0.8-0.3-0.9-0.7c-0.2-0.5,0.1-1.1,0.6-1.3c10.4-3.8,17.2-12.4,17.2-21.8
                c0-13.2-12.9-23.9-28.8-23.9c-0.6,0-1-0.4-1-1s0.4-1,1-1c17,0,30.8,11.6,30.8,25.9c0,10.3-7.3,19.6-18.5,23.7
                C232.3,106.2,232.2,106.2,232.1,106.2z"
        fill={"#829FC0"}
      />
      <path
        d="M126.7,84.5c-0.3,0-0.5,0-0.8,0c-0.5-0.1-0.9-0.5-0.9-1.1c0.1-0.5,0.5-0.9,1.1-0.9
                c1,0.1,1.9,0,2.8-0.4c1.2-0.5,2.2-1.3,2.9-2.4c0.3-0.5,0.9-0.6,1.4-0.3c0.5,0.3,0.6,0.9,0.3,1.4c-0.9,1.5-2.2,2.6-3.8,3.2
                C128.7,84.3,127.7,84.5,126.7,84.5z"
        fill={"#E5EBF2"}
      />
      <path
        d="M208.5,79.1c-1.5,0-3-0.3-4.4-0.9c-2.4-1-4.4-2.6-5.7-4.8c-0.3-0.5-0.1-1.1,0.3-1.4
                c0.5-0.3,1.1-0.1,1.4,0.3c1.1,1.8,2.7,3.2,4.7,4c1.5,0.6,3.1,0.8,4.7,0.7c0.6,0,1,0.3,1.1,0.9c0.1,0.5-0.3,1-0.9,1.1
                C209.3,79.1,208.9,79.1,208.5,79.1z"
        fill={"#E5EBF2"}
      />
      <g>
        <path d="M155.6,98.6c1.2-2.9,4.1-4.9,7.4-4.9c3.3,0,6.1,1.9,7.3,4.7" fill={"#FFFFFF"} />
        <path
          d="M155.6,99.6c-0.1,0-0.3,0-0.4-0.1c-0.5-0.2-0.7-0.8-0.5-1.3c1.4-3.4,4.7-5.5,8.3-5.5
                    c3.6,0,6.8,2.1,8.2,5.3c0.2,0.5,0,1.1-0.5,1.3c-0.5,0.2-1.1,0-1.3-0.5c-1.1-2.5-3.7-4.2-6.4-4.2c-2.8,0-5.4,1.7-6.5,4.3
                    C156.4,99.4,156,99.6,155.6,99.6z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <g opacity={"0.5"}>
          <circle cx="205.3" cy="92.4" fill={"#E4EBF1"} r="26.3" />
          <path
            d="M205.3,119.1c-14.8,0-26.8-12-26.8-26.8c0-14.8,12-26.8,26.8-26.8s26.8,12,26.8,26.8
                        C232,107.1,220,119.1,205.3,119.1z M205.3,66.6c-14.2,0-25.8,11.6-25.8,25.8s11.6,25.8,25.8,25.8S231,106.6,231,92.4
                        S219.5,66.6,205.3,66.6z"
            fill={"#829FC0"}
          />
        </g>
        <g>
          <g>
            <rect
              fill={"#E8EEF3"}
              height="16.5"
              transform="matrix(0.7071 0.7071 -0.7071 0.7071 136.437 -91.4959)"
              width="4"
              x="176.7"
              y="110.7"
            />
            <path
              d="M174.2,127.6l-4.2-4.2l13.1-13.1l4.2,4.2L174.2,127.6z M172.8,123.4l1.4,1.4l10.3-10.3
                            l-1.4-1.4L172.8,123.4z"
              fill={"#829FC0"}
            />
          </g>
          <g>
            <path
              d="M227.8,69.9c-12.4-12.4-32.6-12.4-45,0c-12.4,12.4-12.4,32.6,0,45c12.4,12.4,32.6,12.4,45,0
                            C240.2,102.5,240.2,82.3,227.8,69.9z M186.7,111c-10.3-10.3-10.3-26.9,0-37.2c10.3-10.3,26.9-10.3,37.2,0
                            c10.3,10.3,10.3,26.9,0,37.2C213.6,121.2,196.9,121.2,186.7,111z"
              fill={"#C8D7E3"}
            />
            <path
              d="M205.2,125.2c-8.4,0-16.8-3.2-23.2-9.6c-12.8-12.8-12.8-33.6,0-46.5
                            c12.8-12.8,33.6-12.8,46.5,0l0,0c12.8,12.8,12.8,33.6,0,46.5C222.1,122,213.7,125.2,205.2,125.2z M205.2,61.5
                            c-7.9,0-15.8,3-21.8,9c-12,12-12,31.6,0,43.6c12,12,31.6,12,43.6,0c12-12,12-31.6,0-43.6C221,64.5,213.1,61.5,205.2,61.5z
                            M205.2,119.6c-7,0-14-2.7-19.3-8l0,0c-10.6-10.6-10.6-27.9,0-38.6c10.6-10.6,27.9-10.6,38.6,0s10.6,27.9,0,38.6
                            C219.2,117,212.2,119.6,205.2,119.6z M187.4,110.2c9.9,9.9,25.9,9.9,35.7,0c9.9-9.9,9.9-25.9,0-35.7s-25.9-9.9-35.7,0
                            C177.5,84.4,177.5,100.4,187.4,110.2L187.4,110.2z"
              fill={"#829FC0"}
            />
          </g>
          <g>
            <path
              d="M141.4,156.2L141.4,156.2c-2.2-2.2-2.2-5.8,0-8l27-27c2.2-2.2,5.8-2.2,8,0v0
                            c2.2,2.2,2.2,5.8,0,8l-27,27C147.2,158.4,143.6,158.4,141.4,156.2z"
              fill={"#FFFFFF"}
            />
            <path
              d="M145.4,158.9c-1.7,0-3.4-0.6-4.7-1.9c-1.3-1.3-1.9-2.9-1.9-4.7s0.7-3.4,1.9-4.7l27-27
                            c2.6-2.6,6.8-2.6,9.4,0c1.3,1.3,2,2.9,2,4.7s-0.7,3.4-2,4.7l-27,27C148.8,158.2,147.1,158.9,145.4,158.9z M172.3,120.6
                            c-1.2,0-2.4,0.5-3.3,1.4l-27,27c-0.9,0.9-1.4,2-1.4,3.3s0.5,2.4,1.4,3.3c1.8,1.8,4.8,1.8,6.6,0l27-27c0.9-0.9,1.4-2,1.4-3.3
                            s-0.5-2.4-1.4-3.3C174.7,121.1,173.5,120.6,172.3,120.6z"
              fill={"#829FC0"}
            />
          </g>

          <rect
            fill={"#829FC0"}
            height="3.4"
            opacity={"0.2"}
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 135.1921 -94.3394)"
            width="4.2"
            x="179.4"
            y="114.3"
          />
          <path
            d="M168.4,135.8l-3.1-3.1c-1.1-1.1-1.1-3,0-4.1v0c1.1-1.1,3-1.1,4.1,0l3.1,3.1L168.4,135.8z"
            fill={"#C8D7E3"}
          />
          <path
            d="M163.3,140.9l-3.1-3.1c-1.1-1.1-1.1-3,0-4.1l0,0c1.1-1.1,3-1.1,4.1,0l3.1,3.1L163.3,140.9z"
            fill={"#C8D7E3"}
          />
          <path
            d="M157.8,146.4l-3.1-3.1c-1.1-1.1-1.1-3,0-4.1l0,0c1.1-1.1,3-1.1,4.1,0l3.1,3.1L157.8,146.4z"
            fill={"#C8D7E3"}
          />
          <path
            d="M152.3,151.9l-3.1-3.1c-1.1-1.1-1.1-3,0-4.1v0c1.1-1.1,3-1.1,4.1,0l3.1,3.1L152.3,151.9z"
            fill={"#C8D7E3"}
          />
        </g>
        <circle cx="220.6" cy="93.7" fill={"#FFFFFF"} opacity={"0.7"} r="4.9" />
        <path
          d="M230.1,87.1c-1.9-9.1-8.7-16.4-17.4-19c-0.6,1.6-1,3.3-1,5.2c0,8,6.5,14.4,14.4,14.4
                    C227.5,87.7,228.9,87.5,230.1,87.1z"
          fill={"#FFFFFF"}
          opacity={"0.7"}
        />
      </g>
      <g>
        <circle cx="46.8" cy="71.6" fill={"#FFFFFF"} r="19.2" />
        <path
          d="M46.8,91.8c-11.1,0-20.2-9.1-20.2-20.2c0-11.1,9.1-20.2,20.2-20.2S67,60.5,67,71.6
                    C67,82.7,58,91.8,46.8,91.8z M46.8,53.4c-10,0-18.2,8.2-18.2,18.2s8.2,18.2,18.2,18.2S65,81.6,65,71.6S56.9,53.4,46.8,53.4z"
          fill={"#829FC0"}
        />
      </g>
      <g>
        <g>
          <circle cx="187.2" cy="19.5" fill={"#FFFFFF"} r="12" />
          <path
            d="M187.2,32.5c-7.2,0-13-5.8-13-13c0-7.2,5.8-13,13-13s13,5.8,13,13
                        C200.2,26.7,194.4,32.5,187.2,32.5z M187.2,8.5c-6.1,0-11,4.9-11,11s4.9,11,11,11s11-4.9,11-11S193.3,8.5,187.2,8.5z"
            fill={"#829FC0"}
          />
        </g>
      </g>
      <g>
        <path
          d="M46.6,79.9c-0.6,0-1-0.4-1-1v-1.7c0-2,1.2-3.8,3.1-4.6c2.3-1,3.6-3.5,3.1-6
                    c-0.4-2.1-2.1-3.8-4.2-4.2c-1.6-0.3-3.3,0.1-4.5,1.1c-1.2,1-2,2.5-2,4.1c0,0.6-0.4,1-1,1s-1-0.4-1-1c0-2.2,1-4.3,2.7-5.7
                    c1.7-1.4,3.9-2,6.1-1.5c2.9,0.6,5.2,2.9,5.8,5.8c0.7,3.5-1.1,6.9-4.3,8.2c-1.2,0.5-1.9,1.6-1.9,2.7v1.7
                    C47.6,79.4,47.1,79.9,46.6,79.9z"
          fill={"#E5EBF2"}
        />
        <path
          d="M46.6,82.9c-0.6,0-1-0.4-1-1v-0.6c0-0.6,0.4-1,1-1s1,0.4,1,1v0.6
                    C47.6,82.5,47.1,82.9,46.6,82.9z"
          fill={"#E5EBF2"}
        />
      </g>
      <g>
        <path
          d="M187,24.7c-0.6,0-1-0.4-1-1v-1c0-1.3,0.8-2.5,2.1-3c1.2-0.5,1.8-1.8,1.6-3
                    c-0.2-1-1-1.9-2.1-2.1c-0.8-0.2-1.6,0-2.3,0.6c-0.6,0.5-1,1.3-1,2.1c0,0.6-0.4,1-1,1s-1-0.4-1-1c0-1.4,0.6-2.7,1.7-3.6
                    c1.1-0.9,2.5-1.3,3.9-1c1.8,0.4,3.3,1.8,3.7,3.7c0.4,2.2-0.7,4.4-2.7,5.2c-0.5,0.2-0.9,0.7-0.9,1.2v1
                    C188,24.3,187.5,24.7,187,24.7z"
          fill={"#E5EBF2"}
        />
        <path
          d="M187,27.2c-0.6,0-1-0.4-1-1v-0.4c0-0.6,0.4-1,1-1s1,0.4,1,1v0.4C188,26.8,187.5,27.2,187,27.2z
                    "
          fill={"#E5EBF2"}
        />
      </g>
      <path
        d="M160.3,139.1c-5.5,0-12.3-0.6-20.4-1.7c-35.2-5.1-34.9-31.3-34.9-31.6c0-0.6,0.5-1,1-1
                c0.6,0,1,0.5,1,1c0,1-0.2,24.7,33.2,29.5c23.7,3.4,31.8,1,32.9-1c0.1-0.1,0.3-0.5-0.2-1c-0.4-0.4-0.3-1,0.1-1.4
                c0.4-0.4,1-0.3,1.4,0.1c0.9,1,1.1,2.2,0.5,3.3C173.6,137.8,168.5,139.1,160.3,139.1z"
        fill={"#829FC0"}
      />
    </g>
  </svg>
)

export const TeamEmptySvg = () => (
  <svg version="1.1" viewBox="-322 137.2 314.5 286.8" x="0px" y="0px">
    <g opacity={"0.5"}>
      <circle cx="-193.1" cy="151.7" fill={"#B3DDFF"} r="14.5" />
      <circle cx="-286.1" cy="375.6" fill={"#B3DDFF"} r="14.5" />
      <circle cx="-36.9" cy="285.3" fill={"#B3DDFF"} r="10.1" />
      <g>
        <path
          d="M-193.5,153.4c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.4-0.9c0.1-0.2,0.3-0.4,0.5-0.4
                    c0.2-0.1,0.5,0,0.7,0.2l0.8,0.6c0.3,0.3,0.4,0.7,0.1,1.1c-0.3,0.3-0.7,0.3-1,0.1C-192.9,153.2-193.2,153.4-193.5,153.4z"
          fill={"#B3DDFF"}
        />
        <path
          d="M-284.2,371.7c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4
                    c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9C-283.6,371.5-283.9,371.7-284.2,371.7z M-281.9,366.1c-0.1,0-0.2,0-0.3-0.1
                    c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9C-281.3,365.9-281.6,366.1-281.9,366.1z
                    M-279.5,360.5c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9
                    C-279,360.3-279.2,360.5-279.5,360.5z M-277.2,354.9c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4
                    s0.6,0.6,0.4,1l-0.8,1.9C-276.6,354.7-276.9,354.9-277.2,354.9z M-274.9,349.3c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1
                    l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9C-274.3,349.1-274.6,349.3-274.9,349.3z M-272.6,343.7
                    c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9
                    C-272,343.5-272.3,343.7-272.6,343.7z M-270.2,338.1c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4
                    s0.6,0.6,0.4,1l-0.8,1.9C-269.7,337.9-269.9,338.1-270.2,338.1z M-267.9,332.5c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1
                    l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9C-267.3,332.3-267.6,332.5-267.9,332.5z M-265.6,326.9
                    c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4s0.6,0.6,0.4,1l-0.8,1.9
                    C-265,326.7-265.3,326.9-265.6,326.9z M-263.3,321.3c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4
                    c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9C-262.7,321.1-263,321.3-263.3,321.3z M-260.9,315.7c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1
                    l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9C-260.4,315.5-260.6,315.7-260.9,315.7z M-258.6,310.1
                    c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4s0.6,0.6,0.4,1l-0.8,1.9
                    C-258,309.9-258.3,310.1-258.6,310.1z M-256.3,304.5c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4
                    c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9C-255.7,304.3-256,304.5-256.3,304.5z M-254,298.9c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1
                    l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9C-253.4,298.7-253.7,298.9-254,298.9z M-251.6,293.3
                    c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4s0.6,0.6,0.4,1l-0.8,1.9
                    C-251.1,293.1-251.3,293.3-251.6,293.3z M-249.3,287.7c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9
                    c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9C-248.7,287.5-249,287.7-249.3,287.7z M-247,282.1c-0.1,0-0.2,0-0.3-0.1
                    c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9C-246.4,281.9-246.7,282.1-247,282.1z
                    M-244.7,276.5c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9
                    C-244.1,276.3-244.4,276.5-244.7,276.5z M-242.3,270.9c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9
                    c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9C-241.8,270.8-242,270.9-242.3,270.9z M-240,265.3c-0.1,0-0.2,0-0.3-0.1
                    c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9C-239.4,265.2-239.7,265.3-240,265.3z
                    M-237.7,259.7c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9
                    C-237.1,259.6-237.4,259.7-237.7,259.7z M-235.3,254.1c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9
                    c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9C-234.8,254-235.1,254.1-235.3,254.1z M-233,248.5c-0.1,0-0.2,0-0.3-0.1
                    c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9C-232.5,248.4-232.7,248.5-233,248.5z
                    M-230.7,242.9c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9
                    C-230.1,242.8-230.4,242.9-230.7,242.9z M-228.4,237.3c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9
                    c0.2-0.4,0.6-0.6,1-0.4s0.6,0.6,0.4,1l-0.8,1.9C-227.8,237.2-228.1,237.3-228.4,237.3z M-226,231.7c-0.1,0-0.2,0-0.3-0.1
                    c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9C-225.5,231.6-225.8,231.7-226,231.7z
                    M-223.7,226.1c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,2
                    C-223.2,226-223.4,226.1-223.7,226.1z M-221.4,220.6c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4
                    s0.6,0.6,0.4,1l-0.8,1.9C-220.8,220.4-221.1,220.6-221.4,220.6z M-219.1,215c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9
                    c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9C-218.5,214.8-218.8,215-219.1,215z M-216.7,209.4c-0.1,0-0.2,0-0.3-0.1
                    c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9C-216.2,209.2-216.5,209.4-216.7,209.4z
                    M-214.4,203.8c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4s0.6,0.6,0.4,1l-0.8,1.9
                    C-213.8,203.6-214.1,203.8-214.4,203.8z M-212.1,198.2c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9
                    c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9C-211.5,198-211.8,198.2-212.1,198.2z M-209.8,192.6c-0.1,0-0.2,0-0.3-0.1
                    c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9C-209.2,192.4-209.5,192.6-209.8,192.6z
                    M-207.4,187c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9
                    C-206.9,186.8-207.2,187-207.4,187z M-205.1,181.4c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4
                    s0.6,0.6,0.4,1l-0.8,1.9C-204.5,181.2-204.8,181.4-205.1,181.4z M-202.8,175.8c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1
                    l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9C-202.2,175.6-202.5,175.8-202.8,175.8z M-200.5,170.2
                    c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9
                    C-199.9,170-200.2,170.2-200.5,170.2z M-198.1,164.6c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9c0.2-0.4,0.6-0.6,1-0.4
                    s0.6,0.6,0.4,1l-0.8,1.9C-197.6,164.4-197.9,164.6-198.1,164.6z M-195.8,159c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l0.8-1.9
                    c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1l-0.8,1.9C-195.2,158.8-195.5,159-195.8,159z"
          fill={"#B3DDFF"}
        />
        <path
          d="M-286.1,376.3c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.2-0.3-0.5-0.1-0.8l0.4-0.9
                    c0.2-0.4,0.6-0.6,1-0.4c0.2,0.1,0.4,0.3,0.4,0.5c0.2,0.1,0.4,0.2,0.5,0.5c0.1,0.4-0.1,0.8-0.5,1l-0.9,0.3
                    C-285.9,376.3-286,376.3-286.1,376.3z"
          fill={"#B3DDFF"}
        />
        <path
          d="M-281.4,374.6c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5
                    c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C-281.2,374.6-281.3,374.6-281.4,374.6z M-275.7,372.6c-0.3,0-0.6-0.2-0.7-0.5
                    c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C-275.6,372.6-275.6,372.6-275.7,372.6z
                    M-270.1,370.5c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7
                    C-269.9,370.5-270,370.5-270.1,370.5z M-264.4,368.5c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7
                    c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C-264.2,368.5-264.3,368.5-264.4,368.5z M-258.7,366.4
                    c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7
                    C-258.6,366.4-258.7,366.4-258.7,366.4z M-253.1,364.4c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7
                    c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C-252.9,364.4-253,364.4-253.1,364.4z M-247.4,362.3
                    c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.4c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7
                    C-247.2,362.3-247.3,362.3-247.4,362.3z M-241.7,360.3c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7
                    c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C-241.6,360.2-241.7,360.3-241.7,360.3z M-236.1,358.2
                    c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.4c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7
                    C-235.9,358.2-236,358.2-236.1,358.2z M-230.4,356.2c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7
                    c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C-230.2,356.1-230.3,356.2-230.4,356.2z M-224.7,354.1
                    c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7
                    C-224.6,354.1-224.7,354.1-224.7,354.1z M-219.1,352.1c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7
                    c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C-218.9,352-219,352.1-219.1,352.1z M-213.4,350c-0.3,0-0.6-0.2-0.7-0.5
                    c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C-213.2,350-213.3,350-213.4,350z
                    M-207.8,348c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7
                    C-207.6,347.9-207.7,348-207.8,348z M-202.1,345.9c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5
                    c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C-201.9,345.9-202,345.9-202.1,345.9z M-196.4,343.9c-0.3,0-0.6-0.2-0.7-0.5
                    c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.4c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C-196.2,343.8-196.3,343.9-196.4,343.9z
                    M-190.8,341.8c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7
                    C-190.6,341.8-190.7,341.8-190.8,341.8z M-185.1,339.7c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7
                    c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C-184.9,339.7-185,339.7-185.1,339.7z M-179.4,337.7
                    c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7
                    C-179.3,337.7-179.3,337.7-179.4,337.7z M-173.8,335.6c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7
                    c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C-173.6,335.6-173.7,335.6-173.8,335.6z M-168.1,333.6
                    c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7
                    C-167.9,333.6-168,333.6-168.1,333.6z M-162.4,331.5c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7
                    c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.5,1l-1.9,0.7C-162.3,331.5-162.3,331.5-162.4,331.5z M-156.8,329.5
                    c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.4c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7
                    C-156.6,329.5-156.7,329.5-156.8,329.5z M-151.1,327.4c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.5-1l1.9-0.7
                    c0.4-0.1,0.8,0.1,1,0.4c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C-150.9,327.4-151,327.4-151.1,327.4z M-145.4,325.4
                    c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.4c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7
                    C-145.3,325.4-145.4,325.4-145.4,325.4z M-139.8,323.3c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.5-1l1.9-0.7
                    c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.5,1l-1.9,0.7C-139.6,323.3-139.7,323.3-139.8,323.3z M-134.1,321.3
                    c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.5-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.5,1l-1.9,0.7
                    C-133.9,321.3-134,321.3-134.1,321.3z M-128.4,319.2c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.5-1l1.9-0.7
                    c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.5,1l-1.9,0.7C-128.3,319.2-128.4,319.2-128.4,319.2z M-122.8,317.2
                    c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.5-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.5,1l-1.9,0.7
                    C-122.6,317.2-122.7,317.2-122.8,317.2z M-117.1,315.1c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.5-1l1.9-0.7
                    c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.5,1l-1.9,0.7C-116.9,315.1-117,315.1-117.1,315.1z M-111.4,313.1
                    c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.5-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.5,1l-1.9,0.7
                    C-111.3,313.1-111.4,313.1-111.4,313.1z M-105.8,311c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7
                    c0.4-0.1,0.8,0.1,1,0.4c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C-105.6,311-105.7,311-105.8,311z M-100.1,309c-0.3,0-0.6-0.2-0.7-0.5
                    c-0.1-0.4,0.1-0.8,0.5-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.4c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C-99.9,309-100,309-100.1,309z
                    M-94.5,306.9c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.5,1l-1.9,0.7
                    C-94.3,306.9-94.4,306.9-94.5,306.9z M-88.8,304.9c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5
                    c0.1,0.4-0.1,0.8-0.5,1l-1.9,0.7C-88.6,304.9-88.7,304.9-88.8,304.9z M-83.1,302.8c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.5-1
                    l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.5,1l-1.9,0.7C-83,302.8-83,302.8-83.1,302.8z M-77.5,300.8
                    c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.5-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.5,1l-1.9,0.7
                    C-77.3,300.8-77.4,300.8-77.5,300.8z M-71.8,298.7c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.5-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5
                    c0.1,0.4-0.1,0.8-0.5,1l-1.9,0.7C-71.6,298.7-71.7,298.7-71.8,298.7z M-66.1,296.7c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1
                    l1.9-0.7c0.4-0.1,0.8,0.1,1,0.4c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C-66,296.7-66,296.7-66.1,296.7z M-60.5,294.6
                    c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.5-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.5,1l-1.9,0.7
                    C-60.3,294.6-60.4,294.6-60.5,294.6z M-54.8,292.6c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.4
                    c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C-54.6,292.6-54.7,292.6-54.8,292.6z M-49.1,290.5c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.5-1
                    l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.4,1l-1.9,0.7C-49,290.5-49,290.5-49.1,290.5z M-43.5,288.5
                    c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.4-1l1.9-0.7c0.4-0.1,0.8,0.1,1,0.5c0.1,0.4-0.1,0.8-0.5,1l-1.9,0.7
                    C-43.3,288.5-43.4,288.5-43.5,288.5z"
          fill={"#B3DDFF"}
        />
        <path
          d="M-37.8,286.4c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.3,0-0.7,0.2-0.8c-0.2-0.3-0.1-0.6,0.1-0.9
                    c0.3-0.3,0.7-0.4,1.1-0.1l0.8,0.7c0.2,0.2,0.3,0.4,0.3,0.7s-0.2,0.5-0.5,0.6l-0.9,0.3C-37.6,286.4-37.7,286.4-37.8,286.4z"
          fill={"#B3DDFF"}
        />
        <path
          d="M-40.7,282.8c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1
                    c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C-40.3,282.7-40.5,282.8-40.7,282.8z M-45.3,278.9
                    c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1
                    C-44.9,278.8-45.1,278.9-45.3,278.9z M-49.9,274.9c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1
                    c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C-49.5,274.9-49.7,274.9-49.9,274.9z M-54.5,271c-0.2,0-0.3-0.1-0.5-0.2
                    l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C-54.1,270.9-54.3,271-54.5,271z
                    M-59.1,267.1c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3
                    c0.3,0.3,0.4,0.7,0.1,1.1C-58.6,267-58.9,267.1-59.1,267.1z M-63.7,263.2c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3
                    c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C-63.2,263.1-63.5,263.2-63.7,263.2z
                    M-68.3,259.2c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3
                    c0.3,0.3,0.4,0.7,0.1,1.1C-67.8,259.1-68.1,259.2-68.3,259.2z M-72.9,255.3c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3
                    c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C-72.4,255.2-72.6,255.3-72.9,255.3z
                    M-77.5,251.4c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3
                    c0.3,0.3,0.4,0.7,0.1,1.1C-77,251.3-77.2,251.4-77.5,251.4z M-82,247.4c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1
                    c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C-81.6,247.3-81.8,247.4-82,247.4z M-86.6,243.5c-0.2,0-0.3-0.1-0.5-0.2
                    l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C-86.2,243.4-86.4,243.5-86.6,243.5z
                    M-91.2,239.6c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3
                    c0.3,0.3,0.4,0.7,0.1,1.1C-90.8,239.5-91,239.6-91.2,239.6z M-95.8,235.6c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3
                    c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C-95.4,235.6-95.6,235.6-95.8,235.6z
                    M-100.4,231.7c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3
                    c0.3,0.3,0.4,0.7,0.1,1.1C-100,231.6-100.2,231.7-100.4,231.7z M-105,227.8c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3
                    c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C-104.6,227.7-104.8,227.8-105,227.8z
                    M-109.6,223.9c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3
                    c0.3,0.3,0.4,0.7,0.1,1.1C-109.2,223.8-109.4,223.9-109.6,223.9z M-114.2,219.9c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3
                    c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C-113.8,219.8-114,219.9-114.2,219.9z
                    M-118.8,216c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1
                    C-118.4,215.9-118.6,216-118.8,216z M-123.4,212.1c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1
                    c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C-123,212-123.2,212.1-123.4,212.1z M-128,208.1c-0.2,0-0.3-0.1-0.5-0.2
                    l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C-127.6,208-127.8,208.1-128,208.1z
                    M-132.6,204.2c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3
                    c0.3,0.3,0.4,0.7,0.1,1.1C-132.2,204.1-132.4,204.2-132.6,204.2z M-137.2,200.3c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3
                    c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C-136.8,200.2-137,200.3-137.2,200.3z
                    M-141.8,196.3c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3
                    c0.3,0.3,0.4,0.7,0.1,1.1C-141.4,196.3-141.6,196.3-141.8,196.3z M-146.4,192.4c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3
                    c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C-146,192.3-146.2,192.4-146.4,192.4z
                    M-151,188.5c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1
                    C-150.6,188.4-150.8,188.5-151,188.5z M-155.6,184.6c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1
                    c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C-155.2,184.5-155.4,184.6-155.6,184.6z M-160.2,180.6
                    c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1
                    C-159.8,180.5-160,180.6-160.2,180.6z M-164.8,176.7c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1
                    c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C-164.4,176.6-164.6,176.7-164.8,176.7z M-169.4,172.8
                    c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1
                    C-169,172.7-169.2,172.8-169.4,172.8z M-174,168.8c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1
                    c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C-173.5,168.7-173.8,168.8-174,168.8z M-178.6,164.9
                    c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1
                    C-178.1,164.8-178.4,164.9-178.6,164.9z M-183.2,161c-0.2,0-0.3-0.1-0.5-0.2l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1
                    c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C-182.7,160.9-182.9,161-183.2,161z M-187.8,157c-0.2,0-0.3-0.1-0.5-0.2
                    l-1.5-1.3c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.5,1.3c0.3,0.3,0.4,0.7,0.1,1.1C-187.3,157-187.5,157-187.8,157z"
          fill={"#B3DDFF"}
        />
      </g>
    </g>
    <path
      d="M-307.9,310.8c-3.8,0-6.8-3.1-6.8-6.8c0-3.7,3.1-6.8,6.8-6.8c3.7,0,6.8,3.1,6.8,6.8
            C-301.1,307.7-304.1,310.8-307.9,310.8z M-307.9,299.2c-2.6,0-4.8,2.2-4.8,4.8c0,2.6,2.2,4.8,4.8,4.8s4.8-2.2,4.8-4.8
            C-303.1,301.4-305.3,299.2-307.9,299.2z"
      fill={"#B3DDFF"}
      opacity={"0.3"}
    />
    <path
      d="M-87.6,188.6c-3.8,0-6.8-3.1-6.8-6.8c0-3.7,3.1-6.8,6.8-6.8s6.8,3.1,6.8,6.8
            C-80.8,185.5-83.8,188.6-87.6,188.6z M-87.6,177c-2.6,0-4.8,2.2-4.8,4.8c0,2.6,2.2,4.8,4.8,4.8s4.8-2.2,4.8-4.8
            C-82.8,179.1-84.9,177-87.6,177z"
      fill={"#B3DDFF"}
      opacity={"0.3"}
    />
    <path
      d="M-168.6,424c-3.8,0-6.8-3.1-6.8-6.8s3.1-6.8,6.8-6.8s6.8,3.1,6.8,6.8S-164.8,424-168.6,424z
            M-168.6,412.4c-2.6,0-4.8,2.2-4.8,4.8c0,2.6,2.2,4.8,4.8,4.8s4.8-2.2,4.8-4.8C-163.8,414.5-165.9,412.4-168.6,412.4z"
      fill={"#B3DDFF"}
      opacity={"0.3"}
    />
    <path
      d="M-40.3,371.7h23.2c5.3,0,9.6-4.3,9.6-9.6s-4.3-9.6-9.6-9.6h-42.3c-5.3,0-9.6-4.3-9.6-9.6
            s4.3-9.6,9.6-9.6h23.2c5.3,0,9.6-4.3,9.6-9.6s-4.3-9.6-9.6-9.6h-46.3c-5.3,0-9.6-4.3-9.6-9.6s4.3-9.6,9.6-9.6h18.3
            c5.3,0,9.6-4.3,9.6-9.6s-4.3-9.6-9.6-9.6h-79.2c-5.3,0-9.6,4.3-9.6,9.6s4.3,9.6,9.6,9.6s9.6,4.3,9.6,9.6s-4.3,9.6-9.6,9.6h-35.7
            c-5.3,0-9.6,4.3-9.6,9.6s4.3,9.6,9.6,9.6h41.9c5.3,0,9.6,4.3,9.6,9.6s-4.3,9.6-9.6,9.6h-15.6c-5.3,0-9.6,4.3-9.6,9.6
            s4.3,9.6,9.6,9.6h51.3C-96.3,371.7-45.5,371.7-40.3,371.7z"
      fill={"#B3DDFF"}
      opacity={"0.2"}
    />
    <g>
      <circle cx="-170.9" cy="288.8" fill={"#D4EAFF"} r="107" />
      <path
        d="M-170.9,396.8c-59.6,0-108-48.5-108-108s48.5-108,108-108c59.6,0,108,48.5,108,108
                S-111.4,396.8-170.9,396.8z M-170.9,182.8c-58.5,0-106,47.6-106,106s47.6,106,106,106c58.5,0,106-47.6,106-106
                S-112.5,182.8-170.9,182.8z"
        fill={"#3390FF"}
      />
    </g>
    <g>
      <circle cx="-170.9" cy="288.8" fill={"#FFFFFF"} r="92.1" />
      <path
        d="M-170.9,381.9c-51.3,0-93.1-41.8-93.1-93.1s41.8-93.1,93.1-93.1s93.1,41.8,93.1,93.1
                C-77.8,340.1-119.6,381.9-170.9,381.9z M-170.9,197.7c-50.2,0-91.1,40.9-91.1,91.1s40.9,91.1,91.1,91.1s91.1-40.9,91.1-91.1
                C-79.8,238.6-120.7,197.7-170.9,197.7z"
        fill={"#3390FF"}
      />
    </g>
    <path
      d="M-173.6,274.6h23.2c5.3,0,9.6-4.3,9.6-9.6s-4.3-9.6-9.6-9.6h-42.3c-5.3,0-9.6-4.3-9.6-9.6
            c0-5.3,4.3-9.6,9.6-9.6h23.2c5.3,0,9.6-4.3,9.6-9.6c0-5.3-4.3-9.6-9.6-9.6h-46.3c-5.3,0-9.6-4.3-9.6-9.6c0-5.3,4.3-9.6,9.6-9.6h18.3
            c5.3,0,9.6-4.3,9.6-9.6c0-5.3-4.3-9.6-9.6-9.6h-79.2c-5.3,0-9.6,4.3-9.6,9.6c0,5.3,4.3,10,9.6,10c5.3,0,9.6,4.3,9.6,9.6
            c0,5.3-4.3,9.6-9.6,9.6h-35.7c-5.3,0-9.6,4.3-9.6,9.6c0,5.3,4.3,9.6,9.6,9.6h41.9c5.3,0,9.6,4.3,9.6,9.6c0,5.3-4.3,9.6-9.6,9.6
            h-15.7c-5.3,0-9.6,4.3-9.6,9.6c0,5.3,4.3,9.6,9.6,9.6h51.3C-229.7,274.6-178.9,274.6-173.6,274.6z"
      fill={"#B3DDFF"}
      opacity={"0.2"}
    />
    <g>
      <path
        d="M-153.6,327.2L-153.6,327.2V315h-34.5v11.9c-18.6,4-34.7,14.6-45.8,29.2
                c16.5,15.5,38.6,24.9,63,24.9c23.9,0,45.8-9.1,62.1-24.1C-119.6,342.2-135.4,331.5-153.6,327.2z"
        fill={"#FFFFFF"}
      />
      <path
        d="M-170.9,381.9c-23.7,0-46.4-8.9-63.7-25.2c-0.4-0.4-0.4-0.9-0.1-1.3
                c11.3-14.9,27.5-25.3,45.6-29.4v-11.1c0-0.6,0.4-1,1-1h34.5c0.6,0,1,0.4,1,1v11.5c17.8,4.4,33.6,15,44.6,29.8
                c0.3,0.4,0.2,1-0.1,1.3C-125.3,373.3-147.6,381.9-170.9,381.9z M-232.6,355.9c16.9,15.5,38.7,24,61.7,24c22.5,0,44.1-8.2,60.8-23.2
                c-10.8-14.3-26.3-24.4-43.7-28.5c-0.5-0.1-0.8-0.5-0.8-1V316h-32.5v10.9c0,0.5-0.3,0.9-0.8,1C-205.6,331.6-221.4,341.5-232.6,355.9
                z"
        fill={"#3390FF"}
      />
    </g>
    <path
      d="M-153.7,323.5v-8.9h-34.5v9c5.3,2,11.1,3.2,17.2,3.2l0,0C-164.9,326.7-159.1,325.5-153.7,323.5z
            "
      fill={"#3390FF"}
      opacity={"0.2"}
    />
    <g>
      <path
        d="M-170.9,319.6L-170.9,319.6c-26.7,0-48.3-21.6-48.3-48.3V236c0-26.7,21.6-48.3,48.3-48.3l0,0
                c26.7,0,48.3,21.6,48.3,48.3v35.3C-122.7,298-144.3,319.6-170.9,319.6z"
        fill={"#FFFFFF"}
      />
      <path
        d="M-170.9,320.6c-27.2,0-49.3-22.1-49.3-49.3V236c0-27.2,22.1-49.3,49.3-49.3
                s49.3,22.1,49.3,49.3v35.3C-121.7,298.5-143.8,320.6-170.9,320.6z M-170.9,188.7c-26.1,0-47.3,21.2-47.3,47.3v35.3
                c0,26.1,21.2,47.3,47.3,47.3l0,0c26.1,0,47.3-21.2,47.3-47.3V236C-123.7,210-144.9,188.7-170.9,188.7z"
        fill={"#3390FF"}
      />
    </g>
    <g>
      <path
        d="M-166.9,274.9h-8c-2.4,0-4.1-2.4-3.3-4.6l4.3-12c1.1-3.1,5.6-3,6.5,0.1l3.7,12
                C-163,272.6-164.6,274.9-166.9,274.9z"
        fill={"#D4EAFF"}
      />
      <path
        d="M-166.9,275.9h-8c-1.4,0-2.8-0.7-3.6-1.9c-0.8-1.2-1-2.7-0.6-4.1l4.3-12c0.7-1.8,2.3-3,4.3-3
                s3.6,1.3,4.2,3.1l3.7,12c0.4,1.4,0.2,2.8-0.7,4S-165.5,275.9-166.9,275.9z M-170.6,256.9c-0.8,0-1.9,0.4-2.3,1.6l-4.3,12
                c-0.3,0.8-0.2,1.6,0.3,2.2c0.5,0.7,1.2,1,2,1h8c0.8,0,1.5-0.4,2-1c0.5-0.6,0.6-1.4,0.4-2.2l-3.7-12
                C-168.7,257.4-169.7,257-170.6,256.9L-170.6,256.9z"
        fill={"#3390FF"}
      />
    </g>
    <path
      d="M-163.6,270.4l-3.7-12c-0.9-2.8-4.5-3.2-6-1.1c0.2,0.3,0.4,0.7,0.5,1.1l3.7,12
            c0.7,2.2-1,4.5-3.3,4.5h5.5C-164.6,274.9-163,272.6-163.6,270.4z"
      fill={"#3390FF"}
      opacity={"0.2"}
    />
    <g>
      <path d="M-205.4,264.6c0-4.7,3.8-8.5,8.5-8.5c4.7,0,8.5,3.8,8.5,8.5" fill={"#FFFFFF"} />
      <path
        d="M-188.5,265.6c-0.6,0-1-0.4-1-1c0-4.1-3.3-7.5-7.5-7.5s-7.5,3.3-7.5,7.5c0,0.6-0.4,1-1,1
                s-1-0.4-1-1c0-5.2,4.2-9.5,9.5-9.5c5.2,0,9.5,4.2,9.5,9.5C-187.5,265.2-187.9,265.6-188.5,265.6z"
        fill={"#3390FF"}
      />
    </g>
    <g>
      <path d="M-154.7,264.6c0-4.7,3.8-8.5,8.5-8.5s8.5,3.8,8.5,8.5" fill={"#FFFFFF"} />
      <path
        d="M-137.7,265.6c-0.6,0-1-0.4-1-1c0-4.1-3.3-7.5-7.5-7.5s-7.5,3.3-7.5,7.5c0,0.6-0.4,1-1,1
                s-1-0.4-1-1c0-5.2,4.2-9.5,9.5-9.5s9.5,4.2,9.5,9.5C-136.7,265.2-137.2,265.6-137.7,265.6z"
        fill={"#3390FF"}
      />
    </g>
    <circle cx="-204.4" cy="285.3" fill={"#D4EAFF"} r="8.9" />
    <circle cx="-138.7" cy="285.3" fill={"#D4EAFF"} r="8.9" />
    <g>
      <path d="M-219.2,271.3h-4.6c-4.6,0-8.3-3.7-8.3-8.3l0,0c0-4.6,3.7-8.3,8.3-8.3h4.6V271.3z" fill={"#FFFFFF"} />
      <path
        d="M-219.2,272.3h-4.6c-5.1,0-9.3-4.2-9.3-9.3c0-5.1,4.2-9.3,9.3-9.3h4.6c0.6,0,1,0.4,1,1v16.5
                C-218.2,271.9-218.7,272.3-219.2,272.3z M-223.8,255.8c-4,0-7.3,3.3-7.3,7.3s3.3,7.3,7.3,7.3h3.6v-14.5L-223.8,255.8L-223.8,255.8z
                "
        fill={"#3390FF"}
      />
    </g>
    <g>
      <path d="M-223.2,265.7c-1.6,0-2.9-1.3-2.9-2.9s1.3-2.9,2.9-2.9" fill={"#FFFFFF"} />
      <path
        d="M-223.2,266.7c-2.1,0-3.9-1.7-3.9-3.9c0-2.1,1.7-3.9,3.9-3.9c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1
                c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9c0.6,0,1,0.4,1,1C-222.2,266.2-222.6,266.7-223.2,266.7z"
        fill={"#3390FF"}
      />
    </g>
    <g>
      <path d="M-122.7,254.8h4.6c4.6,0,8.3,3.7,8.3,8.3l0,0c0,4.6-3.7,8.3-8.3,8.3h-4.6V254.8z" fill={"#FFFFFF"} />
      <path
        d="M-118.1,272.3h-4.6c-0.6,0-1-0.4-1-1v-16.5c0-0.6,0.4-1,1-1h4.6c5.1,0,9.3,4.2,9.3,9.3
                C-108.8,268.2-112.9,272.3-118.1,272.3z M-121.7,270.3h3.6c4,0,7.3-3.3,7.3-7.3s-3.3-7.3-7.3-7.3h-3.6V270.3z"
        fill={"#3390FF"}
      />
    </g>
    <g>
      <path d="M-118.7,260.4c1.6,0,2.9,1.3,2.9,2.9s-1.3,2.9-2.9,2.9" fill={"#FFFFFF"} />
      <path
        d="M-118.7,267.2c-0.6,0-1-0.4-1-1s0.4-1,1-1c1,0,1.9-0.8,1.9-1.9c0-1-0.8-1.9-1.9-1.9
                c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1c2.1,0,3.9,1.7,3.9,3.9C-114.9,265.4-116.6,267.2-118.7,267.2z"
        fill={"#3390FF"}
      />
    </g>
    <g>
      <path
        d="M-169.7,298.2h-2.4c-8.7,0-15.7-7-15.7-15.7l0,0h33.8l0,0C-154,291.2-161,298.2-169.7,298.2z"
        fill={"#D4EAFF"}
      />
      <path
        d="M-169.7,299.2h-2.4c-9.2,0-16.7-7.5-16.7-16.7c0-0.6,0.4-1,1-1h33.8c0.6,0,1,0.4,1,1
                C-153,291.7-160.5,299.2-169.7,299.2z M-186.8,283.5c0.5,7.6,6.9,13.7,14.7,13.7h2.4c7.8,0,14.2-6.1,14.7-13.7H-186.8z"
        fill={"#3390FF"}
      />
    </g>
    <g>
      <path d="M-186.5,288.9h31.2c0.8-1.9,1.3-4,1.3-6.3h-33.8C-187.9,284.8-187.4,286.9-186.5,288.9z" fill={"#FFFFFF"} />
      <path
        d="M-155.3,289.9h-31.2c-0.4,0-0.8-0.2-0.9-0.6c-0.9-2.1-1.4-4.4-1.4-6.7c0-0.6,0.4-1,1-1h33.8
                c0.6,0,1,0.4,1,1c0,2.3-0.5,4.5-1.4,6.7C-154.6,289.6-154.9,289.9-155.3,289.9z M-185.9,287.9h29.9c0.5-1.4,0.8-2.8,0.9-4.3h-31.8
                C-186.7,285.1-186.4,286.5-185.9,287.9z"
        fill={"#3390FF"}
      />
    </g>
    <g>
      <line fill={"none"} x1="-160.2" x2="-160.2" y1="286" y2="288.8" />
      <path
        d="M-160.2,289.8c-0.6,0-1-0.4-1-1V286c0-0.6,0.4-1,1-1s1,0.4,1,1v2.8
                C-159.2,289.4-159.7,289.8-160.2,289.8z"
        fill={"#3390FF"}
      />
    </g>
    <path
      d="M-163.5,282.5L-163.5,282.5c0,7.5-5.2,13.7-12.2,15.3c1.1,0.3,2.3,0.4,3.5,0.4h2.4
            c8.7,0,15.7-7,15.7-15.7l0,0H-163.5z"
      fill={"#3390FF"}
      opacity={"0.2"}
    />
    <g>
      <line fill={"none"} x1="-187.9" x2="-187.9" y1="316.5" y2="334.6" />
      <path
        d="M-187.9,335.6c-0.6,0-1-0.4-1-1v-18c0-0.6,0.4-1,1-1s1,0.4,1,1v18
                C-186.9,335.1-187.3,335.6-187.9,335.6z"
        fill={"#3390FF"}
      />
    </g>
    <g>
      <path
        d="M-141,331.4c-4,12.6-15.8,21.8-29.8,21.8c-14.2,0-26.2-9.5-30-22.4
                c-13.2,5.3-24.6,14.1-33.1,25.3c16.5,15.5,38.6,24.9,63,24.9c23.9,0,45.8-9.1,62.1-24.1C-117,345.7-128.1,336.9-141,331.4z"
        fill={"#A6D7FF"}
      />
      <path
        d="M-170.9,381.9c-23.7,0-46.4-8.9-63.7-25.2l-0.7-0.6l0.5-0.7c8.7-11.4,20.3-20.3,33.6-25.6
                l1-0.4l0.3,1.1c3.8,12.8,15.7,21.7,29,21.7c13.2,0,24.8-8.5,28.8-21.1l0.3-1l1,0.4c12.9,5.5,24.2,14.4,32.6,25.8l0.5,0.7l-0.7,0.6
                C-125.3,373.3-147.6,381.9-170.9,381.9z M-232.6,355.9c16.9,15.5,38.7,24,61.7,24c22.5,0,44.1-8.2,60.8-23.2
                c-7.9-10.4-18.3-18.7-30.3-24c-4.6,12.9-16.7,21.4-30.4,21.4c-13.8,0-26.3-9-30.6-22.1C-213.7,337.2-224.4,345.4-232.6,355.9z"
        fill={"#3390FF"}
      />
    </g>
    <path
      d="M-104.2,327.1c-23.2,0-42,18.8-42,42c0,7.7,2.1,15,5.8,21.2c27.7-8.3,50.6-27.7,63.7-52.9
            C-84.1,331-93.7,327.1-104.2,327.1z"
      fill={"#3390FF"}
      opacity={"0.2"}
    />
    <g>
      <line fill={"none"} x1="-153.6" x2="-153.6" y1="316.5" y2="334.6" />
      <path
        d="M-153.6,335.6c-0.6,0-1-0.4-1-1v-18c0-0.6,0.4-1,1-1s1,0.4,1,1v18
                C-152.6,335.1-153.1,335.6-153.6,335.6z"
        fill={"#3390FF"}
      />
    </g>
    <g>
      <g>
        <circle cx="-104.2" cy="369.1" fill={"#FFFFFF"} r="34.5" />
        <path
          d="M-104.2,404.6c-19.6,0-35.5-15.9-35.5-35.5s15.9-35.5,35.5-35.5s35.5,15.9,35.5,35.5
                    S-84.6,404.6-104.2,404.6z M-104.2,335.6c-18.5,0-33.5,15-33.5,33.5s15,33.5,33.5,33.5s33.5-15,33.5-33.5S-85.7,335.6-104.2,335.6
                    z"
          fill={"#3390FF"}
        />
      </g>
      <g>
        <g>
          <line fill={"none"} x1="-104.2" x2="-104.2" y1="350.5" y2="387.7" />
          <path
            d="M-104.2,388.7c-0.6,0-1-0.4-1-1v-37.3c0-0.6,0.4-1,1-1s1,0.4,1,1v37.3
                        C-103.2,388.3-103.7,388.7-104.2,388.7z"
            fill={"#3390FF"}
          />
        </g>
        <g>
          <line fill={"none"} x1="-85.6" x2="-122.8" y1="369.1" y2="369.1" />
          <path
            d="M-85.6,370.1h-37.3c-0.6,0-1-0.4-1-1s0.4-1,1-1h37.3c0.6,0,1,0.4,1,1S-85,370.1-85.6,370.1z"
            fill={"#3390FF"}
          />
        </g>
      </g>
      <path
        d="M-113.4,397.6c-3.8,0-7.5-0.6-11-1.8c5.6,4.2,12.6,6.7,20.1,6.7c18.5,0,33.5-15,33.5-33.5
                c0-12.5-6.9-23.4-17-29.1c5.5,6.1,8.9,14.2,8.9,23.1C-78.8,382.2-94.3,397.6-113.4,397.6z"
        fill={"#B3DDFF"}
      />
    </g>
    <g>
      <line fill={"none"} x1="-213.3" x2="-213.3" y1="362.1" y2="370.6" />
      <path
        d="M-213.3,371.6c-0.6,0-1-0.4-1-1v-8.5c0-0.6,0.4-1,1-1s1,0.4,1,1v8.5
                C-212.3,371.2-212.8,371.6-213.3,371.6z"
        fill={"#3390FF"}
      />
    </g>
    <g>
      <path
        d="M-145.3,205c13.6,8.5,22.6,23.8,22.6,41v-10c0-26.7-21.6-48.3-48.3-48.3l0,0
                c-26.7,0-48.3,21.6-48.3,48.3v10c0-17.2,9-32.3,22.6-40.9c3.9,10.4,13.9,17.7,25.7,17.7s21.7-7.4,25.7-17.7V205"
        fill={"#A6D7FF"}
      />
      <path
        d="M-122.7,247c-0.6,0-1-0.4-1-1c0-16.1-7.9-30.7-21.1-39.5c-4.4,10.4-14.8,17.4-26.2,17.4
                c-11.3,0-21.7-6.9-26.1-17.2c-13.3,8.8-21.1,23.4-21.1,39.4c0,0.6-0.4,1-1,1s-1-0.4-1-1v-10c0-27.2,22.1-49.3,49.3-49.3
                s49.3,22.1,49.3,49.3v10C-121.7,246.6-122.1,247-122.7,247z M-170.9,188.7c-24.5,0-44.6,18.7-47,42.5c3.4-11,10.7-20.6,20.8-27
                c0.3-0.2,0.6-0.2,0.9-0.1c0.3,0.1,0.5,0.3,0.6,0.6c3.9,10.2,13.8,17.1,24.7,17.1s20.8-6.8,24.7-17l0,0c0-0.3,0.2-0.7,0.6-0.8
                c0.3-0.2,0.7-0.1,1,0c10.1,6.3,17.4,15.9,20.8,27C-126.4,207.3-146.5,188.7-170.9,188.7z"
        fill={"#3390FF"}
      />
    </g>
    <path
      d="M-148.6,192.8v0.2c-3.9,10.4-13.9,17.7-25.7,17.7c-5,0-9.6-1.3-13.6-3.6c4.8,4,10.9,6.4,17.6,6.4
            c11.7,0,21.7-7.4,25.7-17.7v-0.1C-145.9,194.6-147.2,193.7-148.6,192.8z"
      fill={"#3390FF"}
      opacity={"0.2"}
    />
    <path
      d="M-158.1,188.4L-158.1,188.4c-2.5,6.8-9,11.5-16.5,11.5c-3.2,0-6.2-0.9-8.8-2.3
            c3.1,2.6,7,4.1,11.3,4.1c7.5,0,14-4.7,16.5-11.4v-0.1C-156.4,189.6-157.2,189-158.1,188.4z"
      fill={"#3390FF"}
      opacity={"0.2"}
    />
    <path
      d="M-209.1,252.7c-0.2,0-0.3,0-0.5-0.1c-0.5-0.3-0.7-0.9-0.4-1.4c0-0.1,3.2-5.7,10-4.6
            c0.5,0.1,0.9,0.6,0.8,1.1s-0.6,0.9-1.1,0.8c-5.4-0.9-7.9,3.4-8,3.6C-208.4,252.5-208.8,252.7-209.1,252.7z"
      fill={"#D4EAFF"}
    />
    <path
      d="M-134.1,252.7c-0.4,0-0.7-0.2-0.9-0.5c-0.1-0.2-2.5-4.5-8-3.6c-0.6,0.1-1.1-0.3-1.1-0.8
            c-0.1-0.5,0.3-1.1,0.8-1.1c6.9-1.1,10,4.6,10,4.6c0.3,0.5,0.1,1.1-0.4,1.4C-133.8,252.7-134,252.7-134.1,252.7z"
      fill={"#D4EAFF"}
    />
    <rect fill={"#3390FF"} height="16.5" opacity={"0.2"} width="5.4" x="-122.7" y="254.8" />
    <rect fill={"#3390FF"} height="16.5" opacity={"0.2"} width="5.4" x="-224.6" y="254.8" />
    <path
      d="M-168.9,311.4c-0.8,0-1.5-0.3-2.1-0.8c-0.6,0.5-1.3,0.8-2.1,0.8c-1.8,0-3.2-1.4-3.2-3.2
            c0-0.6,0.4-1,1-1s1,0.4,1,1c0,0.7,0.5,1.2,1.2,1.2c0.5,0,0.9-0.3,1.1-0.8c0.1-0.4,0.5-0.7,0.9-0.7s0.8,0.3,0.9,0.7
            c0.2,0.5,0.6,0.8,1.1,0.8c0.7,0,1.2-0.5,1.2-1.2c0-0.6,0.4-1,1-1s1,0.4,1,1C-165.7,309.9-167.1,311.4-168.9,311.4z"
      fill={"#D4EAFF"}
    />
    <polygon fill={"#3390FF"} opacity={"0.2"} points="-205,374.3 -212.6,361.5 -213.3,369.7 " />
  </svg>
)
