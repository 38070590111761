import { createContext, useContext, useEffect, useState } from "react"

const IsInsidePowerPointContext = createContext<null | boolean>(null)

function getSubdomain() {
  const hostname = window.location.hostname
  const parts = hostname.split(".")
  if (parts.length >= 2) {
    return parts[0]
  }
  return null
}

export const IsInsidePowerPointProvider = ({ children }) => {
  const [isInsidePowerPoint, setIsInsidePowerPoint] = useState<null | boolean>(null)

  useEffect(() => {
    const isPowerPoint = getSubdomain() === "powerpoint"
    setIsInsidePowerPoint(isPowerPoint)
  }, [])

  return <IsInsidePowerPointContext.Provider value={isInsidePowerPoint}>{children}</IsInsidePowerPointContext.Provider>
}

export const useIsInsidePowerPoint = () => {
  return useContext(IsInsidePowerPointContext)
}
