import PricingContainer from "./PricingContainer"
import { SlidesIcon, IconsIcon, EmailIcon, QuoteIcon } from "../../svg/paymentIcons"

export const PricingPage = () => {
  return (
    <div className="pricing element-appear">
      <div className="thumbnail-list">
        <div id="pricing-container">
          <div className="pricing-top">
            <h1>
              Unleash your team <strong>productivity&nbsp;today</strong>
            </h1>
            <p className="top">All our plans include unlimited slide&nbsp;downloads</p>
            <PricingContainer />
          </div>
        </div>
        <hr />
        <div className="include">
          <h3 className="text-center">
            See what’s <strong>included in&nbsp;all&nbsp;plans</strong>:
          </h3>
          <div className="include-item">
            <SlidesIcon />
            <h4>Unlimited slide downloads</h4>
          </div>
          <div className="include-item">
            <IconsIcon />
            <h4>Searchable icons</h4>
          </div>
          <div className="include-item">
            <EmailIcon />
            <h4>Priority email support available</h4>
          </div>
          {/* <div className="include-item">
							<PaintIcon />
							<h4>7 color versions for all slides</h4>
						</div>
						<div className="include-item">
							<NewSlidesIcon />
							<h4>New slides every week</h4>
						</div>
						<div className="include-item">
							<ExamplesIcon />
							<h4>Presentation examples</h4>
						</div> */}
        </div>
      </div>
      <div className="pricing-opinion">
        <div className="thumbnail-list">
          <QuoteIcon />
          <p className="comment">
            "SlideCamp is AWESOME! <br /> I've been looking for something <br /> similar to this for a long time"
          </p>
          <p className="author">Melissa</p>
        </div>
      </div>
      <div className="pricing-question">
        <div className="thumbnail-list">
          <h2 className="text-center">
            Frequently <strong>Asked Questions</strong>
          </h2>
          <div className="questions">
            <div className="questions-item">
              <hr />
              <h3>
                Can I download all the <br />
                slides instantly?
              </h3>
              <p>
                All plans include unlimited slide downloads. Once you've selected the slides, you need just press
                Download and start working on your presentation&nbsp;in&nbsp;PowerPoint.
              </p>
            </div>
            <div className="questions-item">
              <hr />
              <h3>
                What payment options <br />
                are available?
              </h3>
              <p>We proudly accept all major credit cards (including American Express) and&nbsp;even&nbsp;Paypal!</p>
            </div>
            <div className="questions-item">
              <hr />
              <h3>
                Are subscriptions a one-time <br />
                or recurring payment?
              </h3>
              <p>
                For all our plans you'll be billed monthly but if you wish we could setup also a yearly
                plan&nbsp;for&nbsp;you.
              </p>
            </div>
            <div className="questions-item">
              <hr />
              <h3>
                Can I cancel <br />
                anytime?
              </h3>
              <p>
                Yes! You're able to cancel any subscription, anytime, very quickly! We hate to see you go though, so
                feel free to let us know what we can do to better your SlideCamp experience! We're available around the
                clock to help you and are constantly upgrading&nbsp;and&nbsp;improving!
              </p>
            </div>
            {/* <div className="questions-item">
                <hr/>
                <h3>How do SlideCamp subscription <br/>plans work?</h3>
                <p>Once you choose your plan, you'll gain access to our entire library of slides. Each slide you download counts towards your monthly limit, unless you're using our&nbsp;Unlimited plan,&nbsp;of&nbsp;course.</p>
              </div>
              <div className="questions-item">
                <hr/>
                <h3>Does downloading different colors <br/> of the same slide influence my&nbsp;slide&nbsp;limit?</h3>
                <p>Not at all! If you downloaded a slide and&nbsp;realize you'd prefer it in a different color, or&nbsp;love it so much that you'd like it in&nbsp;every color...it&nbsp;will still only count as one slide download towards your limit. So, feel free to go crazy experimenting with our colors, at no extra&nbsp;cost&nbsp;to&nbsp;you!</p>
              </div>
              <div className="questions-item">
                <hr/>
                <h3>Are free slides available?</h3>
                <p>Yes, and they're 100% free! They will not&nbsp;count towards your slide download limit and are available even without a purchased plan. You can find them easily on your dashboard, grouped together under&nbsp;“Free&nbsp;Slides”.</p>
              </div>
              <div className="questions-item">
                <hr/>
                <h3>What if I need more slides?</h3>
                <p>You can always upgrade your plan to&nbsp;increase your download limit and ensure that you have all the slides&nbsp;you&nbsp;need!</p>
              </div> */}
          </div>
        </div>
      </div>
      <div className="more-questions">
        <div className="thumbnail-list text-center">
          <h2>
            Have more <strong>Questions</strong>?
          </h2>
          <p>Don’t hesitate to ask!</p>
          <a className="btn btn-lightblue" href="mailto:contact@slidecamp.io">
            Contact Us
          </a>
        </div>
      </div>
      <div className="sc-footer">
        <p className="thumbnail-list">{new Date().getFullYear()} © SlideCamp</p>
      </div>
    </div>
  )
}
