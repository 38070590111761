import HowItWorksIcon from "../../assets/img/how_it_works/how-it-works.png"
import HowItWorksIcon2x from "../../assets/img/how_it_works/how-it-works_2x.png"

export const Instructions = () => (
  <div className="hot-it-works-container">
    <h1>Welcome to SlideCamp!</h1>
    <img alt="how it works" src={HowItWorksIcon} srcSet={HowItWorksIcon2x} />
    <div className="hot-it-works-steps">
      <p>Find the slides you need</p>
      <p>Add to your cart & download </p>
      <p>Edit directly in PowerPoint</p>
    </div>
  </div>
)
