import { useMutation } from "@apollo/client"

import { changeTeamOwner } from "../../graphql/mutations"
import { useForceUpdate } from "../../hooks/ForceUpdate"
import { useForm } from "../../hooks/FormHook"
import { emailValidator, isRequired } from "../utils/helpers"
import { InputWrapper } from "../utils/forms"

const ChangeTeamOwner = () => {
  const [_changeTeamOwner, { loading }] = useMutation(changeTeamOwner)

  const forceUpdate = useForceUpdate()
  const [values, onChange, validations, validate] = useForm([
    {
      name: "currentTeamOwnerId",
      validators: [isRequired, emailValidator]
    },
    {
      name: "newTeamOwnerId",
      validators: [isRequired, emailValidator]
    }
  ])

  const submitForm = () => {
    if (!validate()) {
      forceUpdate()
      return
    }
    _changeTeamOwner({ variables: values })
  }

  return (
    <div>
      <form>
        <div>
          <div>
            <div>
              <InputWrapper error={validations.currentTeamOwnerId} label="Current team owner Email">
                <input
                  name="currentTeamOwnerId"
                  onChange={onChange}
                  placeholder="Current team owner email"
                  type="text"
                  value={values.currentTeamOwnerId}
                />
              </InputWrapper>
            </div>
          </div>
          <div>
            <div>
              <InputWrapper error={validations.newTeamOwnerId} label="New team owner Email">
                <input
                  name="newTeamOwnerId"
                  onChange={onChange}
                  placeholder="New team owner email"
                  type="text"
                  value={values.newTeamOwnerId}
                />
              </InputWrapper>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="admin-button-submit">
            <button className="btn btn-dark-blue" disabled={loading} onClick={submitForm} type="button">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ChangeTeamOwner
