import { useApolloClient, useMutation } from "@apollo/client"
import { useState } from "react"
import TagsInput from "react-tagsinput"
import { createPlan, updatePlan } from "../../graphql/mutations"
import { AdminData } from "../../graphql/queries"
import { useForm } from "../../hooks/FormHook"
import { isRequired } from "../utils/helpers"
import { useForceUpdate } from "../../hooks/ForceUpdate"
import { InputWrapper } from "../utils/forms"
import { fragments } from "../../graphql/fragments"

const CreatePlanWrapper = ({ action, objectId }) => {
  const [submitSucceeded, setSubmitSucceeded] = useState(false)

  const client = useApolloClient()
  const [_createPlan, { loading: createLoading }] = useMutation(createPlan)
  const [_updatePlan, { loading: updateLoading }] = useMutation(updatePlan)

  const handleCreate = (data) =>
    _createPlan({
      variables: {
        data: {
          name: data.name,
          interval: data.interval,
          stripeId: data.stripeId,
          amount: data.amount,
          teamMembers: data.teamMembers,
          description: data.description
        }
      },
      refetchQueries: [
        {
          query: AdminData
        }
      ]
    }).then(() => {
      setSubmitSucceeded(true)
    })

  const handleUpdate = (data) =>
    _updatePlan({
      variables: {
        planId: data.planId,
        data: {
          name: data.name,
          teamMembers: data.teamMembers,
          description: data.description
        }
      },
      refetchQueries: [
        {
          query: AdminData
        }
      ]
    }).then(() => {
      setSubmitSucceeded(true)
    })

  const getInitialData = () => {
    const { planId, name, amount, interval, stripeId, teamMembers, description } = client.readFragment({
      id: objectId,
      fragment: fragments.plan
    })
    return { planId, name, amount, interval, stripeId, teamMembers, description }
  }

  return (
    <CreatePlan
      action={action}
      handleSubmitForm={action === "add" ? handleCreate : handleUpdate}
      initialValues={action === "add" ? {} : getInitialData()}
      submitSucceeded={submitSucceeded}
      submitting={createLoading || updateLoading}
    />
  )
}

export default CreatePlanWrapper

const CreatePlan = ({ action, initialValues, handleSubmitForm, submitting, submitSucceeded }) => {
  const forceUpdate = useForceUpdate()
  const [values, onChange, validations, validate] = useForm([
    {
      name: "planId",
      initialValue: initialValues.planId
    },
    {
      name: "name",
      initialValue: initialValues.name,
      validators: [isRequired]
    },
    {
      name: "amount",
      initialValue: initialValues.amount,
      validators: [isRequired]
    },
    {
      name: "interval",
      initialValue: initialValues.interval,
      validators: [isRequired]
    },
    {
      name: "stripeId",
      initialValue: initialValues.stripeId,
      validators: [isRequired]
    },
    {
      name: "teamMembers",
      initialValue: initialValues.teamMembers
    },
    {
      name: "description",
      initialValue: initialValues.description,
      validators: [isRequired]
    }
  ])

  const submitForm = () => {
    if (!validate()) {
      forceUpdate()
      return
    }
    handleSubmitForm(values)
  }

  return (
    <div>
      <form>
        <h1>{`${action} plan`}</h1>
        <div>
          <div>
            <div>
              <label>PlanId</label>
              <div>
                <input disabled name="planId" placeholder="PlanId" type="text" value={values.planId} />
              </div>
            </div>
            <div>
              <InputWrapper error={validations.name} label="Name">
                <input name="name" onChange={onChange} placeholder="Name" type="text" value={values.name} />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper error={validations.amount} label="Amount">
                <input
                  disabled={action === "edit"}
                  name="amount"
                  onChange={onChange}
                  placeholder="Amount"
                  type="number"
                  value={values.amount}
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper error={validations.interval} label="Interval">
                <select disabled={action === "edit"} name="interval" onChange={onChange} value={values.interval}>
                  <option value="year">year</option>
                  <option value="month">month</option>
                </select>
              </InputWrapper>
            </div>
            {action !== "edit" && (
              <div>
                <InputWrapper error={validations.stripeId} label="Stripe Id">
                  <input
                    disabled={action === "edit"}
                    name="stripeId"
                    onChange={onChange}
                    placeholder="Stripe ID"
                    type="text"
                    value={values.stripeId}
                  />
                </InputWrapper>
              </div>
            )}
            <div>
              <InputWrapper error={validations.teamMembers} label="Team members">
                <input
                  name="teamMembers"
                  onChange={onChange}
                  placeholder="Team Members"
                  type="number"
                  value={values.teamMembers}
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper error={validations.description} label="Description">
                <TagsInput
                  addKeys={[9, 13, 188]}
                  inputProps={{ placeholder: "line of description" }}
                  maxTags={8}
                  onChange={(value) => {
                    onChange({ persist: () => {}, target: { name: "description", value } })
                  }}
                  value={values.description || []}
                />
              </InputWrapper>
            </div>
          </div>
        </div>
        <div>
          <div>
            <button className="btn btn-dark-blue" disabled={submitting} onClick={submitForm} type="button">
              Submit
            </button>
          </div>
          {submitSucceeded && <div>{`Plan ${action}ed `}</div>}
        </div>
      </form>
    </div>
  )
}
