import { useMutation } from "@apollo/client"
import { KeyboardEvent, useContext, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { InputWrapper } from "../utils/forms"
import { login } from "../../graphql/mutations"
import { AuthContext } from "../../hooks/AuthHook"
import { useForceUpdate } from "../../hooks/ForceUpdate"
import { useForm } from "../../hooks/FormHook"
import { isRequired } from "../utils/helpers"

const SignInForm = () => {
  const history = useHistory()

  const [error, setError] = useState<boolean>(false)
  const [formError, setFormError] = useState<string | null>(null)
  const [_login, { loading }] = useMutation(login, {
    context: { isUsingNewScApi: true }
  })
  const { checkCurrentUser } = useContext(AuthContext)

  const forceUpdate = useForceUpdate()

  const [values, onChange, validations, validate] = useForm([
    {
      name: "email",
      validators: [isRequired]
    },
    {
      name: "password",
      validators: [isRequired]
    }
  ])

  // TODO: BU: not sure about this whole login logic :)
  const submitForm = () => {
    if (!validate()) {
      forceUpdate()
      return
    }
    _login({
      variables: {
        email: values.email,
        password: values.password,
        timezoneOffset: new Date().getTimezoneOffset()
      }
    })
      .then((response) => {
        localStorage.setItem("jwtToken", response.data.login)
        checkCurrentUser()
        history.push("/")
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0] && err.graphQLErrors[0].message === "Forbidden") {
          setFormError("Email or password is wrong")
        } else if (err.graphQLErrors && err.graphQLErrors[0] && err.graphQLErrors[0].message === "User deleted") {
          setFormError("Access to your account has been restricted. Please contact your SlideCamp administrator.")
        } else {
          setError(true)
        }
      })
  }

  const onKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.key === "Enter" && !loading) {
      submitForm()
    }
  }

  return (
    <div className="sc-auth-panel">
      <div className="sc-login-panel" style={error ? { display: "none" } : {}}>
        <h2>Login to Your Account</h2>
        <p>Enter your details below.</p>
        <form className={`sc-authentic-form ${formError ? "sc-form-error" : ""}`} onKeyDown={onKeyDown}>
          <InputWrapper error={validations.email} label="Email">
            <input
              disabled={loading}
              name="email"
              onChange={(e) => {
                e.target.value = e.target.value.trim()
                onChange(e)
              }}
              type="text"
              value={values.email}
            />
          </InputWrapper>
          <Link className="sc-forgot-password" tabIndex={-1} to="/users/forgot-password">
            Forgot password?
          </Link>
          <InputWrapper error={validations.password} label="Password">
            <input
              disabled={loading}
              name="password"
              onChange={(e) => {
                setFormError(null)
                onChange(e)
              }}
              type="password"
              value={values.password}
            />
          </InputWrapper>
          <span className="sc-form-error-top">{formError || ""}</span>
          <div className="sc-auth-links">
            <button className="btn btn-lightblue" disabled={loading} onClick={submitForm} type="button">
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SignInForm
