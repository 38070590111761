import { useState } from "react"
import { convertSecondsToTime } from "../../../utils/dates"
import { EyeIcon, RepeatIcon, TimeIcon } from "../../../../svg/SvgIcons"

const THUMBNAIL_WIDTH = "95"
const THUMBNAIL_WIDTH_2X = "190"
const colorCodes = ["#EEEEEE", "#FFBEAF", "#FF7C5D", "#FB5731"]

const HeatMap = ({ logs = [], slides = [], isOpened }) => {
  const [hoveredSlide, setHoveredSlide] = useState(null)

  const durationMap = new Map()

  logs.forEach((log) => {
    const {
      duration,
      slide: { id }
    } = log

    if (durationMap.has(id)) {
      durationMap.set(id, durationMap.get(id) + duration)
    } else {
      durationMap.set(id, duration)
    }
  })

  const durations = [...durationMap.values()]
  const maxDuration = Math.max(...durations)
  const minDuration = Math.min(...durations)
  const range = (maxDuration - minDuration) / 3

  const handleMouseEnter = (slideId) => {
    setHoveredSlide(slideId)
  }

  const handleMouseLeave = () => {
    setHoveredSlide(null)
  }

  const data = slides.map((slide) => {
    const { duration, times } = logs.reduce(
      (acc, log) => (log.slide.id === slide.id ? { duration: acc.duration + log.duration, times: acc.times + 1 } : acc),
      {
        duration: 0,
        times: 0
      }
    )

    let color = colorCodes[0]

    if (duration === 0) {
      color = colorCodes[0]
    } else if (duration <= minDuration + range) {
      color = colorCodes[1]
    } else if (duration <= minDuration + range * 2) {
      color = colorCodes[2]
    } else {
      color = colorCodes[3]
    }
    return { ...slide, duration, times, color }
  })

  return (
    <div className="grid grid-cols-1 tablet-md:flex flex-wrap gap-4 pt-9">
      {data.map((slide, i) => {
        return (
          <div key={slide.id}>
            {isOpened && (
              <div className="w-full">
                <div
                  onMouseEnter={() => handleMouseEnter(slide.id)}
                  onMouseLeave={handleMouseLeave}
                  style={{ backgroundColor: slide.color }}
                >
                  <div className="relative w-full h-full">
                    <div
                      className={
                        "w-full sm:w-36 sm:h-[80px] h-auto shadow-[0_0_12px_1px_rgba(122,127,135,0.2)]"
                      }
                    >
                      <div
                        className={`absolute inset-0 flex items-center justify-center text-xl
            ${hoveredSlide === slide.id ? "opacity-0" : "opacity-100"}`}
                      >
                        <EyeIcon />
                        <p className="text-[#0F2642] text-xs ml-1">Slide {i + 1}</p>
                      </div>
                      <picture>
                        <img
                          alt={`Slide thumbnail ${i}`}
                          className={` w-full sm:w-36
            ${hoveredSlide === slide.id ? "opacity-100" : "opacity-0"} hover:opacity-100`}
                          src={slide.thumbUrl.replace("{width}", THUMBNAIL_WIDTH)}
                          srcSet={`${slide.thumbUrl.replace("{width}", THUMBNAIL_WIDTH_2X)} 2x`}
                        />
                      </picture>
                    </div>
                  </div>
                </div>
                <div className="flex gap-2.5 mt-2">
                  <div className="flex items-center text-[#979797] text-xs">
                    <TimeIcon />
                    <p className="ml-1">{convertSecondsToTime(slide.duration)}</p>
                  </div>
                  {slide.times > 1 && (
                    <div className="flex items-center text-[#979797] text-xs">
                      <RepeatIcon />
                      <p className="ml-1">{slide.times > 1 && <span> {slide.times}</span>}</p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default HeatMap
