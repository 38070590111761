import { useMutation, gql } from "@apollo/client"
import { useContext, useState } from "react"
import { MainModalContext } from "../../hooks/MainModalHook"
import { OrganizationMemberData } from "@/components/profile/Users"

export const CHANGE_MEMBER_ROLE = gql`
  mutation changeMemberRole($userId: String!, $memberRole: String!, $isEditModeActive: Boolean!) {
    changeMemberRole(userId: $userId, memberRole: $memberRole, isEditModeActive: $isEditModeActive)
  }
`

const ChangeMemberRole = ({ userId }) => {
  const [changeMemberRole] = useMutation(CHANGE_MEMBER_ROLE, {
    context: { isUsingNewScApi: true },
    onError: (err) => {
      console.log(err)
    },
    onCompleted: () => {
      console.log("completed")
    }
  })
  const { closeModal } = useContext(MainModalContext)
  const [memberRole, setMemberRole] = useState()

  const handleRoleChange = () => {
    if (memberRole) {
      changeMemberRole({
        variables: {
          userId,
          memberRole,
          isEditModeActive: memberRole === "admin"
        },
        refetchQueries: [
          {
            query: OrganizationMemberData,
            context: { isUsingNewScApi: true }
          }
        ]
        // TODO: BU: why this does not work? :(
        // update: (proxy) => {
        //   const data = proxy.readQuery({ query: TeamData })
        //   const updatedMembers = data.session.user.team.members.map((member) =>
        //     member.memberId === memberId ? { ...member, role: memberRole } : member
        //   )
        //   data.session.user.team.members = updatedMembers
        //   proxy.writeQuery({ query: TeamData, data })
        // }
      })
    }
    closeModal()
  }

  const handleRadioChange = (e) => {
    setMemberRole(e.target.value === "admin" ? "admin" : "member")
  }

  return (
    <div>
      <div className="sc-modal-header">
        <h1>Select a new role</h1>
      </div>
      <div className="pl-5">
        <label className="text-[#93a3aa] text-sm font-normal ml-[30px] mb-[12px] mt-[30px] block" htmlFor="adminRole">
          <input
            className="absolute mt-[.3rem] ml-[-1.25rem]"
            id="adminRole"
            name="role"
            onChange={handleRadioChange}
            type="radio"
            value="admin"
          />
          <strong>Admin</strong>
          <br />
          <small>Can add and remove presentations in the library.</small>
        </label>
      </div>
      <div className="pl-5 mb-3">
        <label className="text-[#93a3aa] text-sm font-normal ml-[30px] mb-[12px] mt-[30px] block" htmlFor="memberRole">
          <input
            className="absolute mt-[.3rem] ml-[-1.25rem]"
            id="memberRole"
            name="role"
            onChange={handleRadioChange}
            type="radio"
            value="member"
          />
          <strong>Member</strong>
          <br />
          <small>Regular user with no extra special privileges.</small>
        </label>
      </div>
      <div className="sc-modal-buttons">
        <button className="btn-gray-blue" onClick={closeModal} type="button">
          Cancel
        </button>
        <button className="btn-dark-blue" onClick={handleRoleChange} type="button">
          Change role
        </button>
      </div>
    </div>
  )
}

export default ChangeMemberRole
