import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import Slide from "../../slide/Slide"
import EmptyPage from "../../EmptyPage"
import { gql, useLazyQuery } from "@apollo/client"

const GET_PRESENTATION = gql`
  query presentation($id: String!) {
    presentation(id: $id) {
      id
      thumbUrl
      state
      slides {
        id
        blueprintId
        state
        thumbUrl
      }
    }
  }
`

const BatchDetailsPage = () => {
  const location = useLocation()
  const { slides: locationSlides, batchId } = location.state
  const [slides, setSlides] = useState(locationSlides || [])
  const [getPresentationData] = useLazyQuery(GET_PRESENTATION, {
    context: { isUsingNewScApi: true },
    fetchPolicy: "network-only",
    onCompleted: ({ presentation }) => {
      if (!presentation) {
        return
      }
      setSlides(presentation.slides)
    }
  })

  useEffect(() => {
    if (!locationSlides) {
      getPresentationData({ variables: { id: batchId } })
    }
  }, [locationSlides])

  return (
    <div className={"flex justify-center mobile-xs:px-[20px] my-[21px] tablet-sm:px-[60px]"}>
      <div className="w-full desktop-big:w-[1812px]">
        {slides.length > 0 ? (
          <div className={"flex flex-col gap-[15px]"} style={{ clear: "both" }}>
            {slides.map((slide, index) => {
              return (
                <div key={slide.id}>
                  <div
                    className={
                      "grid gap-[20px] mobile-sm:grid-cols-1 w-full tablet-md:grid-cols-2 desktop-lg:grid-cols-3 desktop-2xl:grid-cols-4"
                    }
                  >
                    <Slide index={index} isSearchPage noActions slide={slide} />
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <EmptyPage />
        )}
      </div>
    </div>
  )
}

export default BatchDetailsPage
