import { useContext } from "react"
import { Link } from "react-router-dom"
import { UserDataContext } from "@/hooks/UserDataHook"
import { dateToLLFormat } from "./dates"

interface MainLoaderProps {
  height?: string
}

export const MainLoader = ({ height }: MainLoaderProps) => (
  <div className="loading-container loading-pres" style={height ? { height } : undefined}>
    <div className="sc-loading" />
    <h2 className="sc-loading-header">Loading...</h2>
  </div>
)

interface LoadingPlaceholderProps {
  title: string
}

export const LoadingPlaceholder = ({ title }: LoadingPlaceholderProps) => (
  <div className="sc-center-slides pt-7 mx-auto max-w-screen-4xl whitespace-nowrap px-16">
    {!!title && (
      <ul className="top-breadcrumb">
        <li className="active">{title}</li>
      </ul>
    )}
    <div className="loading-container loading-pres">
      <div className="sc-loading" />
      <h2 className="sc-loading-header">Loading...</h2>
    </div>
  </div>
)

interface ErrorPlaceholderProps {
  tryAgain?(): void
  visible?: boolean
  isReplace?: boolean
}

export const ErrorPlaceholder = ({ visible = true, tryAgain, isReplace = false }: ErrorPlaceholderProps) => {
  return (
    <div className="sc-error-wrapper" style={!visible ? { display: "none" } : {}}>
      <div className={`sc-error${isReplace ? " sc-error__replace" : ""}`}>
        {!isReplace && <div className="sc-error-icon" />}
        <p className="error-title">{isReplace ? "Problem loading presentation" : "OH SNAP!"}</p>
        <p>
          {isReplace
            ? "Slide replace failed. Please ensure that you are uploading presentation containing only 1 slide."
            : "An error has occured while loading a page."}
        </p>
        <button onClick={() => (tryAgain ? tryAgain() : window.location.reload())}>
          {isReplace ? "Choose another" : "Try again"}
        </button>
      </div>
    </div>
  )
}

export const TrialBar = () => {
  const { user } = useContext(UserDataContext)
  return (
    <div className="trial-bar-main">
      <span className="trial-text">
        Your '{user.subscription.plan.name}' plan expires on {dateToLLFormat(user.subscription.payedTo)}
      </span>
      <i className="far fa-clock trial-icon" />
      <Link className="trial-upgrade-link" to="/pricing">
        UPGRADE NOW
      </Link>
    </div>
  )
}

export const SlideShowLoader = () => (
  <div className="viewer-loading">
    <div className="sc-loading" />
    <h2>Loading Slide Show</h2>
    <svg version="1.1" viewBox="0 0 87.3 80.9" x="0px" y="0px">
      <path
        d="M87.3,7.2H45.7V0h-4v7.2H0v4h6.3v53.1h35.4v12.6H29.9v4h27.6v-4H45.7V64.3h35.4V11.2h6.3V7.2z
        M77.1,60.3H10.3V11.2h66.8V60.3z"
      />
      <polygon points="36.6,24.4 36.6,48.7 55.3,36.5 	" />
    </svg>
  </div>
)

export const SlideShowErrorMessage = () => (
  <div className="viewer-error">
    <div className="svg-poof" />
    <h2>& THE LINK IS GONE.</h2>
    <p>
      Looks like the owner of this presentation changed the permission
      <br /> settings. Get in touch with them to ask what went wrong.
    </p>
  </div>
)

export const PresentationIsEmptyMessage = () => (
  <div className="viewer-error">
    <div className="svg-poof" />
    <h2>& THE PRESENTATION IS EMPTY</h2>
    <p>
      Looks like the owner of this presentation removed all slides from it.
      <br />
      Get in touch with them to ask what went wrong.
    </p>
  </div>
)
