import { useContext } from "react"
import { NavLink, useLocation } from "react-router-dom"
import { CategoryContext } from "@/context/CategoryContext"
import { UserDataContext } from "@/hooks/UserDataHook"
import { getLibraryLink } from "@/utils/helpers"

export default function AppNavLinks() {
  const { categories } = useContext(CategoryContext)
  const location = useLocation()

  const isActiveMenu = () => location.pathname.startsWith("/library")

  const { user } = useContext(UserDataContext)

  return (
    <>
      <div className="whitespace-nowrap">
        <ul className="flex pl-[5px] items-center border-l border-solid border-l-[#fff] flex-row justify-start m-0 p-0">
          <li className="flex flex-row text-[13px] font-normal mx-[12px]">
            <NavLink
              activeClassName="active"
              className="text-[#ffffff80] h-full w-full m-0 p-0 hover:text-[#fff]"
              isActive={isActiveMenu}
              title="Slides"
              to={getLibraryLink(categories)}
            >
              Library
            </NavLink>
          </li>
          <li className="flex-row text-[13px] font-normal mx-[12px] hidden tablet-xl:flex">
            <NavLink
              activeClassName="active"
              className="text-[#ffffff80] h-full w-full m-0 p-0 hover:text-[#fff]"
              title="My Presentations"
              to="/my-presentations"
            >
              My Presentations
            </NavLink>
          </li>
          <li className="flex-row text-[13px] font-normal mx-[12px] hidden tablet-xl:flex">
            <NavLink
              activeClassName="active"
              className="text-[#ffffff80] h-full w-full m-0 p-0 hover:text-[#fff]"
              title="Activity"
              to="/activity"
            >
              Activity
            </NavLink>
          </li>
          {/*
          <li className="hidden md:block text-[#ffffff80] text-[13px] font-normal mx-[12px] ">old stuff:</li>
          <li className="flex flex-row text-[13px] font-normal mx-[12px] hidden md:block">
            <NavLink
              activeClassName="active"
              className="text-[#ffffff80] h-full w-full m-0 p-0 hover:text-[#fff]"
              title="Slides"
              to="/templates"
            >
              Slides
            </NavLink>
          </li>
          {user.teamOwner ? (
            <li className="flex flex-row text-[13px] font-normal mx-[12px] hidden md:block">
              <NavLink
                activeClassName="active"
                className="text-[#ffffff80] h-full w-full m-0 p-0 hover:text-[#fff]"
                title="Team Presentations"
                to="/presentations"
              >
                Team Presentations
              </NavLink>
            </li>
          ) : (
            <>
              <li className="flex flex-row text-[13px] font-normal mx-[12px] hidden md:block">
                <NavLink
                  activeClassName="active"
                  className="text-[#ffffff80] h-full w-full m-0 p-0 hover:text-[#fff]"
                  title="Shared with me"
                  to="/shared"
                >
                  Team Presentations
                </NavLink>
              </li>
              <li className="flex flex-row text-[13px] font-normal mx-[12px] hidden md:block">
                <NavLink
                  activeClassName="active"
                  className="text-[#ffffff80] h-full w-full m-0 p-0 hover:text-[#fff]"
                  title="My Presentations"
                  to="/presentations"
                >
                  My Presentations
                </NavLink>
              </li>
            </>
          )}
           */}
        </ul>
      </div>
    </>
  )
}
