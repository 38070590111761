import { ReactElement } from "react"
import { ReactComponent as BlueTickSvg } from "../../svg2/blue-tick.svg"

interface INotificationPopUpPropTypes {
  children: ReactElement
}

const NotificationPopUp = ({ children }: INotificationPopUpPropTypes) => {
  return (
    <div className="profile-notif-content cursor-default">
      <div className="profile-notif-content-ico">
        <BlueTickSvg />
      </div>
      <div className="profile-notif-content-text">{children}</div>
    </div>
  )
}
export default NotificationPopUp
