import { useContext } from "react"
import { MainModalContext } from "../../hooks/MainModalHook"

const UnitUser = ({ unitMember, unitId, removeButton }) => {
  const { openModal } = useContext(MainModalContext)
  const { firstName, lastName, email } = unitMember

  const initials = `${firstName ? firstName[0] : ""}${lastName ? lastName[0] : ""}`

  return (
    <div className="profile-team-member-info">
      <div className="profile-member-initials">{initials}</div>
      <div className="profile-member-data">
        <p>
          {firstName} {lastName}
        </p>
        <p>{email}</p>
      </div>
      {removeButton && (
        <div className="profile-buttons-end">
          <button
            className="btn btn-gray-blue"
            onClick={() => {
              openModal({ content: "removeUserFromUnit", data: { unitId, unitMember } })
            }}
          >
            Remove
          </button>
        </div>
      )}
    </div>
  )
}

export default UnitUser
