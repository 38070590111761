import { useState, createContext, Dispatch, SetStateAction } from "react"

interface StripeError {
  cvcFormat: string | null
  expirationFormat: number | null
  numberFormat: number | null
}

interface UsePricingModalContext {
  modalOpened: boolean
  modalComponent: string
  selectedPlan: string | null
  paymentStage: string
  stripeStage: string
  stripeError: StripeError
  openModal(component: string): void
  closeModal(): void
  setSelectedPlan: Dispatch<SetStateAction<string | null>>
  setPaymentStage: Dispatch<SetStateAction<string>>
  setStripeStage: Dispatch<SetStateAction<string>>
  setStripeError: Dispatch<SetStateAction<StripeError>>
}

export const usePricingModal = (): UsePricingModalContext => {
  const [modalOpened, setModalOpened] = useState<UsePricingModalContext["modalOpened"]>(false)
  const [selectedPlan, setSelectedPlan] = useState<UsePricingModalContext["selectedPlan"]>(null)
  const [modalComponent, setModalComponent] = useState<UsePricingModalContext["modalComponent"]>("pricing")
  const [paymentStage, setPaymentStage] = useState<UsePricingModalContext["paymentStage"]>("loading")
  const [stripeStage, setStripeStage] = useState<UsePricingModalContext["stripeStage"]>("pay")
  const [stripeError, setStripeError] = useState<UsePricingModalContext["stripeError"]>({
    cvcFormat: null,
    expirationFormat: null,
    numberFormat: null
  })

  const openModal = (component: string) => {
    setModalOpened(true)
    if (component) {
      setModalComponent(component)
    }
  }

  const closeModal = () => {
    setModalOpened(false)
    setModalComponent("pricing")
    setSelectedPlan(null)
    setPaymentStage("loading")
    setStripeStage("pay")
    setStripeError({
      cvcFormat: null,
      expirationFormat: null,
      numberFormat: null
    })
  }

  return {
    modalOpened,
    modalComponent,
    selectedPlan,
    paymentStage,
    stripeStage,
    stripeError,
    openModal,
    closeModal,
    setSelectedPlan,
    setPaymentStage,
    setStripeStage,
    setStripeError
  }
}

export const PricingModalContext = createContext({} as UsePricingModalContext)
