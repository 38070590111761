import React, { useContext, useEffect, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { CategoryContext } from "../../context/CategoryContext"
import Presentation from "../presentation/Presentation"
import { LoadingPlaceholder } from "../utils/placeholders"
import { useLazyQuery } from "@apollo/client"
import slugify from "slug"
import { GET_PRESENTATION } from "./CollectionSection"
import EmptyPage from "../EmptyPage"

const PowerPointCategoryPage = () => {
  const history = useHistory()
  const { urlToken } = useParams()
  const { getCategory, loading, categories } = useContext(CategoryContext)

  const [category, setCategory] = useState(null)

  const [getPresentationData, { stopPolling: stopPresentationDataPolling }] = useLazyQuery(GET_PRESENTATION, {
    context: { isUsingNewScApi: true },
    pollInterval: 1500,
    fetchPolicy: "network-only",
    onCompleted: ({ presentation }) => {
      if (!presentation) {
        stopPresentationDataPolling()
        return
      }
      const slides = presentation.slides
      if (!slides?.length) return
      if (slides[0]?.state === "uploaded") {
        stopPresentationDataPolling()
      }
    }
  })

  useEffect(() => {
    if (categories.length) {
      setCategory(getCategory(urlToken))
    }
  }, [urlToken, categories])

  const sections = useMemo(() => {
    if (!category) {
      return null
    }
    return category.subCategories.reduce(
      (sections, subCategory) => {
        if (subCategory.type === "collection") {
          sections.collectionSubCategories.push(subCategory)
        } else if (subCategory.type === "presentation") {
          sections.presentationSubCategories.push(subCategory)
        }
        return sections
      },
      { collectionSubCategories: [], presentationSubCategories: [] }
    )
  }, [category])

  const handleViewBatch = (urlToken, name, slides) => {
    history.push({ pathname: `/dashboard/${urlToken}/${slugify(name)}`, state: { slides, currentPathname: name } })
  }

  if (loading) {
    return <LoadingPlaceholder />
  }
  if (
    category &&
    category.collections.length +
      category.presentations.length +
      sections.presentationSubCategories.length +
      sections.collectionSubCategories.length ===
      0
  ) {
    return <EmptyPage />
  }

  return (
    <div className="px-[20px] my-[21px] flex flex-col gap-[15px] tablet-sm:px-[60px]">
      {category?.collections.length + sections?.collectionSubCategories.length > 0 && (
        <div className="flex flex-col gap-[15px]">
          {category?.collections.length > 0 && (
            <div
              className={
                "grid gap-[15px] mobile-md:grid-cols-2 w-full tablet-md:grid-cols-2 desktop-lg:grid-cols-3 desktop-2xl:grid-cols-4"
              }
            >
              {category.collections.map((collection) => (
                <Presentation
                  active={false}
                  batch={collection}
                  handleViewBatch={handleViewBatch}
                  hideTools
                  id={collection.id}
                  isSearchPage={true}
                  key={collection.id}
                  name={collection.name || "No given name"}
                  noActions="true"
                  refetch={() => {
                    getPresentationData({ variables: { id: collection.batchId } })
                  }}
                  type="templates"
                />
              ))}
            </div>
          )}
          {sections.collectionSubCategories.length > 0 && (
            <div className="flex flex-col gap-[15px]">
              {sections.collectionSubCategories.map((subCategory) => (
                <div key={subCategory._id}>
                  <h2 className="text-[9px] text-[#717171] uppercase mb-[15px]">{subCategory.name}</h2>
                  <div
                    className={
                      "grid gap-[15px] mobile-md:grid-cols-2 w-full tablet-md:grid-cols-2 desktop-lg:grid-cols-3 desktop-2xl:grid-cols-4"
                    }
                  >
                    {subCategory.collections.map((collection) => (
                      <div key={collection.id}>
                        <Presentation
                          active={false}
                          batch={collection}
                          handleViewBatch={handleViewBatch}
                          hideTools
                          id={collection.id}
                          isSearchPage={true}
                          key={collection.id}
                          name={collection.name || "No given name"}
                          noActions="true"
                          refetch={() => {
                            getPresentationData({ variables: { id: collection.batchId } })
                          }}
                          type="templates"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      {category?.presentations.length + sections?.presentationSubCategories.length > 0 && (
        <div className="flex flex-col gap-[20px]">
          {category?.presentations.length > 0 && (
            <div
              className={
                "grid gap-x-[15px] gap-y-[20px] mobile-md:grid-cols-2 w-full tablet-md:grid-cols-2 desktop-lg:grid-cols-3 desktop-2xl:grid-cols-4"
              }
            >
              {category.presentations.map((presentation) => (
                <Presentation
                  active={false}
                  batch={presentation}
                  handleViewBatch={handleViewBatch}
                  hideTools
                  id={presentation.id}
                  isFavourite={presentation.isFavourite}
                  isSearchPage={true}
                  key={presentation.id}
                  name={presentation.name || "No given name"}
                  noActions
                  refetch={() => {
                    getPresentationData({ variables: { id: presentation.batchId } })
                  }}
                />
              ))}
            </div>
          )}
          {sections.presentationSubCategories.length > 0 && (
            <div className="flex flex-col gap-[15px]">
              {sections.presentationSubCategories.map((subCategory) => (
                <div key={subCategory._id}>
                  <h2 className="text-[9px] text-[#717171] uppercase mb-[15px]">{subCategory.name}</h2>
                  <div
                    className={
                      "grid gap-x-[15px] gap-y-[20px] mobile-md:grid-cols-2 w-full tablet-md:grid-cols-2 desktop-lg:grid-cols-3 desktop-2xl:grid-cols-4"
                    }
                  >
                    {subCategory.presentations.map((presentation) => (
                      <div key={presentation.id}>
                        <Presentation
                          active={false}
                          batch={presentation}
                          handleViewBatch={handleViewBatch}
                          hideTools
                          id={presentation.id}
                          isFavourite={presentation.isFavourite}
                          isSearchPage={true}
                          key={presentation.id}
                          name={presentation.name || "No given name"}
                          noActions
                          refetch={() => {
                            getPresentationData({ variables: { id: presentation.batchId } })
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default PowerPointCategoryPage
