import { useMutation } from "@apollo/client"
import { useState } from "react"
import { Link } from "react-router-dom"
import { InputWrapper } from "../utils/forms"
import { SentPasswordInfo } from "./SentPasswordInfo"
import { resetPasswordInit as resetPasswordInitMutation } from "../../graphql/mutations"
import { useForceUpdate } from "../../hooks/ForceUpdate"
import { useForm } from "../../hooks/FormHook"
import { emailValidator, isRequired } from "../utils/helpers"

const ForgotPasswordForm = () => {
  const [resetPasswordInit, { loading }] = useMutation(resetPasswordInitMutation, {
    context: { isUsingNewScApi: true }
  })
  const [sent, setSent] = useState(false)
  const [formError, setFormError] = useState(null)

  const forceUpdate = useForceUpdate()
  const [values, onChange, validations, validate, reset] = useForm([
    {
      name: "email",
      validators: [isRequired, emailValidator]
    }
  ])

  const submitForm = () => {
    if (!validate()) return forceUpdate()

    resetPasswordInit({
      variables: { email: values.email },
      onCompleted: ({ resetPasswordInit: { success, message } }) => {
        setSent(success)
        setFormError(success ? null : message)
      }
    })
  }

  const onKeyDown = (e) => {
    if (e.key === "Enter" && !loading) submitForm()
  }

  const handleTryAgain = () => {
    setSent(false)
    reset()
  }

  if (sent) return <SentPasswordInfo email={values.email} handleTryAgain={handleTryAgain} />

  return (
    <div className="sc-auth-panel">
      <div className="sc-forgot-panel">
        <h2>Forgot your password?</h2>
        <p>Enter your email address below and we'll reset your&nbsp;password.</p>
        <form className="sc-authentic-form" onKeyDown={onKeyDown}>
          {formError && <span className="sc-form-error-top">{formError}</span>}
          <InputWrapper error={validations.email} label="Email">
            <input disabled={loading} name="email" onChange={onChange} type="text" value={values.email} />
          </InputWrapper>
          <div className="sc-auth-links">
            <button className="btn btn-lightblue" disabled={loading} onClick={submitForm} type="button">
              Reset password
            </button>
            <p>Back to</p>
            <Link to="/users/sign_in">Sign In</Link>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ForgotPasswordForm
