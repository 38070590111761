import { useDropzone } from "react-dropzone"
import UploadingIndicator from "./uploading-indicator"
import { GlobalDropzoneProps } from "./types"
import { GET_UPLOAD_FILE_URL } from "./constants"
import React, { forwardRef, useEffect, useRef, useState } from "react"
import { useLazyQuery, useMutation } from "@apollo/client"
import { GET_JOB } from "@/graphql/queries"
import { Job } from "@/graphql/types/queries"
import AddPresentationButton from "@/components/category/AddPresentationButton"

// eslint-disable-next-line react/display-name
const GlobalDropzone = forwardRef(
  ({ dragEnabled, handlePicker, refetch, handleDrop, isButton }: GlobalDropzoneProps, ref) => {
    const [showUploadProgress, setShowUploadProgress] = useState(false)
    const [filesToUpload, setFilesToUpload] = useState<Record<string, number>>({})
    const filesWithProgress = useRef<Record<string, number>>({})
    const [isDragOver, setIsDragOver] = useState(false)

    const [getUploadFileUrl] = useMutation(GET_UPLOAD_FILE_URL, { context: { isUsingNewScApi: true } })
    const [getJob, { stopPolling }] = useLazyQuery(GET_JOB, {
      notifyOnNetworkStatusChange: true,
      context: { isUsingNewScApi: true },
      pollInterval: 1500,
      fetchPolicy: "cache-and-network",
      onCompleted: ({ job }) => {
        if (job.every(({ data }: Job) => data.isReady)) {
          stopPolling()
          refetch && refetch()
        }
      }
    })

    useEffect(() => {
      filesWithProgress.current = { ...filesWithProgress.current, ...filesToUpload }
    }, [filesToUpload])

    const changeProgress = (loaded: number, total: number, name: string): void => {
      const progress = loaded / total
      setFilesToUpload((prevState) => ({ ...prevState, [name]: progress }))
    }

    const onDrop = handleDrop({
      changeProgress,
      getJob,
      getUploadFileUrl,
      setShowUploadProgress,
      setFilesToUpload,
      filesWithProgress
    })

    const { getRootProps, getInputProps, inputRef } = useDropzone({
      onDrop,
      multiple: true,
      onDragLeave: handlePicker,
      accept: { "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"] }
    })

    useEffect(() => {
      if (inputRef.current) {
        ;(ref as React.MutableRefObject<HTMLInputElement>).current = inputRef.current
      }
    }, [inputRef.current])

    return (
      <>
        <div
          {...getRootProps()}
          className={`sc-dropzone w-full h-full ${isDragOver ? "bg-sc-hover-dark" : ""} ${
            dragEnabled ? "border-2 border-sc-line" : ""
          }`}
          onDragLeave={() => setIsDragOver(false)}
          onDragOver={() => setIsDragOver(true)}
        >
          <input {...getInputProps()} className="slide-upload-input" />
          {dragEnabled && (
            <div className="dnd-to-upload-area">
              <div className="dnd-to-upload-area__zone text-center">Drag and drop .pptx files here</div>
            </div>
          )}
          {isButton && <AddPresentationButton handleAddPresentation={undefined} />}
        </div>
        {showUploadProgress && <UploadingIndicator slidesWithProgress={filesWithProgress.current} />}
      </>
    )
  }
)

export default GlobalDropzone
