import { useMemo, useState } from "react"
import TeamMember from "./TeamMember"
import TeamSearchForm from "./TeamSearchForm"

export default function UserList({ users, invitees, userId }) {
  const [searchFilter, setSearchFilter] = useState("")
  const [statusFilter, setStatusFilter] = useState("everyone")
  const [roleFilter, setRoleFilter] = useState("everyone")
  const [teamMemberDropDown, setTeamMemberDropDown] = useState("")

  const allMembers = [...users, ...invitees]

  const filteredTeamMembers = useMemo(() => {
    return allMembers
      .filter((m) => {
        if (statusFilter === "active") {
          return !m.invitationLink
        }
        if (statusFilter === "inactive") {
          return m.invitationLink
        }
        return true
      })
      .filter((m) => {
        if (roleFilter === "admin") {
          return m.role && m.role === "admin"
        }
        if (roleFilter === "member") {
          return !m.role || m.role === "member"
        }
        return true
      })
      .filter((u) => {
        const searchableFields = `${u.firstName || ""} ${u.lastName || ""} ${u.email}`
        return searchableFields.toLowerCase().match(searchFilter.toLowerCase())
      })
  }, [statusFilter, roleFilter, searchFilter])

  return (
    <div className="container">
      <div className=" mb-4 pb-4 flex flex-wrap justify-between items-center mr-[-15px] ml-[-15px] border-b border-solid border-[#dee2e6]">
        <div className="pl-[15px]">
          <h1 className="mb-5 md:mb-0 font-bold text-2xl text-[#3390ff]">Users</h1>
        </div>
        <TeamSearchForm
          activeTeamMembersCount={users.length}
          inactiveTeamMembersCount={invitees.length}
          roleFilter={roleFilter}
          setRoleFilter={setRoleFilter}
          setSearchFilter={setSearchFilter}
          setStatusFilter={setStatusFilter}
          statusFilter={statusFilter}
        />
      </div>

      {filteredTeamMembers.length > 0
        ? filteredTeamMembers.map((teamMember) => (
            <TeamMember
              key={teamMember._id || teamMember.id}
              setTeamMemberDropDown={setTeamMemberDropDown}
              teamMember={teamMember}
              teamMemberDropDown={teamMemberDropDown}
              viewerId={userId}
            />
          ))
        : "No users found"}
    </div>
  )
}
