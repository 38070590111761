/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, createContext } from "react"
import { Ability } from "@casl/ability"
import createClient from "../apolloClient"
import { unpackRules } from "@casl/ability/extra"
import { decodeToken } from "react-jwt"
import * as Sentry from "@sentry/react"

import { AdminData, UserData, UserAbilityData } from "../graphql/queries"
import { useIsInsidePowerPoint } from "@/context/IsInsidePowerPointContext"
import { changeCartVisibility } from "@/hooks/UserDataHook"

const client = createClient((err: any) => {
  console.error("AUTH ERROR", err)
})

interface UseAuthParams {
  openPricingModal(): void
  openMainModal({ content }: { content: string }): void
  setActiveColor(data: any): void
}

interface UseAuthContext {
  isAuthenticated: boolean
  token: string
  admin: boolean
  adminData: any
  logout(): void
  checkCurrentUser(): void
  hasAdminPowers: boolean
  ability: Ability | null
  isEditModeActive: boolean
}

export const useAuth = ({ openPricingModal, openMainModal }: UseAuthParams): UseAuthContext => {
  const [isAuthenticated, setIsAuthenticated] = useState(true)
  const [token, setToken] = useState("")
  const [userGroup, setUserGroup] = useState({})
  const [admin, setAdmin] = useState(false)
  const [adminData, setAdminData] = useState({})
  const [hasAdminPowers, setHasAdminPowers] = useState(false)
  const [ability, setAbility] = useState(null)
  const [isEditModeActive, setIsEditModeActive] = useState(false)
  const isInsidePowerPoint = useIsInsidePowerPoint()

  const logout = () => {
    localStorage.removeItem("jwtToken")
    setIsAuthenticated(false)
    setToken("")
    setUserGroup({})
    setAdmin(false)
    setAbility(null)
    setIsEditModeActive(false)
    client.cache.reset()
  }

  const checkCurrentUser = async () => {
    const token = localStorage.getItem("jwtToken")

    if (token && decodeToken(token)) {
      try {
        setIsAuthenticated(true)
      } catch (e) {
        logout()
        return
      }

      const { data } = await client.query({
        query: UserData,
        fetchPolicy: "network-only"
      })

      if (!data.session || data.session === null) {
        logout()
        return
      }

      const {
        data: { userAbility }
      } = await client.query({
        query: UserAbilityData,
        context: { isUsingNewScApi: true },
        fetchPolicy: "network-only"
      })

      // FIX BU: this is a temp fix for the "token" field returning an object and passed to the redirect /user/redirect?token=[object%20Object]
      const userAbilityToken = decodeToken(userAbility)

      setAbility(new Ability().update(unpackRules(userAbilityToken.rules)))

      const { user } = data.session
      const { group, cart } = user
      if ((!user.subscription || !user.subscription.active) && group.groupId === "management") {
        if (user.teamOwner) {
          openPricingModal()
        } else {
          openMainModal({ content: "inactiveAccount" })
        }
      }
      if (data.session.sessionOwner) {
        if (import.meta.env.PROD) {
          window.mixpanel.register({ ignore: true })
        }
        const {
          data: { admin }
        } = await client.query({
          query: AdminData
        })
        setAdminData(admin)
      }

      // temp fix for https://github.com/cypress-io/cypress/issues/2193
      // if (document.domain.split('.')[0] !== group.urlPrefix) {
      // 		const array = document.domain.split('.')
      const hrefMatch = document.location.href.match(/\/\/([^:/]+)/)![1]
      if (hrefMatch.split(".")[0] !== group.urlPrefix && isInsidePowerPoint === false) {
        // If inside dev environment or inside PowerPoint - do not redirect to subdomain url
        const array = hrefMatch.split(".")

        array.shift()
        const targetUrl = array.join(".")
        logout()
        return window.location.replace(
          `${window.location.protocol}//${group.urlPrefix}.${targetUrl}:${window.location.port}/user/redirect?token=${token}`
        )
      }
      if (Object.hasOwn(user, "isEditModeActive")) {
        setIsEditModeActive(user.isEditModeActive)
      }
      setUserGroup(group)
      setAdmin(user.admin)
      setHasAdminPowers(user.teamOwner || user.role === "admin" || user.admin)
      if (cart) {
        changeCartVisibility(true)
      }

      if (import.meta.env.PROD) {
        window.mixpanel.identify(user.userId)
        window.mixpanel.people.set({
          $name: `${user.firstName} ${user.lastName}`,
          $email: user.email,
          Group: group.groupId
        })
      }

      Sentry.setUser({ email: user.email })

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "scUserLogin",
        user: {
          id: user.userId,
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          group: group.groupId
        },
        cart: { visible: false }
      })
    } else {
      logout()
    }
  }

  return {
    isAuthenticated,
    token,
    admin,
    adminData,
    logout,
    checkCurrentUser,
    hasAdminPowers,
    isEditModeActive,
    ability
  }
}

export const AuthContext = createContext({} as UseAuthContext)
