import { useApolloClient, useMutation } from "@apollo/client"
import { useState } from "react"
import TagsInput from "react-tagsinput"
import { createGroup, updateGroup } from "../../graphql/mutations"
import { AdminData, OrganizationsData } from "../../graphql/queries"
import { useForceUpdate } from "../../hooks/ForceUpdate"
import { useForm } from "../../hooks/FormHook"
import { isRequired } from "../utils/helpers"
import { InputWrapper } from "../utils/forms"
import { fragments } from "../../graphql/fragments"

const CreateGroupWrapper = ({ action, plans, objectId }) => {
  const [success, setSuccess] = useState(false)

  const client = useApolloClient()
  const [_createGroup, { loading: createLoading }] = useMutation(createGroup)
  const [_updateGroup, { loading: updateLoading }] = useMutation(updateGroup)

  const handleCreate = (data) =>
    _createGroup({
      variables: {
        data: {
          groupId: data.groupId,
          name: data.name,
          planIds: data.plan,
          urlPrefix: data.urlPrefix,
          searchTags: data.searchTags || [],
          custom: data.custom === "custom"
        }
      },
      refetchQueries: [
        {
          query: AdminData
        },
        {
          query: OrganizationsData,
          context: { isUsingNewScApi: true }
        }
      ]
    }).then(() => {
      setSuccess(true)
      // if (wizard) {
      // 	dispatch({type: 'CHANGE_STAGE', newStage: 'colors'})
      // 	dispatch({type: 'SET_GROUP_DATA', groupData: res.data.admin.createGroup})
      // }
    })

  const handleUpdate = (data) =>
    _updateGroup({
      variables: {
        groupId: data.groupId,
        data: {
          name: data.name,
          planIds: data.plan,
          urlPrefix: data.urlPrefix,
          searchTags: data.searchTags,
          custom: data.custom === "custom"
        }
      },
      refetchQueries: [
        {
          query: AdminData
        },
        {
          query: OrganizationsData,
          context: { isUsingNewScApi: true }
        }
      ]
    }).then(() => {
      setSuccess(true)
    })

  const getInitialData = () => {
    const { groupId, urlPrefix, searchTags, plans, name, custom } = client.readFragment({
      id: objectId,
      fragment: fragments.group
    })
    return {
      groupId,
      urlPrefix,
      searchTags,
      plan: plans.map((p) => p.planId),
      name,
      custom: custom ? "custom" : "standard"
    }
  }

  return (
    <CreateGroup
      action={action}
      handleSubmit={action === "add" ? handleCreate : handleUpdate}
      initialValues={action === "add" ? {} : getInitialData()}
      plans={plans}
      submitSucceeded={success}
      submitting={createLoading || updateLoading}
    />
  )
}

export default CreateGroupWrapper

const CreateGroup = ({ action, initialValues, submitting, submitSucceeded, handleSubmit, plans }) => {
  const forceUpdate = useForceUpdate()
  const [values, onChange, validations, validate] = useForm([
    {
      name: "groupId",
      validators: [isRequired],
      initialValue: initialValues.groupId
    },
    {
      name: "name",
      validators: [isRequired],
      initialValue: initialValues.name
    },
    {
      name: "urlPrefix",
      validators: [isRequired],
      initialValue: initialValues.urlPrefix
    },
    {
      name: "custom",
      initialValue: initialValues.custom
    },
    {
      name: "searchTags",
      initialValue: initialValues.searchTags
    },
    {
      name: "plan",
      initialValue: initialValues.plan
    }
  ])

  const submitForm = () => {
    if (!validate()) {
      forceUpdate()
      return
    }
    handleSubmit(values)
  }

  return (
    <div className="w-full">
      <form>
        <div className="flex justify-between">
          <div>
            <h1>{`${action} group`}</h1>
            <div>
              <InputWrapper error={validations.groupId} label="Group ID">
                <input
                  disabled={action === "edit"}
                  name="groupId"
                  onChange={onChange}
                  placeholder="GroupId"
                  type="text"
                  value={values.groupId}
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper error={validations.name} label="Name">
                <input name="name" onChange={onChange} placeholder="Name" type="text" value={values.name} />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper error={validations.urlPrefix} label="URL Prefix">
                <input
                  name="urlPrefix"
                  onChange={onChange}
                  placeholder="URL Prefix"
                  type="text"
                  value={values.urlPrefix}
                />
              </InputWrapper>
            </div>
            <div className="create-group-radio-wrapper">
              <label>Slidecamp version</label>
              <div className="create-group-radio-wrapper">
                <label>Slidecamp version</label>
                <div>
                  <label className={`radio-label${values.custom === "custom" ? " radio-checked" : ""}`}>
                    <input
                      checked={values.custom === "custom"}
                      name="custom"
                      onChange={onChange}
                      type="radio"
                      value="custom"
                    />
                    <div className="square" />
                    <span>Custom (slidecamp without templates)</span>
                  </label>
                  <label className={`radio-label${values.custom === "standard" ? " radio-checked" : ""}`}>
                    <input
                      checked={values.custom === "standard"}
                      name="custom"
                      onChange={onChange}
                      type="radio"
                      value="standard"
                    />
                    <div className="square" />
                    <span>Slidecamp with templates</span>
                  </label>
                </div>
              </div>
            </div>
            <div>
              <label>Search tags</label>
              <TagsInput
                addKeys={[9, 13, 188]}
                inputProps={{ placeholder: "Tags" }}
                maxTags={8}
                onChange={(value) => {
                  onChange({ persist: () => {}, target: { name: "searchTags", value } })
                }}
                value={values.searchTags || []}
              />
            </div>
            <div className="create-group-btn">
              <button className="btn btn-dark-blue" disabled={submitting} onClick={submitForm} type="button">
                Submit
              </button>
              {submitSucceeded && <div className="alert alert-success create-group-success">{`Group ${action}ed`}</div>}
            </div>
          </div>
          <div className="h-[500px] overflow-scroll px-6">
            <ul className="admin-plan-list">
              {plans.map((plan) => (
                <li className="w-full" key={plan.planId}>
                  <input
                    checked={(values.plan || []).includes(plan.planId)}
                    className="peer hidden"
                    id={`plan-${plan.planId}`}
                    onChange={() => {
                      onChange({
                        persist: () => {},
                        target: {
                          name: "plan",
                          value: (values.plan || []).includes(plan.planId)
                            ? values.plan.filter((id) => id !== plan.planId)
                            : (values.plan || []).concat([plan.planId])
                        }
                      })
                    }}
                    type="checkbox"
                  />
                  <label
                    className="inline-flex items-center justify-between w-full p-2 px-4 text-gray-500 bg-white border-2 border-gray-200 rounded cursor-pointer peer-checked:border-[#3390ff] hover:text-gray-600 peer-checked:text-gray-600"
                    htmlFor={`plan-${plan.planId}`}
                  >
                    <div className="block">
                      <div className="w-full text-sm">
                        {plan.name} - ${plan.amount / 100} - {plan.interval}
                      </div>
                    </div>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </form>
    </div>
  )
}
