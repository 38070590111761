import { useContext, useState } from "react"
import ConfirmationModal from "@/components/modals/ConfirmationModal"
import NotificationPopUp from "@/components/notifications/NotificationPopUp"
import useModal from "@/hooks/useModal"
import { CategoryContext } from "@/context/CategoryContext"
import { NotificationContext } from "@/hooks/NotificationHook"

const AddSection = ({ category, batchesName }) => {
  const { createCategory } = useContext(CategoryContext)
  const { open } = useContext(NotificationContext)
  const [inputValue, setInputValue] = useState("")
  const { isOpen: isOpenAddSectionModal, openModal: openAddSectionModal, closeModal: closeAddSectionModal } = useModal()

  const isConfirmButtonDisabled = !inputValue.trim()
  const handleCloseAddModal = () => {
    setInputValue("")
    closeAddSectionModal()
  }

  const handleAddSection = () => {
    createCategory(inputValue, category?._id, batchesName === "Collections" ? "collection" : "presentation")
    setInputValue("")
    closeAddSectionModal()
    open({
      type: "success",
      duration: 4,
      content: (
        <NotificationPopUp>
          <p>Section created below.</p>
        </NotificationPopUp>
      )
    })
  }
  return (
    <>
      <div className="mt-[25px]">
        <button
          className="py-[7px] px-[13px] flex justify-center items-center gap-[12px] rounded-sm transition ease-in-out hover:bg-sc-hover-dark add-section text-sm"
          data-testid="add-section-button"
          onClick={openAddSectionModal}
          onKeyDown={(e) => {
            e.preventDefault()
          }}
        >
          <svg fill="none" height="11" viewBox="0 0 10 11" width="10" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2136_13246)">
              <path
                d="M5.44766 0.792239L5.44772 0.792236L5.44749 0.790568C5.41744 0.569143 5.22764 0.398584 4.99803 0.398584C4.74755 0.398584 4.54456 0.601765 4.54462 0.852262L4.5453 4.9702H0.42852V4.97014L0.426853 4.97025L0.368706 4.97414L0.368702 4.97408L0.367009 4.97431C0.145618 5.00437 -0.025 5.19413 -0.025 5.42378C-0.025 5.67428 0.17804 5.87737 0.42852 5.87737H4.54531L4.54645 9.99506H4.54639L4.54651 9.99674L4.55045 10.0549L4.55039 10.0549L4.55062 10.0566C4.58068 10.278 4.77047 10.4486 5.00009 10.4486C5.25057 10.4486 5.45355 10.2454 5.45349 9.99487L5.45235 5.87737H9.57148V5.87743L9.57315 5.87732L9.63131 5.87343L9.63131 5.87349L9.63301 5.87326C9.85435 5.8432 10.025 5.65344 10.025 5.42378C10.025 5.17329 9.82195 4.9702 9.57148 4.9702H5.45234L5.45166 0.852083H5.45172L5.45161 0.850396L5.44766 0.792239Z"
                fill="#6B6F7A"
                stroke="#6B6F7A"
                strokeWidth="0.05"
              />
            </g>
            <defs>
              <clipPath id="clip0_2136_13246">
                <rect fill="white" height="10" transform="translate(0 0.423584)" width="10" />
              </clipPath>
            </defs>
          </svg>
          <span>Add Section</span>
        </button>
      </div>
      <ConfirmationModal
        close={handleCloseAddModal}
        confirmButtonText="Save"
        confirmFunction={handleAddSection}
        context={
          <div className="flex justify-center">
            <input
              className="border-0 border-b border-solid border-[#dcdee3] bg-[#f6f7f8] text-[#0f2642] text-15 font-normal focus:border-[#3390ff] focus:shadow-none focus:text-[#0f2642] placeholder:text-[#bcbec0] w-full"
              data-testid="input-section-name"
              onChange={(event) => {
                setInputValue(event.target.value)
              }}
              placeholder="Section Name"
              type="text"
            />
          </div>
        }
        isDisabled={isConfirmButtonDisabled}
        isOpen={isOpenAddSectionModal}
        title="Add Section"
      />
    </>
  )
}
export default AddSection
