import { useContext } from "react"
import { withRouter } from "react-router"
import { Redirect } from "react-router-dom"
import DashboardTop from "./DashboardTop"
import { UserDataContext } from "../../../hooks/UserDataHook"
import Batches from "./Batches"
import { useIsInsidePowerPoint } from "@/context/IsInsidePowerPointContext"
import PowerPointDashboard from "./PowerPointDashboard"

const DashboardContainer = ({ history, ...rest }) => {
  const { user } = useContext(UserDataContext)
  const { group } = user
  const isInsidePowerPoint = useIsInsidePowerPoint()
  if (group.groupId === "management") {
    return <Redirect to="/presentations" />
  }

  return (
    <div id="dashboard">
      {isInsidePowerPoint ? (
        <PowerPointDashboard />
      ) : (
        <>
          <DashboardTop />
          <Batches push={history.push} rest={rest} />
        </>
      )}
    </div>
  )
}

export default withRouter(DashboardContainer)
