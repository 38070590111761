import { useEffect, useState } from "react"
import { LoadingPlaceholder } from "@/components/utils/placeholders"
import { useIsInsidePowerPoint } from "@/context/IsInsidePowerPointContext"

const OfficeReady = ({ children }: { children: NodeList }) => {
  const [loading, setLoading] = useState(true)
  const isInsidePowerPoint = useIsInsidePowerPoint()

  useEffect(() => {
    const initializeOffice = async () => {
      await new Promise((resolve) => {
        Office.onReady(() => {
          resolve("Ready")
        })
      })
      setLoading(false)
    }

    if (isInsidePowerPoint) {
      initializeOffice().catch((error) => {
        console.error("Error initializing Office:", error)
        setLoading(false)
      })
    }
  }, [isInsidePowerPoint])

  if (isInsidePowerPoint && loading) {
    return <LoadingPlaceholder title="" />
  }

  return children
}

export default OfficeReady
