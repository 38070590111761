import PricingContainer from "../pricing/PricingContainer"
import PaymentMethodComponent from "../pricing/PaymentMethodComponent"
import { Modal } from "./Modal"
import PaymentFinish from "../pricing/PaypalFinish"

const CurrentComponent = ({ component, paymentStage }) => {
  switch (component) {
    case "pricing":
      return (
        <div className="pricing-top">
          <h1>
            Unleash your team <strong>productivity&nbsp;today</strong>
          </h1>
          <p className="top">All our plans include unlimited slide&nbsp;downloads</p>
          <PricingContainer />
        </div>
      )
    case "payment":
    case "update_card":
      return <PaymentMethodComponent type={component} />
    case "payresponse":
      return <PaymentFinish stage={paymentStage} />
    default:
      return null
  }
}

const PricingModal = ({ modalOpened, modalComponent, selectedPlan, paymentStage, closeModal }) => {
  return (
    <div>
      <Modal
        className="fade modal show modal-center-vh in h-full"
        isOpened={modalOpened}
        overlayClassName="sc-modal-bg"
        portalClassName="sc-modal"
      >
        <div
          className={`${
            ["payment", "payresponse", "update_card"].includes(modalComponent)
              ? "pricing-checkout"
              : "pricing-modal-plans"
          } modal-dialog`}
        >
          <div className="modal-content relative">
            <div className="modal-body">
              {modalOpened && (
                <button
                  aria-label="Close"
                  className="close right-[8px]"
                  data-dismiss="modal"
                  onClick={closeModal}
                  type="button"
                >
                  <span aria-hidden="true">×</span>
                </button>
              )}
              {modalOpened && (
                <>
                  {modalComponent === "payment" && (
                    <div className="modal-header flex items-center justify-between">
                      <div className="checkout-plan-name">
                        <h2>{selectedPlan ? selectedPlan.name : ""}</h2>
                      </div>
                      <div className="checkout-plan-price">
                        {selectedPlan && (
                          <h3>
                            ${selectedPlan.amount / 100}
                            <span>{`/ ${selectedPlan.interval}`}</span>
                          </h3>
                        )}
                      </div>
                    </div>
                  )}
                  <div className={modalComponent === "payresponse" ? "modal-body paypal_payment" : "modal-body"}>
                    <CurrentComponent component={modalComponent} paymentStage={paymentStage} />
                  </div>
                  {modalComponent === "payment" && (
                    <div className="modal-footer">
                      <p>Secure credit card payment</p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default PricingModal
