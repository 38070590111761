export const Notification = ({ type, children }) => {
  if (!type) return null

  return (
    <div className="notifications-wrapper">
      <div className="notifications-tr">
        <div className={`notification notification-${type} notification-visible`}>{children}</div>
      </div>
    </div>
  )
}

const NotificationContent = (props) => {
  const { title, message } = props
  return (
    <div className="profile-notif-content">
      <div className="profile-notif-content-ico">{props.children}</div>
      <div className="profile-notif-content-text">
        <h2>{title}</h2>
        <p>{message}</p>
      </div>
    </div>
  )
}

export const NotificationSuccess = (props) => (
  <NotificationContent {...props}>
    <svg version="1.1" viewBox="-470 271 19 19" x="0px" y="0px">
      <path
        d="M-460.5,271c-5.2,0-9.5,4.3-9.5,9.5s4.3,9.5,9.5,9.5s9.5-4.3,9.5-9.5S-455.3,271-460.5,271z
      M-456.3,278l-4.9,5.9c-0.1,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.4-0.1-0.6-0.3l-2.4-3c-0.2-0.2-0.2-0.7,0.2-1c0.3-0.2,0.8-0.2,1,0.1
      l1.9,2.3l4.3-5.2c0.3-0.3,0.7-0.4,1.1-0.1C-456,277.2-456,277.7-456.3,278z"
        fill={"#3390FF"}
      />
    </svg>
  </NotificationContent>
)

export const NotificationError = (props) => (
  <NotificationContent {...props}>
    <svg id="Layer_1" version="1.1" viewBox="0 0 38.3 38.3" x="0px" y="0px">
      <circle cx="19.2" cy="19.2" fill={"#ED1E79"} r="19.2" />
      <path
        d="M27.4,28.1c-0.2,0-0.4-0.1-0.5-0.2L10.4,11.5c-0.3-0.3-0.3-0.8,0-1.1s0.8-0.3,1.1,0l16.4,16.4
          c0.3,0.3,0.3,0.8,0,1.1C27.8,28.1,27.6,28.1,27.4,28.1z"
        fill={"#FFFFFF"}
      />
      <path
        d="M10.9,28.1c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1l16.4-16.4c0.3-0.3,0.8-0.3,1.1,0
          s0.3,0.8,0,1.1L11.5,27.9C11.3,28.1,11.1,28.1,10.9,28.1z"
        fill={"#FFFFFF"}
      />
    </svg>
  </NotificationContent>
)
