import { useContext } from "react"
import { UserDataContext } from "@/hooks/UserDataHook"

const CartModalQuestion = ({ closeModal, changeModalContent }) => {
  const { updateSlidesInCartFunction } = useContext(UserDataContext)

  const handleClick = async () => {
    await updateSlidesInCartFunction([])
    closeModal()
  }

  return (
    <div className="sc-modal-content">
      <div className="sc-modal-header">
        <h1>Save presentation</h1>
        <button className="close-modal" onClick={closeModal}>
          <svg version="1.1" viewBox="0 0 14 14" x="0px" y="0px">
            <path
              className="close-icon-fill"
              d="M7.7,7l6.1-6.1c0.2-0.2,0.2-0.5,0-0.7s-0.5-0.2-0.7,0L7,6.3L0.9,0.1C0.7,0,0.3,0,0.1,0.1
            S0,0.7,0.1,0.9L6.3,7l-6.1,6.1c-0.2,0.2-0.2,0.5,0,0.7C0.2,14,0.4,14,0.5,14s0.3,0,0.4-0.1L7,7.7l6.1,6.1c0.1,0.1,0.2,0.1,0.4,0.1
            s0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7L7.7,7z"
            />
          </svg>
        </button>
      </div>
      <h2>Do you want to save your presentation before closing?</h2>
      <div className="sc-modal-buttons">
        <button className="btn-gray-blue" onClick={handleClick}>
          No
        </button>{" "}
        <button className="btn-dark-blue" onClick={changeModalContent}>
          Save
        </button>
      </div>
    </div>
  )
}

export default CartModalQuestion
