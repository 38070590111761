import { useMutation, gql } from "@apollo/client"
import { useContext } from "react"
import { MainModalContext } from "../../hooks/MainModalHook"
import { Batch } from "../../graphql/types/queries"
import { Refetch } from "./types"

interface DeleteAllSlidesProps {
  batch: Batch
  refetch: Refetch
}

const REMOVE_ALL_SLIDES = gql`
  mutation removeAllSlides($id: String!) {
    removeAllSlides(id: $id) {
      code
      success
    }
  }
`

const DeleteAllSlides = ({ batch, refetch }: DeleteAllSlidesProps) => {
  const { closeModal } = useContext(MainModalContext)

  const [removeAllSlides] = useMutation(REMOVE_ALL_SLIDES, { context: { isUsingNewScApi: true } })

  const handleRemove = async () => {
    await removeAllSlides({ variables: { id: batch.batchId } })
    // // TODO: BU: replece refetch() with data (update:) state (delete deleted presentation from state/query cache)
    // // https://www.apollographql.com/docs/react/caching/cache-interaction/
    refetch()
    closeModal()
  }

  return (
    <div className="sc-modal-content">
      <div className="sc-modal-header">
        <h1>Delete all slides</h1>
      </div>
      <h2>
        Are you sure you want to delete all slides from the <span>{batch.name}</span>?
      </h2>
      <div className="sc-modal-buttons">
        <button className="btn-gray-blue" onClick={closeModal} type="button">
          Cancel
        </button>
        <button className="btn-dark-blue" onClick={handleRemove} type="button">
          Delete
        </button>
      </div>
    </div>
  )
}

export default DeleteAllSlides
