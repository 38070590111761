export const ResetPasswordSvgIcon = () => (
  <svg version="1.1" viewBox="0 0 272 180.2">
    <g>
      <g>
        <path d="M213.8,25.8H125v103.3h48.4h40.3h40.3v-63C254.1,43.9,236,25.8,213.8,25.8z" fill={"#B3DDFF"} />
        <path
          d="M254.1,130.1H125c-0.6,0-1-0.4-1-1V25.8c0-0.6,0.4-1,1-1h88.7c22.8,0,41.3,18.5,41.3,41.3v63
                C255.1,129.7,254.7,130.1,254.1,130.1z M126,128.1h127.1v-62c0-21.7-17.6-39.3-39.3-39.3H126V128.1z"
          fill={"#3390FF"}
        />
      </g>
      <g>
        <path d="M84.4,66.2v63h80.7v-63c0-22.3-18.1-40.3-40.3-40.3" fill={"#FFFFFF"} />
        <path
          d="M165.1,130.1H84.4c-0.6,0-1-0.4-1-1v-63c0-0.6,0.4-1,1-1s1,0.4,1,1v62h78.7v-62
                c0-21.7-17.6-39.3-39.3-39.3c-0.6,0-1-0.4-1-1s0.4-1,1-1c22.8,0,41.3,18.5,41.3,41.3v63C166.1,129.7,165.6,130.1,165.1,130.1z"
          fill={"#3390FF"}
        />
      </g>
      <path
        d="M145.1,32.5c-11.3,6.9-18.8,19.4-18.8,33.6v62H164v-62C164,51.9,156.4,39.4,145.1,32.5z"
        fill={"#B3DDFF"}
        opacity={"0.5"}
      />
      <g>
        <path d="M254,129.1H154v11.8H254c3.3,0,5.9-2.6,5.9-5.9C259.9,131.8,257.3,129.1,254,129.1z" fill={"#D4EAFF"} />
        <path
          d="M254,141.9H154c-0.6,0-1-0.4-1-1v-11.8c0-0.6,0.4-1,1-1H254c3.8,0,6.9,3.1,6.9,6.9
                C260.9,138.8,257.8,141.9,254,141.9z M155,139.9H254c2.7,0,4.9-2.2,4.9-4.9c0-2.7-2.2-4.9-4.9-4.9H155V139.9z"
          fill={"#3390FF"}
        />
      </g>
      <path
        d="M242.4,69.4c-1.3,0-2.4-1.1-2.4-2.4c0-14.3-11.6-25.9-25.9-25.9c-1.3,0-2.4-1.1-2.4-2.4c0-1.3,1.1-2.4,2.4-2.4
            c16.9,0,30.7,13.8,30.7,30.7C244.8,68.4,243.7,69.4,242.4,69.4z"
        fill={"#3390FF"}
        opacity={"0.2"}
      />
      <path
        d="M242.4,84.3c-1.3,0-2.4-1.1-2.4-2.4v-8.6c0-1.3,1.1-2.4,2.4-2.4c1.3,0,2.4,1.1,2.4,2.4v8.6
            C244.8,83.3,243.7,84.3,242.4,84.3z"
        fill={"#3390FF"}
        opacity={"0.2"}
      />
      <g>
        <path d="M228.1,1h-2.1c-2.4,0-4.3,1.9-4.3,4.3v50h6.4V1z" fill={"#FFFFFF"} />
        <path
          d="M228.1,56.3h-6.4c-0.6,0-1-0.4-1-1v-50c0-2.9,2.4-5.3,5.3-5.3h2.1c0.6,0,1,0.4,1,1v54.3
                C229.1,55.9,228.6,56.3,228.1,56.3z M222.6,54.3h4.4V2h-1.1c-1.8,0-3.3,1.5-3.3,3.3V54.3z"
          fill={"#3390FF"}
        />
      </g>
      <g>
        <circle cx="224.9" cy="55.3" fill={"#FFFFFF"} r="5.9" />
        <path
          d="M224.9,62.3c-3.8,0-6.9-3.1-6.9-6.9s3.1-6.9,6.9-6.9c3.8,0,6.9,3.1,6.9,6.9
                S228.7,62.3,224.9,62.3z M224.9,50.4c-2.7,0-4.9,2.2-4.9,4.9s2.2,4.9,4.9,4.9s4.9-2.2,4.9-4.9S227.6,50.4,224.9,50.4z"
          fill={"#3390FF"}
        />
      </g>
      <g>
        <polyline fill={"#FFFFFF"} points="234.1,1 228.2,1 228.2,26.7 271,26.7 263,13.9 271,1 258.5,1 		" />
        <path
          d="M271,27.7h-42.8c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1h5.9c0.6,0,1,0.4,1,1s-0.4,1-1,1h-4.9
                v23.7h40l-7-11.3c-0.2-0.3-0.2-0.7,0-1.1l7-11.3h-10.7c-0.6,0-1-0.4-1-1s0.4-1,1-1H271c0.4,0,0.7,0.2,0.9,0.5c0.2,0.3,0.2,0.7,0,1
                l-7.6,12.3l7.6,12.3c0.2,0.3,0.2,0.7,0,1C271.7,27.5,271.4,27.7,271,27.7z"
          fill={"#3390FF"}
        />
      </g>
      <circle cx="224.9" cy="55.3" fill={"#3390FF"} r="3.1" />
      <g>
        <path d="M221.6,5.3v21.4h6.4V1h-2.1C223.6,1,221.6,2.9,221.6,5.3z" fill={"#A6D7FF"} />
        <path
          d="M228.1,27.7h-6.4c-0.6,0-1-0.4-1-1V5.3c0-2.9,2.4-5.3,5.3-5.3h2.1c0.6,0,1,0.4,1,1v25.7
                C229.1,27.3,228.6,27.7,228.1,27.7z M222.6,25.7h4.4V2h-1.1c-1.8,0-3.3,1.5-3.3,3.3V25.7z"
          fill={"#3390FF"}
        />
      </g>
      <path
        d="M224.8,47c-0.6,0-1-0.4-1-1V6.4c0-0.6,0.4-1,1-1s1,0.4,1,1V46C225.8,46.5,225.4,47,224.8,47z"
        fill={"#3390FF"}
        opacity={"0.2"}
      />
      <path
        d="M170.8,140.9h-19.6v13.5h0c0,0.2,0,0.3,0,0.5c0,5.4,4.4,9.8,9.8,9.8c5.4,0,9.8-4.4,9.8-9.8c0-0.2,0-0.3,0-0.5
            h0.1V140.9z"
        fill={"#3390FF"}
        opacity={"0.2"}
      />
      <path
        d="M185,140.6h-14.2v9.8h0c0,0.1,0,0.2,0,0.3c0,3.9,3.2,7.1,7.1,7.1c3.9,0,7.1-3.2,7.1-7.1c0-0.1,0-0.2,0-0.3h0.1
            V140.6z"
        fill={"#3390FF"}
        opacity={"0.2"}
      />
      <g>
        <path
          d="M185,167.9c-0.6,0-1-0.4-1-1v-1.5c0-0.6,0.4-1,1-1s1,0.4,1,1v1.5
                C186,167.5,185.6,167.9,185,167.9z"
          fill={"#3390FF"}
        />
        <path
          d="M185,174.6c-0.6,0-1-0.4-1-1v-2.5c0-0.6,0.4-1,1-1s1,0.4,1,1v2.5
                C186,174.1,185.6,174.6,185,174.6z"
          fill={"#3390FF"}
        />
        <path
          d="M185,180.2c-0.6,0-1-0.4-1-1v-1.5c0-0.6,0.4-1,1-1s1,0.4,1,1v1.5
                C186,179.8,185.6,180.2,185,180.2z"
          fill={"#3390FF"}
        />
      </g>
      <path
        d="M170.7,167.7c-0.6,0-1-0.4-1-1v-25.8c0-0.6,0.4-1,1-1H185c0.6,0,1,0.4,1,1v24.5
            c0,0.6-0.4,1-1,1s-1-0.4-1-1v-23.5h-12.3v24.8C171.7,167.2,171.3,167.7,170.7,167.7z"
        fill={"#3390FF"}
      />
      <path
        d="M170.7,169.3c-0.6,0-1-0.4-1-1v-26.4h-17.5v7.5c0,0.6-0.4,1-1,1s-1-0.4-1-1v-8.5
            c0-0.6,0.4-1,1-1h19.5c0.6,0,1,0.4,1,1v27.4C171.7,168.8,171.3,169.3,170.7,169.3z"
        fill={"#3390FF"}
      />
      <path
        d="M151.2,176.5c-0.6,0-1-0.4-1-1v-3c0-0.6,0.4-1,1-1s1,0.4,1,1v3
            C152.2,176,151.7,176.5,151.2,176.5z M151.2,168.5c-0.6,0-1-0.4-1-1v-3c0-0.6,0.4-1,1-1s1,0.4,1,1v3
            C152.2,168,151.7,168.5,151.2,168.5z M151.2,160.5c-0.6,0-1-0.4-1-1v-3c0-0.6,0.4-1,1-1s1,0.4,1,1v3
            C152.2,160,151.7,160.5,151.2,160.5z M151.2,152.5c-0.6,0-1-0.4-1-1v-3c0-0.6,0.4-1,1-1s1,0.4,1,1v3
            C152.2,152,151.7,152.5,151.2,152.5z"
        fill={"#3390FF"}
      />
      <rect fill={"#3390FF"} height="5.8" opacity={"0.2"} width="33.8" x="151.2" y="140.9" />
      <path d="M258.5,2h-24.4c-0.6,0-1-0.4-1-1s0.4-1,1-1h24.4c0.6,0,1,0.4,1,1S259.1,2,258.5,2z" fill={"#3390FF"} />
      <g>
        <path d="M125,25.8c-22.3,0-40.3,18.1-40.3,40.3" fill={"#FFFFFF"} />
        <path
          d="M84.7,67.2c-0.6,0-1-0.4-1-1c0-22.8,18.5-41.3,41.3-41.3c0.6,0,1,0.4,1,1s-0.4,1-1,1
                c-21.7,0-39.3,17.6-39.3,39.3C85.7,66.7,85.3,67.2,84.7,67.2z"
          fill={"#3390FF"}
        />
      </g>
      <g>
        <path
          d="M245.3,14.9c0,0.8-0.3,1.5-0.9,2c-0.6,0.5-1.4,0.7-2.3,0.7c-0.6,0-1.3-0.1-1.9-0.3c-0.7-0.2-1.3-0.5-1.8-1
                l1-1.5c0.9,0.7,1.8,1,2.8,1c0.3,0,0.5-0.1,0.7-0.2c0.2-0.1,0.3-0.3,0.3-0.5c0-0.2-0.1-0.4-0.4-0.5c-0.2-0.2-0.6-0.3-1.1-0.5
                c-0.5-0.1-0.8-0.3-1.1-0.4c-0.2-0.1-0.5-0.3-0.8-0.4c-0.6-0.4-0.9-1-0.9-1.7c0-0.8,0.3-1.4,0.9-1.9c0.6-0.5,1.4-0.7,2.4-0.7
                s2,0.3,2.9,1l-0.9,1.5c-0.7-0.5-1.4-0.7-2.2-0.7c-0.7,0-1.1,0.2-1.1,0.6c0,0.2,0.1,0.4,0.3,0.5c0.2,0.1,0.6,0.3,1.1,0.4
                c0.5,0.2,0.9,0.3,1.1,0.4c0.2,0.1,0.5,0.2,0.8,0.4C245.1,13.5,245.3,14.1,245.3,14.9z"
          fill={"#B3DDFF"}
        />
        <path
          d="M250.7,15.6c0.8,0,1.5-0.4,2.1-1.2l1.4,1.5c-1.1,1.1-2.2,1.7-3.5,1.7c-1.2,0-2.3-0.4-3.2-1.2
                c-0.9-0.8-1.3-1.8-1.3-3.1c0-1.3,0.4-2.3,1.3-3.1c0.9-0.8,1.9-1.2,3.1-1.2c0.6,0,1.2,0.1,1.8,0.4c0.6,0.2,1.2,0.6,1.6,1.1
                l-1.2,1.6c-0.3-0.3-0.6-0.6-1-0.7c-0.4-0.2-0.8-0.3-1.2-0.3c-0.6,0-1.1,0.2-1.6,0.6c-0.4,0.4-0.7,0.9-0.7,1.7
                c0,0.7,0.2,1.3,0.7,1.6C249.6,15.4,250.1,15.6,250.7,15.6z"
          fill={"#B3DDFF"}
        />
      </g>
      <g>
        <rect fill={"#D4EAFF"} height="11.8" width="25.1" x="139.9" y="129.1" />
        <path
          d="M165.1,141.9h-25.1c-0.6,0-1-0.4-1-1v-11.8c0-0.6,0.4-1,1-1h25.1c0.6,0,1,0.4,1,1v11.8
                C166.1,141.5,165.6,141.9,165.1,141.9z M140.9,139.9h23.1v-9.8h-23.1V139.9z"
          fill={"#3390FF"}
        />
      </g>
      <g>
        <path d="M62.4,129.1h84.4v6c0,3.2-2.6,5.8-5.8,5.8H68.2c-3.2,0-5.8-2.6-5.8-5.8V129.1z" fill={"#FFFFFF"} />
        <path
          d="M141,141.9H68.2c-3.8,0-6.8-3.1-6.8-6.8v-6c0-0.6,0.4-1,1-1h84.4c0.6,0,1,0.4,1,1v6
                C147.8,138.8,144.7,141.9,141,141.9z M63.4,130.1v5c0,2.6,2.2,4.8,4.8,4.8H141c2.6,0,4.8-2.2,4.8-4.8v-5H63.4z"
          fill={"#3390FF"}
        />
      </g>
      <path
        d="M156.3,42.9c-4.9,6.6-7.8,14.7-7.8,23.5v61.7h15.7V66.4C164.1,57.6,161.2,49.4,156.3,42.9z"
        fill={"#A6D7FF"}
      />
      <g>
        <path d="M1,52.3l40.5-43c1.6-2.2,4.7-2.8,6.9-1.2l52.7,26.7L59.7,93.3L1,52.3z" fill={"#D4EAFF"} />
        <path
          d="M59.7,94.3c-0.2,0-0.4-0.1-0.6-0.2l-58.7-41C0.2,52.9,0,52.7,0,52.4c0-0.3,0.1-0.6,0.3-0.8
                L40.7,8.7c1.9-2.6,5.5-3.2,8.2-1.4l52.6,26.7c0.3,0.1,0.4,0.4,0.5,0.7c0.1,0.3,0,0.6-0.2,0.8L60.5,93.9
                C60.3,94.1,60,94.3,59.7,94.3z M2.5,52.1l57,39.8l40.1-56.7L48,9c0,0-0.1,0-0.1-0.1c-1.8-1.3-4.2-0.8-5.5,1c0,0-0.1,0.1-0.1,0.1
                L2.5,52.1z"
          fill={"#3390FF"}
        />
      </g>
      <g>
        <rect
          fill={"#A6D7FF"}
          height="69.5"
          transform="matrix(0.9851 -0.172 0.172 0.9851 -9.3142 10.2831)"
          width="87.3"
          x="11.1"
          y="24.2"
        />
        <path
          d="M17.7,101.6c-0.5,0-0.9-0.3-1-0.8L4.7,32.3c0-0.3,0-0.5,0.2-0.7c0.2-0.2,0.4-0.4,0.6-0.4
                l86-15c0.3,0,0.5,0,0.7,0.2c0.2,0.2,0.4,0.4,0.4,0.6l11.9,68.5c0.1,0.5-0.3,1.1-0.8,1.2l-86,15C17.8,101.6,17.7,101.6,17.7,101.6z
                M6.9,33l11.6,66.5l84-14.7L90.9,18.3L6.9,33z"
          fill={"#3390FF"}
        />
      </g>
      <g>
        <rect
          fill={"#FFFFFF"}
          height="79.8"
          transform="matrix(0.9851 -0.172 0.172 0.9851 -8.4535 10.0548)"
          width="71.6"
          x="18"
          y="13.9"
        />
        <path
          d="M25.4,100.3c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.2-0.4-0.4-0.4-0.6L10.7,20.8
                c-0.1-0.5,0.3-1.1,0.8-1.2L82,7.4c0.5-0.1,1.1,0.3,1.2,0.8l13.7,78.6c0.1,0.5-0.3,1.1-0.8,1.2l-70.5,12.3
                C25.5,100.3,25.5,100.3,25.4,100.3z M12.8,21.5l13.4,76.6l68.6-12L81.4,9.5L12.8,21.5z"
          fill={"#3390FF"}
        />
      </g>
      <g>
        <g opacity={"0.5"}>
          <rect
            fill={"#D4EAFF"}
            height="13.7"
            transform="matrix(0.9851 -0.172 0.172 0.9851 -10.3514 6.7365)"
            width="16.6"
            x="25.4"
            y="56.3"
          />

          <rect
            fill={"#D4EAFF"}
            height="13.7"
            transform="matrix(0.9851 -0.172 0.172 0.9851 -8.4575 13.8776)"
            width="16.6"
            x="67.6"
            y="48.9"
          />

          <rect
            fill={"#D4EAFF"}
            height="13.7"
            transform="matrix(0.9851 -0.172 0.172 0.9851 -9.4044 10.307)"
            width="16.6"
            x="46.5"
            y="52.6"
          />
        </g>
        <g opacity={"0.5"}>
          <rect
            fill={"#D4EAFF"}
            height="13.7"
            transform="matrix(0.9851 -0.172 0.172 0.9851 -13.3469 7.5309)"
            width="16.6"
            x="28.5"
            y="74"
          />

          <rect
            fill={"#D4EAFF"}
            height="13.7"
            transform="matrix(0.9851 -0.172 0.172 0.9851 -11.453 14.672)"
            width="16.6"
            x="70.6"
            y="66.6"
          />

          <rect
            fill={"#D4EAFF"}
            height="13.7"
            transform="matrix(0.9851 -0.172 0.172 0.9851 -12.3999 11.1015)"
            width="16.6"
            x="49.6"
            y="70.3"
          />
        </g>
      </g>
      <g>
        <polygon fill={"#D4EAFF"} points="114.8,110.8 14.3,128.4 1,52.3 57.9,81.8 101.5,34.7 		" />
        <path
          d="M14.3,129.4c-0.5,0-0.9-0.3-1-0.8L0,52.4c-0.1-0.4,0.1-0.8,0.4-1c0.3-0.2,0.7-0.3,1.1-0.1
                l56.3,29.2l43-46.5c0.3-0.3,0.7-0.4,1-0.3c0.4,0.1,0.6,0.4,0.7,0.8l13.3,76.1c0.1,0.5-0.3,1.1-0.8,1.2L14.5,129.4
                C14.4,129.4,14.3,129.4,14.3,129.4z M2.3,54.1l12.8,73.1l98.5-17.2l-12.8-73.1L58.7,82.5c-0.3,0.3-0.8,0.4-1.2,0.2L2.3,54.1z"
          fill={"#3390FF"}
        />
      </g>
      <g>
        <path d="M14.3,128.4l38.5-54.7c1.6-2.2,4.7-2.8,6.9-1.2l54.7,38.5L14.3,128.4z" fill={"#FFFFFF"} />
        <path
          d="M14.3,129.4c-0.3,0-0.7-0.2-0.8-0.5c-0.2-0.3-0.2-0.8,0-1.1l38.5-54.7
                c1.9-2.7,5.6-3.3,8.3-1.4l54.7,38.5c0.3,0.2,0.5,0.6,0.4,1c-0.1,0.4-0.4,0.7-0.8,0.8L14.5,129.4C14.4,129.4,14.3,129.4,14.3,129.4
                z M56.8,72.6c-1.2,0-2.5,0.6-3.2,1.7L16.5,127l95.3-16.6L59.1,73.3C58.4,72.8,57.6,72.6,56.8,72.6z"
          fill={"#3390FF"}
        />
      </g>
      <path
        d="M16.7,114c-0.5,0.1-1.1-0.3-1.2-0.8l-0.4-2.4c-0.1-0.5,0.3-1.1,0.8-1.2c0.5-0.1,1.1,0.3,1.2,0.8l0.4,2.4
            C17.6,113.4,17.2,113.9,16.7,114z"
        fill={"#A6D7FF"}
      />
      <path
        d="M15.1,104.6c-0.5,0.1-1.1-0.3-1.2-0.8l-0.4-2.4c-0.1-0.5,0.3-1.1,0.8-1.2c0.5-0.1,1.1,0.3,1.2,0.8l0.4,2.4
            C16,104,15.6,104.5,15.1,104.6z"
        fill={"#A6D7FF"}
      />
      <path
        d="M13.4,95.2c-0.5,0.1-1.1-0.3-1.2-0.8l-0.4-2.4c-0.1-0.5,0.3-1.1,0.8-1.2c0.5-0.1,1.1,0.3,1.2,0.8l0.4,2.4
            C14.3,94.6,14,95.1,13.4,95.2z"
        fill={"#A6D7FF"}
      />
      <path
        d="M11.8,85.8c-0.5,0.1-1.1-0.3-1.2-0.8l-0.4-2.4c-0.1-0.5,0.3-1.1,0.8-1.2c0.5-0.1,1.1,0.3,1.2,0.8l0.4,2.4
            C12.7,85.2,12.3,85.7,11.8,85.8z"
        fill={"#A6D7FF"}
      />
      <path
        d="M10.1,76.4c-0.5,0.1-1.1-0.3-1.2-0.8l-0.4-2.4c-0.1-0.5,0.3-1.1,0.8-1.2c0.5-0.1,1.1,0.3,1.2,0.8l0.4,2.4
            C11,75.8,10.7,76.3,10.1,76.4z"
        fill={"#A6D7FF"}
      />
      <path
        d="M8.5,67c-0.5,0.1-1.1-0.3-1.2-0.8l-0.4-2.4c-0.1-0.5,0.3-1.1,0.8-1.2s1.1,0.3,1.2,0.8l0.4,2.4
            C9.4,66.4,9,66.9,8.5,67z"
        fill={"#A6D7FF"}
      />
      <path
        d="M107.4,98.2c-0.5,0.1-1.1-0.3-1.2-0.8l-0.4-2.4c-0.1-0.5,0.3-1.1,0.8-1.2c0.5-0.1,1.1,0.3,1.2,0.8l0.4,2.4
            C108.3,97.6,108,98.1,107.4,98.2z"
        fill={"#A6D7FF"}
      />
      <path
        d="M105.8,88.8c-0.5,0.1-1.1-0.3-1.2-0.8l-0.4-2.4c-0.1-0.5,0.3-1.1,0.8-1.2c0.5-0.1,1.1,0.3,1.2,0.8l0.4,2.4
            C106.7,88.2,106.3,88.7,105.8,88.8z"
        fill={"#A6D7FF"}
      />
      <path
        d="M104.1,79.4c-0.5,0.1-1.1-0.3-1.2-0.8l-0.4-2.4c-0.1-0.5,0.3-1.1,0.8-1.2c0.5-0.1,1.1,0.3,1.2,0.8l0.4,2.4
            C105,78.8,104.7,79.3,104.1,79.4z"
        fill={"#A6D7FF"}
      />
      <path
        d="M102.5,70c-0.5,0.1-1.1-0.3-1.2-0.8l-0.4-2.4c-0.1-0.5,0.3-1.1,0.8-1.2c0.5-0.1,1.1,0.3,1.2,0.8l0.4,2.4
            C103.4,69.4,103,69.9,102.5,70z"
        fill={"#A6D7FF"}
      />
      <path
        d="M100.8,60.6c-0.5,0.1-1.1-0.3-1.2-0.8l-0.4-2.4c-0.1-0.5,0.3-1.1,0.8-1.2c0.5-0.1,1.1,0.3,1.2,0.8l0.4,2.4
            C101.7,60,101.4,60.5,100.8,60.6z"
        fill={"#A6D7FF"}
      />
      <path
        d="M99.2,51.2c-0.5,0.1-1.1-0.3-1.2-0.8L97.6,48c-0.1-0.5,0.3-1.1,0.8-1.2c0.5-0.1,1.1,0.3,1.2,0.8L100,50
            C100.1,50.5,99.7,51.1,99.2,51.2z"
        fill={"#A6D7FF"}
      />
      <rect
        fill={"#3390FF"}
        height="3.9"
        opacity={"0.2"}
        transform="matrix(0.9851 -0.172 0.172 0.9851 -19.2868 12.7944)"
        width="102"
        x="13.2"
        y="115.8"
      />
      <polygon fill={"#3390FF"} opacity={"0.2"} points="14.8,128.6 13.4,123.4 46.5,75.3 49.8,77.6 	" />
      <polygon fill={"#3390FF"} opacity={"0.2"} points="114.7,111.1 114.3,105.8 66.8,71.8 64.5,75.1 	" />
      <g>
        <circle cx="31.5" cy="40.3" fill={"#3390FF"} r="2" />
        <circle cx="70.2" cy="33.6" fill={"#3390FF"} r="2" />
      </g>
      <g>
        <path d="M56.2,39.4c-0.8,1.7-2.3,2.9-4.2,3.3c-2,0.4-4-0.4-5.3-1.8" fill={"#FFFFFF"} />
        <path
          d="M50.9,43.7c-1.9,0-3.7-0.8-5-2.2c-0.4-0.4-0.3-1,0.1-1.4c0.4-0.4,1-0.3,1.4,0.1
                c1.1,1.2,2.7,1.8,4.4,1.5c1.5-0.3,2.9-1.3,3.5-2.7c0.2-0.5,0.8-0.7,1.3-0.5c0.5,0.2,0.7,0.8,0.5,1.3c-0.9,2-2.8,3.4-5,3.8
                C51.7,43.7,51.3,43.7,50.9,43.7z"
          fill={"#3390FF"}
        />
      </g>
      <rect
        fill={"#D4EAFF"}
        height="5.6"
        opacity={"0.5"}
        transform="matrix(0.9851 -0.172 0.172 0.9851 -3.1616 8.6363)"
        width="59.2"
        x="18.6"
        y="19.7"
      />
      <polygon fill={"#3390FF"} opacity={"0.2"} points="3.7,49.4 52.3,74.2 49.8,77.6 1,52.3 	" />
      <polygon fill={"#3390FF"} opacity={"0.2"} points="97.8,32.7 60.6,72.6 64.1,75 101.3,34.5 	" />
      <g>
        <path
          d="M126.9,80.2h-9.6c-0.6,0-1-0.4-1-1s0.4-1,1-1h9.6c0.6,0,1,0.4,1,1S127.4,80.2,126.9,80.2z"
          fill={"#3390FF"}
        />
        <g>
          <path
            d="M141.4,72.3h-1.5c-0.6,0-1-0.4-1-1s0.4-1,1-1h1.5c0.6,0,1,0.4,1,1S142,72.3,141.4,72.3z"
            fill={"#3390FF"}
          />
          <path
            d="M148.3,72.3h-2.6c-0.6,0-1-0.4-1-1s0.4-1,1-1h2.6c0.6,0,1,0.4,1,1S148.8,72.3,148.3,72.3z"
            fill={"#3390FF"}
          />
          <path
            d="M154,72.3h-1.5c-0.6,0-1-0.4-1-1s0.4-1,1-1h1.5c0.6,0,1,0.4,1,1S154.6,72.3,154,72.3z"
            fill={"#3390FF"}
          />
        </g>
        <path
          d="M139.9,72.3h-24c-0.6,0-1-0.4-1-1s0.4-1,1-1h24c0.6,0,1,0.4,1,1S140.5,72.3,139.9,72.3z"
          fill={"#3390FF"}
        />
        <g>
          <path
            d="M128.4,80.2h-1.5c-0.6,0-1-0.4-1-1s0.4-1,1-1h1.5c0.6,0,1,0.4,1,1S128.9,80.2,128.4,80.2z"
            fill={"#3390FF"}
          />
          <path
            d="M136.2,80.2h-2.9c-0.6,0-1-0.4-1-1s0.4-1,1-1h2.9c0.6,0,1,0.4,1,1S136.7,80.2,136.2,80.2z"
            fill={"#3390FF"}
          />
          <path
            d="M142.5,80.2H141c-0.6,0-1-0.4-1-1s0.4-1,1-1h1.5c0.6,0,1,0.4,1,1S143.1,80.2,142.5,80.2z"
            fill={"#3390FF"}
          />
        </g>
        <g>
          <path
            d="M115.9,64.4h-1.5c-0.6,0-1-0.4-1-1s0.4-1,1-1h1.5c0.6,0,1,0.4,1,1S116.5,64.4,115.9,64.4z"
            fill={"#3390FF"}
          />
          <path
            d="M123.1,64.4h-2.7c-0.6,0-1-0.4-1-1s0.4-1,1-1h2.7c0.6,0,1,0.4,1,1S123.7,64.4,123.1,64.4z"
            fill={"#3390FF"}
          />
          <path
            d="M129.2,64.4h-1.5c-0.6,0-1-0.4-1-1s0.4-1,1-1h1.5c0.6,0,1,0.4,1,1S129.7,64.4,129.2,64.4z"
            fill={"#3390FF"}
          />
        </g>
        <g>
          <path d="M121.7,96h-1.5c-0.6,0-1-0.4-1-1s0.4-1,1-1h1.5c0.6,0,1,0.4,1,1S122.2,96,121.7,96z" fill={"#3390FF"} />
          <path d="M128.9,96h-2.7c-0.6,0-1-0.4-1-1s0.4-1,1-1h2.7c0.6,0,1,0.4,1,1S129.4,96,128.9,96z" fill={"#3390FF"} />
          <path d="M134.9,96h-1.5c-0.6,0-1-0.4-1-1s0.4-1,1-1h1.5c0.6,0,1,0.4,1,1S135.4,96,134.9,96z" fill={"#3390FF"} />
        </g>
        <path
          d="M156.9,88.1h-38.1c-0.6,0-1-0.4-1-1s0.4-1,1-1h38.1c0.6,0,1,0.4,1,1S157.4,88.1,156.9,88.1z"
          fill={"#3390FF"}
        />
      </g>
    </g>
  </svg>
)
