import { gql, useMutation } from "@apollo/client"
import { useContext } from "react"
import { removeUserFromUnit } from "../../graphql/mutations"
import { MainModalContext } from "../../hooks/MainModalHook"

const UNIT_DATA = gql`
  query unit($id: String!) {
    unit(id: $id) {
      _id
      name
      description
      members {
        _id
        firstName
        lastName
        email
      }
    }
  }
`

const RemoveUserFromUnit = ({ unitId, unitMember }) => {
  const { _id, firstName, lastName, email } = unitMember
  const { closeModal } = useContext(MainModalContext)
  const [_removeUserFromUnit] = useMutation(removeUserFromUnit)

  const removeUser = () => {
    _removeUserFromUnit({
      variables: {
        memberId: _id,
        unitId
      },
      context: { isUsingNewScApi: true },
      update: (cache) => {
        const existData = cache.readQuery({
          query: UNIT_DATA,
          variables: { id: unitId }
        })

        cache.writeQuery({
          query: UNIT_DATA,
          data: {
            unit: {
              ...existData.unit,
              members: existData.unit.members.filter((member) => member._id !== _id)
            }
          },
          variables: { id: unitId }
        })
      }
    }).then(() => {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "scRemoveMemberFromTeam",
        removedTeamMember: { teamId: unitId, userId: _id }
      })
    })
    closeModal()
  }

  const initials = `${firstName ? firstName[0] : ""}${lastName ? lastName[0] : ""}`
  const nameInModal = firstName ? `${firstName} ${lastName}` : email

  return (
    <div className="sc-modal-member-user-remove">
      <div className="profile-initials-border">
        <div className="profile-initials">{initials}</div>
      </div>
      <h2>
        Are you sure you want to remove <span>{nameInModal}</span> from your team?
      </h2>
      <div className="profile-buttons-ctr">
        <button className="btn btn-gray-blue" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn btn-dark-blue" onClick={removeUser}>
          Confirm
        </button>
      </div>
    </div>
  )
}

export default RemoveUserFromUnit
