import { gql, useMutation } from "@apollo/client"
import React, { Fragment, useEffect, useState } from "react"
import { Ellipsis } from "../utils/tooltip"
import { Menu, Transition } from "@headlessui/react"

const SET_BATCH_SHARING = gql`
  mutation setBatchSharing($batchId: String!, $removedUnitIds: [String!] = []) {
    removedBatchSharing: setBatchSharing(unitIds: $removedUnitIds, batchId: $batchId, state: false) {
      code
      success
      message
    }
  }
`

const TeamPresentation = ({ batchId, thumbUrl, name, unitId, refetch, type, icon }) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false)
  const [_setBatchSharing] = useMutation(SET_BATCH_SHARING)

  const handleUnshare = () => {
    _setBatchSharing({
      variables: {
        batchId,
        removedUnitIds: [unitId]
      },
      context: { isUsingNewScApi: true },
      onCompleted: () => {
        refetch()
      }
    }).then(() => {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "scUnshareTeamPresentation",
        batch: {
          batchId,
          teamId: unitId
        }
      })
    })
  }

  return (
    <div
      className={`relative group bg-white rounded-[3px] aspect-[190/201] ${
        type === "collection"
          ? "transition-shadow duration-200 ease-in-out text-[#829FC0] shadow-[0_0_0_1px] hover:text-sc-blue hover:!shadow-[0_0_0_2px] hover:!shadow-sc-blue shadow-sc-line"
          : ""
      }`}
    >
      {type === "collection" && (
        <div className={"h-full flex flex-col pt-[10px]"}>
          <div className="h-[49.5%] flex justify-center items-end">
            <div className="w-[25.6%] aspect-[46/51] flex" data-testid="collection-icon">
              <img
                alt={icon}
                className="absolute group-hover:opacity-0"
                src={`/icons/collectionIcons/regular/${icon}.svg`}
              />
              <img
                alt={icon}
                className="absolute opacity-0 group-hover:opacity-100"
                src={`/icons/collectionIcons/blue/${icon}.svg`}
              />
            </div>
          </div>
          <div className="h-[50.5%] p-[9.9%] w-full text-center font-bold text-[#0E2642] text-[15px]/[20px]">
            <h2
              className="h-full max-h-[2.9em] line-clamp-2 mobile-sm:max-h-[2.9em] mobile-sm:line-clamp-2 min-[1170px]:max-desktop-lg:max-h-[4em] min-[1170px]:max-desktop-lg:line-clamp-3 min-[1570px]:max-desktop-2xl:max-h-[4em] min-[1570px]:max-desktop-2xl:line-clamp-3 min-[1770px]:max-desktop-3xl:max-h-[4em] min-[1770px]:max-desktop-3xl:line-clamp-3 desktop-big:max-h-[4em] desktop-big:line-clamp-3"
              data-testid="collection-name"
            >
              <Ellipsis length={32} text={name} tooltip />
            </h2>
          </div>
        </div>
      )}

      {type === "presentation" && (
        <div className="grid cursor-default gap-[17px]">
          <div className="h-full relative border border-sc-line">
            <div
              className={`opacity-0 border-2 transition ease-in-out group-hover:opacity-100 absolute top-0 w-full h-full text-center border-transparent bg-sc-overlay-light duration-700" ${
                isDropdownOpened ? "opacity-100" : ""
              }`}
            ></div>
            <picture>
              <img
                alt="thumb"
                className="basic__img"
                src={thumbUrl && thumbUrl.replace("{width}", 278)}
                srcSet={`${thumbUrl && thumbUrl.replace("{width}", 556)} 2x`}
              />
            </picture>
          </div>
          <div className={"flex-1 flex flex-col pr-[5.5%] font-bold text-sc-text-dark gap-[7px]"}>
            <div className="flex gap-[14px]">
              <span data-testid="presentation-name">
                <Ellipsis length={36} text={name} tooltip />
              </span>
            </div>
          </div>
        </div>
      )}

      <Menu
        as="div"
        className={`group-hover:visible w-[32px] h-[24px] absolute top-[10px] right-[4px] flex items-center justify-center px-[8px] py-[4px] ${
          isDropdownOpened ? "visible" : "invisible"
        } ${type === "presentation" ? "bg-white rounded-[50px]" : ""}`}
      >
        {({ open }) => {
          useEffect(() => {
            setIsDropdownOpened(open)
          }, [open])

          return (
            <>
              <Menu.Button
                className="flex justify-end text-[#6B6F7A] hover:text-scblue p-[10px]"
                data-testid="category-menu-button"
                id="contextMenu"
              >
                <svg fill="currentColor" height="12" viewBox="0 0 3 12" width="3" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.622559 6C0.622559 6.60406 1.11225 7.09375 1.71631 7.09375C2.32037 7.09375 2.81006 6.60406 2.81006 6C2.81006 5.39594 2.32037 4.90625 1.71631 4.90625C1.11225 4.90625 0.622559 5.39594 0.622559 6Z"
                    stroke="#6B6F7A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.21875"
                  />
                  <path
                    d="M0.622559 1.625C0.622559 2.22906 1.11225 2.71875 1.71631 2.71875C2.32037 2.71875 2.81006 2.22906 2.81006 1.625C2.81006 1.02094 2.32037 0.53125 1.71631 0.53125C1.11225 0.53125 0.622559 1.02094 0.622559 1.625Z"
                    stroke="#6B6F7A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.21875"
                  />
                  <path
                    d="M0.622559 10.375C0.622559 10.9791 1.11225 11.4687 1.71631 11.4687C2.32037 11.4687 2.81006 10.9791 2.81006 10.375C2.81006 9.77094 2.32037 9.28125 1.71631 9.28125C1.11225 9.28125 0.622559 9.77094 0.622559 10.375Z"
                    stroke="#6B6F7A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.21875"
                  />
                </svg>
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className="absolute top-0 z-20 w-56 origin-top-right rounded-md bg-white shadow-lg ring-gray-300 focus:outline-none p-1 left-[23px]"
                  id="contextMenuItems"
                >
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        className={`block px-4 py-2 text-sm cursor-pointer hover:!bg-gray-100 hover:!text-sc-blue ${
                          active ? "bg-gray-100 !text-gray-500" : "text-gray-500"
                        }`}
                        onClick={handleUnshare}
                      >
                        Unshare
                      </a>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </>
          )
        }}
      </Menu>
    </div>
  )
}

export default TeamPresentation
