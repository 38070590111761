export const reorderArr = (arr, fromIndex, toIndex) =>
  fromIndex > toIndex
    ? arr
        .slice(0, toIndex)
        .concat([arr[fromIndex]])
        .concat(arr.slice(toIndex, fromIndex))
        .concat(arr.slice(fromIndex + 1))
    : arr
        .slice(0, fromIndex)
        .concat(arr.slice(fromIndex + 1, toIndex))
        .concat([arr[fromIndex]])
        .concat(arr.slice(toIndex))

export const isRequired = (value) => (value ? false : "This field is required")

export const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
export const delimiterRegex = /[:,\n;\t]/

export const emailValidator = (value) => (emailRegex.test(value) ? false : "Invalid email address")

export const isHash = (value) => (/(^[0-9A-F]{6}$)|(^[0-9A-F]{3}$)/i.test(value) ? false : "Invalid hash")

export const preloadImage = (url) =>
  new Promise((resolve, reject) => {
    const img = new Image()
    img.addEventListener("load", () => {
      resolve()
    })
    img.addEventListener("error", (e) => {
      reject(e)
    })
    img.src = url
  })

export const getUrlParameter = (location, name) => {
  const results = new RegExp(
    // eslint-disable-next-line no-useless-escape
    "[\\?&]" + name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]") + "=([^&#]*)"
  ).exec(location)
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "))
}

const hexToRgb = (hex) => {
  const bigint = parseInt(hex, 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255
  return [r, g, b]
}

export const brightness = (s) => {
  const rgb = hexToRgb(s)
  return Math.round((parseInt(rgb[0]) * 299 + parseInt(rgb[1]) * 587 + parseInt(rgb[2]) * 114) / 1000)
}
