import { useMutation, gql, useLazyQuery } from "@apollo/client"
import { useContext, useState, useEffect, useRef, MouseEvent } from "react"
import PresentationForm from "../utils/upload/PresentationForm"
import { GET_JOB } from "@/graphql/queries"
import ExpandedAreaContainer from "./ExpandedAreaContainer"
import { Modal } from "../modals/Modal"
import { PresentationProps } from "./types"
import CollectionPresentation from "./presentationTypes/CollectionPresentation"
import ThumbnailPresentation from "./presentationTypes/ThumbnailPresentation"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { UserDataContext } from "@/hooks/UserDataHook"
import { fragments } from "@/graphql/fragments"
import { Batch } from "@/graphql/types/queries"
import { useParams } from "react-router-dom"

const GET_PRESENTATION = gql`
  query presentation($id: String!) {
    presentation(id: $id) {
      id
      thumbUrl
      state
      slides {
        state
        thumbUrl
      }
    }
  }
`

export const BATCH_DATA = gql`
  query getBatches($batchesLimit: Int, $unstable: Float) {
    session(unstable: $unstable) {
      user {
        myBatches(limit: $batchesLimit) {
          id
        }
      }
    }
  }
`

const REMOVE_PRESENTATION = gql`
  mutation removePresentation($id: String!) {
    removePresentation(id: $id) {
      code
      success
      message
      presentation {
        _id
        id
        name
        icon
        mode
        thumbUrl
        progress
        batchId
        deletedAt
      }
    }
  }
`

const GET_SLIDES_AND_LOCK = gql`
  query presentation($id: String!) {
    presentation(id: $id) {
      id
      slides {
        id
        thumbUrl
        state
      }
      lock
    }
  }
`

const setPresentationFavourite = gql`
  mutation setPresentationFavourite($presentationId: String!, $state: Boolean!) {
    setPresentationFavourite(presentationId: $presentationId, state: $state) {
      id
      presentationId
      isFavourite
    }
  }
`

const uploadStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 1)"
  },
  content: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: 0,
    background: "#fff",
    overflow: "hidden",
    WebkitOverflowScrolling: "touch",
    borderRadius: 0,
    outline: "none",
    padding: 0
  }
}

const Presentation = ({
  type,
  batch,
  active,
  id,
  isFavourite,
  name,
  hideTools,
  noActions,
  refetch,
  handleViewBatch,
  onFavouriteChange,
  setOpenedBatch,
  activeItem,
  isSearchPage
}: PresentationProps) => {
  const {
    user: { cart, isEditModeActive }
  } = useContext(UserDataContext)
  const rootNode = useRef<HTMLElement | null>()
  const [modalIsOpened, setModalIsOpened] = useState<boolean>(false)
  const [thumbUrl, setThumbUrl] = useState(batch.slides[0]?.thumbUrl ?? batch.thumbUrl)
  const [isReplacing, setIsReplacing] = useState(false)
  const [locked, setLocked] = useState(false)
  const [isEmpty, setIsEmpty] = useState(!batch.slides.length)
  const [offsetLeft, setOffsetLeft] = useState(0)
  const { slideId: slideIdParam } = useParams()

  const [_setPresentationFavourite] = useMutation(setPresentationFavourite, {
    context: { isUsingNewScApi: true }
  })
  const prevIsReplacing = useRef(isReplacing)
  const prevLockedRef = useRef(locked)
  const isTemplates = type === "templates"

  useEffect(() => {
    // stop pooling when the PowerPoint is closed and presentation stops being locked
    if (!isReplacing && prevLockedRef.current && !locked) {
      stopPoolingGetSlidesAndLock()
    }
    prevLockedRef.current = locked
  }, [locked])

  useEffect(() => {
    // stop pooling when the PowerPoint is closed and presentation was replaced
    if (!locked && prevIsReplacing.current && !isReplacing) {
      stopPoolingGetSlidesAndLock()
    }
    prevIsReplacing.current = isReplacing
  }, [isReplacing])

  useEffect(() => {
    const handleResize = () => {
      if (rootNode.current) {
        setOffsetLeft(rootNode.current?.offsetLeft)
      }
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    if (offsetLeft !== rootNode.current?.offsetLeft) {
      setOffsetLeft(rootNode.current?.offsetLeft)
    }
  }, [rootNode.current?.offsetLeft, cart])

  const [getSlidesAndLock, { stopPolling: stopPoolingGetSlidesAndLock }] = useLazyQuery(GET_SLIDES_AND_LOCK, {
    notifyOnNetworkStatusChange: true,
    context: { isUsingNewScApi: true },
    pollInterval: 500,
    fetchPolicy: "network-only",
    onCompleted: async ({ presentation }) => {
      const firstSlide = presentation.slides[0]
      setLocked(presentation.lock)

      if (presentation.lock === true && presentation.slides.length === 0) setIsReplacing(true)
      if (firstSlide?.thumbUrl && firstSlide?.state !== "locked") setThumbUrl(firstSlide.thumbUrl)

      // BU: because we delete all the slides before uploading new ones on replace, there will be no slides for a while
      // BU: after upload is completed, we need to refetch the batch to get the new slides when it shows up
      if (presentation.slides.length > 0 && firstSlide?.state !== "locked") {
        setIsReplacing(false)
        await refetch()
      }
    }
  })

  const [getJob, { stopPolling }] = useLazyQuery(GET_JOB, {
    notifyOnNetworkStatusChange: true,
    context: { isUsingNewScApi: true },
    pollInterval: 1500,
    fetchPolicy: "network-only",
    onCompleted: ({ job }) => {
      const { lifecycle } = job[0]

      if (lifecycle.status === "completed" || lifecycle.status === "failed") stopPolling()
    }
  })

  const [getPresentationData, { stopPolling: stopPresentationDataPolling }] = useLazyQuery(GET_PRESENTATION, {
    notifyOnNetworkStatusChange: true,
    context: { isUsingNewScApi: true },
    pollInterval: 1500,
    variables: { id: batch?.batchId },
    fetchPolicy: "network-only",
    onCompleted: ({ presentation }) => {
      const isEmpty = presentation.state === "uploaded" && !presentation.slides.length
      setIsEmpty(isEmpty)
      if (!presentation.slides?.length) {
        if (isEmpty) stopPresentationDataPolling()
        return
      }

      const firstSlide = presentation.slides[0]

      if (firstSlide.state === "uploaded") {
        stopPresentationDataPolling()
      }
      if (firstSlide.thumbUrl && firstSlide.state !== "locked") {
        refetch()
        setThumbUrl(firstSlide.thumbUrl)
      }
    }
  })

  const handleMergeJobStatus = (queueName: string, jobId: string) => {
    getJob({ variables: { queueName: queueName, jobIds: [jobId] } })
  }

  const [removePresentation] = useMutation(REMOVE_PRESENTATION, {
    context: { isUsingNewScApi: true }
  })

  const removePresentationAndClose = async () => {
    await removePresentation({ variables: { id: batch.batchId } })
    // TODO: BU: replece refetch() with data (update:) state (delete deleted presentation from state/query cache)
    // https://www.apollographql.com/docs/react/caching/cache-interaction/
    await refetch()
    // closeModal()
  }

  useEffect(() => {
    if (active && rootNode.current && !slideIdParam) {
      setOffsetLeft(rootNode.current?.offsetLeft)
      window.scrollTo(0, window.scrollY + rootNode.current.getBoundingClientRect().top - 30)
    }
  }, [active])

  useEffect(() => {
    // no need to run polling if presentation type is collection(templates)
    if (!isTemplates && type !== "favourites" && batch.slides[0]?.state !== "uploaded") getPresentationData()
  }, [batch.slides.length])

  useEffect(() => {
    // if presentation is uploaded and its first slide thumbnail is changed then change presentation thumbnail too
    if (
      !isTemplates &&
      type !== "favourites" &&
      batch.slides[0]?.thumbUrl !== thumbUrl &&
      batch.slides[0]?.state === "uploaded"
    )
      getPresentationData()
  }, [batch.slides[0]?.thumbUrl])

  useEffect(() => {
    if (activeItem && active) {
      handleClick()
    }
  }, [activeItem])

  const handleClick = () => {
    if (handleViewBatch) {
      handleViewBatch(batch.urlToken, batch.name, batch.slides)
    } else {
      setOpenedBatch(active ? null : batch) // This is only for pages that don't change the URL when opening a batch
    }
  }

  const handleLike = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    const { data } = await _setPresentationFavourite({
      variables: {
        presentationId: batch.batchId,
        state: !isFavourite
      },
      update: (cache) => {
        const favouriteBatch: Batch | null = cache.readFragment({
          id: batch.batchId,
          fragment: fragments.favouriteBatch
        })

        if (favouriteBatch && favouriteBatch.id) {
          const updated = { ...favouriteBatch, isFavourite: !isFavourite }
          cache.writeFragment({
            id: batch.batchId,
            fragment: fragments.favouriteBatch,
            data: updated
          })
        }

        const presentation: Batch | null = cache.readFragment({
          id: batch.batchId,
          fragment: fragments.presentationSmall
        })

        if (presentation && presentation.id) {
          const updated = { ...presentation, isFavourite: !isFavourite }
          cache.writeFragment({
            id: batch.batchId,
            fragment: fragments.presentationSmall,
            data: updated
          })
        }
      }
    })
    onFavouriteChange && onFavouriteChange(data.setPresentationFavourite.isFavourite)
  }

  const renderPresentation = () => {
    // show icon thumbnail instead of slide image thumbnail in case of collection(template) presentation
    const { attributes, listeners, setNodeRef, transform } = useSortable({
      id: batch.id,
      data: batch,
      disabled: !isEditModeActive || !batch.urlToken
    })

    const style = {
      position: activeItem && batch.id === activeItem.id ? "relative" : undefined,
      zIndex: activeItem && batch.id === activeItem.id ? "-1" : "1",
      transform: CSS.Transform.toString(transform),
      transition: activeItem ? "transform 500ms cubic-bezier(0.25, 1, 0.5, 1)" : undefined,
      border: activeItem && batch.id === activeItem.id ? "1px solid #D1D5DB" : ""
    }
    if (isTemplates) {
      return (
        <div
          className={`relative ${isSearchPage ? "" : "aspect-[181/192]"}`}
          data-testid="collection"
          ref={(node) => {
            rootNode.current = node
          }}
        >
          <div
            className={`${!isSearchPage && "h-full"} ${
              active && !activeItem
                ? "relative before:absolute after:absolute before:-bottom-[49px] after:-bottom-[47px] before:ml-[-15px] after:ml-[-15px] after:left-1/2 before:left-1/2 before:border-[15px] before:z-20 before:border-transparent after:border-transparent after:border-[15px] before:border-b-[#DCDDE2] after:border-b-sc-line"
                : ""
            }`}
            {...attributes}
            {...listeners}
            ref={setNodeRef}
            style={style}
          >
            <div
              className={`h-full ${activeItem && batch.id === activeItem.id ? "opacity-0" : "opacity-100"} ${
                !batch.urlToken ? "cursor-not-allowed" : "cursor-pointer"
              }`}
            >
              <CollectionPresentation
                active={active}
                activeItem={activeItem}
                batch={batch}
                handleClick={!batch.urlToken ? null : handleClick}
                isSearchPage={isSearchPage}
                refetch={refetch}
                type={type}
              />
            </div>
          </div>

          {active && (
            <ExpandedAreaContainer
              batch={batch}
              batchType={type}
              handleMergeJobStatus={handleMergeJobStatus}
              name={name}
              noActions={noActions}
              offsetLeft={offsetLeft}
              refetch={refetch}
              setThumbUrl={setThumbUrl}
            />
          )}
        </div>
      )
    } else {
      return (
        <div
          className={`${isSearchPage ? "h-fit" : "aspect-[490/357]"}`}
          data-testid="presentation"
          ref={(node) => {
            rootNode.current = node
          }}
        >
          <div
            className={`h-full ${
              active && !activeItem
                ? "relative before:absolute after:absolute before:-bottom-[49px] after:-bottom-[47px] before:ml-[-15px] after:ml-[-15px] after:left-1/2 before:left-1/2 before:border-[15px] before:z-20 before:border-transparent after:border-transparent after:border-[15px] before:border-b-[#DCDDE2] after:border-b-sc-line"
                : ""
            }`}
            {...(!isEditModeActive || !batch.urlToken ? {} : attributes)}
            {...(!isEditModeActive || !batch.urlToken ? {} : listeners)}
            ref={setNodeRef}
            style={style}
          >
            <div className="h-full" style={{ opacity: activeItem && batch.id === activeItem.id ? 0 : 1 }}>
              <ThumbnailPresentation
                active={active}
                batch={{ ...batch, thumbUrl: isReplacing ? null : thumbUrl }}
                getSlidesAndLock={getSlidesAndLock}
                handleClick={id !== "temp-id" ? handleClick : null}
                handleLike={handleLike}
                hideTools={hideTools}
                isEditModeActive={isEditModeActive}
                isEmpty={isEmpty}
                isFavourite={isFavourite}
                isSearchPage={isSearchPage}
                name={name}
                refetch={refetch}
                setModalIsOpened={setModalIsOpened}
                setThumbUrl={setThumbUrl}
                type={type}
              />
            </div>
          </div>
          {/* TODO: BU: this is the modal that is opened when you click on the presentation edit this should be refactored to use the default modal component (the edit category one) */}
          <Modal contentStyle={uploadStyle.content} isOpened={modalIsOpened} overlayStyle={uploadStyle.overlay}>
            {/* {renderModalContent()} */}
            <PresentationForm
              closeModal={async () => {
                await refetch()
                setModalIsOpened(false)
              }}
              id={batch.batchId}
              initialValues={{ name: batch.name, labels: batch.labels }}
            />
          </Modal>
          {active && (
            <ExpandedAreaContainer
              batch={batch}
              batchType={type}
              handleMergeJobStatus={handleMergeJobStatus}
              name={name}
              noActions={noActions}
              offsetLeft={offsetLeft}
              refetch={refetch}
              setThumbUrl={setThumbUrl}
            />
          )}
        </div>
      )
    }
  }

  // if (batch.progress === -1) {
  //   // This condition is old and outdated, but we will need this component in the future with some other condition probably
  //   return (
  //     <div className="image__cell">
  //       <div className="image--basic image--upload-bar">
  //         {/* FIX: BU: z brakiem handleRemove (ustawialo deletedAt na batchu, trzeba sie zastanowic co zrobic tutaj) */}
  //         {/* <button type="button" className="delete-pres" onClick={handleRemove}> */}
  //         <button className="delete-pres" onClick={removePresentationAndClose} type="button">
  //           <svg version="1.1" viewBox="0 0 11 11" x="0px" y="0px">
  //             <path
  //               d="M10.5,11c-0.1,0-0.3,0-0.4-0.1l-10-10C0,0.7,0,0.3,0.1,0.1s0.5-0.2,0.7,0l10,10
  //           c0.2,0.2,0.2,0.5,0,0.7C10.8,11,10.6,11,10.5,11z"
  //             />
  //             <path
  //               d="M0.5,11c-0.1,0-0.3,0-0.4-0.1c-0.2-0.2-0.2-0.5,0-0.7l10-10c0.2-0.2,0.5-0.2,0.7,0
  //           s0.2,0.5,0,0.7l-10,10C0.8,11,0.6,11,0.5,11z"
  //             />
  //           </svg>
  //         </button>
  //         <UploadErrorSvg />
  //         <h2>We weren&apos;t able to process your presentation.</h2>
  //         <p>Try adding the presentation again.</p>
  //       </div>
  //     </div>
  //   )
  // }
  return renderPresentation()
}

export default Presentation
